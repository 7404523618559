import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { mapper } from '../common/utils';

const FIELDS_MAP = {
  meta: ['meta', 'en-US'],
  name: ['name', 'en-US'],
  source: ['source', 'en-US'],
};

export const IframeWrapper = ({ section }) => {
  if (!section) return null;
  const values = mapper(section.fields, FIELDS_MAP);

  return (
    <div style={values.meta && values.meta.wrapperLayout}>
      <iframe
        src={values.source}
        style={values.meta && values.meta.iFrameLayout}
        frameBorder="0"
      />
    </div>
  );
};

IframeWrapper.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    meta: PropTypes.object,
  }).isRequired,
};

export const IFrameFields = graphql`
  fragment IFrameFields on ContentfulLayoutIFrame {
    contentful_id
    __typename
    name
    source
    meta {
      internal {
        content
      }
    }
  }
`;

IframeWrapper.fragment = IFrameFields;
