import React, { useState } from 'react';
import { Button, Column, Row, PeopleModal } from '@sygnia/components';
import { PeopleCardWrapper } from '../../modules/PeopleCardWrapper';
import { CONTENT_TYPES, CONTENT_TITLES } from '../../common/constants';
import { useModal, ModalWrapper } from '../../common/hooks';
import { mapper } from '../../common/utils';

export const MenuArchive = props => {
  const { isShowing, toggle } = useModal();
  const [info, setInfo] = useState(0);

  const MENU_MAPPING = {
    [CONTENT_TYPES.CONTENT_PEOPLE]: {
      component: PeopleCardWrapper,
      modal: {
        component: PeopleModal,
        map: {
          title: ['title'],
          subtext: ['role'],
          image: ['image', 'fluid', 'src'],
          content: ['content', 'childMarkdownRemark', 'html'],
          meta: ['qualifications', 'childMarkdownRemark', 'html'],
        },
      },
      options: {
        column: {
          width: [1, 1, 6 / 12, 4 / 12],
          flex: ['1 1 auto', '0 1 auto'],
        },
        card: {},
        section: index => {
          return {
            renderCta: (
              <Button
                onClick={() => {
                  toggle();
                  setInfo(index);
                }}
                variant="primary"
              >
                {CONTENT_TITLES.readMore}
              </Button>
            ),
          };
        },
      },
    },
  };

  const {
    section: { items },
  } = props;

  const getModal = () => {
    const item = items[info];
    if (!item) return null;
    const { modal } = MENU_MAPPING[item.__typename];

    if (!modal) return null;

    const { component: Component, map = {} } = modal;

    return (
      <ModalWrapper
        isShowing={isShowing}
        hide={toggle}
        goToNext={() => {
          if (info < items.length - 1) {
            setInfo(info + 1);
          } else {
            setInfo(0);
          }
        }}
        goToPrevious={() => {
          if (info > 0) {
            setInfo(info - 1);
          } else {
            setInfo(items.length - 1);
          }
        }}
        isMultiView
      >
        <Component {...mapper(item, modal.map || {})} />
      </ModalWrapper>
    );
  };

  return (
    <>
      <Row
        className="MenuArchive_Row"
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start']}
        flexWrap={'wrap'}
      >
        {items &&
          items.map((item, index) => {
            const { component: Component, options } = MENU_MAPPING[
              item.__typename
            ];
            return (
              <Column {...options.column} className="MenuArchive_Column">
                <Component
                  className="MenuArchive_Component"
                  section={{ ...item, ...options.section(index) }}
                  {...options.card}
                />
              </Column>
            );
          })}
      </Row>
      {getModal()}
    </>
  );
};
