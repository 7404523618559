import React, { useState } from 'react';
import { QNA } from '@sygnia/components';
import { graphql } from 'gatsby';
import { mapperWithFunction } from '../common/utils';
import { get } from 'lodash';
import convertToHTML from 'markdown-to-html-converter';

const CONTENT_MAP = {
  question: props => {
    if (props.isDynamic) {
      return get(props, ['qnA', 'fields', 'question']);
    } else {
      return get(props, ['qnA', 'question', 'childMarkdownRemark', 'html']);
    }
  },
  answer: props => {
    if (props.isDynamic) {
      return convertToHTML(get(props, ['qnA', 'fields', 'answer']));
    } else {
      return get(props, ['qnA', 'answer', 'childMarkdownRemark', 'html']);
    }
  },
  avatar: props => {
    if (props.isDynamic) {
      return get(props, ['qnA', 'fields', 'avatar', 'fields', 'file', 'url']);
    } else {
      return get(props, ['qnA', 'avatar', 'fluid', 'src']);
    }
  },
  isCollapsable: props => {
    return props.isDynamic ? false : get(props, ['isCollapsable']);
  },
  subtitle: props => {
    if (props.isDynamic) {
      return get(props, ['qnA', 'fields', 'name']);
    } else {
      return get(props, ['qnA', 'name']);
    }
  },
};

export const QNAWrapper = props => {
  const [isOpen, setOpen] = useState(false);
  const handleCollapseState = () => setOpen(!isOpen);

  const { section } = props;
  const mappedOptions = mapperWithFunction(section, CONTENT_MAP);

  return (
    <QNA isOpen={isOpen} onClick={handleCollapseState} {...mappedOptions} />
  );
};

QNAWrapper.defaultProps = {};

export const fragment = graphql`
  fragment QNALayoutFields on ContentfulLayoutQna {
    id
    isCollapsable
    qnA {
      name
      answer {
        childMarkdownRemark {
          html
        }
      }
      question {
        childMarkdownRemark {
          html
        }
      }
      avatar {
        fluid {
          src
        }
      }
    }
  }
`;

QNAWrapper.fragment = fragment;
