import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import { Column } from '../Grid';
import { theme } from '../common/theme';

const BlockQuoteWapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 0 auto;
`;

const StyledBlockQuote = styled.blockquote`
  font-family: ${theme.fonts.serif};
  letter-spacing: 0;
  @media (min-width: ${theme.breakpoints[1]}) {
    line-height: 50px;
  }
  text-align: center;
`;

const StyledImage = styled.img`
  max-width: 128px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 30px;
`;

const StyledCite = styled.span`
  font-family: ${theme.fonts.dmSans};
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;

const StyledRole = styled.span`
  font-family: ${theme.fonts.dmSans};
  font-size: 17px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;

const BlockQuote = ({ headline, image, cite, role, color }) => {
  return (
    <Column bg={color.bg} px={[2, 8]} py={[3, 13]} my={[0, 6]}>
      <BlockQuoteWapper width={[1, 7 / 12]} fontSize={[16, 42]}>
        <StyledBlockQuote color={color.headline}>{headline}</StyledBlockQuote>
        <StyledImage src={image} alt="" mb={[6, 8]} />
        <StyledCite color={color.cite}>{cite}</StyledCite>
        <StyledRole color={color.role}>{role}</StyledRole>
      </BlockQuoteWapper>
    </Column>
  );
};

export default BlockQuote;

BlockQuote.propTypes = {
  headline: PropTypes.string,
  image: PropTypes.string,
  role: PropTypes.string,
  cite: PropTypes.string,
  color: PropTypes.shape({
    bg: PropTypes.string,
    text: PropTypes.string,
    headline: PropTypes.string,
    role: PropTypes.string,
    cite: PropTypes.string,
  }),
};
