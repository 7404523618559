import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';
import contentfulClient from '../contentful/client';
import resolveResponse from 'contentful-resolve-response';
import { get } from 'lodash';
import Layout from './layout';
import { Box } from 'rebass';

function getTypename(item) {
  const contentType = get(item, ['sys', 'contentType', 'sys', 'id']);
  return `Contentful${contentType.charAt(0).toUpperCase()}${contentType.slice(
    1,
  )}`;
}

export const UrlObserverWrapper = ({ section: { queryParameter, style } }) => {
  const [queryParam] = useQueryParam(queryParameter, StringParam);
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (queryParam) {
      async function getEntry() {
        const res = await contentfulClient.get(`entries`, {
          params: { include: 10, 'sys.id': queryParam },
        });
        const resolved = resolveResponse(res.data);
        const typename = getTypename(resolved[0]);
        setItem({
          __typename: typename,
          isDynamic: true,
          ...resolved[0].fields,
        });
      }
      getEntry();
    }
  }, [queryParam]);

  return item ? (
    <Box style={style}>
      <Layout layout={[item]} />
    </Box>
  ) : null;
};

export const fragment = graphql`
  fragment UrlObserverFields on ContentfulUrlObserver {
    name
    queryParameter
    style {
      maxWidth
      margin
    }
  }
`;
