import React, { useState, useCallback, useRef, useEffect } from 'react';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import './style.css';
import { FinancialModel } from './FinancialModel';
import { FeesLabel, Container } from '@sygnia/components';

const createCircle = t => {
  return new Chartist.Svg(
    'circle',
    {
      cx: 0,
      cy: 0,
      r: ['8px'],
      fill: 'white',
      filter: 'url(#dotShadow)',
    },
    t,
  );
};

function pointOnLine(line, i, s, length) {
  var r = Math.floor((s + length) / 2), // half the length of path
    a = line.getPointAtLength(s), // point at 0
    o = line.getPointAtLength(r), // point at half the length
    l = line.getPointAtLength(length); // point at length

  return i === o.x
    ? o
    : length - 1 === s
    ? Math.abs(a.x - i) > Math.abs(l.x - i)
      ? l
      : a
    : i > o.x
    ? pointOnLine(line, i, r, length)
    : i < o.x
    ? pointOnLine(line, i, s, r)
    : void 0;
}
//w
function getCurrentPoint(width, line) {
  return pointOnLine(line, width, 0, Math.floor(line.getTotalLength()));
}

function eFunc(t) {
  return Math.max(Math.min(100 * t, 100), 0) / 100;
}

const displayActiveYear = (t, year) => {
  t.svg
    .querySelectorAll('.feesGraph__x--label')
    .removeClass('feesGraph__x--label--active');
  t.svg
    .querySelector('.feesGraph__x--label--' + year)
    .addClass('feesGraph__x--label--active');
};

const updateDescender = (t, year) => {
  const series = ['sygnia', 'traditional', 'bank'];
  const width = t.svg.width();

  const n = (t.svg.height(), t.svg.height(), year * width);

  const r = parseInt(t.svg.querySelector('.feesGraph__x-axis').attr('y2'));

  series.forEach(serie => {
    const line = t.svg
      .querySelector(`.ct-series--${serie}`)
      .querySelector('.ct-line');
    const pointCoords = getCurrentPoint(n, line.getNode());

    t.svg.querySelector(`.feesGraph__descender-marker--${serie}`).attr({
      cx: pointCoords.x,
      cy: pointCoords.y,
    });

    if (serie === 'sygnia') {
      t.svg.querySelector('.feesGraph__descender').attr({
        x1: n,
        x2: n,
        y1: r,
        y2: pointCoords.y,
      });
    }
  });
};

const getPercentHoverFromMousemove = (x, chart) => {
  var i = chart.width();
  return eFunc((x - chart.getNode().getBoundingClientRect().left) / i);
};

const getActiveYearFromPercentHover = (percent, max = 30) => {
  return Math.max(Math.min(Math.round(max * percent), max), 0);
};

const initChartInteractivity = (t, year) => {
  displayActiveYear(t, year);
  updateDescender(t, year / 30);
};

const FeesGraph = () => {
  const initialProps = {
    initialDeposit: 1000,
    monthlyContribution: 1e2,
    isRevealed: 1,
  };

  const model = new FinancialModel(
    initialProps.initialDeposit,
    initialProps.monthlyContribution,
    30,
  );
  const modelSeries = model.buildSeries(
    initialProps.initialDeposit,
    initialProps.monthlyContribution,
  );

  const overlayRef = useRef();
  const chartRef = useRef(null);

  const [activeYear, setActiveYear] = useState(15);

  const setYearCb = useCallback(year => {
    setActiveYear(year);
  }, []);

  let amounts = [];
  const handler = useCallback(
    ({ clientX }) => {
      if (chartRef.current) {
        const percent = getPercentHoverFromMousemove(
          clientX,
          chartRef.current.chartist.svg,
        );

        const year = getActiveYearFromPercentHover(percent);
        setYearCb(year);

        displayActiveYear(chartRef.current.chartist, year);
        updateDescender(chartRef.current.chartist, year / 30);

        amounts = [
          modelSeries.sygnia.getValueAtYear(year),
          modelSeries.traditional.getValueAtYear(year),
          modelSeries.bank.getValueAtYear(year),
        ];
      }
    },
    [amounts],
  );

  const formatGraphData = data => {
    const { xAxisLabels, ...model } = data;

    const series = Object.keys(model).map(serie => {
      return {
        name: serie,
        className: `ct-series--${serie}`,
        data: model[serie].series,
      };
    });

    return {
      labels: xAxisLabels,
      series,
    };
  };
  // Add event listener using our hook
  const options = {
    axisX: {
      showGrid: !1,
      showLabel: !0,
      labelInterpolationFnc: function(t) {
        return 0 === t ? 'Today' : 'Year' + ' ' + t;
      },
    },
    axisY: {
      low: 0,
      //high: 160,
      showGrid: !1,
      showLabel: !1,
      offset: 0,
      type: Chartist.FixedScaleAxis,
    },
    showArea: !0,
    showLine: !0,
    showPoint: !0,
    smoothLine: !0,
    fullWidth: !0,
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 15,
      left: 0,
    },
  };

  var type = 'Line';

  useEventListener('mousemove', handler, overlayRef.current);

  return (
    <Container>
      <div id="fees" className="feesGraph__container">
        <ChartistGraph
          ref={chartRef}
          className="feesGraph__svg"
          data={formatGraphData(modelSeries)}
          options={options}
          type={type}
          listener={{
            draw: function(t) {
              if ('label' === t.type) {
                var e = 'feesGraph__x--label feesGraph__x--label--' + t.index;
                t.element.attr({
                  class: e,
                });
              }
            },
            created: t => {
              t.svg.elem(
                'line',
                {
                  x1: t.chartRect.x1,
                  x2: t.chartRect.x2,
                  y1: t.chartRect.y1,
                  y2: t.chartRect.y1,
                },
                'feesGraph__x-axis',
              ); // drawAxis

              var e = t.svg.elem('defs');

              e
                .elem('linearGradient', {
                  id: 'sygniaGradient',
                  x1: 0,
                  y1: 1,
                  x2: 1,
                  y2: 0,
                })
                .elem('stop', {
                  offset: '0%',
                  'stop-color': '#F9F8F6',
                })
                .parent()
                .elem('stop', {
                  offset: '2%',
                  'stop-color': '#F9F8F6',
                })
                .parent()
                .elem('stop', {
                  offset: '10%',
                  'stop-color': '#f6ebd8',
                })
                .parent()
                .elem('stop', {
                  offset: '20%',
                  'stop-color': '#F1D3A5',
                })
                .parent()
                .elem('stop', {
                  offset: '30%',
                  'stop-color': '#EAB562',
                })
                .parent()
                .elem('stop', {
                  offset: '100%',
                  'stop-color': '#EAB562',
                }),
                e
                  .elem('linearGradient', {
                    id: 'traditionalGradient',
                    x1: 0,
                    y1: 0,
                    x2: 1,
                    y2: 0,
                  })
                  .elem('stop', {
                    offset: '0%',
                    'stop-color': '#F9F8F6',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '2%',
                    'stop-color': '#F9F8F6',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '20%',
                    'stop-color': '#f7eedf',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '30%',
                    'stop-color': '#F4E2C4',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '50%',
                    'stop-color': '#F0D09B',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '100%',
                    'stop-color': '#F0D09B',
                  }),
                e
                  .elem('linearGradient', {
                    id: 'bankGradient',
                    x1: 0,
                    y1: 0,
                    x2: 1,
                    y2: 0,
                  })
                  .elem('stop', {
                    offset: '0%',
                    'stop-color': '#F9F8F6',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '20%',
                    'stop-color': '#f8f3eb',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '30%',
                    'stop-color': '#F6EDDD',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '50%',
                    'stop-color': '#F4E4C9',
                  })
                  .parent()
                  .elem('stop', {
                    offset: '100%',
                    'stop-color': '#F4E4C9',
                  }),
                e
                  .elem('filter', {
                    id: 'dotShadow',
                    x: 0,
                    y: 0,
                    width: '300%',
                    height: '300%',
                  })
                  .elem('feOffset', {
                    result: 'offOut',
                    in: 'SourceAlpha',
                    dx: '3',
                    dy: '3',
                  })
                  .parent()
                  .elem('feColorMatrix', {
                    type: 'matrix',
                    result: 'matrixOut',
                    in: 'offOut',
                    values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0',
                  })
                  .parent()
                  .elem('feGaussianBlur', {
                    result: 'blurOut',
                    in: 'matrixOut',
                    stdDeviation: '2',
                  })
                  .parent()
                  .elem('feBlend', {
                    in: 'SourceGraphic',
                    in2: 'blurOut',
                    mode: 'normal',
                  });

              const oneS =
                (new Chartist.Svg('g', {}),
                createCircle(
                  'feesGraph__descender-marker feesGraph__descender-marker--sygnia',
                ));

              const twoS = createCircle(
                'feesGraph__descender-marker feesGraph__descender-marker--traditional',
              );
              const threeS = createCircle(
                'feesGraph__descender-marker feesGraph__descender-marker--bank',
              );
              const lineS = new Chartist.Svg('line', {
                class: 'feesGraph__descender',
                x: 0,
                y1: 0,
                y2: 600,
              });

              t.svg
                .elem('g')
                .append(lineS)
                .append(oneS)
                .append(twoS)
                .append(threeS);

              t.svg
                .elem('defs')
                .elem('clipPath', {
                  id: 'feesGraph__clip-path',
                })
                .elem('rect', {
                  class: 'feesGraph__clip-path__rect',
                  x: '-20',
                  y: '0',
                  height: '120%',
                });

              initChartInteractivity(t, activeYear);
            },
          }}
        />
        <div className="feesGraph__overlay" ref={overlayRef}></div>
        <div className="feesGraph__valueLabels">
          <FeesLabel
            color="brandYellowBright"
            labelText="Sygnia"
            value={modelSeries.sygnia
              .getValueAtYear(activeYear)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$& ')}
          />
          <FeesLabel
            color="brandYellowPop"
            labelText="Industry Avg"
            value={modelSeries.traditional
              .getValueAtYear(activeYear)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$& ')}
          />
          <FeesLabel
            color="brandYellowPale"
            labelText="Traditional"
            value={modelSeries.bank
              .getValueAtYear(activeYear)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$& ')}
          />
        </div>
      </div>
    </Container>
  );
};

function useEventListener(
  eventName,
  handler,
  element = typeof window !== 'undefined' ? window : null,
) {
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = event => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export default FeesGraph;
