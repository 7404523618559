import React from 'react';
import { Bio } from '@sygnia/components';
import { mapper } from '../common/utils';

const FIELDS_MAP = {
  fullname: ['fullname', 'en-US'],
  role: ['role', 'en-US'],
  image: ['image', 'en-US', 'fields', 'file', 'en-US', 'url'],
  description: ['description', 'en-US'],
};

const BioWrapper = ({ section }) => {
  if (!section) return null;
  const values = mapper(section.fields, FIELDS_MAP);
  return <Bio {...values} />;
};

export default BioWrapper;

BioWrapper.defaultProps = {
  section: {},
};
