import React from 'react';
import { IconText } from '@sygnia/components';

const IconTextWrapper = ({ section }) => {
  if (!section) return null;

  return <IconText icon={section.icon} text={section.label} />;
};

export default IconTextWrapper;
