import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from '../Grid';
import { theme } from '../common/theme';
import Breadcrumb from './Breadcrumb';

const StyledWrapper = styled.div`
  border-bottom: 1px solid ${theme.colors.baseGrayLight};
`;
const StyledBreadcrumbs = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Breadcrumbs = ({ items }) => {
  return (
    <StyledWrapper>
      <Container mx={0} py={[1, 2]}>
        <StyledBreadcrumbs>
          {items.map((link, index, arr) => (
            <Breadcrumb
              {...link}
              key={index}
              isLast={arr.length - 1 === index ? true : false}
              isFirst={index === 0 ? true : false}
            />
          ))}
        </StyledBreadcrumbs>
      </Container>
    </StyledWrapper>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  /** Breadcrumb Items */
  items: PropTypes.array.isRequired,
};

Breadcrumbs.defaultProps = {
  items: null,
};
