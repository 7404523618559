import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Text } from 'rebass';
import { theme } from '../common/theme';

const Circle = styled(Box)`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color ease 0.3s;
  z-index: 1;
`;

const Content = styled(Text)`
  position: absolute;
  top: 50px;
  text-transform: uppercase;
  width: 110px;
  cursor: default;
`;

export const CircleItem = ({ id, activeId, content, color, setActive }) => (
  <React.Fragment>
    <Circle
      data-testid={`selector-${id}-${activeId}`}
      onClick={() => setActive(id)}
      bg={activeId === id ? color : 'baseWhite'}
    />
    <Content
      fontSize="tiny"
      textAlign="center"
      fontWeight="bold"
      color={activeId === id ? 'baseBlack' : 'baseGray'}
    >
      {content}
    </Content>
  </React.Fragment>
);

CircleItem.defaultProps = {
  color: 'baseBlack',
};

CircleItem.propTypes = {
  activeId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  color: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
};
