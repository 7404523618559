import React from 'react';
import { SectionLogo, Container, Heading, Text } from '@sygnia/components';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { mapperWithFunction, setupLink } from '../common/utils';
import { graphql } from 'gatsby';

const CONTENT_MAP = {
  title: ['title'],
  description: value =>
    value?.description?.childMarkdownRemark?.html ||
    value?.description?.description ||
    null,
  modules: ['modules'],
};

const CARD_CONTENT_MAP = {
  altText: ['altText'],
  image: value => {
    return value.image?.file?.url || value.image?.fluid?.src || null;
  },
};

export const SectionLogoWrapper = ({ section }) => {
  const { title, description, modules } = mapperWithFunction(
    section,
    CONTENT_MAP,
  );
  const meta = section.meta ? JSON.parse(section.meta.internal.content) : null;

  return (
    <Box
      className="SectionLogoWraper_Box"
      py={[7, 7, 10]}
      width={[1]}
      flex="0 1 auto"
      mx={'auto'}
      bg={meta?.colors?.bg}
    >
      <Box width={[10 / 12, 6 / 12]} flex="0 1 auto" mx={'auto'}>
        {title.length > 0 && (
          <Heading
            size={'h1'}
            textAlign={'center'}
            mb={[3, 8]}
            color={meta?.colors?.title}
          >
            {title}
          </Heading>
        )}
        {description && (
          <Box mt={[1, 2]} mb={[2, 5]}>
            <Text
              size={'intro'}
              textAlign={'center'}
              html={description}
              color={meta?.colors?.text}
            />
          </Box>
        )}
      </Box>

      {modules && (
        <Container>
          <Flex
            flexDirection={['row']}
            alignItems={['center']}
            justifyContent={'center'}
            flexWrap={'wrap'}
          >
            {modules.map((item, index) => {
              const cardInfo = mapperWithFunction(item, CARD_CONTENT_MAP);
              const cardlink = item.link ? setupLink(item.link) : null;

              return (
                <Box
                  width={
                    meta?.layout?.logoWidth
                      ? meta.layout.logoWidth
                      : modules.length > 3
                      ? [3 / 12, 2 / 12]
                      : [3 / 12, 4 / 12]
                  }
                  flex={['0 0 auto']}
                >
                  <SectionLogo
                    key={`SectionLogo_${index}`}
                    {...cardInfo}
                    link={{ ...cardlink }}
                  />
                </Box>
              );
            })}
          </Flex>
        </Container>
      )}
    </Box>
  );
};

SectionLogoWrapper.defaultProps = {};
export const fragment = graphql`
  fragment SectionLogoLayoutFields on ContentfulLayoutSectionLogos {
    __typename
    id
    title
    description {
      childMarkdownRemark {
        html
      }
      description
    }
    modules {
      __typename
      ... on Node {
        ...SectionLogoFields
      }
    }
    meta {
      internal {
        content
      }
    }
  }
  fragment SectionLogoFields on ContentfulSectionLogo {
    id
    image {
      fluid {
        src
      }
    }
    altText
    link {
      title
      label
      type
      to
      external
      file {
        title
        file {
          fileName
          url
        }
      }
    }
  }
`;

SectionLogoWrapper.fragment = fragment;
