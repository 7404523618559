import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Table } from '@sygnia/components';
import { ProductPageContext } from '../templates/product-template';

const CompatibleFundTable = () => {
  const value = useContext(ProductPageContext.Consumer);

  if (!value) return null;

  return <FundTable funds={value.funds} />;
};

const FundTable = ({ funds }) => (
  <Table.Table>
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Fund</Table.Th>
        <Table.Th>Fund Class</Table.Th>
        <Table.Th>Risk Profile</Table.Th>
        <Table.Th>Management Fees (incl. VAT)</Table.Th>
      </Table.Tr>
    </Table.Thead>
    <tbody>
      {funds.map(fund => (
        <>
          <Table.Tr>
            <Table.Td>
              <Link to={`/fund/${fund.slug}`}>{fund.title}</Link>
            </Table.Td>
            <Table.Td>{fund.fundClass}</Table.Td>
            <Table.Td>{fund.riskProfile}</Table.Td>
            <Table.Td>{fund.managementFee}</Table.Td>
          </Table.Tr>
        </>
      ))}
    </tbody>
  </Table.Table>
);

export default CompatibleFundTable;
