import styled, { css } from 'styled-components';
import { theme } from '../common/theme';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${theme.space[1]}px;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  padding-bottom: ${theme.space[1]}px;
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.baseBlack};
  font-family: ${theme.fonts.dmSans};
  font-weight: ${theme.fontWeight.bold};
`;

export const StyledLabelPlaceholder = styled(StyledLabel)`
  font-size: ${theme.fontSizes.small};
  opacity: ${props =>
    props.focused ? theme.opacity.full : theme.opacity.none};
  transition: opacity ${theme.speed.slow} ease;
`;

export const baseStyles = css`
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  ${({ formType, focused }) =>
    formType === 'modern'
      ? css`
          border: none;
          border-bottom: 1px solid
            ${focused ? theme.colors.baseGrayDark : theme.colors.baseGrayDark};
        `
      : css`
          border: 1px solid ${theme.colors.baseGrayLight};
          ::placeholder {
            color: ${props => props.theme.colors.baseGrayDark};
          }
        `},
  padding: ${theme.space[1]}px ${theme.space[0]}px;
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.baseBlack};
  font-family: ${theme.fonts.dmSans};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;
