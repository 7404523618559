import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../common/theme';
import Text from '../Text/Text';
import { Flex } from 'rebass';
import styled from 'styled-components';

const StyledIconText = styled(Flex)`
  flex-direction: row;
  align-items: center;
  display: inline-flex;
`;

const StyledIcon = styled.i`
  font-size: ${theme.fontSizes.intro};
  margin-right: 8px;
`;

export const IconText = props => {
  const { text, icon } = props;

  return (
    <StyledIconText {...props} as="span">
      {icon && <StyledIcon className={`icon icon-${icon}`} />}

      {text && (
        <Text line-height="1" fontWeight={theme.fontWeight.bold}>
          {text}
        </Text>
      )}
    </StyledIconText>
  );
};

IconText.propTypes = {
  /** Name of the icon to render */
  icon: PropTypes.oneOf(Object.values(theme.icons)).isRequired,
  /** Text to display with the icon */
  text: PropTypes.string,
};

IconText.defaultProps = {
  text: null,
  icon: null,
  mt: [0],
  mb: [1],
};

export default IconText;
