import React from 'react';
import { CardBanner, CardPoster } from '@sygnia/components';
import LinkGroup from './LinkGroup';
import { mapper, getEmText } from '../common/utils';
import { graphql } from 'gatsby';

export const CARDBANNER_TYPES = {
  CENTER: 'Text Center',
  RIGHT: 'Text Right',
  LEFT: 'Text Left',
  CONTAINED_IMAGE_LEFT: 'Text Left Image Contained',
  CONTAINED_IMAGE_RIGHT: 'Text Right Image Contained',
  HALF_IMAGE_LEFT: 'Text Left Image Half',
  HALF_IMAGE_RIGHT: 'Text Right Image Half',
};

const CARDBANNER_MAPPING = {
  [CARDBANNER_TYPES.CENTER]: {
    component: CardBanner,
    options: {
      layout: 'center',
    },
  },
  [CARDBANNER_TYPES.RIGHT]: {
    component: CardBanner,
    options: {
      layout: 'right',
    },
  },
  [CARDBANNER_TYPES.LEFT]: {
    component: CardBanner,
    options: {
      layout: 'left',
    },
  },
  [CARDBANNER_TYPES.CONTAINED_IMAGE_LEFT]: {
    component: CardPoster,
    options: {
      layout: 'left',
    },
  },
  [CARDBANNER_TYPES.CONTAINED_IMAGE_RIGHT]: {
    component: CardPoster,
    options: {
      layout: 'right',
    },
  },
  [CARDBANNER_TYPES.HALF_IMAGE_RIGHT]: {
    component: CardBanner,
    options: {
      layout: 'rightHalf',
    },
  },
  [CARDBANNER_TYPES.HALF_IMAGE_LEFT]: {
    component: CardBanner,
    options: {
      layout: 'leftHalf',
    },
  },
};

const CONTENT_MAP = {
  text: ['content', 'childMarkdownRemark', 'html'],
  eyebrow: ['eyeBrow'],
  image: ['background', 'fluid', 'src'],
  style: ['style'],
};

export const CardBannerWrapper = props => {
  const { colorScheme, section } = props;

  let color = colorScheme;
  if (section.colorScheme) {
    color = section.colorScheme;
  }
  const mappedOptions = mapper(section, CONTENT_MAP);
  let component = CARDBANNER_MAPPING[CARDBANNER_TYPES.LEFT];

  if (section.layout) {
    component = CARDBANNER_MAPPING[section.layout];
  }

  const { component: CardBanner, options = {} } = component;

  const renderCta = section.links ? (
    <LinkGroup color={color} links={section.links || []} />
  ) : null;

  let title = <span>{section.title}</span>;
  if (
    section.style?.titleAnnotations &&
    section.style?.titleAnnotations.length
  ) {
    const [annotation] = section.style.titleAnnotations;
    title = getEmText(section.title, annotation.value, annotation.type);
  }
  const { ...restSection } = section;

  if (
    section.style?.eyebrowAnnotations &&
    section.style?.eyebrowAnnotations.length
  ) {
    const [annotation] = section.style.eyebrowAnnotations;
    const _eyebrow = getEmText(
      mappedOptions.eyebrow,
      annotation.value,
      annotation.type,
    );
    mappedOptions.eyebrow = _eyebrow;
  }

  return (
    <CardBanner
      {...restSection}
      title={title}
      {...options}
      {...mappedOptions}
      color={color}
      renderCta={renderCta}
      {...mappedOptions.style}
    />
  );
};

CardBannerWrapper.defaultProps = {
  section: null,
};

export const fragment = graphql`
  fragment CardBannerLayoutFields on ContentfulLayoutCardBanner {
    id
    title
    colorScheme
    layout
    eyeBrow
    background {
      fluid(quality: 100, maxWidth: 1800) {
        src
      }
    }
    content {
      childMarkdownRemark {
        html
      }
    }
    style {
      pt
      pb
      mb
      #borderBottom
      bg
      titleAnnotations {
        type
        value
        color
      }
      eyebrowAnnotations {
        value
      }
      internal {
        content
      }
    }
    links {
      ... on ContentfulLinks {
        __typename
        ...LinkFields
      }
      ... on ContentfulMenu {
        __typename
        ...LayoutMenuFields
      }
    }
  }
`;

CardBannerWrapper.fragment = fragment;
