import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';

import { theme } from '../common/theme';
import Text from '../Text/Text';

/**
 * The use of the ::after selector is used to prevent the 'unselected'
 * border from overlapping the primary border running along the bottom
 * of all the tabs.
 */
const StyledTab = styled(Box)`
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &::after {
    transition: border ${theme.speed.default} ease;
    z-index: -1;
    position: absolute;
    border: solid
      ${({ inactiveBorderColor }) => theme.colors[inactiveBorderColor]};
    border-width: 1px 1px 0 1px;
    height: 100%;
    width: calc(100% + 2px);
    content: '';
    top: -1px;
    left: -1px;
  }

  ${({ isActive, activeBorderColor }) =>
    isActive
      ? `
    background-color: white;

    &::after {
      border: solid ${theme.colors[activeBorderColor]};
      border-width 1px 1px 0 1px;
    }
  `
      : ''}
`;

const Tab = ({
  idx,
  name,
  isActive,
  setActiveTab,
  color,
  activeBorderColor,
  inactiveBorderColor,
}) => {
  /**
   * Function that will scroll a tab into view if it is off the side
   * of the current viewport. Also sets the active tab to the current
   * index.
   */
  const onClick = idx => {
    setActiveTab(idx);
    const elem = document.getElementById(`tab-${idx}`);
    const bounding = elem.getBoundingClientRect();

    if (
      bounding.right >
        (window.innerWidth || document.documentElement.clientWidth) ||
      bounding.left < 0
    ) {
      elem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  };

  return (
    <StyledTab
      id={`tab-${idx}`}
      data-testid={`tab-${idx}`}
      idx={idx}
      isActive={isActive}
      onClick={() => onClick(idx)}
      activeBorderColor={activeBorderColor}
      inactiveBorderColor={inactiveBorderColor}
      ml="1px"
      mb="-1px"
      mr={[1]}
      px={[2]}
      py={[1]}
    >
      <Text color={color} size="small" fontWeight="bold">
        {name}
      </Text>
    </StyledTab>
  );
};

Tab.propTypes = {
  /** Integer indicating the index of this tab */
  idx: PropTypes.number,
  /** The name to render for this tab */
  name: PropTypes.string,
  /** Whether the current tab is active or not */
  isActive: PropTypes.bool,
  /** Function to set the active tab index */
  setActiveTab: PropTypes.func,
  /** Defines the color of text in a tab */
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  /** Defines the color of the border of an active tab */
  activeBorderColor: PropTypes.oneOf(Object.keys(theme.colors)),
  /** Defines the color of the border of an active tab */
  inactiveBorderColor: PropTypes.oneOf(Object.keys(theme.colors)),
};

Tab.defaultProps = {
  idx: null,
  name: '',
  isActive: false,
  setActiveTab: null,
  color: 'baseBlack',
  activeBorderColor: 'baseBlack',
  inactiveBorderColor: 'baseGrayVeryLight',
};

export default Tab;
