export class FinancialSavingsModel {
  constructor(
    initialDeposit,
    monthlyContribution,
    assumedReturn,
    marginalTaxRate,
    timeHorizon,
  ) {
    this.initialDeposit = initialDeposit;
    this.monthlyContribution = monthlyContribution;
    this.assumedReturn = assumedReturn;
    this.marginalTaxRate = marginalTaxRate;
    this.afterTaxReturn = (1 - marginalTaxRate) * assumedReturn;
    this.timeHorizon = timeHorizon;
  }

  calculateReturn = (rate, timeHorizon) => {
    return [
      Math.pow(1 + rate / 12, timeHorizon * 12),
      Math.pow(1 + rate, timeHorizon),
    ];
  };

  buildSeries(timeHorizon) {
    const [a1, a2] = this.calculateReturn(this.assumedReturn, timeHorizon);

    const [b1, b2] = this.calculateReturn(this.afterTaxReturn, timeHorizon);

    const calcA =
      this.monthlyContribution * ((a1 - 1) / (this.assumedReturn / 12)) +
      this.initialDeposit * a2;

    const calcB =
      this.monthlyContribution * ((b1 - 1) / (this.afterTaxReturn / 12)) +
      this.initialDeposit * b2;

    return {
      labels: ['Sygnia TFSA', 'Ordinary investment account'],
      series: [[calcA, calcB]],
    };
  }
}
