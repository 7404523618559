import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';

import { TabItem, TabBorder } from '../TabItem';
import Heading from '../Heading/Heading';

//TODO: change text color if necessary
export const getColors = {
  black: {
    textColor: 'baseBlack',
    activeBorderColor: 'baseBlack',
  },
  yellow: {
    textColor: 'baseBlack',
    activeBorderColor: 'brandYellow',
  },
  red: {
    textColor: 'baseBlack',
    activeBorderColor: 'brandRed',
  },
  purple: {
    textColor: 'baseBlack',
    activeBorderColor: 'brandPurple',
  },
  violet: {
    textColor: 'baseBlack',
    activeBorderColor: 'brandViolet',
  },
  navy: {
    textColor: 'baseBlack',
    activeBorderColor: 'brandNavy',
  },
  green: {
    textColor: 'baseBlack',
    activeBorderColor: 'brandGreen',
  },
};

const Wrapper = styled(Flex)`
  overflow-x: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const TimedTabs = ({
  align,
  tabs,
  activeTab,
  setActiveTab,
  color,
  duration,
  isTimed,
}) => {
  const scrollTabIntoView = idx => {
    setActiveTab(idx);

    const elem = document.getElementById(`timed-tab-${idx}`);
    const bounding = elem.getBoundingClientRect();

    if (
      bounding.right >
        (window.innerWidth || document.documentElement.clientWidth) ||
      bounding.left < 0
    ) {
      elem.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'center',
      });
    }
  };

  if (isTimed) {
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        const nextTab = (activeTab + 1) % tabs.length;
        scrollTabIntoView(nextTab);
      }, durationInMs);
      return () => clearTimeout(timeout);
    }, [activeTab]);
  }

  const { textColor, activeBorderColor } = getColors[color] || getColors.black;

  const durationInMs = duration * 1000; // setTimeout works with milliseconds

  return (
    <Wrapper width={1} pl={1} py={3} justifyContent={align}>
      {tabs.map((name, idx) => {
        const isActive = activeTab === idx;

        return (
          <TabItem
            key={idx}
            id={`timed-tab-${idx}`}
            pr={3}
            mr={1}
            flex="0 0 auto"
            color={textColor}
            alignItems={'center'}
            isActive={activeTab === idx}
            onClick={() => scrollTabIntoView(idx)}
          >
            <Heading size="h6" textTransform="uppercase">
              {name}
            </Heading>
            <TabBorder
              height={3}
              transition={`transition: width ${
                isActive ? duration : 0
              }s linear`}
              mt={1}
              isActive={isActive}
              bg={activeBorderColor}
            />
          </TabItem>
        );
      })}
    </Wrapper>
  );
};

TimedTabs.propTypes = {
  /** Enable auto timed tabs */
  isTimed: PropTypes.bool,
  /** Array containing tab names */
  tabs: PropTypes.array,
  /** Integer indicating the index of the active tab */
  activeTab: PropTypes.number,
  /** Function to set the active tab index */
  setActiveTab: PropTypes.func,
  /** Defines the main color to be used */
  color: PropTypes.oneOf(Object.keys(getColors)),
  /** Defines the duration that a tab is active for (in seconds) */
  duration: PropTypes.number,
  /** Set the flex alignment */
  align: PropTypes.string,
};

TimedTabs.defaultProps = {
  tabs: [],
  activeTab: 0,
  setActiveTab: () => {},
  color: 'black',
  duration: 5,
  isTimed: false,
  align: 'center',
};

export default TimedTabs;
