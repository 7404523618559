import React, { useState } from 'react';
import { Button, Alert, logGtmEvent } from '@sygnia/components';
import { postForm } from '../../common/utils';
import { FormBuilderContainer } from './FormBuilder';
import { Message } from '../../components/messages/Message';
// import { Modal } from '@sygnia/components';
import { useLocation } from '@reach/router';

export const Form = props => {
  const { form } = props;

  const [formData, setForm] = useState({});
  const [formErrors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [resMsg, setResMsg] = useState('');

  const { pathname } = useLocation();
  const validate = values => {
    const errors = {};

    form.fields.forEach(field => {
      if (field.required && !values[field.name]) {
        errors[field.name] = 'Required Field';
      } else if (
        field.name === 'email' &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field.name])
      ) {
        errors[field.name] = 'Invalid email address';
      }
    });

    return {
      errors,
      isValid: !Object.keys(errors).length,
    };
  };

  const onFieldChange = (name, value) => {
    setForm({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const { errors, isValid } = validate(formData);

    if (!isValid) {
      setErrors(errors);
      return null;
    } else {
      setErrors(null);
    }
    setLoading(true);

    if (props.onSubmit) {
      props.onSubmit(formData);
    } else {
      //Remove trailing slash
      formData.tags = [pathname.replace(/\/+$/, '')];
      const response = await postForm(formData, form.name);

      if (response.success) {
        setError(false);
        setForm({});
        setSubmitted(true);
        setShowModal(true);
        props.gtmEventInfo &&
          logGtmEvent({
            ...props.gtmEventInfo,
          });
      } else {
        setError(true);
        setResMsg(response.message);
        setShowModal(true);
      }

      setLoading(false);
    }
  };

  if (submitted) {
    return <Message form={form.id} />;
  }

  return (
    <>
      {/* {showModal && (
        <Modal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          closeButtonVariant="secondary"
        >
          {error && <Alert text={`${resMsg}`} status="error" />}
          {submitted && <Alert text={resMsg} status="success" />}
        </Modal>
      )} */}

      <FormBuilderContainer
        form={form}
        handleFieldChange={onFieldChange}
        formData={formData}
        formType={'default'}
        errors={formErrors}
      />
      <Button mt={'20px'} onClick={handleSubmit}>
        {loading ? 'Loading' : form.buttonLabel}
      </Button>

      {error && <Alert text={`${resMsg}`} status="error" p={[1, 2]} mt={[3]} />}
    </>
  );
};

Form.defaultProps = {
  buttonLabel: 'Submit',
};
