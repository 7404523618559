import React, { useEffect } from 'react';

import ChartistGraph from 'react-chartist';
import { Container } from '@sygnia/components';

let ChartistTooltip;
const BarGraph = React.forwardRef((props, ref) => {
  useEffect(() => {
    ChartistTooltip = require('chartist-plugin-tooltips-updated');
  }, []);

  const { options = {} } = props;

  var data = {
    labels: props.xAxis,
    series: props.series,
  };

  var graph = {
    axisX: {
      showGrid: 1,
      showLabel: 1,
      ...(options.axisX || {}),
    },
    axisY: {
      ...(options.axisY || {}),
    },
    chartPadding: {
      ...(options.chartPadding || {}),
    },
    plugins: [],
  };

  if (ChartistTooltip) {
    graph.plugins.push(
      ChartistTooltip({
        anchorToPoint: true,
      }),
    );
  }

  var type = 'Bar';

  return (
    <div style={{ height: props.height || '300px' }}>
      <ChartistGraph
        ref={ref}
        className={`barGraph barGraph--${props.color} ${props.graphClass}`}
        style={{ height: props.height || '300px' }}
        data={data}
        options={graph}
        type={type}
        listener={props.listener || {}}
      />
    </div>
  );
});

BarGraph.defaultProps = {
  color: 'yellow',
  graphClass: '',
};
export default BarGraph;
