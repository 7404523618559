import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from '../common/utils';
import { theme } from '../common/theme';
import { Box } from 'rebass';
import { useSpring, animated } from 'react-spring';

const AnimatedCollapse = styled(animated.div)`
  display: block;
`;

/** Collapse will be rendered as part of the <CollapseWrapper/> component */
const CollapseBody = props => {
  const { isOpen, renderBody } = props;

  /* TODO: add ability to pass in springprops */
  const springProps = useSpring({
    from: { opacity: isOpen ? 0 : 1 },
    to: { opacity: isOpen ? 1 : 0 },
    config: { duration: 300 },
  });

  return <AnimatedCollapse style={springProps}>{renderBody}</AnimatedCollapse>;
};

export const Collapse = props => {
  const { isOpen, renderHeader, renderBody, borderBottom } = props;

  const StyledCollapse = styled(Box)`
    position: relative;
    border-bottom: ${borderBottom};
  `;

  return (
    <StyledCollapse>
      <Box
        role="none presentation"
        onClick={() => props.handleCollapse()}
        onKeyPress={() => props.handleCollapse()}
        style={{ cursor: 'pointer' }}
      >
        {renderHeader &&
          renderHeader({
            isOpen,
          })}
      </Box>
      {isOpen && (
        <Box px={[4]} pb={[2]}>
          <CollapseBody isOpen={isOpen} renderBody={renderBody} />
        </Box>
      )}
    </StyledCollapse>
  );
};

Collapse.propTypes = {
  /** Is the collapsible div currently open? */
  isOpen: PropTypes.bool.isRequired,
  /* Function which handles opening and closing of collapse body */
  handleCollapse: PropTypes.func.isRequired,
  /** Function which renders Collapse Header */
  renderHeader: PropTypes.func.isRequired,
  /** Object which renders Collapse Body */
  renderBody: PropTypes.object.isRequired,
  /** Style for bottom border */
  borderBottom: PropTypes.string,
};

Collapse.defaultProps = {
  isOpen: false,
  handleCollapse: () => {},
  renderHeader: () => {},
  renderBody: null,
  borderBottom: `0.5px solid ${rgba(theme.colors.baseWhite, 0.75)}`,
};

CollapseBody.propTypes = {
  /** Is the collapsible div currently open? */
  isOpen: PropTypes.bool.isRequired,
  /** Object passed to Collapse Body */
  renderBody: PropTypes.object.isRequired,
};

CollapseBody.defaultProps = {
  isOpen: false,
  renderBody: null,
};

export default Collapse;
