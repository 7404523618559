import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

const StyledImageContainer = styled(Box)`
  position: relative;
`;

const SectionLogo = props => {
  const { altText, image, color, link } = props;
  const CardWrapper = link && link.as ? link.as : React.Fragment;

  return (
    <CardWrapper {...(link ? link : {})}>
      <Flex
        my={[1, 3, 0]}
        flexDirection={['column', 'row', 'column']}
        alignItems={'center'}
        borderColor={color}
      >
        {image && (
          <StyledImageContainer width={[9 / 12]} mb={[3, 0, 3]}>
            <img src={image} alt={altText || ''} title={altText || ''} />
          </StyledImageContainer>
        )}
      </Flex>
    </CardWrapper>
  );
};

export default SectionLogo;

SectionLogo.propTypes = {
  altText: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.oneOf([
    'baseBlack',
    'brandGreen',
    'brandPurple',
    'brandViolet',
    'brandRed',
    'brandYellow',
    'brandNavy',
    'black',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]).isRequired,
  link: PropTypes.func,
  label: PropTypes.string,
};

SectionLogo.defaultProps = {
  title: null,
  image: null,
  text: null,
  color: 'baseBlack',
  link: {},
};
