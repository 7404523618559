import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import LinkGroup, { BUTTON_TYPES } from './LinkGroup';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import {
  Container,
  TabAnchors,
  Row,
  Column,
  Content,
  Widget,
  Video,
  logGtmEvent,
  GTM_CATEGORY,
  GTM_EVENT,
  GTM_LOCATION,
  GuideContent,
} from '@sygnia/components';
import Layout, { LAYOUT_TYPES } from './layout';
import {
  CONTENT_TYPES,
  CONTENT_MODELS_BY_TYPE,
  CONTENT_BASE_BY_TYPE,
} from '../common/constants';
import {
  mapper,
  mapperWithFunction,
  setupPage,
  setupContent,
} from '../common/utils';
import { MenuArchive } from '../components/menu/MenuArchive';
import styled from 'styled-components';

const StickyWrapper = styled.div`
  position: sticky;
  top: 100px;
`;

export const BasicContent = ({
  wrapComponent,
  children,
  mb,
  html = null,
  section: {
    title = null,
    __typename = null,
    content = null,
    richContent = null,
    contentStyling = false,
    image = null,
    meta = {},
  },
  ...rest
}) => {
  let bodyContent;
  let BodyComponent = React.Fragment;

  const WrapperComponent = wrapComponent ? wrapComponent : React.Fragment;

  if (__typename === CONTENT_TYPES.CONTENT) {
    BodyComponent = GuideContent.Body;
  } else {
    BodyComponent = contentStyling === 'Yes' ? React.Fragment : Content.Body;
  }

  if (richContent) {
    bodyContent = (
      <BodyComponent mb={mb}>
        {documentToReactComponents(richContent.document, options)}
      </BodyComponent>
    );
  } else if (content) {
    bodyContent = (
      <BodyComponent html={content.childMarkdownRemark.html} mb={mb} />
    );
  } else if (html) {
    bodyContent = <BodyComponent html={html} mb={mb} />;
  }

  const titleProps = {};

  if (meta && meta.title) {
    titleProps.size = meta.title;
  }

  if (__typename === CONTENT_TYPES.CONTENT) {
    return (
      <WrapperComponent>
        {bodyContent}
        {children}
      </WrapperComponent>
    );
  }

  return (
    <WrapperComponent>
      {title ? <Content.Title {...titleProps}>{title}</Content.Title> : null}
      {bodyContent}
      {children}
    </WrapperComponent>
  );
};

BasicContent.defaultProps = {
  section: {},
  mb: 6,
};

const CONTENT_MAPPING = {
  [CONTENT_TYPES.GENERAL_CARD]: BasicContent,
  [CONTENT_TYPES.CONTENT_MENU]: MenuArchive,
};

const CONTENT_OPTIONS_MAPPING = {
  [CONTENT_TYPES.CONTENT_MENU]: {
    showTitle: true,
  },
};

const EMBEDED_ENTRY = {
  __typename: ['data', 'target', 'sys', 'contentType', 'sys', 'id'],
  fields: ['data', 'target', 'fields'],
};

const RICH_TYPES_MAP = {
  styledText: {
    fields: ['fields'],
    __typename: () => {
      return CONTENT_TYPES.TEXT;
    },
  },
  bio: {
    fields: ['fields'],
    __typename: () => {
      return CONTENT_TYPES.BIO;
    },
  },
  iFrame: {
    fields: ['fields'],
    __typename: () => {
      return CONTENT_TYPES.IFRAME;
    },
  },
  buttons: {
    links: value => {
      return [
        {
          external: value.fields.external['en-US'],
          label: value.fields.label['en-US'],
          to: value.fields.to['en-US'],
          type: value.fields.type['en-US'],
          __typename: CONTENT_TYPES.CONTENT_LINK,
        },
      ];
    },
    __typename: () => {
      return CONTENT_TYPES.CONTENT_LINK;
    },
  },
  layoutComponent: {
    component: ['fields', 'component', 'en-US'],
    table: ['fields', 'table', 'en-US'],
    __typename: value => {
      if (value.fields.table) {
        return LAYOUT_TYPES.COMPONENT_TABLE;
      }

      return CONTENT_TYPES.COMPONENT;
    },
  },
  menu: {
    __typename: () => CONTENT_TYPES.CONTENT_MENU,
    items: value => {
      const CONTENTFUL_MAPPING = {
        Link: 'ContentfulLinks',
      };

      return value.fields.menuItems['en-US'].map(item => {
        const obj = Object.keys(item.fields).reduce((result, currentValue) => {
          result[currentValue] = item.fields[currentValue]['en-US'];

          return result;
        }, {});

        const entryType = _.get(item, ['sys', 'contentType', 'sys', 'type']);

        obj.__typename =
          CONTENTFUL_MAPPING[entryType] || `Contentful${entryType}`;

        return obj;
      });
    },
    inContent: () => false,
  },
  asset: {
    contentType: ['data', 'target', 'fields', 'file', 'en-US', 'contentType'],
    url: ['data', 'target', 'fields', 'file', 'en-US', 'url'],
    title: ['data', 'target', 'fields', 'title', 'en-US', 'title'],
  },
  entry: {
    contentType: ['data', 'target', 'sys', 'contentType', 'sys', 'id'],
    data: ['data', 'target', 'fields'],
  },
  page: {
    parent: ['parent', 'en-US'],
    slug: ['slug', 'en-US'],
  },
};

const renderAsset = (node, next) => {
  const { url, contentType, title } = mapperWithFunction(
    node,
    RICH_TYPES_MAP.asset,
  );

  if (!url || !contentType || !contentType.length) return null;

  if (contentType && contentType.includes('image')) {
    return <img src={url} alt={title} />;
  }

  if (contentType && contentType.includes('pdf')) {
    return (
      <LinkGroup
        links={[
          {
            type: BUTTON_TYPES.DOWNLOAD,
            as: 'a',
            label: title,
            external: true,
            to: url,
          },
        ]}
      />
    );
  }

  return null;
};

const options = {
  renderNode: {
    'entry-hyperlink': (node, next) => {
      const { contentType, data } = mapper(node, RICH_TYPES_MAP.entry);
      if (contentType === CONTENT_TYPES.PAGE) {
        const { parent, slug } = mapper(data, RICH_TYPES_MAP.page);

        const { to = '/' } = setupPage({
          contentfulparent: parent,
          slug: slug,
        });

        return <Link to={to}>{next}</Link>;
      }
      const base = CONTENT_BASE_BY_TYPE[CONTENT_MODELS_BY_TYPE[contentType]];
      const { slug } = mapper(data, RICH_TYPES_MAP.page);
      const { to } = setupContent(null, slug, {}, base);

      return <Link to={to}>{next}</Link>;
    },
    'asset-hyperlink': (node, next) => {
      const { url, title } = mapper(node, RICH_TYPES_MAP['asset']);
      return (
        <a href={url} title={title} target={'_download'}>
          {next}
        </a>
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node, next) => {
      const mod = mapper(node, EMBEDED_ENTRY);
      if (mod.__typename && RICH_TYPES_MAP[mod.__typename]) {
        return (
          <Layout
            section={mod}
            layout={[mapperWithFunction(mod, RICH_TYPES_MAP[mod.__typename])]}
          />
        );
      }
      return null;
    },
    'embedded-asset-block': (node, next) => renderAsset(node, next),
    [BLOCKS.EMBEDDED_ENTRY]: (node, next) => {
      const mod = mapper(node, EMBEDED_ENTRY);
      if (mod.__typename && RICH_TYPES_MAP[mod.__typename]) {
        return (
          <Layout
            section={mod}
            layout={[mapperWithFunction(mod, RICH_TYPES_MAP[mod.__typename])]}
          />
        );
      }
      return null;
    },
    [INLINES.HYPERLINK]: node => {
      const videoTypes = [
        'youtube.com',
        'youtu.be',
        'youtube-nocookie.com',
        'youtu-nocookie.be',
        'vimeo.com',
        'wistia.com',
      ];

      if (videoTypes.some(v => node.data.uri.includes(v))) {
        return <Video src={node.data.uri} />;
      }

      if (node.data.uri.includes('tel:+27860794642')) {
        return (
          <a
            href={node.data.uri}
            onClick={() => {
              logGtmEvent({
                eventName: GTM_EVENT.TEL_CLICKED,
                data: {
                  category: GTM_CATEGORY.CONTACT,
                  label: '+27860794642',
                  location: GTM_LOCATION.CONTENT,
                },
              });
            }}
          >
            {node.content[0].value}
          </a>
        );
      }

      if (node.data.uri.includes('mailto:admin@sfs.sygnia.co.za')) {
        return (
          <a
            href={node.data.uri}
            onClick={() => {
              logGtmEvent({
                eventName: GTM_EVENT.EMAIL_CLICKED,
                data: {
                  category: GTM_CATEGORY.CONTACT,
                  label: 'admin@sfs.sygnia.co.za',
                  location: GTM_LOCATION.CONTENT,
                },
              });
            }}
          >
            {node.content[0].value}
          </a>
        );
      }
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(process.env.BASE_URL) ? '_self' : '_blank'
          }`}
          rel={`${
            node.data.uri.startsWith(process.env.BASE_URL)
              ? ''
              : 'noopener noreferrer'
          }`}
        >
          {node.content[0].value}
        </a>
      );
    },
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export const ContentWrapper = props => {
  const {
    contentGrid,
    sidebarGrid,
    section: {
      modules = [],
      sidebar = [],
      scrollTabs,
      content,
      document,
      isRich,
      contentStyling,
      isWrapped = true,
      ...sectionProps
    },
    colorScheme,
  } = props;

  let BodyWrapper = Content.Body;
  if (contentStyling === 'Yes') {
    BodyWrapper = React.Fragment;
  }

  let contentGridObj = contentGrid || {};
  if (sectionProps.contentGrid) {
    contentGridObj = {
      ...contentGrid,
      ...sectionProps.contentGrid,
    };
  }

  let sidebarGridObj = sidebarGrid || {};
  if (sectionProps.sidebarGrid) {
    sidebarGridObj = {
      ...sidebarGridObj,
      ...sectionProps.sidebarGrid,
    };
  }

  const [active, setActive] = useState(0);

  const tabs = modules
    ? modules
        .filter(mod => !!mod.title)
        .map((mod, i) => {
          return {
            id: `tab-${i}`,
            name: mod.title,
          };
        })
    : [];

  let ContentCompiled;
  let SidebarCompliled =
    sidebar &&
    sidebar.length &&
    sidebar.map(mod => {
      const Component = CONTENT_MAPPING[mod.__typename] || Layout;
      const l = (
        <Component
          contained={true}
          wrapComponent={Widget}
          section={mod}
          layout={[mod]}
        />
      );
      if (sidebarGridObj.isSticky) {
        return (
          <StickyWrapper as={Column} {...sidebarGridObj}>
            {l}
          </StickyWrapper>
        );
      }
      return <Column {...sidebarGridObj}>{l}</Column>;
    });

  if (isRich) {
    if (!isWrapped) {
      ContentCompiled = (
        <BasicContent>
          {documentToReactComponents(document, options)}
        </BasicContent>
      );
    } else {
      ContentCompiled = (
        <BasicContent>
          <BodyWrapper>
            {documentToReactComponents(document, options)}
          </BodyWrapper>
        </BasicContent>
      );
    }
  } else if (modules && modules.length) {
    ContentCompiled = modules.map((mod, i) => {
      const Component = CONTENT_MAPPING[mod.__typename] || Layout;
      const options = CONTENT_OPTIONS_MAPPING[mod.__typename] || {};
      const l = (
        <>
          {options.showTitle ? (
            <Content.Title>{mod.title}</Content.Title>
          ) : null}
          <Component
            contained={true}
            options={options}
            section={mod}
            layout={[mod]}
          />
        </>
      );

      if (scrollTabs) {
        return <div id={`tab-${i}`}>{l}</div>;
      }

      return l;
    });
  } else {
    return (
      <Container mt={[1]}>
        <Row
          flexDirection={['column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'flex-start']}
          justifyContent={['space-between']}
          flexWrap={'wrap'}
        >
          <Column {...sidebarGridObj}></Column>
          <Column {...contentGridObj}>
            <BasicContent>
              <Content.Body
                html={content ? content.childMarkdownRemark.html : null}
              />
            </BasicContent>
          </Column>
        </Row>
      </Container>
    );
  }

  return (
    <>
      {scrollTabs ? (
        <TabAnchors
          tabs={tabs}
          colorScheme={colorScheme}
          active={active}
          wrapInContainer
          setActive={id => setActive(id)}
        />
      ) : null}
      {!SidebarCompliled ? (
        ContentCompiled
      ) : (
        <Container mt={[2, 2, 2, 5]}>
          <Row
            flexDirection={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'flex-start']}
            justifyContent={['space-between']}
            flexWrap={'wrap'}
          >
            <Column {...contentGridObj}>{ContentCompiled}</Column>
            {SidebarCompliled || null}
          </Row>
        </Container>
      )}
    </>
  );
};

ContentWrapper.defaultProps = {
  contentGrid: {
    width: [1, 1, 1, 7.5 / 12],
    mb: [3, 5],
    mx: 0,
    order: 0,
    flex: '0 0 auto',
  },

  sidebarGrid: {
    width: [1, 1, 1, 3.5 / 12],
    mb: [3, 3, 3, 0],
    mx: 0,
    flex: '0 0 auto',
    isSticky: false,
  },
};

export const fragment = graphql`
  fragment ContentGenericFields on ContentfulContentGeneric {
    title
    image {
      fluid {
        src
      }
      file {
        url
      }
    }
    content {
      childMarkdownRemark {
        html
      }
    }
    richContent {
      document: json
    }
    contentStyling
    colorScheme
    backgroundColor
    links {
      ...LinkFields
      ...PageLinkFields
    }
    meta {
      title
      label
      drawing
      showTitle
      sidebarGrid {
        width
        mb
        mt
      }
      contentGrid {
        width
        mb
        mt
      }
      customColors {
        text
      }
    }
  }
  fragment LayoutContentFields on ContentfulLayoutContentBody {
    scrollTabs
    content {
      childMarkdownRemark {
        html
      }
    }
    modules {
      __typename
      ... on Node {
        ...ContentGenericFields
        ...HeroLayoutFields
        ...TestimonialsLayoutFields
        ...CardBannerLayoutFields
        ...SectionLayoutFields
        ...HeroImageLayoutFields
        ...ToggleLayoutFields
        ...SliderLayoutFields
        ...LayoutColumnFields
        ...ComponentLayoutFields
        ...LayoutMenuFields
        ...IFrameFields
      }
    }
    sidebar {
      __typename
      ... on Node {
        ...ComponentLayoutFields
        ...ContentGenericFields
      }
    }
  }

  fragment ContentFields on ContentfulContent {
    __typename
    contentful_id
    title
    image {
      fluid {
        src
      }
      file {
        url
      }
    }
    richContent {
      document: json
    }
  }
`;

ContentWrapper.fragment = fragment;
