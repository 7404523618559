import React from 'react';
import { CTACentered } from '@sygnia/components';
import { mapper } from '../common/utils';
import { graphql } from 'gatsby';
import LinkGroup from './LinkGroup';

const CTACENTERED_CONTENT_MAP = {
  image: ['image', 'fluid', 'src'],
  title: ['title'],
  description: ['description', 'childMarkdownRemark', 'html'],
  footnote: ['footnote', 'childMarkdownRemark', 'html'],
  size: ['size'] || null,
};

const fallbackColors = {
  green: {
    bg: '#FFFFFF',
    title: 'baseBlack',
    button: 'baseBlack',
    description: 'baseBlack',
  },
};

export const CTACenteredWrapper = ({ section }) => {
  const mappedOptions = mapper(section, CTACENTERED_CONTENT_MAP);
  const meta = section.meta?.internal
    ? JSON.parse(section.meta.internal.content)
    : null;

  const colors = meta?.colors || fallbackColors.green;

  const renderCta = () => {
    return section.links ? (
      <LinkGroup color={colors.button || 'baseBlack'} links={section.links} />
    ) : null;
  };
  return (
    <CTACentered {...mappedOptions} renderCta={renderCta} colors={colors} />
  );
};

CTACenteredWrapper.defaultProps = {
  section: {},
};

export const fragment = graphql`
  fragment CTACenteredFields on ContentfulCtaCentered {
    __typename
    image {
      fluid {
        src
      }
    }
    title
    size
    description {
      childMarkdownRemark {
        html
      }
    }
    links {
      ... on ContentfulLinks {
        __typename
        title
        ...LinkFields
      }
    }
    footnote {
      childMarkdownRemark {
        html
      }
    }
    meta {
      internal {
        content
      }
    }
  }
`;

CTACenteredWrapper.fragment = fragment;
