import React from 'react';
import { Text } from '@sygnia/components';
import { mapper } from '../common/utils';

const FIELDS_MAP = {
  text: ['text', 'en-US'],
  meta: ['meta', 'en-US'],
};

const StyledTextWrapper = ({ section }) => {
  if (!section) return null;

  const values = mapper(section.fields, FIELDS_MAP);
  const style = values.meta ? values.meta?.style : {};
  const size = values.meta ? values.meta?.size : 'body';

  return (
    <Text style={{ ...style }} size={size}>
      {values.text}
    </Text>
  );
};

export default StyledTextWrapper;
