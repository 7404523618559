import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import { ListPost } from './ListPost';
import ContentHeading from '../../components/ContentHeading/ContentHeading';

const StyledList = styled(Box)`
  display: block;
  width: 100%;
`;

/** ListPosts is used to render a list of items like News & Updates*/
// TODO: render as link with URL
const ListPosts = props => {
  const { items, title, children } = props;

  return (
    <StyledList {...props}>
      {title && <ContentHeading title={title} />}
      {items && items.map((i, index) => <ListPost {...i} key={index} />)}
      {children}
    </StyledList>
  );
};

export default ListPosts;

ListPosts.propTypes = {
  /** Items to be listed */
  items: PropTypes.array,
  /** Title for the list */
  title: PropTypes.string,
  /** Children node wrapper */
  children: PropTypes.node,
  /** title size */
  titleSize: PropTypes.string,
};

ListPosts.defaultProps = {
  items: null,
  title: null,
  titleSize: 'h1',
};
