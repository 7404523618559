import React from 'react';
import { Dropdown, Button } from '@sygnia/components';
import { sentenceCase } from '../../../common/utils';
import { Flex } from 'rebass';
import { REFINEMENT_TYPES } from '../../../common/constants';

const RefinementList = ({ items, refine, title, type, currentRefinement }) => {
  if (!items.length) return null;

  switch (type) {
    case REFINEMENT_TYPES.BUTTONGROUP:
      return (
        <Flex flexDirection="row" flex="1 0 auto" mb={[1, 0, 0, 0]}>
          {items.map(item => {
            return (
              <Button
                mr={['1px']}
                onClick={() => {
                  refine(item.value);
                }}
                variant={
                  currentRefinement.length && item.isRefined
                    ? 'primary'
                    : 'secondary'
                }
              >
                {item.label}
              </Button>
            );
          })}
        </Flex>
      );
    default:
      return (
        <Dropdown
          className="RefinementList_Dropdown"
          isMultiSelect={true}
          label={
            currentRefinement.length
              ? currentRefinement.length > 1
                ? `${currentRefinement.length} ${title[1]} selected`
                : currentRefinement[0]
              : title[0]
          }
          renderOptions={Component => {
            return (
              <>
                {items.map(item => (
                  <Component
                    isSelected={currentRefinement.length && item.isRefined}
                    isMultiSelect={true}
                    onChange={() => {
                      refine(item.value);
                    }}
                    label={sentenceCase(item.label)}
                  />
                ))}
              </>
            );
          }}
        />
      );
  }
};

export default RefinementList;
