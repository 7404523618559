import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Image, Card as RebassCard, Flex } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { Container, Row, Column } from '../Grid';
import { theme } from '../common/theme';
import { rgba } from '../common/utils';

const getOverlay = {
  none: {
    title: 'baseBlack',
    text: 'baseBlack',
    overlay: 'transparent',
    bg: 'transparent',
  },
  transparent: {
    title: 'baseBlack',
    text: 'baseBlack',
    overlay: 'transparent',
    bg: 'transparent',
  },
  inherit: {
    title: 'baseBlack',
    text: 'baseBlack',
    overlay: 'transparent',
    bg: 'transparent',
  },
  white: {
    title: 'baseBlack',
    text: 'baseBlack',
    overlay: rgba(theme.colors.baseGrayLight, 0.5),
    bg: 'transparent',
  },
  gray: {
    title: 'baseBlack',
    text: 'baseBlack',
    overlay: 'transparent',
    bg: theme.colors.baseGrayVeryLight,
  },
  black: {
    title: 'baseWhite',
    text: 'baseWhite',
    overlay: rgba(theme.colors.baseBlack, 0.5),
    bg: 'transparent',
  },
  green: {
    title: theme.colors.green,
    text: theme.colors.green,
    overlay: 'transparent',
    bg: theme.colors.brandGreenPale,
  },
  violet: {
    title: 'brandViolet',
    emphasis: 'brandYellow',
    titleHero: 'brandViolet',
    bg: 'brandVioletPale',
    bgHero: 'brandViolet',
    textHero: 'brandViolet',
    text: 'brandViolet',
  },
  custom: {
    title: 'baseWhite',
    text: 'baseWhite',
    overlay: 'transparent',
    bg: 'transparent',
  },
};

const bgImageStyles = image => {
  return css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${image});
    @media (max-width: ${theme.breakpoints[2]}) {
      background-image: none;
    }
  `;
};

const StyledTestimonial = styled(RebassCard)`
  overflow-x: hidden;
  display: block;
  position: relative;
  background: ${props =>
    props.customColors && props.customColors.bg
      ? props.customColors.bg
      : props.overlay
      ? getOverlay[props.overlay]?.bg || 'none'
      : 'none'};
  :after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props =>
      props.customColors && props.customColors.overlay
        ? props.customColors.overlay
        : props.overlay
        ? getOverlay[props.overlay]?.overlay || 'none'
        : 'none'};
  }
  ${({ overlay, image }) =>
    overlay !== 'none' && overlay !== 'transparent' && overlay !== 'violet'
      ? bgImageStyles(image)
      : `
        border-top: 1px solid ${theme.colors.baseGrayLight};
      `};
  @media (min-width: ${theme.breakpoints[1]}) {
    ${({ setMinHeight }) => setMinHeight && 'min-height: 320px;'};
  }
  @media (min-width: ${theme.breakpoints[2]}) {
    ${({ setMinHeight }) =>
      setMinHeight && 'min-height: 800px;border-top: none'};
    ${props => bgImageStyles(props.image)};
  }
`;

const StyledContent = styled.div`
  position: relative;
  z-index: 2;
  img {
    margin-top: ${theme.space[2]}px;
    width: inherit !important;
  }

  @media (min-width: ${theme.breakpoints[2]}) {
    margin: ${theme.space[0]}px ${theme.space[8]}px;
  }
`;

const Testimonial = props => {
  const {
    testimonialTitle,
    text,
    cite,
    overlay,
    image,
    layout,
    renderCta,
    customColors,
    eyebrow,
  } = props;

  const layoutProps = {
    standard: {
      wrapper: {
        py: [9, 25],
      },
      content: {
        mr: 'auto',
        ml: '0px',
        width: [1, 1, 1, 6 / 12],
      },
    },
    left: {
      wrapper: {
        py: [9, 25],
      },
      content: {
        mr: 'auto',
        ml: '0px',
        width: [1, 1, 1, 6 / 12],
      },
    },
    right: {
      wrapper: {
        py: [9, 25],
      },
      content: {
        mr: '0px',
        ml: 'auto',
        width: [1, 1, 1, 6 / 12],
      },
    },
    center: {
      wrapper: {
        py: [9, 25],
      },
      content: {
        mr: 'auto',
        ml: 'auto',
        width: [1, 1, 1, 10 / 12],
      },
    },
    rightContained: {
      wrapper: {
        pt: [4, 7],
        alignItems: 'center',
      },
      row: {
        alignItems: 'center',
      },
      content: {
        mr: '0px',
        ml: 'auto',
        my: '24px',
        width: [1, 1, 1, 6 / 12],
        order: [2, 1, 1],
      },
      image: {
        mr: 'auto',
        ml: '0px',
        width: [1, 1, 1, 6 / 12],
        order: [1, 2, 2],
      },
    },
    leftContained: {
      wrapper: {
        pt: [4, 7],
      },
      row: {
        alignItems: 'center',
      },
      content: {
        mr: 'auto',
        ml: '0px',
        my: '24px',
        width: [1, 1, 1, 6 / 12],
        order: [2],
      },
      image: {
        mr: 'auto',
        ml: 'auto',
        width: [1, 1, 1, 6 / 12],
        order: [1],
      },
    },
  };

  const isBgImage = layout => {
    if (layout !== 'rightContained' && layout !== 'leftContained') {
      return true;
    } else {
      return false;
    }
  };
  return (
    <StyledTestimonial
      {...props}
      {...layoutProps[layout].wrapper}
      image={isBgImage(layout) && image}
      customColors={customColors}
    >
      <Container>
        <Row {...layoutProps[layout].row}>
          {!isBgImage(layout) && (
            <Column flex={'0 1 auto'} {...layoutProps[layout].image}>
              {image && <Image src={image} />}
            </Column>
          )}

          <Column flex={'0 1 auto'} {...layoutProps[layout].content}>
            <StyledContent>
              {eyebrow && (
                <Heading
                  size="h5"
                  color={
                    customColors.eyebrow ||
                    getOverlay[overlay]?.title ||
                    'baseBlack'
                  }
                  textTransform="uppercase"
                  mb={[1, 3]}
                  {...layoutProps[layout].text}
                >
                  {eyebrow}
                </Heading>
              )}
              {testimonialTitle && (
                <Heading
                  size="h5"
                  color={
                    customColors && customColors.title
                      ? customColors.title
                      : getOverlay[overlay]?.title || 'baseBlack'
                  }
                  textTransform="uppercase"
                  mb={[3, 7]}
                  textAlign={
                    overlay === 'transparent'
                      ? ['center', 'left']
                      : ['center', 'center', 'center', 'left']
                  }
                >
                  {testimonialTitle}
                </Heading>
              )}
              {text && (
                <Heading
                  mt={[2, 0]}
                  size={layout === 'center' ? 'h2' : 'h1'}
                  color={
                    customColors && customColors.title
                      ? customColors.title
                      : getOverlay[overlay]?.title || 'baseBlack'
                  }
                  lineHeight={'1.2666'}
                  textAlign={
                    overlay === 'transparent'
                      ? ['center', 'left']
                      : ['center', 'center', 'center', 'left']
                  }
                  html={text}
                />
              )}
              {cite && (
                <Text
                  size="body"
                  color={
                    customColors && customColors.text
                      ? customColors.text
                      : getOverlay[overlay]?.text || 'baseBlack'
                  }
                  mt={[2, 4]}
                  textAlign={
                    overlay === 'transparent'
                      ? ['center', 'left']
                      : ['center', 'center', 'center', 'left']
                  }
                >
                  {cite}
                </Text>
              )}
              {renderCta && (
                <Flex
                  flexWrap={'wrap'}
                  mt={[4, 4, 4, 4]}
                  justifyContent={
                    !image
                      ? 'center'
                      : ['center', 'center', 'center', 'flex-start']
                  }
                  alignItems={['center']}
                >
                  {renderCta}
                </Flex>
              )}
            </StyledContent>
          </Column>
        </Row>
      </Container>
    </StyledTestimonial>
  );
};

export default Testimonial;

Testimonial.propTypes = {
  testimonialTitle: PropTypes.string.isRequired,
  cite: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  overlay: PropTypes.oneOf([
    'black',
    'white',
    'none',
    'green',
    'transparent',
    'inherit',
    'violet',
  ]),
  layout: PropTypes.oneOf([
    'left',
    'right',
    'center',
    'rightContained',
    'leftContained',
  ]),
  setMinHeight: PropTypes.bool,
  /** Function to pass in Calls to Action */
  renderCta: PropTypes.func,
  /** Metadata object for custom colors */
  customColors: PropTypes.object,
};

Testimonial.defaultProps = {
  /** Optional title for Testimonial */
  title: null,
  /** Citation or Source */
  cite: null,
  /** Text Testimonial */
  text: null,
  /** Image url */
  image: null,
  /** Set the overlay color and Text color as a result.  */
  overlay: 'none',
  /** Set the alignment of component. Also effects Title size */
  layout: 'center',
  /** Set the minHeight of the component when displayed in Slider */
  setMinHeight: false,
  /** Function to pass in Calls to Action */
  renderCta: null,
  customColors: null,
};
