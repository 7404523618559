import React from 'react';
import { Link } from 'gatsby';
import { Flex } from 'rebass';
import { NavigationIndexItem } from '@sygnia/components';
import { Container, Heading } from '../../../components/src';
import { useLocation } from '@reach/router';
import styled from 'styled-components';

const NavigationIndexWrapper = props => {
  const {
    section: { items, wrapperTitle, isParent },
  } = props;
  const { pathname } = useLocation();

  const StyledTitle = styled(Heading)`
    margin: 0 auto;
  `;
  return (
    <Container my={[3, 6]}>
      {/* Do not show the title on the parent pages of the guides */}
      {wrapperTitle && !isParent && (
        <StyledTitle mb={[1, 2]} size="h3" width={[1, 5 / 12]} px={[3, 2]}>
          {wrapperTitle}
        </StyledTitle>
      )}
      <Flex
        flexDirection={'column'}
        justifyItems={'center'}
        alignItems="center"
      >
        <ol>
          {items?.map((item, index) => {
            return (
              <Link to={`/${item.slug}`} key={index}>
                <NavigationIndexItem
                  title={item.headline}
                  isActive={`/${item.slug}` === pathname}
                />
              </Link>
            );
          })}
        </ol>
      </Flex>
    </Container>
  );
};
export default NavigationIndexWrapper;
export const fragment = graphql`
  fragment NavigationIndexFields on ContentfulNavigationIndex {
    wrapperTitle: title
    items: menuItems {
      slug
      title
    }
  }
`;

NavigationIndexWrapper.fragment = fragment;
