import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import Heading from '../Heading/Heading';
import styled from 'styled-components';
import { theme } from '../common/theme';

const StyledLinkPrimary = styled(Flex)`
  cursor: pointer;
  i {
    transition: all ${theme.speed.default} ease;
    opacity: 0;
  }
  &:hover i {
    color: ${props => theme.colors[props.isHovered]};
    opacity: 1;
    transform: translateX(${theme.space[1]}px);
  }
`;

const StyledHeading = styled(Heading)`
  transition: color ${theme.speed.default} ease;
  color: ${props => theme.colors[props.color]};
  ${StyledLinkPrimary}:hover & {
    color: ${props => theme.colors[props.isHovered]};
  }
`;

const Icon = () => {
  return (
    <Box fontSize={16}>
      <i className={`icon icon-arrow-right link-primary-icon`} />
    </Box>
  );
};

const LinkPrimary = ({ color, isHovered, children, html, link }) => {
  let heading;
  let linkOptions = {};

  if (link) {
    linkOptions = {
      ...link,
      forwardedAs: link.as || null,
      as: StyledHeading,
    };
  }

  if (html) {
    heading = (
      <StyledHeading
        size={'h1'}
        mx={0}
        color={color}
        isHovered={isHovered}
        html={html}
        {...linkOptions}
      />
    );
  } else {
    heading = (
      <Heading
        size={'h1'}
        mx={0}
        color={color}
        isHovered={isHovered}
        {...linkOptions}
      >
        {children}
      </Heading>
    );
  }

  return (
    <StyledLinkPrimary
      alignItems="center"
      color={color}
      isHovered={isHovered}
      justifyContent={['space-between', 'space-between', 'flex-start']}
    >
      {heading}
      <Icon color={color} isHovered={isHovered} />
    </StyledLinkPrimary>
  );
};

LinkPrimary.propTypes = {
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  isHovered: PropTypes.string,
  children: PropTypes.node,
  html: PropTypes.node,
  link: PropTypes.object,
};

LinkPrimary.defaultProps = {
  color: 'baseBlack',
  isHovered: 'baseGray',
  children: null,
  html: null,
};

export default LinkPrimary;
