import React from 'react';
import { CardPerson } from '@sygnia/components';
import { graphql } from 'gatsby';

export const PeopleCardWrapper = ({ section, isFeatured, isSmall }) => {
  return (
    <CardPerson
      variant={isFeatured ? 'featured' : isSmall ? 'small' : 'default'}
      title={section.title}
      image={section.image ? section.image.fluid.src : null}
      role={section.role}
      text={isFeatured ? section.content.childMarkdownRemark.html : null}
      renderCta={
        ((section.content || section.qualifications) && section.renderCta) ||
        null
      }
    />
  );
};

PeopleCardWrapper.defaultProps = {
  section: {},
  isFeatured: false,
  isSmall: false,
};

export const PeopleCardLayoutFields = graphql`
  fragment PeopleLayoutFields on ContentfulPeople {
    title
    role
    content {
      childMarkdownRemark {
        html
      }
    }
    image {
      fluid {
        src
      }
    }
    qualifications {
      childMarkdownRemark {
        html
      }
    }
  }
`;

PeopleCardWrapper.fragment = PeopleCardLayoutFields;
