export const GTM_EVENT = {
  EMAIL_CLICKED: 'EMAIL_CLICKED',
  APPLICATION_PACK_CLICKED: 'APPLICATION_PACK_CLICKED',
  SIGN_ME_UP_CLICKED: 'SIGN_ME_UP_CLICKED',
  REQUEST_A_QUOTE_CLICKED: 'REQUEST_A_QUOTE_CLICKED',
  ROBO_ADVISOR_STARTED: 'ROBO_ADVISOR_STARTED',
  MORE_INFO_CLICKED: 'MORE_INFO_CLICKED',
  SOCIAL_CLICKED: 'SOCIAL_CLICKED',
  TEL_CLICKED: 'TEL_CLICKED',
};

export const GTM_CATEGORY = {
  FORM: 'FORM',
  DOCUMENT: 'DOCUMENT',
  CONTACT: 'CONTACT',
  LEAD: 'LEAD',
  SOCIAL: 'SOCIAL',
  VIDEO: 'VIDEO',
};

export const GTM_LOCATION = {
  CONTENT: 'CONTENT',
  HERO: 'HERO',
  FOOTER: 'FOOTER',
  MENU: 'MENU',
};
