import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout, { LAYOUT_TYPES } from '../modules/layout';
import Main from '../modules/main';
import {
  CONTENT_TITLES,
  GTM_CATEGORY,
  GTM_EVENT,
  GTM_LOCATION,
} from '../common/constants';
import { BUTTON_TYPES } from '../modules/LinkGroup';

export const FundPageContext = React.createContext({ fund: null });
const fundPageColorSheme = 'violet';

const getLayout = (fund, contactBlock, url) => {
  if (!fund) return null;
  let links = [];
  if (!fund?.hideOpenAccountButton) {
    links.push({
      title: CONTENT_TITLES.openAccount,
      label: CONTENT_TITLES.openAccount,
      type: 'Primary',
      to: 'https://online.sygnia.com/Alchemy/Account/SignUp',
      external: true,
      file: null,
      isInverse: true,
    });
  }

  if (fund?.links) {
    fund.links.map(item => {
      links.push({
        label: item.label,
        type: item.type,
        to: item.to,
        external: item.external,
        isInverse: true,
      });
    });
  }

  if (fund?.factSheet) {
    links.push({
      title: fund.factSheet.title,
      label: CONTENT_TITLES.factSheet,
      type: BUTTON_TYPES.DOWNLOAD,
      to: null,
      external: true,
      file: fund.factSheet,
      isInverse: true,
      gtmEventInfo: {
        eventName: GTM_EVENT.FACT_SHEET_DOWNLOADED,
        data: {
          category: GTM_CATEGORY.DOCUMENT,
          label: CONTENT_TITLES.factSheet,
          location: GTM_LOCATION.HERO,
        },
      },
    });
  }

  if (fund?.marketingSheet) {
    links.push({
      title: fund.marketingSheet.title,
      label: CONTENT_TITLES.marketingSheet,
      type: BUTTON_TYPES.DOWNLOAD,
      to: null,
      external: true,
      file: fund.marketingSheet,
      isInverse: true,
      gtmEventInfo: {
        eventName: GTM_EVENT.MARKETING_SHEET_DOWNLOADED,
        data: {
          category: GTM_CATEGORY.DOCUMENT,
          label: CONTENT_TITLES.marketingSheet,
          location: GTM_LOCATION.HERO,
        },
      },
    });
  }

  const items = [
    {
      __typename: LAYOUT_TYPES.HELMET,
      summary: fund.fundSummary || null,
      url: url,
    },
    {
      __typename: LAYOUT_TYPES.URL_OBSERVER,
      queryParameter: 'qna',
      style: {
        maxWidth: '80%',
        margin: 'auto',
      },
    },
    {
      __typename: LAYOUT_TYPES.HERO_TITLE,
      title: fund.title,
      hasLogo: true,
      subtitle: fund.subtitle,
      excerpt: fund.description,
      colorScheme: fundPageColorSheme,
      links: links,
    },
    {
      __typename: LAYOUT_TYPES.RELATED_TESTIMONIALS,
    },
    ...fund.layout,
  ];

  if (fund?.product && fund.product.length) {
    items.push({
      __typename: LAYOUT_TYPES.SECTION,
      title: CONTENT_TITLES.RELATED_PRODUCTS,
      description: {
        childMarkdownRemark: {
          html: `${CONTENT_TITLES.RELATED_PRODUCTS_DESCRITION} ${fund.title}`,
        },
      },
      isGallery: true,
      layout: 'Product Card', // change to constant
      backgroundColor: 'gray', // change to constant
      modules: fund.product,
    });
  }

  if (contactBlock) {
    items.push(contactBlock);
  }

  return items;
};

const FundTemplate = props => {
  const url = props.location.href;
  const post = get(props, 'data.contentfulFunds');
  const contactBlock = get(props, 'pageContext.contactBlock');
  const layout = getLayout(post, contactBlock, url);

  return (
    <FundPageContext.Provider value={{ fund: post }}>
      <Main {...props} page={post}>
        <Layout
          colorScheme={fundPageColorSheme}
          layout={layout}
          contentType={'funds'}
        />
      </Main>
    </FundPageContext.Provider>
  );
};

export default FundTemplate;

export const LayoutFieldsFragment = graphql`
  fragment LayoutFields on ContentfulFunds {
    layout {
      __typename
      ... on Node {
        ...HeroLayoutFields
        ...TestimonialsLayoutFields
        ...SectionLayoutFields
        ...HeroImageLayoutFields
        ...ToggleLayoutFields
        ...SliderLayoutFields
        ...LayoutColumnFields
        ...LayoutContentFields
      }
    }
  }
`;

export const pageQuery = graphql`
  query FundPostBySlug($slug: String!) {
    contentfulFunds(slug: { eq: $slug }) {
      title
      subtitle
      productType
      fundId: internalIndentifier
      fundSummary {
        title
        content {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      marketingSheet {
        title
        file {
          fileName
          url
        }
      }
      factSheet {
        title
        file {
          fileName
          url
        }
      }
      ...FundInfoParam
      ...LayoutFields
      #links {
      #  ...LinkFields
      #}
      hideOpenAccountButton
    }
  }
`;
