import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import Heading from '../Heading/Heading';

const Kpi = props => {
  const { title, value } = props;

  return (
    <Box mr={[7]}>
      <Heading size="h4" as={'span'} mb={[0, 1]}>
        {title}
      </Heading>
      <Heading size="h2" as={'span'} fontWeight="normal" mb={[2, 0]}>
        {value}
      </Heading>
    </Box>
  );
};

export default Kpi;

Kpi.propTypes = {
  /** Title / Label of KPI */
  title: PropTypes.string.isRequired,
  /** Value of KPI */
  value: PropTypes.string,
};

Kpi.defaultProps = {
  title: null,
  value: null,
};
