import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import ReactPlayer from 'react-player';

const StyledVideoWrapper = styled(Box)`
  position: relative;
  padding-top: 56.25%;
`;
export const Video = props => {
  const { src } = props;

  return (
    <StyledVideoWrapper className="videoWrapper">
      <ReactPlayer
        style={{ position: 'absolute', top: 0, left: 0 }}
        url={src}
        //Use embed, not watch!
        // url={'https://www.youtube-nocookie.com/embed/ejby-k1qHKc'}
        controls={true}
        width="100%"
        height="100%"
      />
    </StyledVideoWrapper>
  );
};

export default Video;

Video.propTypes = {
  src: PropTypes.string,
};

Video.defaultProps = {
  src: '',
};
