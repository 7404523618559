import React from 'react';
import { Notice } from '@sygnia/components';
import { graphql } from 'gatsby';
import { mapper } from '../common/utils';

const CONTENT_MAP = {
  text: ['content', 'childMarkdownRemark', 'html'],
  isActive: ['isActive'],
};

export const NoticeWrapper = props => {
  const { colorScheme, section } = props;

  let color = colorScheme;
  if (section.colorScheme) {
    color = section.colorScheme;
  }
  const mappedOptions = mapper(section, CONTENT_MAP);

  return (
    <Notice
      color={color}
      {...mappedOptions}
      isDismissible={section.isDismissible}
    />
  );
};

NoticeWrapper.defaultProps = {};

export const fragment = graphql`
  fragment NoticeFields on ContentfulNotice {
    id
    isActive
    isDismissible
    colorScheme
    content {
      childMarkdownRemark {
        html
      }
    }
  }
`;

NoticeWrapper.fragment = fragment;
