import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { Box, Flex } from 'rebass';
import ButtonAction from '../ButtonAction/ButtonAction';
import { theme } from '../common/theme';
import styled, { css } from 'styled-components';
import ShowAt from '../Responsive/ShowAt';

const bgImageStyles = image => {
  return css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${image});
  `;
};

const StyledImageContainer = styled(Box)`
  position: relative;
`;

const StyledimageOverlay = styled(Box)`
  ${({ imageOverlay }) => bgImageStyles(imageOverlay)};
  @media (min-width: ${theme.breakpoints[3]}) {
    ${props => bgImageStyles(props.imageOverlay)};
  }
  @media (max-width: ${theme.breakpoints[2]}) {
    opacity: ${theme.opacity.full} !important;
  }

  position: absolute;
  top: 30%;
  right: 5%;
  width: 100px;
  height: 100px;
  margin: -60px 0 0 -60px;
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
  @keyframes spin {
    100% {
      transform: 'rotate(360deg)';
    }
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const SectionCard = props => {
  const { title, image, imageOverlay, text, color, link } = props;
  const [style, setStyle] = useState({ opacity: theme.opacity.none });
  const CardWrapper = link && link.as ? link.as : React.Fragment;
  //Remove once deployed and ContentGeneric removed as module option.
  if (color.__typename === 'ContentfulContentGeneric') return null;

  return (
    <CardWrapper {...(link ? link : {})}>
      <Flex
        my={[1, 3, 0]}
        flexDirection={['column', 'row', 'column']}
        alignItems={'center'}
        imageOverlay={imageOverlay}
        borderColor={color}
        onMouseEnter={() => {
          setStyle({ opacity: theme.opacity.full });
        }}
        onMouseLeave={() => {
          setStyle({ opacity: theme.opacity.none });
        }}
      >
        {image && (
          <StyledImageContainer width={[9 / 12]} mb={[3, 0, 3]}>
            <img src={image} alt={title} />
            {imageOverlay && (
              <StyledimageOverlay
                imageOverlay={imageOverlay}
                alt={title}
                style={style}
              />
            )}
          </StyledImageContainer>
        )}
        <Flex
          flexDirection={['column']}
          alignItems={['center', 'flex-start', 'center']}
        >
          {title && (
            <Box style={{ maxWidth: '100%' }} mx={[1]}>
              <Heading
                size={'h1'}
                color={color}
                textAlign={['center', 'left', 'center']}
                data-testid="section-card-title"
              >
                {title}
              </Heading>
            </Box>
          )}
          {text && (
            <Box mt={[1, 3]} style={{ maxWidth: '100%' }}>
              <Text
                textAlign={['left', 'left', 'center']}
                size="body"
                html={text}
              />
            </Box>
          )}
          {link && link.as && (
            <Flex
              mt={[2, 3]}
              justifyContent={'flex-start'}
              alignItems={['flex-start', 'flex-start', 'center']}
              flexWrap={'wrap'}
              flexDirection={['column', 'row']}
              minHeight={['50px']}
            >
              <ShowAt breakpoint="mediumAndBelow">
                <ButtonAction
                  mb={[1, 1, 0]}
                  color={color}
                  isInverse
                  {...link}
                  icon={theme.icons.arrowRight}
                  isIconAfter
                >
                  {link.displayName}
                </ButtonAction>
              </ShowAt>
              <ShowAt breakpoint="large">
                <ButtonAction
                  mb={[1, 1, 0]}
                  color={color}
                  isInverse
                  {...link}
                  style={style}
                  icon={theme.icons.arrowRight}
                  isIconAfter
                >
                  {link.displayName}
                </ButtonAction>
              </ShowAt>
            </Flex>
          )}
        </Flex>
      </Flex>
    </CardWrapper>
  );
};

export default SectionCard;

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageOverlay: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.oneOf([
    'baseBlack',
    'brandGreen',
    'brandPurple',
    'brandViolet',
    'brandRed',
    'brandYellow',
    'brandNavy',
    'black',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]).isRequired,
  link: PropTypes.func,
  label: PropTypes.string,
};

SectionCard.defaultProps = {
  title: null,
  image: null,
  imageOverlay: null,
  text: null,
  color: 'baseBlack',
  link: {},
};
