import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../common/theme';

const Icon = styled.div`
  margin-left: ${theme.space[1]}px;
  width: 25px;
  &:hover {
    cursor: pointer;
  }

  :after,
  :before,
  div {
    background-color: black;
    content: '';
    display: block;
    height: 2px;
    margin: 6px 0;
    transition: all ${theme.speed.default} ease-in-out;
  }

  ${({ isMenuActive }) =>
    isMenuActive
      ? `
    :before {
      transform: translateY(8px) rotate(45deg);
    }

    :after {
      transform: translateY(-8px) rotate(-45deg);
    }

    div {
      transform: scale(0);
    }`
      : ''}
`;

const MenuIcon = ({ isMenuActive }) => {
  return (
    <Icon isMenuActive={isMenuActive}>
      <div />
    </Icon>
  );
};

MenuIcon.propTypes = {
  /** is the menu active? */
  isMenuActive: PropTypes.bool,
};

MenuIcon.defaultProps = {
  isMenuActive: false,
};

export default MenuIcon;
