import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { theme } from '../common/theme';
import Drawing from '../Drawing/Drawing';
import Text from '../Text/Text';
import NavLink from './NavLink';

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const StyledCaretIcon = styled.i`
  font-size: 10px;
  transition: transform ${theme.speed.default} ease;
  transform: ${({ isSiloActive }) =>
    isSiloActive ? `rotate(180deg)` : `rotate(0deg)`};
`;

const StyledCaret = styled(Text)`
  cursor: pointer;
  border-radius: 2px;
  padding: 10px;
`;

const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  transition: color ${theme.speed.default} ease;
`;

const TextLogo = styled(Box)`
  min-width: 80px;
`;

/**
 * we set the min-width and min-height so that the parent flex does not alter the size of the logo
 * */
const ImageLogo = styled(Box)`
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  transition: transform ${theme.speed.default} ease,
    opacity ${theme.speed.default} ease;
  opacity: ${({ isSiloActive }) =>
    isSiloActive ? theme.opacity.full : theme.opacity.none};
  transform: translateX(
    ${({ isSiloActive }) => (isSiloActive ? '-40px' : '20px')}
  );
`;

/**
 *  Main navigation menu.
 *  Display the name of the current silo, as well as any links passed
 *  through as props.
 */
const Nav = ({
  siloName,
  isMobile,
  links,
  isSiloActive,
  onSiloToggle,
  ...props
}) => {
  return (
    <>
      <Box mx="auto" py={[2]} px={[2, 4]} bg={theme.colors.baseWhite}>
        <Flex alignItems={['center']}>
          <Box width={[9 / 12, 9 / 12, 6 / 12, 4 / 12]} mr={1}>
            <Flex alignItems="center" flexDirection="row">
              <TextLogo
                mr={[1, 2]}
                width={['80px', '110px']}
                onClick={onSiloToggle}
              >
                <Drawing name="textlogo" />
              </TextLogo>
              <TruncatedText
                size={['intro']}
                fontWeight={theme.fontWeight.bold}
                isMobile={isMobile}
                mr={[1, 2]}
              >
                {siloName}
              </TruncatedText>
              <StyledCaret
                bg={theme.colors.baseGrayVeryLight}
                onClick={onSiloToggle}
              >
                <StyledCaretIcon
                  isSiloActive={isSiloActive}
                  className={`icon icon-${theme.icons.angleDown}`}
                />
              </StyledCaret>
            </Flex>
          </Box>
          <Box width={[3 / 12, 3 / 12, 6 / 12, 8 / 12]}>
            <StyledFlex
              justifyContent="flex-end"
              alignItems="center"
              flexDirection="row"
              mr="-25px"
            >
              {links.map(
                ({ hideOnMobile, text, icon, ...link }, index, arr) => (
                  <NavLink
                    key={index}
                    color={'baseBlack'}
                    isVisible={!(isMobile && hideOnMobile) && !isSiloActive}
                    hideOnMobile={isMobile && hideOnMobile}
                    isLast={arr.length - 1 === index ? true : false}
                    icon={icon}
                    text={text}
                    isSiloActive={isSiloActive}
                    {...link}
                  />
                ),
              )}
              <ImageLogo isSiloActive={isSiloActive}>
                <Drawing name="logo" />
              </ImageLogo>
            </StyledFlex>
          </Box>
        </Flex>
      </Box>
      {props.children}
    </>
  );
};

Nav.propTypes = {
  /** Toggle the silo click handler */
  onSiloToggle: PropTypes.func,
  /** Name of the currect, active silo */
  siloName: PropTypes.string,
  /** Is this the mobile view? */
  isMobile: PropTypes.bool,
  /** Links array as part of the nav */
  links: PropTypes.array,
  /** Is the Silo active */
  isSiloActive: PropTypes.bool,
  /** Children */
  children: PropTypes.node,
  /** Color of logos and text */
  textColor: PropTypes.oneOf(Object.keys(theme.colors)),
  /** Transition status of parent page */
  transitionStatus: PropTypes.string,
  /** Should the Nav animate? */
  shouldAnimate: PropTypes.bool,
};

Nav.defaultProps = {
  siloName: 'me',
  isMobile: false,
  links: [],
  isSiloActive: false,
  onSiloToggle: () => {},
  textColor: 'baseWhite',
  transitionStatus: '',
  shouldAnimate: false,
};

export default Nav;
