import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../../common/theme';
import { Box } from 'rebass';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: solid 1px ${theme.colors.baseBlack};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background: ${props =>
    props.disabled ? theme.colors.baseGrayLight : theme.colors.baseWhite};
  i {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    font-size: 10px;
  }
`;

const CheckboxNative = ({ checked, disabled, ...props }) => (
  <Box
    className="CheckboxNative_Box"
    width={`${theme.space[2]}px`}
    height={`${theme.space[2]}px`}
  >
    <HiddenCheckbox checked={checked} disabled={disabled} {...props} />
    <StyledCheckbox checked={checked} disabled={disabled} {...props}>
      <i className="icon icon-check" />
    </StyledCheckbox>
  </Box>
);

export default CheckboxNative;

CheckboxNative.propTypes = {
  /** Should the Checkbox be checked by default? */
  checked: PropTypes.bool,
  /** Should the Checkbox be disabled by default? */
  disabled: PropTypes.bool,
};

CheckboxNative.defaultProps = {
  checked: false,
  disabled: false,
};
