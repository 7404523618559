import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { flex } from 'styled-system';
import { theme } from '../common/theme';
import { convertStringToArray } from '../common/utils';
import { Container } from '../Grid';

const StyledHeader = styled(Box)`
  ${flex};
  display: block;
  position: relative;
  text-align: ${props => props.align || 'left'};
  margin-left: ${props =>
    props.align === 'right' || props.align === 'center' ? 'auto' : 'unset'};
  margin-right: ${props =>
    props.align === 'left' || props.align === 'center' ? 'auto' : 'unset'};
`;

const StyledHeaderWrapper = styled(Container)`
  display: flex;
  ${props =>
    props.headerWidth &&
    css`
      @media only screen and (min-width: 160px) and (max-width: ${theme
          .breakpoints[1]}) {
        width: 100%;
      }
      @media only screen and (min-width: ${theme
          .breakpoints[1]}) and (max-width: ${theme.breakpoints[2]}) {
        width: 76%;
      }
      @media only screen and (min-width: ${theme
          .breakpoints[2]}) and (max-width: ${theme.breakpoints[3]}) {
        width: 65%;
      }
      @media only screen and (min-width: ${theme.breakpoints[3]}) {
        width: 45%;
      }
    `}
`;

const SectionHeader = props => {
  const {
    sectionTitle,
    intro,
    color,
    align,
    cta,
    arrows,
    width,
    ...rest
  } = props;

  const headerWidth = width ? convertStringToArray(width) : null;
  if (!sectionTitle && !intro && !cta) return null;
  return (
    <StyledHeaderWrapper
      justifyContent={!headerWidth && 'space-around'}
      headerWidth={headerWidth}
    >
      <StyledHeader
        width={!headerWidth && [1, 8 / 12]}
        flex={'0 1 auto'}
        textAlign={align}
        align={align}
        {...rest}
      >
        {sectionTitle && (
          <Heading size={'h1'} color={color} textAlign={align}>
            {sectionTitle}
          </Heading>
        )}
        {intro && (
          <Box mt={[1, 2]}>
            <Text size={'intro'} textAlign={align} html={intro} />
          </Box>
        )}
        {cta && <Box mt={[3, 5]}>{cta}</Box>}
      </StyledHeader>
      {arrows}
    </StyledHeaderWrapper>
  );
};

export default SectionHeader;

SectionHeader.propTypes = {
  /** Spacing below the component */
  mb: PropTypes.array,
  /** Headling of Section*/
  sectionTitle: PropTypes.string.isRequired,
  /** Supportive text */
  intro: PropTypes.string,
  /** Color of title */
  color: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
  /** Function for Calls to action (passes "align" prop to function) */
  cta: PropTypes.node,
  /** Alignment of text */
  align: PropTypes.oneOf(['left', 'right', 'center']),
  /** Arrows for section slider */
  arrows: PropTypes.node,
  /** Width of the component */
  width: PropTypes.array,
  /** Padding of the component */
  px: PropTypes.array,
};

SectionHeader.defaultProps = {
  mb: [3, 6],
  title: null,
  intro: null,
  color: 'baseBlack',
  align: 'left',
  cta: null,
  arrows: null,
};
