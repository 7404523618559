import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout, { LAYOUT_TYPES } from '../modules/layout';
import {
  CONTENT_TITLES,
  GTM_EVENT,
  GTM_CATEGORY,
  GTM_LOCATION,
} from '../common/constants';
import { TESTIMONIAL_TYPES } from '../modules/TestimonialWrapper';
import Main from '../modules/main';

export const ProductPageContext = React.createContext();

const getLayout = (product, contactBlock, url) => {
  const links = [
    {
      title: CONTENT_TITLES.openAccount,
      label: CONTENT_TITLES.openAccount,
      type: 'Primary',
      to: 'https://online.sygnia.com/Alchemy/Account/SignUp',
      external: true,
      file: null,
      isInverse: true,
    },
  ];

  if (product.brochure) {
    links.push({
      title: product.brochure.title,
      label: 'Brochure',
      type: 'Download',
      to: null,
      external: true,
      file: product.brochure,
      isInverse: true,
      gtmEventInfo: {
        eventName: GTM_EVENT.BROCHURE_DOWNLOADED,
        data: {
          category: GTM_CATEGORY.DOCUMENT,
          label: CONTENT_TITLES.brochure,
          location: GTM_LOCATION.HERO,
        },
      },
    });
  }

  if (product.applicationPack) {
    links.push({
      title: product.applicationPack.title,
      label: CONTENT_TITLES.applicationPack,
      type: 'Download',
      to: null,
      external: true,
      file: product.applicationPack,
      isInverse: true,
      gtmEventInfo: {
        eventName: GTM_EVENT.APPLICATION_PACK_DOWNLOADED,
        data: {
          category: GTM_CATEGORY.DOCUMENT,
          label: CONTENT_TITLES.applicationPack,
          location: GTM_LOCATION.HERO,
        },
      },
    });
  }

  let layout = [
    {
      __typename: LAYOUT_TYPES.HELMET,
      summary: product.productSummary,
      url: url,
    },
    {
      __typename: LAYOUT_TYPES.URL_OBSERVER,
      queryParameter: 'qna',
      style: {
        maxWidth: '80%',
        margin: 'auto',
      },
    },
    {
      __typename: LAYOUT_TYPES.HERO_TITLE,
      title: product.title,
      hasLogo: true,
      subtitle: [product.subHeading],
      excerpt: product.description,
      colorScheme: 'green',
      links: links,
    },
    {
      __typename: 'ContentfulLayoutTestimonial',
      layout: TESTIMONIAL_TYPES.FULL_WIDTH_BACKGROUND,
      testimonial:
        product.testimonials && product.testimonials.length
          ? product.testimonials[0]
          : null,
    },
    ...product.modules,
  ];

  if (contactBlock) {
    layout = [...layout, contactBlock];
  }

  return layout;
};

const ProductTemplate = props => {
  const url = props.location.href;
  const post = get(props, 'data.contentfulProduct');
  const contactBlock = get(props, 'pageContext.contactBlock');
  const layout = getLayout(post, contactBlock, url);

  return (
    <ProductPageContext.Provider value={post}>
      <Main {...props} page={post}>
        <Layout layout={layout} contentType={'products'} />
      </Main>
    </ProductPageContext.Provider>
  );
};

export default ProductTemplate;

export const LayoutFieldsFragment = graphql`
  fragment LayoutProductFields on ContentfulProduct {
    modules {
      __typename
      ... on Node {
        ...HeroLayoutFields
        ...TestimonialsLayoutFields
        ...SectionLayoutFields
        ...HeroImageLayoutFields
        ...ToggleLayoutFields
        ...SliderLayoutFields
        ...LayoutColumnFields
        ...LayoutContentFields
        ...UrlObserverFields
        ...NoticeFields
      }
    }
  }
`;

export const pageQuery = graphql`
  query ProductPostBySlug($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      title
      subHeading
      productSummary {
        title
        image {
          file {
            url
          }
        }
        content {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      testimonials {
        byLine
        eyeBrow
        background {
          fluid(quality: 100, maxWidth: 2400) {
            src
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      brochure {
        title
        file {
          fileName
          url
        }
      }
      applicationPack {
        title
        file {
          fileName
          url
        }
      }
      ...LayoutProductFields
    }
  }
`;
