import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../../Text/Text';
import { theme } from '../../common/theme';
import { rgba } from '../../common/utils';
import { Box } from 'rebass';

const StyledWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
`;

const StyledIcon = styled.i`
  color: ${props => props.color};
  padding-right: ${theme.space[1]}px;
  font-size: ${theme.fontSizes.body};
`;

const getStatusScheme = {
  success: {
    color: theme.colors.statusSuccess,
    bg: rgba(theme.colors.statusSuccess, 0.1),
    icon: theme.icons.success,
  },
  info: {
    color: theme.colors.statusInfo,
    bg: rgba(theme.colors.statusInfo, 0.1),
    icon: theme.icons.info,
  },
  warning: {
    color: theme.colors.statusWarning,
    bg: rgba(theme.colors.statusWarning, 0.1),
    icon: theme.icons.warning,
  },
  error: {
    color: theme.colors.statusError,
    bg: rgba(theme.colors.statusError, 0.1),
    icon: theme.icons.error,
  },
};

const Alert = props => {
  const { text, status, icon, ...rest } = props;

  return (
    <StyledWrapper {...rest} bg={getStatusScheme[status].bg}>
      <StyledIcon
        className={
          icon
            ? `icon icon-${theme.icons[icon]}`
            : `icon icon-${getStatusScheme[status].icon}`
        }
        color={getStatusScheme[status].color}
      />
      <Text size="small" color={getStatusScheme[status].color} html={text} />
    </StyledWrapper>
  );
};

export default Alert;

Alert.propTypes = {
  /** Status of the Alert */
  status: PropTypes.oneOf(Object.keys(getStatusScheme)).isRequired,
  /** Text as content */
  text: PropTypes.string.isRequired,
  /** Icon can be overwritten by a custom one from theme */
  icon: PropTypes.oneOf(Object.keys(theme.icons)),
};

Alert.defaultProps = {
  text: null,
  status: 'error',
};
