import React from 'react';
import { CardHero } from '@sygnia/components';
import { graphql } from 'gatsby';
import { mapperWithFunction, getEmText } from '../common/utils';
import LinkGroup from './LinkGroup';
import Layout, { LAYOUT_TYPES } from './layout';
import { get } from 'lodash';
const CONTENT_MAP = {
  image: ['image', 'fluid', 'src'],
  images: value => {
    return {
      small: get(value.small, 'fluid.src'),
      medium: get(value.medium, 'fluid.src'),
      large: get(value.large, 'fluid.src'),
      xlarge: get(value.xlarge, 'fluid.src'),
    };
  },
  text: value => {
    return (
      <Layout
        layout={[
          {
            __typename: LAYOUT_TYPES.CONTENT_BODY,
            scrollTabs: false,
            isRich: true,
            isWrapped: false,
            document: value.description ? value.description.document : null,
          },
        ]}
      />
    );
  },
};

export const HeroImageWrapper = props => {
  const { colorScheme, section, transitionStatus } = props;
  const meta = section.meta?.internal
    ? JSON.parse(section.meta.internal.content)
    : null;

  let color = colorScheme;
  if (section.colorScheme) {
    color = section.colorScheme;
  }
  const mappedOptions = mapperWithFunction(section, CONTENT_MAP);

  const renderCta = section.links ? (
    <LinkGroup
      color={color}
      links={section.links || []}
      meta={meta ? meta : {}}
    />
  ) : null;

  let title = <span>{section.title}</span>;
  if (section.emphasis) {
    title = getEmText(section.title, section.emphasis);
  }

  const { title: _title, ...restSection } = section;

  return (
    <CardHero
      {...restSection}
      {...mappedOptions}
      heroTitle={title}
      color={color}
      renderCta={renderCta}
      transitionStatus={transitionStatus}
      shouldAnimate={true}
    />
  );
};

HeroImageWrapper.defaultProps = {
  section: null,
};

export const HeroLayoutFields = graphql`
  fragment HeroImageLayoutFields on ContentfulLayoutHeroImage {
    id
    eyeBrow
    title
    emphasis
    colorScheme
    isFeatured
    isAlt: reverseOrder
    description {
      document: json
    }

    xlarge: image {
      fluid(quality: 100, maxWidth: 1200) {
        src
      }
    }
    large: image {
      fluid(quality: 100, maxWidth: 992) {
        src
      }
    }
    medium: image {
      fluid(quality: 100, maxWidth: 768) {
        src
      }
    }
    small: image {
      fluid(quality: 100, maxWidth: 576) {
        src
      }
    }
    links {
      ...LinkFields
    }
    meta {
      internal {
        content
      }
    }
  }
`;

HeroImageWrapper.fragment = HeroLayoutFields;
