import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../common/theme';

const StyledItem = styled.li`
  font-family: ${theme.fonts.sans};
  font-weight: ${({ isActive }) => (isActive ? 'normal' : 'bold')};
  font-size: 16px;
  @media (max-width: ${theme.breakpoints[1]}) {
    font-size: 14px;
  }
  line-height: 24px;
  display: block;
  position: relative;
  margin-bottom: 0.5em;
  margin-left: 2em;

  &::before {
    content: counter(item) '.';
    counter-increment: item;
    position: absolute;
    top: 0;
    left: -2em;
    width: 1.5em;
    text-align: right;
  }
  span {
    margin-left: 0.9em;
    display: inline-block;
    text-decoration: ${({ isActive }) =>
      isActive ? 'no-decoration' : 'underline'};
    &:hover {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
  }
`;

const IndexItem = ({ label }) => {
  return (
    <StyledItem fontSize={[1, 6]}>
      <span>{label}</span>
    </StyledItem>
  );
};

export default IndexItem;

IndexItem.propTypes = {
  /** Is this the current page? */
  isActive: PropTypes.bool,
  /** Link label */
  label: PropTypes.string,
};
