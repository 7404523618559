import React from 'react';
import { HeroEditorial, GuideBreadcrumbs } from '@sygnia/components';
import { graphql } from 'gatsby';
import { mapper, truncateStr, setupLink } from '../common/utils';
import { useLocation } from '@reach/router';
import { ShareWrapper } from './ShareWrapper';

const CONTENT_MAP = {
  image: ['image', 'fluid', 'src'],
  title: ['title'],
  description: ['description', 'childMarkdownRemark', 'html'],
};

const fallbackColors = {
  yellow: {
    bg: '#FFCC57',
    title: 'baseBlack',
    button: 'baseBlack',
    description: 'baseBlack',
  },
};

export const HeroEditorialWrapper = props => {
  const { section } = props;
  const { pathname } = useLocation();

  const meta = section.meta?.internal
    ? JSON.parse(section.meta.internal.content)
    : null;

  const formatLinks = links => {
    return links.map(item => {
      const link = setupLink(item, {});
      return {
        ...link,
        label: item?.label,
        title: item?.title,
      };
    });
  };

  const findIndexBySlug = (guides, slug) => {
    if (!guides || !slug) return null;
    const index = guides.findIndex(obj => obj.slug === slug);
    return index < 10 ? `0${index + 1}` : (index + 1).toString();
  };

  const category = section.category;
  const breadcrumbItems = section.parentReference
    ? [
        {
          label: truncateStr(section.parentReference.category.title),
          title: section.parentReference.category.title,
        },
        {
          to: `/${section.parentReference.slug}`,
          label: truncateStr(section.parentReference.headline),
          title: section.parentReference.headline,
        },
        {
          label: `CHAPTER ${findIndexBySlug(
            section.parentReference.guides,
            section.slug,
          )}`,
        },
      ]
    : [
        {
          label: truncateStr(category?.title),
          title: category?.title,
        },
        {
          label: truncateStr(section?.title),
          title: section?.title,
        },
      ];
  const colors = meta?.colors || fallbackColors.yellow;
  if (section.heroBGColor) colors.bg = section.heroBGColor;
  const mappedOptions = mapper(section, CONTENT_MAP);

  return (
    <HeroEditorial
      {...section}
      {...mappedOptions}
      colors={colors}
      share={<ShareWrapper section={section} />}
    >
      {breadcrumbItems && (
        <GuideBreadcrumbs items={formatLinks(breadcrumbItems)} />
      )}
    </HeroEditorial>
  );
};

HeroEditorialWrapper.defaultProps = {
  section: null,
};

export const fragment = graphql`
  fragment HeroEditorialLayoutFields on ContentfulLayoutHeroEditorial {
    title
    description {
      description
    }
    meta {
      internal {
        content
      }
    }
    image {
      fluid {
        src
      }
    }
  }
`;

HeroEditorialWrapper.fragment = fragment;
