import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { KPI } from '@sygnia/components';
import { Flex } from 'rebass';

const CountdownTimer = ({ section, ...props }) => {
  const calculateTimeLeft = () => {
    const _date = new Date();
    const inc = 2000 * 60 * 60;

    const difference = new Date(section.date) - new Date(_date.getTime() + inc);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timeoutID = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timeoutID);
  }, []);

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (timeLeft[interval] >= 0) {
      timerComponents.push(<KPI value={timeLeft[interval]} title={interval} />);
    }
  });

  if (!timerComponents.length) return null;

  return <Flex mt={[2, 4]}>{timerComponents}</Flex>;
};

export default CountdownTimer;
