export function calculateTax(income, age, withdrawalAmount) {
  console.log(income, age, withdrawalAmount, 'income, age, withdrawalAmount');
  let tax = 0;
  const brackets = [
    { threshold: 237100, rate: 0.18 },
    { threshold: 370500, rate: 0.26 },
    { threshold: 512800, rate: 0.31 },
    { threshold: 673000, rate: 0.36 },
    { threshold: 857900, rate: 0.39 },
    { threshold: 1817000, rate: 0.41 },
    { threshold: Infinity, rate: 0.45 },
  ];

  let previousThreshold = 0;
  for (const bracket of brackets) {
    if (income > bracket.threshold) {
      tax += (bracket.threshold - previousThreshold) * bracket.rate;
      previousThreshold = bracket.threshold;
    } else {
      tax += (income - previousThreshold) * bracket.rate;
      break;
    }
  }
  // Apply rebates based on age
  let rebate = 0;
  if (age < 65) {
    rebate = 17235;
  } else if (age < 75) {
    rebate = 9444 + 17235;
  } else {
    rebate = 3145 + 9444 + 17235;
  }

  tax -= rebate;
  if (tax < 0) {
    tax = 0; // No negative tax
  }

  console.log(withdrawalAmount * (tax / income), 'results');
  return (withdrawalAmount * (tax / income)).toFixed(2);
}
