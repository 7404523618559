import React from 'react';
import ShowAt from '../Responsive/ShowAt';
import StageSelector from './StageSelector';
import StageSelectorMobile from './StageSelectorMobile';

const StageSelectorWrapper = props => (
  <>
    <ShowAt breakpoint="mediumAndBelow">
      <StageSelectorMobile {...props} />
    </ShowAt>
    <ShowAt breakpoint="large">
      <StageSelector {...props} />
    </ShowAt>
  </>
);

export default StageSelectorWrapper;
