import React from 'react';
import { graphql } from 'gatsby';
import { Flex, Box } from 'rebass';
import Layout, { LAYOUT_TYPES } from './layout';
import { theme, rgba, Heading } from '@sygnia/components';

export const colorMap = {
  black: {
    bg: theme.colors.baseBlack,
  },
  green: {
    bg: theme.colors.brandGreenPale,
  },
  purple: {
    bg: theme.colors.brandPurple,
  },
  violet: {
    bg: theme.colors.brandVioletPale,
  },
  red: {
    bg: theme.colors.brandRedPale,
  },
  yellow: {
    bg: theme.colors.brandYellowPale,
  },
  navy: {
    bg: theme.colors.brandNavyPale,
  },
  gray: {
    bg: theme.colors.baseGrayVeryLight,
  },
  whiteYellow: {
    bg: rgba(theme.colors.brandYellowPale, 0.5),
  },
  whiteRed: {
    bg: rgba(theme.colors.brandRedPale, 0.5),
  },
};

export const BoxWrapper = ({ section, colorScheme }) => {
  let color = colorScheme;
  if (section.colorScheme) {
    color = section.colorScheme;
  }

  return (
    <Flex justifyContent="center" bg={colorMap[color].bg}>
      <Box {...section.style} style={{ textAlign: 'center' }}>
        <Layout
          layout={[
            {
              __typename: LAYOUT_TYPES.CONTENT_BODY,
              scrollTabs: false,
              isRich: true,
              isWrapped: false,
              document: section.description
                ? section.description.document
                : null,
            },
          ]}
        />
      </Box>
    </Flex>
  );
};

BoxWrapper.defaultProps = {
  section: {},
  colorScheme: 'white',
};

export const fragment = graphql`
  fragment BoxLayoutFields on ContentfulLayoutBox {
    name
    description {
      document: json
    }
    style {
      p
      my
      width
      bg
      textAlign
    }
  }
`;

BoxWrapper.fragment = fragment;
