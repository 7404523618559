import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Slider } from '@sygnia/components';
import { TestimonialWrapper } from './TestimonialWrapper';
import { TESTIMONIAL_TYPES } from '../modules/TestimonialWrapper';

export const RelatedFundTestimonials = () => {
  const relatedTestimonials = useStaticQuery(graphql`
    query relatedTestimonials {
      allContentfulTestimonials(filter: { tag: { in: ["Funds"] } }) {
        nodes {
          ...TestimonialFields
        }
        totalCount
      }
    }
  `);

  const { nodes, totalCount } = relatedTestimonials.allContentfulTestimonials;
  let ComponentWrapper = totalCount > 1 ? Slider : React.Fragment;
  return (
    <ComponentWrapper arrowColorScheme="light">
      {nodes.map((t, index) => (
        <TestimonialWrapper
          key={index}
          section={{
            testimonial: t,
            layout: t.layout || TESTIMONIAL_TYPES.FULL_WIDTH_BACKGROUND,
            setMinHeight: totalCount > 1 ? true : false,
          }}
        />
      ))}
    </ComponentWrapper>
  );
};
