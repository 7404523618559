import React from 'react';
import { Container, Row, Column, Heading, theme } from '@sygnia/components';
import styled from 'styled-components';
import { BasicContent } from './ContentWrapper';
import { mapper } from '../common/utils';

const StyledTitle = styled(Heading)`
  @media (max-width: ${theme.breakpoints[1]}) {
    font-size: 25px;
  }
  @media (max-width: ${theme.breakpoints[2]}) {
    font-size: 26px;
  }
`;

const StyledContainer = styled(Container)`
  @media only screen and (min-width: 160px) and (max-width: ${theme
      .breakpoints[1]}) {
    width: 100%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[1]}) and (max-width: ${theme.breakpoints[2]}) {
    width: 76%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[2]}) and (max-width: ${theme.breakpoints[3]}) {
    width: 65%;
  }
  @media only screen and (min-width: ${theme.breakpoints[3]}) {
    width: 45%;
  }
`;
const CONTENT_MAP = {
  image: ['image', 'fluid', 'src'] || null,
  title: ['title'] || null,
  id: ['contentful_id'] || null,
};

export const GuideContentWrapper = props => {
  const { section } = props;
  const mappedOptions = mapper(section, CONTENT_MAP);
  return (
    <Container>
      <StyledContainer id={mappedOptions.id} mt={[0, 8]} mx="auto">
        <Row
          flexDirection={['column', 'column', 'column']}
          justifyContent={['center']}
          flexWrap={'wrap'}
        >
          {mappedOptions.image && (
            <Column
              width={[1 / 2, 1 / 3, 1 / 4, 1 / 6]}
              mt={[3, 3, 3, 0]}
              mx="auto"
              mb={[5, 3, 3, 8]}
              order={0}
            >
              <img src={mappedOptions.image} alt="" />
            </Column>
          )}
          {mappedOptions.title && (
            <Column mx={0}>
              <StyledTitle mb={3} size="h1">
                {mappedOptions.title}
              </StyledTitle>
            </Column>
          )}

          <Column mb={[1, 2, 2, 0]} mx={0}>
            <BasicContent {...props} />
          </Column>
        </Row>
      </StyledContainer>
    </Container>
  );
};

GuideContentWrapper.defaultProps = {
  mb: 0,
  contentGrid: {
    width: [1, 1, 1, 7 / 12],
    mb: [3, 3, 3, 0],
    mx: 0,
    order: 0,
    flex: '0 0 auto',
  },
};
