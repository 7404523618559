import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import Text from '../Text/Text';
import { borders } from 'styled-system';
import { theme } from '../common/theme';
import { Row, Column } from '../Grid';
import Heading from '../../components/Heading/Heading';
import ListMeta from '../ListMeta/ListMeta';

const StyledItem = styled(Box)`
  ${borders};
  /* Reset anchor tag defaults */
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
    background: ${theme.colors.baseGrayVeryLight};
  }
`;

/** The single ListPost will be rendered as part of the list of links <ListLinks/> component */
export const ListPost = props => {
  const { title, meta, intro } = props;

  return (
    <StyledItem
      py={[2, 4]}
      px={'2'}
      borderTop={'1px solid'}
      borderColor={'baseGrayLight'}
    >
      <Row>
        <Column width={[1, 1 / 5]}>{meta && <ListMeta meta={meta} />}</Column>
        <Column width={[1, 4 / 5]}>
          {title && (
            <Heading size="h3" mb={1}>
              {title}
            </Heading>
          )}
          {intro && typeof intro === 'string' && (
            <Text size="body" mb={0} lineHeight={'1.5'} html={intro} />
          )}
        </Column>
      </Row>
    </StyledItem>
  );
};

ListPost.propTypes = {
  /** Title of the post */
  title: PropTypes.string.isRequired,
  /** Introduction to post */
  intro: PropTypes.string,
  /** Publishing meta */
  meta: PropTypes.array,
};

ListPost.defaultProps = {
  title: null,
  meta: null,
  intro: null,
};

export default ListPost;
