import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import { ListLink } from './ListLink';
import Heading from '../Heading/Heading';
import { theme } from '../common/theme';

const StyledList = styled(Box)`
  display: block;
  width: 100%;
  & a:first-of-type,
  & button:first-of-type {
    padding-top: 5px;
  }
`;

/** ListLinks is used to render a list of link items */
export const ListLinks = props => {
  const { listTitle, items, renderCta, color, bg, style } = props;

  return (
    <StyledList mt={listTitle ? [0] : [0, 10]} mb={[0, 5]} {...style}>
      {listTitle && (
        <Heading size="h3" color={color} mb={[1, 2]} lineHeight="1.55">
          {listTitle}
        </Heading>
      )}
      {items.map((i, index) => (
        <ListLink borderColor={i.borderColor} {...i} key={index} bg={bg} />
      ))}
      {renderCta && <Box mt={[1, 3]}>{renderCta}</Box>}
    </StyledList>
  );
};

ListLinks.propTypes = {
  /** Title of List Links */
  listTitle: PropTypes.string,
  /** Items to be listed */
  items: PropTypes.array.isRequired,
  /** Render prop after list */
  renderCta: PropTypes.node,
  /** Color used to change title color and arrow of ListLink*/
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  bg: PropTypes.oneOf(Object.keys(theme.colors)),
  style: PropTypes.object,
};

ListLinks.defaultProps = {
  title: null,
  items: [],
  renderCta: null,
  color: 'baseBlack',
  bg: 'baseWhite',
  style: {},
};

export default ListLinks;
