import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import { theme } from '../common/theme';

const StyledButton = styled(Box)`
  display: flex;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: 1px solid ${({ color }) => color.border};
  background: transparent;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  color: ${({ color }) => color.arrow};
  opacity: ${({ isDisabled }) =>
    isDisabled ? theme.opacity.faint : theme.opacity.medium};
  transition-property: opacity, background-color;
  transition-duration: ${theme.speed.default};
  transition-timing-function: ease;
  :hover {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ isDisabled }) =>
      isDisabled ? theme.opacity.faint : theme.opacity.full};
  }
`;

const StyledIcon = styled.i`
  font-size: 14px;
  transition: all ${theme.speed.default} ease;

  @media (min-width: ${theme.breakpoints[1]}) {
    ${StyledButton}:hover & {
      margin-left: ${props =>
        !props.isDisabled
          ? props.direction === 'right'
            ? props.isMouseDown
              ? '24px'
              : '16px'
            : '0px'
          : '0px'};
      margin-right: ${props =>
        !props.isDisabled
          ? props.direction === 'left'
            ? props.isMouseDown
              ? '24px'
              : '16px'
            : '0px'
          : '0px'};
    }
  }
`;

const getColors = {
  light: { arrow: theme.colors.baseWhite, border: theme.colors.baseWhite },
  dark: { arrow: theme.colors.baseBlack, border: theme.colors.baseGrayLight },
};

export function ButtonArrow(props) {
  const [down, toggleItem] = useState(false);

  const { onClick, isDisabled, direction, colorScheme, ...rest } = props;

  const color = getColors[colorScheme];

  return (
    <StyledButton
      onClick={onClick}
      onMouseDown={() => toggleItem(!down)}
      onMouseUp={() => toggleItem(!down)}
      isDisabled={isDisabled}
      color={color}
      {...rest}
      as="button"
      disabled={isDisabled ? true : false}
    >
      <StyledIcon
        isDisabled={isDisabled}
        direction={direction}
        isMouseDown={down}
        className={`icon icon-arrow-${direction}`}
      />
    </StyledButton>
  );
}

ButtonArrow.propTypes = {
  /** onClick function for the button */
  onClick: PropTypes.func,
  /** Direction of the icon arrow */
  direction: PropTypes.oneOf(['left', 'right']),
  /** Sets the button as disabled if needed */
  isDisabled: PropTypes.bool,
  /** Color scheme of the button */
  colorScheme: PropTypes.oneOf(['light', 'dark']),
};

ButtonArrow.defaultProps = {
  onClick: () => {},
  isMouseDown: true,
  direction: 'left',
  isDisabled: false,
  colorScheme: 'light',
};

export default ButtonArrow;
