import React from 'react';
import { connectHits, connectRefinementList } from 'react-instantsearch-dom';
import { Link } from 'gatsby';
import {
  ListPost,
  Text,
  Row,
  Column,
  Table,
  Container,
} from '@sygnia/components';
import { setupContent } from '../../common/utils';
import { CONTENT_TYPES, CONTENT_TITLES } from '../../common/constants';
import FundsRows from '../funds/FundsRow';
import { WebinarCardWrapper } from '../../modules/WebinarCardWrapper';
import { WebinarTable } from '../WebinarTable';
import { FundCardWrapper } from '../../modules/FundCardWrapper';
import { FundTable } from '../funds/FundTable';
import { FundPerformanceTable } from '../funds/FundPerformanceTable';
import FundArchive from '../funds/FundArchive';
import moment from 'moment';

export const RESULT_TYPES = {
  CAREERS_POST: 'CareersHit',
  NEWS_POST: 'NewsHit',
  FUNDS_POST: 'FundsHit',
  FUNDS_CARD: 'FundCard',
  FUNDS_ARCHIVE: 'FundsArchive',
  FUNDS_PERFORMANCE: 'FundPerformance',
  FUNDS_TABLE: 'FundTable',
  FUNDS_PERFORMANCE_TABLE: 'FundPerformanceTable',
  DOWLOAD_POST: 'DownloadHit',
  REPORT_POST: 'ReportHit',
  ANNOUNCEMENT: 'AnnouncementHit',
  WEBINAR_CARD: 'Webinars',
  WEBINAR_LIST: 'WebinarsList',
};

export const DownloadPost = props => {
  return (
    <a href={props.file.file.url} target="_blank">
      <ListPost title={props.title} meta={props.meta}></ListPost>
    </a>
  );
};

export const RESULT_MAPPING = {
  [RESULT_TYPES.CAREERS_POST]: {
    component: ListPost,
    link: value =>
      setupContent(null, value.slug, {}, CONTENT_TYPES.CAREERS_BASE),
    props: value => {
      return {
        meta: value.closingDate
          ? [moment(value.closingDate).format('DD MMM, YYYY')]
          : null,
        title: value.title,
        intro: value.summary,
      };
    },
  },
  [RESULT_TYPES.NEWS_POST]: {
    component: ListPost,
    link: value => setupContent(null, value.slug, {}, CONTENT_TYPES.NEWS_BASE),
    props: value => {
      return {
        meta: value.publishedDate
          ? [moment(value.publishedDate).format('DD MMM, YYYY')]
          : null,
        title: value.title,
        intro: value.summary,
      };
    },
  },
  [RESULT_TYPES.ANNOUNCEMENT]: {
    component: DownloadPost,
    props: value => {
      return {
        ...value,
        meta: value.date ? [moment(value.date).format('DD MMM, YYYY')] : null,
        title: value.title,
      };
    },
  },
  [RESULT_TYPES.FUNDS_POST]: {
    archive: FundArchive,
    as: RESULT_TYPES.FUNDS_TABLE,
    isWrapped: true,
  },
  [RESULT_TYPES.FUNDS_CARD]: {
    component: FundCardWrapper,
    props: value => {
      return {
        section: value,
      };
    },
  },
  [RESULT_TYPES.FUNDS_ARCHIVE]: {
    archive: FundArchive,
    as: RESULT_TYPES.FUNDS_CARD,
  },
  [RESULT_TYPES.FUNDS_PERFORMANCE]: {
    archive: FundArchive,
    as: RESULT_TYPES.FUNDS_PERFORMANCE_TABLE,
    isWrapped: true,
  },
  [RESULT_TYPES.FUNDS_TABLE]: {
    archive: FundTable,
  },
  [RESULT_TYPES.FUNDS_PERFORMANCE_TABLE]: {
    archive: FundPerformanceTable,
  },
  [RESULT_TYPES.DOWLOAD_POST]: {
    component: FundsRows,
    link: null,
    props: value => {
      const downloads = [];
      if (value.file) {
        downloads.push({
          label: CONTENT_TITLES.document,
          ...value.file,
        });
      }
      const includeCols = [];
      if (value.date) {
        includeCols.push({
          value:
            value.category && value.category === 'Forms'
              ? null
              : moment(value.date).format('DD MMM, YYYY'),
        });
      }

      return {
        meta: value.location ? [value.location] : null,
        title: value.title,
        slug: value.slug,
        downloads,
        includeCols,
      };
    },
  },
  [RESULT_TYPES.REPORT_POST]: {
    component: FundsRows,
    link: null,
    props: value => {
      const downloads = [];

      if (value.file) {
        downloads.push({
          label: CONTENT_TITLES.document,
          ...value.file,
        });
      }
      const includeCols = [];
      if (value.date) {
        includeCols.push({
          value: moment(value.date).format('DD MMM, YYYY'),
        });
      }

      includeCols.push({
        value: value.category || '',
      });

      return {
        meta: value.date ? [value.date] : null,
        title: value.title,
        slug: value.slug,
        downloads,
        includeCols,
      };
    },
  },
  [RESULT_TYPES.WEBINAR_CARD]: {
    component: WebinarCardWrapper,
    containerTopMargin: '-40px',
    props: value => {
      return {
        section: value,
      };
    },
  },
  [RESULT_TYPES.WEBINAR_LIST]: {
    archive: WebinarTable,
    isWrapped: true,
  },
};

const Hits = ({ hits, view, ...rest }) => {
  const section = RESULT_MAPPING[view.hitComp];
  if (!section) return null;

  if (section.archive) {
    const ArchiveComponent = section.archive;

    if (section.isWrapped) {
      return (
        <Container px={[0]}>
          <ArchiveComponent hits={hits} view={view} {...section} {...rest} />
        </Container>
      );
    }

    return <ArchiveComponent hits={hits} view={view} {...section} {...rest} />;
  }

  if (!hits.length) {
    return (
      <Container>
        <Text size="body" fontStyle="italic" ml={2}>
          {CONTENT_TITLES.noResults}
        </Text>
      </Container>
    );
  }

  const { component: Component } = section;
  const content = hits.map((hit, index) => {
    let GridWrapper = view.isCard ? Column : React.Fragment;
    let LinkWrapper = section.link ? Link : React.Fragment;

    return (
      <GridWrapper flex="0 0 auto" width={[1, 1 / 3]} key={index}>
        <LinkWrapper {...(section.link ? section.link(hit) : {})}>
          <Component {...section.props(hit)} />
        </LinkWrapper>
      </GridWrapper>
    );
  });

  const WrapperComponent = view.wrapperComponent
    ? view.wrapperComponent
    : React.Fragment;
  const ResultWrapper = view.fullWidth ? WrapperComponent : Container;

  if (view.isCard) {
    return (
      <ResultWrapper color="gray">
        <Row
          flexDirection={['column', 'column', 'row']}
          alignItems={['center', 'center', 'stretch']}
          flexWrap={'wrap'}
        >
          {content}
        </Row>
      </ResultWrapper>
    );
  } else if (view.isTable) {
    return (
      <div className="responsive-table">
        <ResultWrapper>
          <Table.Table>
            <tbody>{content}</tbody>
          </Table.Table>
        </ResultWrapper>
      </div>
    );
  }

  return <ResultWrapper>{content}</ResultWrapper>;
};

export default Hits;
export const CustomHits = connectHits(Hits);
export const VirtualMenu = connectRefinementList(() => null);
