import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { theme } from '../common/theme';
import Text from '../Text/Text';

const StyledOptionWrapper = styled(Flex)`
  margin: ${theme.space[2]}px ${theme.space[2]}px ${theme.space[0]}px
    ${theme.space[0]}px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledOptionLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.showOnRight ? 'space-between' : 'flex-start'};
  &:hover {
    cursor: pointer;
  }
`;

const StyledOptionInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const StyledOptionIcon = styled(Flex)`
  border-radius: 100%;
  width: ${theme.space[3]}px;
  height: ${theme.space[3]}px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.baseGrayDark};
  flex: 0 0 auto;
  margin-right: ${props =>
    props.showOnRight ? `${theme.space[0]}px` : `${theme.space[1]}px`};
  margin-left: ${props =>
    props.showOnRight ? `${theme.space[1]}px` : `${theme.space[0]}px`};

  i {
    font-size: 12px;
    transition: all ${theme.speed.fast} ease;
    opacity: 0;
    /* opacity: ${props => (props.isSelected === true ? '1' : '0')}; */
    ${StyledOptionInput}:checked ~ &{
      opacity: 1;
    }
  }
`;

export const Option = props => {
  const {
    optionsLabel,
    label,
    isSelected,
    isMultiSelect,
    optionId,
    showOnRight,
    ...rest
  } = props;
  const OptionComponent = (
    <>
      <StyledOptionInput
        id={optionId}
        checked={isSelected}
        type={isMultiSelect ? 'checkbox' : 'radio'}
        name={isMultiSelect ? label : optionsLabel}
        {...rest}
      />
      <StyledOptionIcon className="StyledOptionIcon" showOnRight={showOnRight}>
        <i className="icon icon-check" />
      </StyledOptionIcon>
    </>
  );

  return (
    <StyledOptionWrapper>
      <StyledOptionLabel
        className="StyledOptionLabel"
        showOnRight={showOnRight}
      >
        {!showOnRight && OptionComponent}
        <Text size="body">{label}</Text>
        {showOnRight && OptionComponent}
      </StyledOptionLabel>
    </StyledOptionWrapper>
  );
};

Option.propTypes = {
  optionId: PropTypes.number,
  optionsLabel: PropTypes.string,
  label: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  textSize: PropTypes.string,
  showOnRight: PropTypes.bool,
};

Option.defaultProps = {
  optionId: null,
  optionsLabel: 'Please select',
  label: 'Option label',
  isSelected: false,
  isMultiSelect: false,
  showOnRight: true,
};

export default Option;
