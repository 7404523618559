import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '@sygnia/components';
import { Link } from 'gatsby';
import { connectHits } from 'react-instantsearch-dom';
import { RESULT_MAPPING } from '../hitComps';
import { setupContent, setupLink } from '../../../common/utils';
import LinkGroup, { BUTTON_TYPES } from '../../../modules/LinkGroup';
import { CONTENT_TYPES } from '../../../common/constants';

export const RESULT_TYPES = {
  CAREERS_POST: 'CareersHit',
  NEWS_POST: 'NewsHit',
  FUNDS_POST: 'FundsHit',
  DOWLOAD_POST: 'DownloadHit',
};

const StyledSearchResult = styled.div`
  background: ${props => props.theme.colors.baseWhite};
  padding: 20px;
  margin-bottom: 1px;
  border-bottom: 1px solid transparent;
  :hover {
    background: #eee;
  }
`;

const StyledSearchResultHeader = styled.div`
  margin-top: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.baseBlack};
`;

const SearchMenuListPost = props => {
  return (
    <StyledSearchResult>
      <Heading size="h6" mb={[1]}>
        {props.title}
      </Heading>
      {typeof props.intro !== 'object' && (
        <Text size="small">{props.intro}</Text>
      )}
    </StyledSearchResult>
  );
};

const SearchMenuListDownload = props => {
  return (
    <StyledSearchResult>
      <Heading size="h6" mb={[1]}>
        {props.title}
      </Heading>
      {typeof props.intro !== 'object' && (
        <Text size="small">{props.intro}</Text>
      )}
      <LinkGroup links={[props.link]}>Download</LinkGroup>
    </StyledSearchResult>
  );
};

const SearchMenuListHeader = props => {
  return (
    <StyledSearchResultHeader>
      <Heading size="h6" weigth="bold">
        {props.title}
      </Heading>
    </StyledSearchResultHeader>
  );
};

const SEARCH_RESULT_MAPPING = {
  [RESULT_TYPES.NEWS_POST]: {
    component: SearchMenuListPost,
    link: value => setupContent(null, value.slug, {}, CONTENT_TYPES.NEWS_BASE),
    props: value => {
      return {
        title: value.title,
      };
    },
  },
  [RESULT_TYPES.FUNDS_POST]: {
    component: SearchMenuListPost,
    link: value => setupContent(null, value.slug, {}, 'fund'),
    props: value => {
      return {
        title: value.title,
      };
    },
  },
  [RESULT_TYPES.DOWLOAD_POST]: {
    component: SearchMenuListDownload,
    props: value => {
      return {
        title: value.title,
        link: setupLink(value, {
          displayName: 'Download',
          label: 'Download',
          type: BUTTON_TYPES.DOWNLOAD,
        }),
      };
    },
  },
};

const Hits = ({ hits, view }) => {
  const section =
    SEARCH_RESULT_MAPPING[view.hitComp] || RESULT_MAPPING[view.hitComp];

  const Component = section.component || SearchMenuListPost;

  if (!hits.length) return null;

  const content = hits
    .filter(h => {
      return typeof h.slug === 'string' || h.file;
    })
    .map((hit, index) => {
      let LinkWrapper = section.link ? Link : React.Fragment;
      return (
        <LinkWrapper {...(section.link ? section.link(hit) : {})} key={index}>
          <Component {...(section.props ? section.props(hit) : {})} />
        </LinkWrapper>
      );
    });
  return (
    <>
      <SearchMenuListHeader title={view.title} />
      {content}
    </>
  );
};

export default connectHits(Hits);
