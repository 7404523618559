import React from 'react';
import ShowAt from '../Responsive/ShowAt';
import Menu from './Menu';

const MenuWrapper = props => (
  <>
    <ShowAt breakpoint="mediumAndBelow">
      <Menu isMobile {...props} />
    </ShowAt>
    <ShowAt breakpoint="large">
      <Menu {...props} />
    </ShowAt>
  </>
);

export default MenuWrapper;
