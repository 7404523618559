import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card as RebassCard, Box, Flex } from 'rebass';
import { height } from 'styled-system';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { theme } from '../common/theme';

/** Primary Card is a CTA card with a title and button */

const StyledCard = styled(RebassCard)`
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
`;

const StyledWrapper = styled(Flex)`
  ${height}
`;

const getColors = {
  white: {
    title: 'baseBlack',
    bg: theme.colors.baseWhite,
  },
  black: {
    title: 'baseBlack',
    bg: theme.colors.baseGrayLight,
  },
  green: {
    title: 'brandGreen',
    bg: theme.colors.brandGreenPale,
  },
  purple: {
    title: 'brandPurple',
    bg: theme.colors.brandPurplePale,
  },
  violet: {
    title: 'brandViolet',
    bg: theme.colors.brandVioletPale,
  },
  red: {
    title: 'brandRed',
    bg: theme.colors.brandRedPale,
  },
  yellow: {
    title: 'brandYellow',
    bg: theme.colors.brandYellowPale,
  },
  navy: {
    title: 'brandNavy',
    bg: theme.colors.brandNavyPale,
  },
};

const CardPrimary = props => {
  const { cardTitle, text, renderCta, color } = props;

  const { title: titleColor, bg: backgroundColor } =
    getColors[color] || getColors.black;

  return (
    <StyledCard
      px={[4, 5]}
      pt={[5, 9]}
      pb={[6, 9]}
      mb={[2, 2, 0, 0]}
      bg={backgroundColor}
      {...props}
    >
      {cardTitle && (
        <Box style={{ maxWidth: '100%' }}>
          <Heading size="h1" mb={[2, 3]} color={titleColor}>
            {cardTitle}
          </Heading>
        </Box>
      )}

      {text && (
        <StyledWrapper mb={[3, 4]} style={{ maxWidth: '100%' }}>
          <div>
            <Text size="body" html={text} />
          </div>
        </StyledWrapper>
      )}

      {renderCta ? <Box mt={['auto']}>{renderCta(color)}</Box> : null}
    </StyledCard>
  );
};

export default CardPrimary;

CardPrimary.propTypes = {
  /** Title of the Card */
  cardTitle: PropTypes.string.isRequired,
  /** Text as content */
  text: PropTypes.string,
  /** Function for Calls to action (passes "color" prop to function) */
  renderCta: PropTypes.func,
  /** Color as prop. Will set the bg color, button and title color */
  color: PropTypes.oneOf([
    'white',
    'black',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]).isRequired,
};

CardPrimary.defaultProps = {
  title: null,
  text: null,
  color: 'black',
  renderCta: null,
};
