import React from 'react';
import { Link } from 'gatsby';
import { Table, theme } from '@sygnia/components';
import moment from 'moment';

export const WebinarTable = ({ hits, marginTop, view }) => {
  return (
    <div className="responsive-table">
      <Table.Table
        style={{
          marginTop: marginTop,
        }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Webinar</Table.Th>
            <Table.Th>Presenter</Table.Th>
            <Table.Th>Date</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <tbody>
          {hits.map(webinar => {
            const columns = [
              <Link to={`/webinar/${webinar.slug}`}>{webinar.title}</Link>,
              webinar.presenter ? webinar.presenter : <span />,
              webinar.publishedDate ? (
                moment(webinar.publishedDate).format('DD MMM, YYYY')
              ) : (
                <span />
              ),
            ];

            return (
              <>
                <Table.Tr>
                  {columns.map(c => (
                    <Table.Td>{c}</Table.Td>
                  ))}
                </Table.Tr>
              </>
            );
          })}
        </tbody>
      </Table.Table>
    </div>
  );
};

WebinarTable.defaultProps = {
  marginTop: `${theme.space[2]}px`,
};
