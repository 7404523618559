import React from 'react';
import PropTypes from 'prop-types';
import Nav from './Nav';
import ShowAt from '../Responsive/ShowAt';
import styled from 'styled-components';

const FixedHeader = styled.div`
  top: 0;
  position: sticky;
  z-index: 1090;
  overflow: hidden;
`;

const NavWrapper = props => (
  <FixedHeader>
    <ShowAt breakpoint="mediumAndBelow">
      <Nav isMobile={true} {...props} />
    </ShowAt>
    <ShowAt breakpoint="large">
      <Nav {...props} />
    </ShowAt>
  </FixedHeader>
);

Nav.propTypes = {
  siloName: PropTypes.string,
  links: PropTypes.array,
};

Nav.defaultProps = {
  siloName: 'me',
  links: [],
};

export default NavWrapper;
