import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SliderArrow from '../SliderArrow/SliderArrow';
import Heading from '../Heading/Heading';
import { Box, Flex } from 'rebass';
let ReactSlickSlider;

const Slider = props => {
  const [shouldRender, setRender] = useState(false);

  const {
    children,
    centerMode,
    CustomArrowComponent,
    arrowColorScheme,
    options,
    title,
    showTitle,
  } = props;

  const ref = useRef();
  useEffect(() => {
    ReactSlickSlider = require('react-slick').default;
    setRender(true);

    if (props.setRef) {
      props.setRef(ref);
    }
  });

  if (!shouldRender) return false;

  const sliderSettings = {
    centerMode,
    dots: false,
    infinite: false,
    ...options,
  };

  if (CustomArrowComponent) {
    sliderSettings.nextArrow = (
      <CustomArrowComponent layout="right" colorScheme={arrowColorScheme} />
    );
    sliderSettings.prevArrow = (
      <CustomArrowComponent layout="left" colorScheme={arrowColorScheme} />
    );
  } else {
    sliderSettings.nextArrow = null;
    sliderSettings.prevArrow = null;
  }
  return (
    <Box>
      {showTitle && title && (
        <Flex justifyContent="center" alignItems="center" mb={[2, 3]}>
          <Box>
            <Heading
              size="h3"
              as="h3"
              color={'black'}
              textAlign="center"
              mx={[1, 2]}
            >
              {title}
            </Heading>
          </Box>
        </Flex>
      )}
      <ReactSlickSlider ref={ref} {...sliderSettings}>
        {children}
      </ReactSlickSlider>
    </Box>
  );
};

Slider.defaultProps = {
  centerMode: false,
  CustomArrowComponent: SliderArrow,
  children: null,
  options: {},
  arrowColorScheme: 'dark',
  title: null,
  showTitle: false,
  meta: {},
};

Slider.propTypes = {
  /** Pass reference to parent function */
  setRef: PropTypes.func,
  /** Set the slick slider options */
  options: PropTypes.object,
  /** Set the slider to center mode */
  centerMode: PropTypes.bool,
  /** Custom component for the previous arrow */
  CustomArrowComponent: PropTypes.func,
  /** Children to render in component */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.element,
  ]),
  /** Color scheme of arrows */
  arrowColorScheme: PropTypes.oneOf(['dark', 'light']),
  /** Title of the slider */
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  meta: PropTypes.object,
};

export default Slider;
