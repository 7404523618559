import React from 'react';
import { Hero, ListMeta } from '@sygnia/components';
import { mapper, getEmText } from '../common/utils';
import LinkGroup from './LinkGroup';
import { graphql } from 'gatsby';
import { COLOR_SCHEMES, CONTENT_TYPES } from '../common/constants';

const HERO_CONTENT_MAP = {
  products: {
    text: ['excerpt', 'childMarkdownRemark', 'html'],
  },
  default: {
    text: ['excerpt', 'childMarkdownRemark', 'html'],
  },
};

export const HeroWrapper = props => {
  const { section, contentType } = props;

  const color =
    section.colorScheme && section.colorScheme !== CONTENT_TYPES.INHERIT
      ? section.colorScheme
      : COLOR_SCHEMES[contentType];
  const mappedOptions = HERO_CONTENT_MAP[contentType]
    ? mapper(section, HERO_CONTENT_MAP[contentType])
    : mapper(section, HERO_CONTENT_MAP['default']);

  const renderCta = () =>
    section.links && section.links.length ? (
      <LinkGroup color={color} isInverse links={section.links || []} />
    ) : null;

  const renderMeta = color =>
    section.subtitle ? (
      <ListMeta
        meta={
          Array.isArray(section.subtitle)
            ? section.subtitle
            : [section.subtitle]
        }
        options={{
          color: color,
        }}
      />
    ) : null;

  let title = <span>{section.title}</span>;
  if (section.emphasis) {
    title = getEmText(section.title, section.emphasis);
  }

  const { title: _title } = section;

  return (
    <Hero
      {...section}
      heroTitle={title}
      {...mappedOptions}
      color={color}
      renderMeta={renderMeta}
      renderCta={renderCta}
    />
  );
};

HeroWrapper.defaultProps = {
  section: {},
  contentType: 'page',
};

export const HeroLayoutFields = graphql`
  fragment HeroLayoutFields on ContentfulLayoutHeroTitle {
    id
    title
    emphasis
    subtitle
    hasLogo: showLogo
    colorScheme
    excerpt {
      childMarkdownRemark {
        html
      }
    }
    links {
      ...LinkFields
    }
  }
`;

HeroWrapper.fragment = HeroLayoutFields;
