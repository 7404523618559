import React from 'react';
import { Slider } from '@sygnia/components';
import { graphql } from 'gatsby';
import Layout from './layout';

export const SliderWrapper = ({ section }) => {
  const options = section.meta ? JSON.parse(section.meta.internal.content) : {};
  return (
    <Slider {...section} options={options}>
      {section.modules.map((mod, index) => (
        <Layout key={index} layout={[{ ...mod, setMinHeight: true }]} />
      ))}
    </Slider>
  );
};

SliderWrapper.defaultProps = {
  section: null,
};

export const fragment = graphql`
  fragment SliderLayoutFields on ContentfulLayoutSlider {
    id
    layout
    title
    showTitle
    centerMode
    meta {
      internal {
        content
      }
    }
    modules {
      __typename
      ... on Node {
        ...TestimonialsLayoutFields
        ...CardBannerLayoutFields
        ...HeroImageLayoutFields
      }
    }
  }
`;

SliderWrapper.fragment = fragment;
