import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../Text/Text';
import { theme } from '../common/theme';

const StyledContent = styled(Text)`
  img {
    width: inherit;
    max-width: 100%;
    height: auto;
    margin: ${theme.space[7]}px auto;
  }
  @media (max-width: ${theme.breakpoints[0]}) {
    p {
      font-size: ${theme.fontSizes.body};
      line-height: 25px;
    }
  }
  p {
    margin-bottom: ${theme.space[2]}px;
    font-family: ${theme.fonts.dmSans};
  }
  a {
    color: inherit;
    text-decoration: underline !important;
    &:hover {
      text-decoration: none !important;
    }
  }

  .styledButton {
    text-decoration: none !important;
  }
  b,
  strong {
    font-weight: ${theme.fontWeight.bold};
  }
  em {
    font-style: italic;
  }
  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-left: ${theme.space[2]}px;
    margin-bottom: ${theme.space[2]}px;
  }
  li > p {
    margin-bottom: 0px;
  }
  small {
    font-size: ${theme.fontSizes.small};
  }
  hr {
    border-style: solid;
    border-width: 0.5px;
    border-color: ${theme.colors.baseGrayLight};
    margin-block-start: ${theme.space[4]}px;
    margin-block-end: ${theme.space[4]}px;
  }
  blockquote {
    margin: 0;
    padding: 0;
    line-height: 1.6;
    font-size: ${theme.fontSizes.intro};
    margin-top: ${theme.space[4]}px;
    margin-bottom: ${theme.space[4]}px;
  }
  cite {
    font-style: italic;
  }
  address {
    font-style: normal;
  }
  figure {
    margin: 0;
    padding: 0;
    margin-top: ${theme.space[4]}px;
    margin-bottom: ${theme.space[4]}px;
  }
  figcaption {
    margin-top: ${theme.space[1]}px;
    font-size: ${theme.fontSizes.small};
  }
  h6 {
    font-size: ${theme.fontSizes.h6};
  }
  h5 {
    font-size: ${theme.fontSizes.h5};
  }
  h4 {
    font-size: ${theme.fontSizes.h4};
  }
  h3 {
    font-size: ${theme.fontSizes.h3};
  }
  h2 {
    font-size: ${theme.fontSizes.h2};
  }
  h1 {
    font-size: ${theme.fontSizes.h1};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${theme.fontWeight.bold};
  }
`;

const GuideContentBody = props => {
  const { html, mb } = props;
  if (html) {
    return <StyledContent dangerouslySetInnerHTML={{ __html: html }} mb={mb} />;
  }

  return <StyledContent mb={mb}>{props.children}</StyledContent>;
};

export default {
  Body: GuideContentBody,
};

GuideContentBody.propTypes = {
  /**  html renders the Content */
  html: PropTypes.node,
  /** children renders the Content */
  children: PropTypes.node,
  /**  mb content margin bottom */
  mb: PropTypes.array,
};

GuideContentBody.defaultProps = {
  html: null,
  mb: [6],
};
