import React from 'react';
import { Dropdown } from '@sygnia/components';

const ResultsView = ({ views, setView, currentView }) => (
  <Dropdown
    className="ResultsView_Dropdown"
    mx={[0]}
    mr={[0, 0, 0, 1]}
    label={currentView.title}
    renderOptions={Component => {
      return (
        <>
          {views.map(item => (
            <Component
              key={item.title}
              isSelected={currentView.hitComp == item.hitComp}
              onChange={() => {
                setView(item);
              }}
              label={item.title}
            />
          ))}
        </>
      );
    }}
  ></Dropdown>
);

export default ResultsView;
