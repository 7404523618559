import React from 'react';
import ShowAt from '../Responsive/ShowAt';
import Silo from '../Silo/Silo';

const SiloWrapper = props => (
  <>
    <ShowAt breakpoint="mediumAndBelow">
      <Silo isMobile {...props} />
    </ShowAt>
    <ShowAt breakpoint="large">
      <Silo {...props} />
    </ShowAt>
  </>
);

export default SiloWrapper;
