import { Flex } from 'rebass';
import styled from 'styled-components';

import { theme } from '../common/theme';

const TabItem = styled(Flex)`
  height: 100%;
  box-sizing: border-box;
  text-align: left;
  padding-right: ${theme.space[3]};
  cursor: pointer;
  position: relative;
  opacity: ${({ isActive }) =>
    isActive ? theme.opacity.full : theme.opacity.medium};
  text-shadow: ${({ isActive, color }) =>
    isActive
      ? `0.5px 0px 0px ${theme.colors[color]}`
      : `0px 0px 0px ${theme.colors[color]}`};
  transition: all ${theme.speed.default} ease;
`;

export default TabItem;
