import React from 'react';
import PropTypes from 'prop-types';
import Description from './Description/Description';
import Alert from './Alert/Alert';
import { Box } from 'rebass';
import { StyledWrapper, StyledLabel } from './Styles';

export const FieldTemplate = props => {
  const { children, formType, label, error, fieldDescription, ...rest } = props;

  return (
    <Box
      style={{ textAlign: 'left' }}
      className="FieldTemplate_Box"
      pb={[2]}
      {...rest}
    >
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledWrapper formType={formType}>{children}</StyledWrapper>
      {error && (
        <Description text={error} type={'field'} status={'error'} pb={[1]} />
      )}
      {fieldDescription && (
        <Alert p={[1, 2]} text={fieldDescription} status={'info'} />
      )}
    </Box>
  );
};

FieldTemplate.propTypes = {
  /** formType will determine the design of the input */
  formType: PropTypes.oneOf(['default', 'modern']),
  children: PropTypes.node,
  /** Label displayed above input. Not Required */
  label: PropTypes.string,
  /** formType will determine the design of the input */
  error: PropTypes.string,
  /**Any text helping the user on what to enter in the field */
  fieldDescription: PropTypes.string,
};

FieldTemplate.defaultProps = {
  formType: 'default',
  children: null,
  label: null,
  error: null,
  fieldDescription: null,
};

export default FieldTemplate;
