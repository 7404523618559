import React from 'react';
import { Column, Container } from '../Grid';
import PropTypes from 'prop-types';
import Text from '../Text/Text';
import styled from 'styled-components';
import { Box } from 'rebass';
import { mediaQuery } from '../common/utils';
import { theme } from '../common/theme';

const StyledContentWrapper = styled(Box)`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
`;

const StyledHeadlineWrapper = styled(Box)`
  background-color: ${props => props.bg};
  ${mediaQuery.medium} {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  border-radius: 25px;
  padding: 4px 15px;
  width: max-content;
  font-size: 15px;
  font-family: ${theme.fonts.sans};
  margin-bottom: 8px;
`;

const StyledColumn = styled(Column)`
  border-left: 2px solid ${props => props.color};
  padding-top: 4px;
  padding-bottom: 13px;
`;

const Highlight = ({ text, colors, headline }) => {
  const fallbackColors = {
    bg: '#FFFFFF',
    text: '#000000',
    color: '#42C7FE',
  };

  return (
    <Container
      width={[1, 1, 10 / 12, 10 / 12, 7 / 12]}
      p={[2, 2, 2, 4]}
      mx="auto"
      bg={colors.bg || fallbackColors.bg}
    >
      <StyledContentWrapper>
        {headline && (
          <StyledHeadlineWrapper
            bg={colors.color || fallbackColors.color}
            px={[2, 2]}
          >
            {headline}
          </StyledHeadlineWrapper>
        )}

        <StyledColumn color={colors.color || fallbackColors.color}>
          {text && (
            <Text
              size="intro"
              lineHeight={'1.55'}
              color={colors.text || fallbackColors.text}
              html={text}
              fontFamily={theme.fonts.serif}
              paddingLeft={'10px'}
            />
          )}
        </StyledColumn>
      </StyledContentWrapper>
    </Container>
  );
};

export default Highlight;

Highlight.propTypes = {
  /**  content of the Card */
  text: PropTypes.string.isRequired,
  /** bacground color of the card */
  colors: PropTypes.object,
  /** headline of the card */
  headline: PropTypes.string,
};
