import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import LinkGroup, { BUTTON_TYPES } from '../../modules/LinkGroup';
import { Table, theme } from '@sygnia/components';
import {
  CONTENT_TITLES,
  GTM_CATEGORY,
  GTM_EVENT,
  GTM_LOCATION,
} from '../../common/constants';

const StyledTable = styled(Table.Table)`
  @media (min-width: 990px) {
    overflow: auto;
  }
  @media (max-width: ${theme.breakpoints[2]}) {
    max-height: 900px;
    overflow: auto;
  }
`;

const StyledTh = styled(Table.Thead)`
  @media (min-width: 990px) {
    position: sticky;
    top: 72px;
    background-color: ${theme.colors.white};
    z-index: 1;
    width: 100%;
    padding-bottom: 50px !important;
  }
  @media (max-width: ${theme.breakpoints[2]}) {
    position: sticky;
    top: -1px;
    background-color: ${theme.colors.white};
    z-index: 1;
    width: 100%;
    padding-bottom: 50px !important;
  }
`;

const StyledTheader = styled(Table.Th)`
  @media (min-width: 990px) {
    padding-top: 16px !important;
  }
`;

const StyledTableWrapper = styled.div`
  @media (max-width: ${theme.breakpoints[2]}) {
    max-height: 900px;
    overflow: auto;
  }
`;

export const FundTable = ({ hits, marginTop, view, ...props }) => {
  return (
    <StyledTableWrapper className="responsive-table">
      <StyledTable
        style={{
          marginTop: marginTop,
        }}
      >
        <StyledTh>
          <Table.Tr>
            <StyledTheader>Fund</StyledTheader>
            <StyledTheader>
              {CONTENT_TITLES.productType
                ? CONTENT_TITLES.productType[0]
                : null}
            </StyledTheader>
            <StyledTheader>
              {CONTENT_TITLES.assetCategory
                ? CONTENT_TITLES.assetCategory[0]
                : null}
            </StyledTheader>
            <Table.Th></Table.Th>
          </Table.Tr>
        </StyledTh>
        <tbody>
          {hits.map(fund => {
            const columns = [
              <Link to={`/fund/${fund.slug}`}>{fund.title}</Link>,
              fund.productType ? fund.productType : <span />,
              fund.category ? fund.category.join(', ') : <span />,
              fund.factSheet ? (
                <LinkGroup
                  links={[
                    {
                      type: BUTTON_TYPES.DOWNLOAD,
                      as: 'a',
                      label: CONTENT_TITLES.factSheet,
                      external: true,
                      to: fund.factSheet.file.url,
                      gtmEventInfo: {
                        eventName: GTM_EVENT.FACT_SHEET_DOWNLOADED,
                        data: {
                          category: GTM_CATEGORY.DOCUMENT,
                          label: CONTENT_TITLES.factSheet,
                          location: GTM_LOCATION.CONTENT,
                        },
                      },
                    },
                  ]}
                />
              ) : (
                <span />
              ),
            ];

            return (
              <>
                <Table.Tr>
                  {columns.map(c => (
                    <Table.Td>{c}</Table.Td>
                  ))}
                </Table.Tr>
              </>
            );
          })}
        </tbody>
      </StyledTable>
    </StyledTableWrapper>
  );
};

FundTable.defaultProps = {
  marginTop: `${theme.space[2]}px`,
};
