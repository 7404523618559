import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../../src/components/common/theme';
import { Box } from 'rebass';

const StyledSearch = styled(Box)`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${theme.space[3]}px;
  border: 1px solid
    ${props =>
      props.isFocused ? theme.colors.baseGray : theme.colors.baseGrayLight};
  height: ${theme.space[6]}px;
  padding-left: ${theme.space[3]}px;
  padding-right: ${theme.space[1]}px;
  background: ${theme.colors.baseWhite};
  @media (min-width: ${theme.breakpoints[1]}) {
    min-width: 300px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  font-size: ${theme.fontSizes.small};
  font-family: ${theme.fonts.dmSans};
  color: ${theme.colors.baseBlack};
`;

const StyledButton = styled.button`
  cursor: pointer;
  outline: none;
  padding: 0;
  box-sizing: border-box;
  appearance: none;
  border: none;
  background-color: transparent;
  font-size: ${theme.fontSizes.body};
  height: ${theme.space[6]}px;
  width: ${theme.space[6]}px;
`;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.state = {
      focused: false,
    };
  }

  handleFocus() {
    this.setState(prevState => ({ focused: !prevState.focused }));
  }

  onSearchClick() {
    this.props.onSearchClick(this.state.value);
  }

  render() {
    const { placeholder, width, inputRef: ref = null, ...rest } = this.props;
    return (
      <StyledSearch
        width={width}
        isFocused={this.state.focused}
        mr={[0, 0, 0, 2]}
      >
        <StyledInput
          label={placeholder}
          placeholder={placeholder}
          type="text"
          onFocus={this.handleFocus}
          onBlur={this.handleFocus}
          ref={ref}
          {...rest}
        />
        <StyledButton
          type="button"
          onClick={this.onSearchClick}
          title="Search"
          aria-label="Search"
        >
          <i className="icon icon-search" />
        </StyledButton>
      </StyledSearch>
    );
  }
}

Search.propTypes = {
  /** Width of the component. Will default to 256px if not set. Can set to '100%' as example (uses 'rebass' props) */
  width: PropTypes.string,
  /** Text that is getting entered into input */
  searchText: PropTypes.string,
  /** Visible text in input before user enters anything  */
  placeholder: PropTypes.string,
  /** Function to check if input has changed */
  onChange: PropTypes.func,
  /** Function to check if "enter" has been pressed on keyboard */
  onEnter: PropTypes.func,
  /** Function to check if search button has been clicked */
  onSearchClick: PropTypes.func,
  /** Is the input focussed? */
  isFocused: PropTypes.bool,
  /** Input reference */
  inputRef: PropTypes.object,
};

Search.defaultProps = {
  searchText: '',
  placeholder: 'Search',
  onChange: null,
  onEnter: null,
  onSearchClick: null,
  width: '100%',
  isFocused: false,
  inputRef: null,
};

export default Search;
