import React, { useState } from 'react';
import { Form } from '../components/forms/FormContainer';
import { useStaticQuery, graphql } from 'gatsby';
import { Button, Modal, Heading } from '@sygnia/components';
import { form_index } from '../components/forms/forms';

export const FormWrapper = props => {
  const [showModal, setShowModal] = useState(false);

  const forms = useStaticQuery(graphql`
    query formsQuery {
      formapi {
        getPublicForms {
          id
          logiform_id
          name
          formType
          buttonLabel
          modalButtonLabel
          title
          fields {
            label
            placeholder
            name
            prefixText
            defaultValue {
              label
              value
            }
            items {
              label
              value
            }
            options {
              value
              label
              description
            }
            required
            type
          }
        }
      }
    }
  `);

  const {
    formapi: { getPublicForms },
  } = forms;
  const { section = {}, formId } = props;

  if (!section.name && !formId) return null;

  const formName = section.name ? section.name : formId;
  const publicForm = getPublicForms.find(f => f.name === formName);
  const form = publicForm ? publicForm : form_index(formName);

  if (!form) return null;
  if (!Object.keys(form).length) return null;

  const isModal = section && section.modal;

  const gtmEventInfo = section.gtmEventInfo || null;
  if (gtmEventInfo)
    gtmEventInfo.data.label = form.modalButtonLabel || form.buttonLabel;

  if (isModal)
    return (
      <>
        <Modal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          closeButtonVariant="secondary"
        >
          <Heading size="h3">{form.title}</Heading>
          <Form form={form} gtmEventInfo={gtmEventInfo} />
        </Modal>
        <Button
          my={[1]}
          mr={[1]}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {form.modalButtonLabel ? form.modalButtonLabel : form.buttonLabel}
        </Button>
      </>
    );

  return <Form form={form} gtmEventInfo={gtmEventInfo} />;
};
