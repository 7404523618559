import { connectStateResults } from 'react-instantsearch-dom';

// const Stats = connectStateResults(
//   ({ searchResults: res }) =>
//     res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`,
// );

const Stats = connectStateResults(
  ({ searchResults: res }) => res && `Page ${res.page + 1} of ${res.nbPages}`,
);

export default Stats;
