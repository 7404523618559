import React from 'react';
import { HeroCentered } from '@sygnia/components';
import { graphql } from 'gatsby';
import { mapperWithFunction } from '../common/utils';
import LinkGroup from './LinkGroup';
import { get } from 'lodash';

const CONTENT_MAP = {
  image: ['image', 'fluid', 'src'],
  images: value => {
    return {
      small: get(value.small, 'fluid.src'),
      medium: get(value.medium, 'fluid.src'),
      large: get(value.large, 'fluid.src'),
    };
  },
  title: ['title'],
  description: ['description', 'childMarkdownRemark', 'html'],
  tags: ['tags'],
  category: ['category'],
};

const fallbackColors = {
  yellow: {
    bg: '#FFCC57',
    title: 'baseBlack',
    button: 'baseBlack',
    description: 'baseBlack',
  },
};

export const HeroCenteredWrapper = props => {
  const { section } = props;
  const meta = section.meta?.internal
    ? JSON.parse(section.meta.internal.content)
    : null;
  const colors = meta?.colors || fallbackColors.yellow;
  if (section.heroBGColor) colors.bg = section.heroBGColor;
  const mappedOptions = mapperWithFunction(section, CONTENT_MAP);

  const renderCta = () => {
    return section.links ? (
      <LinkGroup color={colors.button || 'baseBlack'} links={section.links} />
    ) : null;
  };

  return (
    <HeroCentered
      {...section}
      {...mappedOptions}
      colors={colors}
      renderCta={renderCta}
    />
  );
};

HeroCenteredWrapper.defaultProps = {
  section: null,
};

export const HeroCenteredFields = graphql`
  fragment HeroCenteredFields on ContentfulHeroCentered {
    __typename
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    image {
      fluid {
        src
      }
    }
    large: image {
      fluid(quality: 100, maxWidth: 476) {
        src
      }
    }
    medium: image {
      fluid(quality: 100, maxWidth: 400) {
        src
      }
    }
    small: image {
      fluid(quality: 100, maxWidth: 376) {
        src
      }
    }
    category
    tags {
      title
      color: colour
    }
    links {
      ... on ContentfulLinks {
        __typename
        ...LinkFields
      }
    }
    meta {
      internal {
        content
      }
    }
  }
`;

HeroCenteredWrapper.fragment = HeroCenteredFields;
