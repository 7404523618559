import React from 'react';
import { Flex } from 'rebass';
import { IndexItem, theme, Container, Row, Column } from '@sygnia/components';
import { Heading } from '../../../components/src';
import styled from 'styled-components';
import { setupLink } from '../common/utils';

const StyledWrapper = styled(Container)`
  @media only screen and (min-awidth: 160px) and (max-width: ${theme
      .breakpoints[1]}) {
    width: 100%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[1]}) and (max-width: ${theme.breakpoints[2]}) {
    width: 76%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[2]}) and (max-width: ${theme.breakpoints[3]}) {
    width: 65%;
  }
  @media only screen and (min-width: ${theme.breakpoints[3]}) {
    width: 45%;
  }
`;

const StyledOl = styled.ol`
  list-style-position: inside;
  padding-left: 0;
  counter-reset: item;
  list-style-type: none;
  margin-left: 0;
  padding-left: 4px;
  font-family: ${theme.fonts.sans};
`;

const IndexWrapper = props => {
  const {
    section: { title, links },
  } = props;

  const StyledTitle = styled(Heading)`
    padding-left: 14px;
  `;
  return (
    <Container>
      <StyledWrapper mt={[1, 3]}>
        <Row>
          <Column flexDirection="column" px={[0, 0]}>
            {links && links.length > 0 && (
              <StyledTitle mb={[1]} mt={[5, 6]} size="h3" px={[1, 1]}>
                {title || 'Contents'}
              </StyledTitle>
            )}
            {links.length > 0 && (
              <StyledOl>
                {links?.map((link, index) => {
                  const l = setupLink(link, {});
                  return (
                    <a {...l} key={index}>
                      <IndexItem label={link.label} />
                    </a>
                  );
                })}
              </StyledOl>
            )}
          </Column>
        </Row>
      </StyledWrapper>
    </Container>
  );
};
export default IndexWrapper;
export const fragment = graphql`
  fragment IndexFields on ContentfulIndex {
    title
    links {
      label
      to
    }
  }
`;

IndexWrapper.fragment = fragment;
