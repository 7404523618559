import _ from 'lodash';

export class RetirementAgeFinancialModel {
  constructor(
    salary,
    requiredRetirementSalary,
    additionalAmount,
    percentageContribution,
  ) {
    this.returnOnSavings = 0.095;
    this.inflation = 0.05;
    this.annualSalary = salary * 12;
    this.replacementRatio = requiredRetirementSalary / salary;
    this.salaryInflation = this.inflation + 1 / 100;
    this.maxAge = 90;
    this.savingsRatio = percentageContribution + additionalAmount / salary;
  }

  calculateReturn = (rate, timeHorizon) => {
    return [
      Math.pow(1 + rate / 12, timeHorizon * 12),
      Math.pow(1 + rate, timeHorizon),
    ];
  };

  buildSeries(age = 10, currentSavings = 0) {
    let nextAge = age;
    let grossSalary = this.annualSalary;
    let requiredRetirement = 0;
    if (nextAge <= this.maxAge) {
      requiredRetirement = grossSalary * this.replacementRatio;
    }
    let contribution = grossSalary * this.savingsRatio;
    let existing = currentSavings;
    let savings = contribution + existing;
    let results = {
      [nextAge]: [grossSalary, requiredRetirement, contribution, savings],
    };
    nextAge++;
    for (nextAge; nextAge <= this.maxAge; nextAge++) {
      grossSalary = grossSalary * (1 + this.salaryInflation);
      requiredRetirement = 0;
      if (nextAge <= this.maxAge) {
        requiredRetirement = grossSalary * this.replacementRatio;
      }
      contribution = grossSalary * this.savingsRatio;
      savings = savings * (1 + this.returnOnSavings) + contribution;

      results[nextAge] = [
        grossSalary,
        requiredRetirement,
        contribution,
        savings,
      ];
    }

    let retirementAge = 0;

    for (let i = this.maxAge; i >= age; i--) {
      const [_, retirementReq, __, savings] = results[i];
      let prev = 0;
      if (results[i + 1] && results[i + 1][4]) {
        prev = results[i + 1][4];
      }

      const cumulative = prev / (1 + this.returnOnSavings) + retirementReq;

      results[i][4] = cumulative;

      if (savings - cumulative < 0 && !retirementAge) {
        retirementAge = i + 1;
      }
    }

    return retirementAge;
  }
}
