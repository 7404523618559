import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Table, Container } from '@sygnia/components';
import moment from 'moment';
import { Box } from 'rebass';
const APOLLO_QUERY = gql`
  {
    funds {
      id
      name: fundName
      unitPrices {
        ... on FundUnitPrice {
          date
          price
        }
      }
    }
  }
`;

const FundUnitPrices = props => {
  const { loading, error, data } = useQuery(APOLLO_QUERY);

  if (!data) return null;
  if (loading) return null;
  if (error) return <p>{error}</p>;
  let dates = [];

  const funds = useStaticQuery(graphql`
    query getFundArchive {
      allContentfulFunds {
        nodes {
          name: title
          slug
          category
          fundId: internalIndentifier
          productType
          fundSilo
          ...FundCardLayoutFields
        }
      }
    }
  `);

  const filteredFunds = funds.allContentfulFunds.nodes
    .filter(fund => {
      if (!fund.fundInfoID) return false;
      if (!fund.fundSilo) return false;
      if (!fund.fundSilo.includes(props.silo)) return false;

      if (props.fundType && fund.productType !== props.fundType) {
        return false;
      }

      const fundInfo = data.funds.find(f => f.id === fund.fundId);

      if (!fundInfo) return false;
      // check for unit prices

      return true;
    })
    .map(fund => {
      const { unitPrices } = data.funds.find(f => f.id === fund.fundId);

      return {
        ...fund,
        unitPrices,
      };
    });

  filteredFunds.forEach(fund => {
    fund.unitPrices.forEach(({ date }) => {
      if (!dates.includes(date)) {
        dates.push(date);
      }
    });
  });

  dates = dates
    .sort((a, b) => new Date(a) - new Date(b))
    .slice(Math.max(dates.length - 5, 0));

  return (
    <Container>
      <Box py={[3, 5]}>
        <div className="responsive-table">
          <Table.Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Fund</Table.Th>
                <Table.Th></Table.Th>
                {dates.map(date => (
                  <Table.Th>
                    {moment(date, 'YYYY-MM-DD').format('DD MMM')}
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <tbody>
              {filteredFunds
                .sort((a, b) =>
                  a.name === b.name ? 0 : a.name < b.name ? -1 : 1,
                )
                .map(({ name, category, slug, unitPrices, ...rest }) => {
                  return (
                    <>
                      <Table.Tr>
                        <Table.Td>
                          <Link to={`/fund/${slug}`}>{name}</Link>
                        </Table.Td>
                        <Table.Td>
                          {category && category.length
                            ? category.join(', ')
                            : ''}
                        </Table.Td>
                        {dates.map(date => {
                          const unit = unitPrices.find(up => up.date === date);
                          return (
                            <Table.Td>{unit ? unit.price : null}</Table.Td>
                          );
                        })}
                      </Table.Tr>
                    </>
                  );
                })}
            </tbody>
          </Table.Table>
        </div>
      </Box>
    </Container>
  );
};
FundUnitPrices.defaultProps = {
  silo: 'Retail',
  fundType: 'Unit Trust',
};
export default FundUnitPrices;
