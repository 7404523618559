import React from 'react';
import { Testimonial } from '@sygnia/components';
import { getEmText, mapper } from '../common/utils';
import LinkGroup from './LinkGroup';
import { graphql } from 'gatsby';

export const TESTIMONIAL_TYPES = {
  FULL_WIDTH_BACKGROUND: 'Full width with background',
  RIGHT_BACKGROUND: 'Right Aligned with Background',
  LEFT_BACKGROUND: 'Left aligned with background',
  STANDARD: 'Standard',
  LEFT_CONTAINED: 'Left Aligned with Contained Image',
  RIGHT_CONTAINED: 'Right Aligned with Contained Image',
};

const TESTIMONIAL_MAPPING = {
  [TESTIMONIAL_TYPES.FULL_WIDTH_BACKGROUND]: {
    component: Testimonial,
    options: {
      layout: 'center',
      overlay: 'black',
    },
  },
  [TESTIMONIAL_TYPES.RIGHT_BACKGROUND]: {
    component: Testimonial,
    options: {
      layout: 'right',
    },
  },
  [TESTIMONIAL_TYPES.LEFT_BACKGROUND]: {
    component: Testimonial,
    options: {
      layout: 'left',
    },
  },
  [TESTIMONIAL_TYPES.STANDARD]: {
    component: Testimonial,
    options: {
      layout: 'standard',
    },
  },
  [TESTIMONIAL_TYPES.LEFT_CONTAINED]: {
    component: Testimonial,
    options: {
      layout: 'leftContained',
    },
  },
  [TESTIMONIAL_TYPES.RIGHT_CONTAINED]: {
    component: Testimonial,
    options: {
      layout: 'rightContained',
    },
  },
};

const TESTIMONIAL_CONTENT_MAP = {
  cite: ['testimonial', 'byLine'],
  text: ['testimonial', 'content', 'childMarkdownRemark', 'html'],
  testimonialTitle: ['testimonial', 'eyeBrow'],
  image: ['testimonial', 'background', 'fluid', 'src'],
  links: ['testimonial', 'links'],
  meta: ['testimonial', 'links', 'meta'],
  eyebrow: ['testimonial', 'eyeBrow'],
};

export const TestimonialWrapper = ({ section, colorScheme }) => {
  const mapperOptions = mapper(section, TESTIMONIAL_CONTENT_MAP);

  let component = TESTIMONIAL_MAPPING[TESTIMONIAL_TYPES.STANDARD];
  if (!section.testimonial) return null;
  if (section.layout) {
    component = TESTIMONIAL_MAPPING[section.layout];
  }

  const { component: Component, options = {} } = component;
  if (!Component) return null;

  const meta = section.testimonial.meta
    ? JSON.parse(section.testimonial.meta.internal.content)
    : {};
  const customColors = meta.customColors || {};
  let color = colorScheme || 'black';
  if (section.colorScheme) {
    color = section.colorScheme;
  }

  const { title: _title, ...restSection } = section;

  let title = <span>{_title}</span>;
  if (section.testimonial.meta?.titleEmphasis) {
    title = getEmText(_title, section.testimonial.meta.titleEmphasis);
  }

  const renderCta = mapperOptions.links ? (
    <LinkGroup
      color={color}
      links={mapperOptions.links || []}
      meta={mapperOptions.meta ? mapperOptions.meta : {}}
    />
  ) : null;

  return (
    <Component
      {...restSection}
      {...mapperOptions}
      testimonialTitle={title}
      {...options}
      overlay={customColors.overlay || color}
      renderCta={renderCta}
      customColors={customColors}
    />
  );
};

TestimonialWrapper.defaultProps = {
  section: {},
  colorScheme: {},
};

export const fragment = graphql`
  fragment TestimonialFields on ContentfulTestimonials {
    byLine
    eyeBrow
    background {
      fluid(quality: 100, maxWidth: 2400) {
        src
      }
    }
    content {
      childMarkdownRemark {
        html
      }
    }
    meta {
      internal {
        content
      }
    }
    links {
      ...LinkFields
    }
  }
  fragment TestimonialsLayoutFields on ContentfulLayoutTestimonial {
    id
    title
    layout
    colorScheme
    testimonial: attachedTestimonial {
      ...TestimonialFields
    }
  }
`;

TestimonialWrapper.fragment = fragment;
