import React from 'react';
import { CONTENT_TITLES } from '../../../common/constants';
import { Box, Flex } from 'rebass';
import { ListMeta, Container } from '@sygnia/components';
import RefinementList from './RefinementList';
import {
  connectRefinementList,
  connectSearchBox,
} from 'react-instantsearch-dom';
import ViewAs from './ViewAs';
import SearchBox from './SearchBox';
import _ from 'lodash';
import styled from 'styled-components';

const CustomSearchBox = connectSearchBox(SearchBox);
const CustomRefinementList = connectRefinementList(RefinementList);

const StyledBox = styled(Box)`
  @media (min-width: 990px) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
`;

const SearchFilters = ({
  isSearchable,
  refinements,
  setQuery,
  query,
  ...props
}) => {
  return (
    <StyledBox bg="#F6F6F6" mb={[5]} py={[2]} className="Filters_Box">
      <Container>
        <Flex
          className="Filters_Flex"
          justifyContent="space-between"
          alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
          flexDirection={['column', 'column', 'column', 'row']}
        >
          <Box className="Filters_Box_Refinements" width={[1]}>
            <Flex
              className="Filters_Flex_Refinements"
              justifyContent={['flex-start', 'flex-start', 'space-between']}
              alignItems={['flex-start', 'center']}
              flexDirection={['column', 'row']}
            >
              {refinements && refinements.length ? (
                <ListMeta
                  flex="1 0 auto"
                  options={{ mb: [1, 0], mr: 2 }}
                  meta={[CONTENT_TITLES.filterBy]}
                />
              ) : null}
              {refinements && refinements.length
                ? refinements.map(r => {
                    const transformObj = {};

                    if (r.refinement === 'year') {
                      transformObj.transformItems = items => {
                        return items.sort((a, b) => {
                          return parseInt(a.label) > parseInt(b.label) ? -1 : 1;
                        });
                      };
                    }

                    if (r.refinement === 'productType') {
                      transformObj.transformItems = items => {
                        return _.orderBy(items, 'label', 'asc');
                      };
                    }

                    return (
                      <CustomRefinementList
                        key={r.refinement}
                        type={r.type}
                        title={CONTENT_TITLES[r.refinement] || r.refinement}
                        attribute={r.refinement}
                        {...transformObj}
                      />
                    );
                  })
                : null}
            </Flex>
          </Box>
          <Box className="Filters_Box_ViewAs" width={[1, 1, 1, '50%']}>
            <Flex
              className="Filters_Flex_ViewAs"
              justifyContent={['flex-start', 'flex-start', 'space-between']}
              alignItems={['flex-start', 'center']}
              flexDirection={['column', 'row']}
              mt={[0, 1, 1, 0]}
            >
              {props.views.length > 1 && (
                <ViewAs
                  className="Filters_ViewAs"
                  setView={props.setView}
                  currentView={props.currentView}
                  views={props.views}
                  mb={[1, 0]}
                  ml={[0, 0, 0, 1]}
                />
              )}
              {isSearchable ? (
                <CustomSearchBox
                  onChange={e => setQuery({ ...query, query: e.target.value })}
                  value={query.query || ''}
                />
              ) : null}
            </Flex>
          </Box>
        </Flex>
      </Container>
    </StyledBox>
  );
};

export default SearchFilters;
