import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card as RebassCard, Box, Flex } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { theme } from '../common/theme';

const variants = {
  default: {
    wrapper: {
      bg: theme.colors.baseWhite,
      flexDirection: 'column',
      mb: [2, 4],
      justifyContent: 'space-between',
    },
    title: {
      size: 'h3',
      mb: [1],
    },
    role: {
      size: 'h4',
      fontWeight: 'normal',
      mb: [1, 3],
    },
    image: {
      order: '0',
      width: '100%',
    },
    contentBox: {
      width: '100%',
      px: [3, 4],
      pt: [3, 4],
      pb: [4, 5],
    },
  },
  featured: {
    wrapper: {
      flexDirection: ['column', 'row'],
      mb: [4, 8],
      justifyContent: 'space-between',
    },
    title: {
      size: 'h1',
      mb: [1],
    },
    role: {
      size: 'h3',
      fontWeight: 'normal',
      mb: [1, 3],
    },
    image: {
      order: ['0', '1'],
      width: [1, 1 / 2],
    },
    contentBox: {
      width: [1, 5 / 12],
      px: '0px',
      pt: [3, 8],
      pb: [4, 8],
    },
  },

  small: {
    wrapper: {
      flexDirection: 'column',
      mb: [2, 4],
      justifyContent: 'space-between',
    },
    title: {
      size: 'h4',
      mb: [1],
    },
    role: {
      size: 'h4',
      fontWeight: 'normal',
      mb: [1],
    },
    image: {
      order: '0',
      width: [1, 1 / 2],
    },
    contentBox: {
      width: '100%',
      px: 0,
      pt: 1,
      pb: 0,
    },
  },
};

const StyledPerson = styled(Flex)`
  display: flex;
`;

const StyledText = styled(Text)`
  p {
    margin-bottom: 1rem;
  }
`;

const CardPerson = props => {
  const { role, title, text, image, variant, renderCta } = props;
  // TODO: Multiple People (!featured) in row - equal heights
  const style = variants[variant] || variants.default;

  return (
    <StyledPerson {...style.wrapper} {...props}>
      {image && (
        <Box flex={'0 1 auto'} {...style.image}>
          <RebassCard
            className="person-card"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
            backgroundImage={`url(${image})`}
            pb={'100%'}
          />
        </Box>
      )}
      <Box {...style.contentBox} style={{ maxWidth: '100%' }}>
        {title && <Heading {...style.title}>{title}</Heading>}
        {role && <Heading {...style.role}>{role}</Heading>}
        {text && <StyledText size="body" lineHeight={'1.5'} html={text} />}
        {renderCta && <Box mt={[2, 2, 0]}>{renderCta}</Box>}
      </Box>
    </StyledPerson>
  );
};

export default CardPerson;

CardPerson.propTypes = {
  /**  Title of the Card */
  title: PropTypes.string.isRequired,
  /** Text as content */
  text: PropTypes.string,
  /** Image URL to be used as background image */
  image: PropTypes.string,
  /** Is this a featured Person? */
  variant: PropTypes.oneOf(['default', 'small', 'featured']),
  /** Function for Calls to action */
  renderCta: PropTypes.func,
  /**  Role on the Card */
  role: PropTypes.string,
};

CardPerson.defaultProps = {
  title: null,
  text: null,
  image: null,
  variant: 'default',
  renderCta: null,
  role: null,
};
