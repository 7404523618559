import React from 'react';
import { Breadcrumbs } from '@sygnia/components';
import { setupLink } from '../common/utils';

const BreadcrumbsWrapper = ({ section }) => {
  const formatMenu = links => {
    return links.map(item => {
      const link = setupLink(item, {});
      return {
        ...link,
        label: item.label,
      };
    });
  };

  return <Breadcrumbs items={formatMenu(section.items)} />;
};

export { BreadcrumbsWrapper };
