import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../common/theme';
import FieldTemplate from '../FieldTemplate';
import { Flex } from 'rebass';

const StyledInputWrapper = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
`;

const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  @media (min-width: ${theme.breakpoints[1]}) {
    width: ${props => (props.formType === 'modern' ? '100%' : '150px')};
  }
  min-width: 80px;
  text-align: center;
  border: 1px solid ${theme.colors.baseGrayLight};
  margin: 10px;
  padding: 12px;
  padding-left: 12px;
  font-size: ${theme.fontSizes.body};
  font-weight: ${props =>
    props.label ? theme.fontWeight.normal : theme.fontWeight.bold};
  font-family: ${theme.fonts.dmSans};
  outline: none;
`;

const StyledButton = styled.button`
  display: flex;
  border-radius: 50%;
  outline: none;
  width: 45px;
  height: 45px;
  border: 1px solid
    ${props =>
      props?.buttonColor ? props.buttonColor : theme.colors.brandYellow};
  background: transparent;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  color: ${props =>
    props?.buttonColor ? props.buttonColor : theme.colors.brandYellow};
  opacity: ${({ disabled }) =>
    disabled ? theme.opacity.faint : theme.opacity.medium};
  transition: opacity ${theme.speed.default} ease;
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) =>
      disabled ? theme.opacity.faint : theme.opacity.medium};
  }
`;

const StyledIcon = styled.i`
  font-size: ${theme.fontSizes.body};
`;

const InputNumber = props => {
  const { handleFieldChange, value, ...field } = props;
  const handleIncrement = () => {
    handleFieldChange(field.name, parseFloat(value + 1));
  };

  const handleDecrement = () => {
    handleFieldChange(field.name, parseFloat(value - 1));
  };

  const onChange = e => {
    handleFieldChange(field.name, parseFloat(e.target.value));
  };

  return (
    <FieldTemplate {...props}>
      <StyledInputWrapper width={[1]}>
        <StyledButton
          {...props}
          onClick={handleDecrement}
          //isDisabled={this.state.isMinusDisabled}
          //disabled={this.state.isMinusDisabled}
        >
          <StyledIcon className="icon icon-minus" />
        </StyledButton>
        <StyledInput
          tabIndex="0"
          {...field}
          value={value}
          onChange={onChange}
        />

        <StyledButton
          {...props}
          onClick={handleIncrement}
          //isDisabled={this.state.isPlusDisabled}
          //disabled={this.state.isPlusDisabled}
        >
          <StyledIcon className="icon icon-plus" />
        </StyledButton>
      </StyledInputWrapper>
    </FieldTemplate>
  );
};

InputNumber.propTypes = {
  /** Label for field */
  label: PropTypes.string,
  /** Label Styling options for field */
  labelOptions: PropTypes.object,
  /** Minimum value allowed */
  min: PropTypes.number,
  /** Maximum value allowed */
  max: PropTypes.number,
  /** Legal number of intervals */
  step: PropTypes.number,
  /** Validation message displayed */
  error: PropTypes.string,
  /** Controlled value function */
  handleFieldChange: PropTypes.func,
  /** Current controlled value */
  value: PropTypes.number,
  /** Input Field name */
  name: PropTypes.string.isRequired,
  /** Button color */
  buttonColor: PropTypes.string.isRequired,
};

InputNumber.defaultProps = {
  step: 1,
  min: 0,
  labelOptions: {
    size: 'h4',
  },
  label: null,
};

export default InputNumber;
