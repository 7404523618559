import React from 'react';
import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../common/theme';
import Heading from '../Heading/Heading';
import { mediaQuery } from '../common/utils';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

const StyledItems = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: left;
  gap: 10px;
`;

const StyledItem = styled.li`
  display: flex;
  border: 2px solid ${theme.colors.baseBlack};
  border-radius: 55%;
  padding: 0.5em 0.6em;

  i {
    margin-right: 4px;
  }
  .SocialMediaShareButton {
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    .icon {
      font-size: 20px;
      margin: 4px 0;
    }
    &:hover {
      text-decoration: none;
      border-bottom-color: ${theme.colors.baseGrayDark};
    }
  }
`;

const StyledHeading = styled(Heading)`
  margin-top: 4px;
  text-align: center;
`;
const StyledShareContentWrapper = styled(Flex)`
  flex-direction: column;
  ${mediaQuery.small} {
    flex-direction: row;
  }
  justify-content: center;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const StyledShareWrapper = styled(Box)`
  background-color: ${theme.colors.baseGrayVeryLight};
  width: 100%;
`;

const LandingShare = props => {
  const { itemDetails } = props;
  const { title, description, pageUrl, image } = itemDetails;
  return (
    <StyledShareWrapper py={[9, 9]} px={[2, 4]}>
      <StyledShareContentWrapper>
        <Box>
          <StyledHeading size="h3">Share this with your friends</StyledHeading>
        </Box>
        <Box>
          <StyledItems>
            <StyledItem>
              <FacebookShareButton
                url={pageUrl}
                quote={description}
                image={image}
              >
                <i className={`icon icon-fw icon-facebook`} />
              </FacebookShareButton>
            </StyledItem>
            <StyledItem>
              <TwitterShareButton url={pageUrl} title={title} image={image}>
                <i className={`icon icon-fw icon-twitter`} />
              </TwitterShareButton>
            </StyledItem>
            <StyledItem>
              <LinkedinShareButton url={pageUrl} title={title} image={image}>
                <i className={`icon icon-fw icon-linkedin`} />
              </LinkedinShareButton>
            </StyledItem>
          </StyledItems>
        </Box>
      </StyledShareContentWrapper>
    </StyledShareWrapper>
  );
};

export default LandingShare;

LandingShare.propTypes = {
  /** Social media sharing details */
  itemDetails: PropTypes.object,
};
