import React from 'react';
import { Highlight } from '@sygnia/components';
import { graphql } from 'gatsby';
import { mapperWithFunction } from '../common/utils';
import { get } from 'lodash';

const CONTENT_MAP = {
  image: ['image', 'fluid', 'src'],
  images: value => {
    return {
      small: get(value.small, 'fluid.src'),
      medium: get(value.medium, 'fluid.src'),
      large: get(value.large, 'fluid.src'),
    };
  },
  text: ['text', 'text'],
  headline: 'highlightHeadline',
};

const fallbackColors = {
  yellow: {
    bg: '#FFCC57',
    title: 'baseBlack',
    button: 'baseBlack',
    description: 'baseBlack',
  },
};

export const HighlightWrapper = props => {
  const { section } = props;
  const meta = section.meta?.internal
    ? JSON.parse(section.meta.internal.content)
    : null;

  const colors = meta?.colors || fallbackColors.yellow;
  const mappedOptions = mapperWithFunction(section, CONTENT_MAP);

  return <Highlight {...section} {...mappedOptions} colors={colors} />;
};

HighlightWrapper.defaultProps = {
  section: null,
};

export const HighlightFields = graphql`
  fragment HighlightFields on ContentfulHighlight {
    text {
      text
    }
    highlightHeadline: headline
    image {
      fluid {
        src
      }
    }
    large: image {
      fluid(quality: 100, maxWidth: 476) {
        src
      }
    }
    medium: image {
      fluid(quality: 100, maxWidth: 400) {
        src
      }
    }
    small: image {
      fluid(quality: 100, maxWidth: 376) {
        src
      }
    }
    meta {
      internal {
        content
      }
    }
  }
`;

HighlightWrapper.fragment = HighlightFields;
