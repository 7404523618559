import React from 'react';
import { CONTENT_TITLES } from '../../../common/constants';
import ResultsView from './ResultsView';
import { ListMeta } from '@sygnia/components';

const ViewAs = ({ setView, currentView, views, ...props }) => {
  return (
    <>
      <ListMeta
        flex="1 0 auto"
        options={{ mb: 0, mr: 2 }}
        meta={[CONTENT_TITLES.viewAs]}
        {...props}
      />
      <ResultsView setView={setView} currentView={currentView} views={views} />
    </>
  );
};

export default ViewAs;
