import React from 'react';
import { graphql } from 'gatsby';
import { HeroWrapper } from './HeroWrapper';
import { HeroCenteredWrapper } from './HeroCenteredWrapper';
import { CTACenteredWrapper } from './CTACenteredWrapper';
import { TestimonialWrapper } from './TestimonialWrapper';
import { CardBannerWrapper } from './CardBannerWrapper';
import { SectionWrapper } from './SectionWrapper';
import { SectionCardWrapper } from './SectionCardWrapper';
import { SectionLogoWrapper } from './SectionLogoWrapper';
import { ContentWrapper } from './ContentWrapper';
import { ContentGenericWrapper } from './ContentGenericWrapper';
import { HeroImageWrapper } from './HeroImageWrapper';
import { ToggleWrapper } from './ToggleWrapper';
import { SliderWrapper } from './SliderWrapper';
import { ComponentWrapper } from './ComponentWrapper';
import { ColumnWrapper } from './ColumnWrapper';
import { HelmetWrapper } from './HelmetWrapper';
import FundInfoWidget from '../components/FundInfoWidget';
import CompatibleFundTable from '../components/CompatibleFundTable';
import { ShareWrapper } from './ShareWrapper';
import { ShareLandingWrapper } from './ShareLandingWrapper';
import IconTextWrapper from './IconTextWrapper';
import LinkGroup from './LinkGroup';
import { RelatedFundTestimonials } from './RelatedFundTestimonials';
import NewsletterWidget from '../components/Newsletter';
import LandingPageWidget from '../components/LandingPageWidget';
import { ArchiveWrapper } from './ArchiveWrapper';
import Search from '../components/search';
import TableWrapper from '../components/table/TableWrapper';
import { BreadcrumbsWrapper } from './BreadcrumbsWrapper';
import { FormWrapper } from './FormWrapper';
import FeesGraph from '../components/FeesGraph';
import FormWizard from '../components/forms/FormWizard';
import TwoPotFormWizard from '../components/forms/TwoPotFormWizard';
import TableGraph from '../components/graphs/TableGraph';
import FundUnitPrices from '../components/funds/FundUnitPrices';
import FundETFTable from '../components/funds/FundETFTable';
import SavingsGraph from '../components/SavingsGraph';
import { RetirementAgeCalculator } from '../components/Calculators/RetirementAgeCalculator';
import CountdownTimer from '../components/Countdown';
import { MenuWrapper } from './MenuWrapper';
import { BoxWrapper } from './BoxWrapper';
import VideoWrapper from './VideoWrapper';
import { QNAWrapper } from './QNAWrapper';
import { UrlObserverWrapper } from './UrlObserverWrapper';
import { NoticeWrapper } from './NoticeWrapper';
import NavigationIndexWrapper from './NavigationIndexWrapper';
import { BlockquoteWrapper } from './BlockQuoteWrapper';
import { HighlightWrapper } from './HighlightWrapper';
import { HeroEditorialWrapper } from './HeroEditorialWrapper';
import { HeroLandingWrapper } from './HeroLandingWrapper';
import { GuideContentWrapper } from './GuideContentWrapper';
import IndexWrapper from './IndexWrapper';
import BioWrapper from './BioWrapper';
import StyledTextWrapper from './StyledTextWrapper';
import { IframeWrapper } from './IframeWrapper';

export const LAYOUT_TYPES = {
  HERO_TITLE: 'ContentfulLayoutHeroTitle',
  HERO_CENTERED: 'ContentfulHeroCentered',
  CTA_CENTERED: 'ContentfulCtaCentered',
  HERO_IMAGE: 'ContentfulLayoutHeroImage',
  TESTIMONIAL: 'ContentfulLayoutTestimonial',
  CARDBANNER: 'ContentfulLayoutCardBanner',
  SECTION: 'ContentfulLayoutSectionWithChildren',
  SECTION_CARDS: 'ContentfulLayoutSectionCards',
  SECTION_LOGOS: 'ContentfulLayoutSectionLogos',
  CONTENT_BODY: 'ContentfulLayoutContentBody',
  CONTENT_TOGGLE: 'ContentfulLayoutContentToggle',
  SLIDER: 'ContentfulLayoutSlider',
  HERO_EDITORIAL: 'ContentfulLayoutHeroEditorial',
  FUND_INFO_WIDGET: 'FundInfoWidget',
  FUND_COMPATIBLE_FUNDS: 'CompatibleFundTable',
  ICON_HEADING: 'IconHeading',
  ARCHIVE: 'Archive',
  SEARCH: 'Search',
  COMPONENT_TABLE: 'TableComponent',
  FEES_GRAPH: 'FeesGraph',
  FORM_WIZARD: 'FormWizard',
  FUND_UNIT_PRICES: 'FundUnitPrices',
  COMPONENT: 'ContentfulLayoutComponent',
  COLUMN_LAYOUT: 'ContentfulLayoutColumn',
  SHARE: 'ShareWrapper',
  SHARE_LANDING: 'ShareLandingWrapper',
  CONTENT_LINKS: 'ContentfulLinks',
  BREADCRUMBS: 'BreadcrumbsWrapper',
  RELATED_TESTIMONIALS: 'RelatedFundTestimonials',
  HELMET: 'HelmetWrapper',
  HERO_LANDING: 'HeroLandingWrapper',
  NEWS_LETTER_WIDGET: 'NewsletterWidget',
  LANDING_PAGE_WIDGET: 'LandingPageWidget',
  FORM_WRAPPER: 'FormWrapper',
  CONTENT_GENERIC: 'ContentfulContentGeneric',
  TABLE_GRAPH: 'TableGraph',
  FUND_ETF_TABLE: 'FundETFTable',
  SAVINGS_GRAPH: 'SavingsGraph',
  COUNTDOWN_TIMER: 'CountdownTimer',
  RETIREMENT_AGE_CALCULATOR: 'RetirementAgeCalculator',
  CONTENT_MENU: 'ContentfulMenu',
  BOX: 'ContentfulLayoutBox',
  VIDEO: 'VideoWrapper',
  QNA: 'ContentfulLayoutQna',
  URL_OBSERVER: 'ContentfulUrlObserver',
  NOTICE: 'ContentfulNotice',
  NAVIGATION_INDEX: 'ContentfulNavigationIndex',
  BLOCKQUOTE: 'ContentfulBlockquote',
  HIGHLIGHT: 'ContentfulHighlight',
  CONTENT: 'ContentfulContent',
  INDEX: 'ContentfulIndex',
  BIO: 'Bio',
  STYLEDTEXT: 'StyledText',
  IFRAME: 'ContentfulLayoutIFrame',
  TWO_POT_FORM_WIZARD: 'TwoPotFormWizard',
};

export const LAYOUT_MAPPING = {
  [LAYOUT_TYPES.CONTENT_MENU]: MenuWrapper,
  [LAYOUT_TYPES.HERO_TITLE]: HeroWrapper,
  [LAYOUT_TYPES.HERO_CENTERED]: HeroCenteredWrapper,
  [LAYOUT_TYPES.CTA_CENTERED]: CTACenteredWrapper,
  [LAYOUT_TYPES.TESTIMONIAL]: TestimonialWrapper,
  [LAYOUT_TYPES.CARDBANNER]: CardBannerWrapper,
  [LAYOUT_TYPES.SECTION]: SectionWrapper,
  [LAYOUT_TYPES.CONTENT_BODY]: ContentWrapper,
  [LAYOUT_TYPES.HERO_IMAGE]: HeroImageWrapper,
  [LAYOUT_TYPES.HERO_LANDING]: HeroLandingWrapper,
  [LAYOUT_TYPES.CONTENT_TOGGLE]: ToggleWrapper,
  [LAYOUT_TYPES.SLIDER]: SliderWrapper,
  [LAYOUT_TYPES.COMPONENT]: ComponentWrapper,
  [LAYOUT_TYPES.COMPONENT_TABLE]: TableWrapper,
  [LAYOUT_TYPES.COLUMN_LAYOUT]: ColumnWrapper,
  [LAYOUT_TYPES.FUND_INFO_WIDGET]: FundInfoWidget,
  [LAYOUT_TYPES.FUND_COMPATIBLE_FUNDS]: CompatibleFundTable,
  [LAYOUT_TYPES.SHARE]: ShareWrapper,
  [LAYOUT_TYPES.SHARE_LANDING]: ShareLandingWrapper,
  [LAYOUT_TYPES.ICON_HEADING]: IconTextWrapper,
  [LAYOUT_TYPES.CONTENT_LINKS]: LinkGroup,
  [LAYOUT_TYPES.BREADCRUMBS]: BreadcrumbsWrapper,
  [LAYOUT_TYPES.RELATED_TESTIMONIALS]: RelatedFundTestimonials,
  [LAYOUT_TYPES.NEWS_LETTER_WIDGET]: NewsletterWidget,
  [LAYOUT_TYPES.ARCHIVE]: ArchiveWrapper,
  [LAYOUT_TYPES.SEARCH]: Search,
  [LAYOUT_TYPES.FEES_GRAPH]: FeesGraph,
  [LAYOUT_TYPES.TABLE_GRAPH]: TableGraph,
  [LAYOUT_TYPES.FORM_WIZARD]: FormWizard,
  [LAYOUT_TYPES.HELMET]: HelmetWrapper,
  [LAYOUT_TYPES.FORM_WRAPPER]: FormWrapper,
  [LAYOUT_TYPES.CONTENT_GENERIC]: ContentGenericWrapper,
  [LAYOUT_TYPES.FUND_UNIT_PRICES]: FundUnitPrices,
  [LAYOUT_TYPES.FUND_ETF_TABLE]: FundETFTable,
  [LAYOUT_TYPES.SAVINGS_GRAPH]: SavingsGraph,
  [LAYOUT_TYPES.COUNTDOWN_TIMER]: CountdownTimer,
  [LAYOUT_TYPES.RETIREMENT_AGE_CALCULATOR]: RetirementAgeCalculator,
  [LAYOUT_TYPES.BOX]: BoxWrapper,
  [LAYOUT_TYPES.VIDEO]: VideoWrapper,
  [LAYOUT_TYPES.QNA]: QNAWrapper,
  [LAYOUT_TYPES.URL_OBSERVER]: UrlObserverWrapper,
  [LAYOUT_TYPES.NOTICE]: NoticeWrapper,
  [LAYOUT_TYPES.SECTION_CARDS]: SectionCardWrapper,
  [LAYOUT_TYPES.SECTION_LOGOS]: SectionLogoWrapper,
  [LAYOUT_TYPES.NAVIGATION_INDEX]: NavigationIndexWrapper,
  [LAYOUT_TYPES.BLOCKQUOTE]: BlockquoteWrapper,
  [LAYOUT_TYPES.HIGHLIGHT]: HighlightWrapper,
  [LAYOUT_TYPES.HERO_EDITORIAL]: HeroEditorialWrapper,
  [LAYOUT_TYPES.CONTENT]: GuideContentWrapper,
  [LAYOUT_TYPES.LANDING_PAGE_WIDGET]: LandingPageWidget,
  [LAYOUT_TYPES.INDEX]: IndexWrapper,
  [LAYOUT_TYPES.BIO]: BioWrapper,
  [LAYOUT_TYPES.STYLEDTEXT]: StyledTextWrapper,
  [LAYOUT_TYPES.IFRAME]: IframeWrapper,
  [LAYOUT_TYPES.TWO_POT_FORM_WIZARD]: TwoPotFormWizard,
};

export default function Layout({
  layout,
  testing,
  colorScheme,
  contentType,
  ...rest
}) {
  if (layout === null) {
    return null;
  }
  return (
    <React.Fragment>
      {layout.map((section, i) => {
        if (section === null) return null;
        if (!section.__typename) return null;
        const Component = LAYOUT_MAPPING[section.__typename];
        if (!Component) return null;
        return (
          <Component
            colorScheme={rest.color || colorScheme || 'white'}
            key={i}
            section={section}
            contentType={contentType}
            parentReference={
              section.__typename === 'ContentfulLayoutHeroEditorial' &&
              rest.parentReference
            }
            pageTitle={
              section.__typename === 'ContentfulLayoutHeroEditorial' &&
              rest.title
            }
          />
        );
      })}
    </React.Fragment>
  );
}

export const LayoutFieldsPageFragment = graphql`
  fragment LayoutPageFields on ContentfulPage {
    layout {
      __typename
      ... on Node {
        ...HeroLayoutFields
        ...HeroCenteredFields
        ...CTACenteredFields
        ...ContentGenericFields
        ...TestimonialsLayoutFields
        ...CardBannerLayoutFields
        ...SectionLayoutFields
        ...SectionCardLayoutFields
        ...SectionLogoLayoutFields
        ...HeroImageLayoutFields
        ...ToggleLayoutFields
        ...SliderLayoutFields
        ...LayoutColumnFields
        ...LayoutContentFields
        ...ComponentLayoutFields
        ...BoxLayoutFields
        ...QNALayoutFields
        ...UrlObserverFields
        ...NoticeFields
        ...LayoutMenuFields
        ...IndexFields
        ...IFrameFields
        ...ContentFields
      }
    }
  }
`;
