import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { theme } from '../common/theme';

const StyledHeroWrapper = styled(Box)`
  text-align: center;
`;

const StyledHeroContent = styled(Flex)`
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${theme.space[8]}px;
`;

const StyledTag = styled(Box)`
  padding: 10px;
  margin: 10px;
  border-radius: 10em;
`;

const StyledImageWrapper = styled(Flex)`
  position: relative;
  &:before {
    z-index: 10;
    background-color: white;
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const StyledImageContainer = styled(Box)`
  z-index: 20;
`;

const HeroCentered = props => {
  const {
    category,
    title,
    description,
    images,
    tags,
    renderCta,
    colors,
    imageSmall = false,
  } = props;

  return (
    <StyledHeroWrapper textAlign={'center'} bg={colors.bg}>
      <StyledHeroContent
        textAlign="center"
        width={[7 / 12]}
        boxSizing="border-box"
        borderWidth={0}
        borderStyle={'solid'}
        borderColor={colors.bg}
        mx={'auto'}
        maxWidth={'56rem'}
        px={[6, 8]}
        pt={[6, 12]}
      >
        <Flex alignItems="center">
          {category && (
            <Heading fontWeigth="bold" size="h6" color={colors.category}>
              {category}
            </Heading>
          )}
          {tags &&
            tags.map((tag, index) => (
              <StyledTag key={index} bg={tag.color}>
                {tag.title}
              </StyledTag>
            ))}
        </Flex>

        {title && (
          <Box my={[1, 2]}>
            <Heading
              className="HeroCentered_Heading"
              size="hero"
              as="h1"
              color={colors.title}
            >
              {title}
            </Heading>
          </Box>
        )}

        {description && (
          <Box my={[1, 2]}>
            <Text
              size={'intro'}
              lineHeight={'1.55'}
              textAlign={'center'}
              color={colors.description}
              html={description}
              fontWeight={theme.fontWeight.lightBold}
            />
          </Box>
        )}

        {renderCta && (
          <Flex
            my={[2, 3]}
            justifyContent={['center', 'flex-start']}
            alignItems={['center']}
            flexWrap={'wrap'}
            flexDirection={['column', 'row']}
          >
            {renderCta('baseBlack')}
          </Flex>
        )}
      </StyledHeroContent>

      <StyledImageWrapper
        textAlign={'center'}
        justifyContent={'center'}
        mx={'auto'}
      >
        <StyledImageContainer
          mx={'auto'}
          maxWidth={'36rem'}
          width={imageSmall ? [3 / 12, 3 / 12, 3 / 12, 2 / 12] : [4 / 12]}
        >
          <img
            src={images.small}
            width="100%"
            height="auto"
            text-align="center"
            alt=""
          />
        </StyledImageContainer>
      </StyledImageWrapper>
    </StyledHeroWrapper>
  );
};

export default HeroCentered;

HeroCentered.propTypes = {
  /**  DEPRECATED: title prop deprecated*/
  title: PropTypes.string,
  /**  Title of the Hero Centered Component */
  description: PropTypes.string,
  /**  The description of the Hero Centered Component */
  category: PropTypes.string,
  renderCta: PropTypes.func,
};

HeroCentered.defaultProps = {
  title: null,
  description: null,
  category: null,
  color: 'black',
  renderCta: null,
};
