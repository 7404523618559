import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { theme } from '../common/theme';
import { mediaQuery } from '../common/utils';

const StyledWidget = styled(Box)`
  display: block;
`;

const StyledHeading = styled(Heading)`
  border-bottom: 1px solid;
  font-size: ${theme.fontSizes.intro};
  ${mediaQuery.small} {
    font-size: ${theme.fontSizes.h3};
  }
`;

const Widget = props => {
  const { title, intro, children, bgColor } = props;
  return (
    <StyledWidget bg={bgColor} p={[5, 5]} {...props}>
      {title && (
        <StyledHeading pb={2} mb={4} size={'h3'}>
          {title}
        </StyledHeading>
      )}
      {intro && (
        <Box mb={[1, 2]}>
          <Text size="body">{intro}</Text>
        </Box>
      )}
      {children && <Box mt={[1, 2]}>{children && children}</Box>}
    </StyledWidget>
  );
};

export default Widget;

Widget.propTypes = {
  /** Title of widget */
  title: PropTypes.string,
  /** Intro text below heading */
  intro: PropTypes.string,
  /** Render prop to allow rendering of other elements */
  children: PropTypes.node,
};

Widget.defaultProps = {
  title: null,
  intro: null,
  children: null,
  bgColor: 'baseGrayVeryLight',
};
