import React from 'react';
import { graphql } from 'gatsby';
import Layout from './layout';

export const ComponentWrapper = ({ section }) => {
  if (!section || !section.component) return null;

  const {
    component: { component, headline, props },
    table,
    meta,
  } = section;

  return (
    <Layout
      layout={[
        {
          __typename: component,
          headline,
          table,
          meta,
          ...props,
        },
      ]}
    />
  );
};

ComponentWrapper.defaultProps = {};

export const ComponentLayoutFields = graphql`
  fragment ComponentLayoutFields on ContentfulLayoutComponent {
    component {
      component
      headline
      props {
        ...ArchiveOptions
        ...TableGraphColumnFields
      }
    }
    table {
      ...TableGraphDataFields
    }
    meta {
      internal {
        content
      }
    }
  }
`;
