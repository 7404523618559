import React from 'react';
import { Button, ButtonAction, theme } from '@sygnia/components';
import { setupPage, setupLink } from '../common/utils';
import { graphql } from 'gatsby';
import { CONTENT_TYPES } from '../common/constants';
import Layout from './layout';
import { logGtmEvent } from '@sygnia/components';

export const BUTTON_TYPES = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  DOWNLOAD: 'Download',
  ICON: 'Icon',
};

const BUTTON_MAPPING = {
  [BUTTON_TYPES.PRIMARY]: {
    component: Button,
    options: {
      variant: 'primary',
    },
  },
  [BUTTON_TYPES.SECONDARY]: {
    component: Button,
    options: {
      variant: 'secondary',
    },
  },
  [BUTTON_TYPES.DOWNLOAD]: {
    component: ButtonAction,
    options: {
      icon: theme.icons.download,
    },
  },
  [BUTTON_TYPES.ICON]: {
    component: ButtonAction,
    options: {
      icon: theme.icons.arrowRight, //ToDo: Add icon to Contentful
      isIconAfter: true,
    },
  },
};

const LinkGroup = ({
  links,
  color,
  isInverse,
  isBlock,
  section = {},
  meta = {},
}) => {
  let linkGroup = links;
  if (section.links) {
    linkGroup = section.links;
  }

  if (!linkGroup) return null;

  return linkGroup.map((link, i) => {
    if (link.__typename === CONTENT_TYPES.CONTENT_MENU) {
      return <Layout layout={[link]} />;
    }
    if (!link) {
      return null;
    }

    const { component: Component, options = {} } = BUTTON_MAPPING[
      link.type || BUTTON_TYPES.PRIMARY
    ];

    if (!Component) {
      return null;
    }

    const linkOptions = { ...options, isInverse };

    if (isBlock && linkGroup.length === 1) {
      linkOptions.width = '100%';
    }

    const infoLink =
      link.__typename === 'ContentfulPage'
        ? setupPage(link, linkOptions)
        : setupLink(link, linkOptions);

    let gtmEventInfo = null;
    if (link.gtmEventInfo) {
      gtmEventInfo = link.gtmEventInfo;
    } else if (link.meta) {
      gtmEventInfo = JSON.parse(link.meta?.internal?.content)?.gtmEventInfo;
      if (gtmEventInfo) gtmEventInfo.data.label = infoLink.displayName;
    } else if (link.pageSummary?.gtmEventInfo) {
      gtmEventInfo = JSON.parse(
        link.pageSummary.gtmEventInfo?.internal?.content,
      )?.gtmEventInfo;
      if (gtmEventInfo) gtmEventInfo.data.label = link.pageSummary.label;
    }

    return (
      <Component
        mr={[0, linkGroup.length > 0 && i === 0 ? 1 : 0]}
        mb={[1, 1, 0]}
        color={color}
        meta={meta}
        key={i}
        {...infoLink}
        onClick={() => {
          gtmEventInfo &&
            logGtmEvent({
              ...gtmEventInfo,
            });
        }}
      >
        {infoLink.displayName}
      </Component>
    );
  });
};

LinkGroup.defaultProps = {
  isInverse: false,
};

export default LinkGroup;

export const LinkFields = graphql`
  fragment PageLinkFields on ContentfulPage {
    id
    name
    slug
    label: name
    contentfulparent
    pageSummary {
      title
      meta {
        label
      }
    }
  }
  fragment LinkFields on ContentfulLinks {
    title
    label
    type
    to
    external
    meta {
      internal {
        content
      }
    }
    file {
      title
      file {
        fileName
        url
      }
    }
  }
`;
