import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RESULT_TYPES } from '../search/ui/SearchMenuHits';
import Hits from '../search/hitComps';

const FundArchive = ({
  hits,
  view,
  columns = [],
  excludeColumns = [],
  showLink = false,
  as: hitComp = RESULT_TYPES.FUNDS_CARD,
}) => {
  const data = useStaticQuery(graphql`
    query getFundsArchive {
      allContentfulFunds(sort: { fields: title, order: ASC }) {
        nodes {
          category
          productType
          fundSilo
          ...FundCardLayoutFields
          fundPerformance {
            _3m
            _6m
            _1y
            _3y
            _5y
            _10y
          }
        }
      }
    }
  `);

  let filteredFunds = hits.length
    ? data.allContentfulFunds.nodes.filter(fund => {
        return hits.find(hit => hit.objectID === fund.fundInfoID);
      })
    : [];

  return (
    <Hits
      hits={filteredFunds}
      view={{ ...view, hitComp, columns }}
      showLink={showLink}
      excludeColumns={excludeColumns}
    />
  );
};

export default FundArchive;
