import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import ChartistGraph from 'react-chartist';

import { mapperWithFunction } from '../../common/utils';
import { Container } from '@sygnia/components';
let ChartistTooltip;

const getLabelWithParts = (value, column) => {
  const { prefix, suffix } = column;
  const parts = [];
  if (prefix) {
    parts.push(prefix);
  }

  parts.push(value);

  if (suffix) {
    parts.push(suffix);
  }
  return parts;
};
const TableGraph = ({ section, color }) => {
  useEffect(() => {
    ChartistTooltip = require('chartist-plugin-tooltips-updated');
  }, []);
  const TABLE_CONTENT_MAP = {
    tableData: ({ table = {} }) => {
      return table.internal && table.internal.content
        ? JSON.parse(table.internal.content)
        : null;
    },
  };

  const { tableData: data } = mapperWithFunction(section, TABLE_CONTENT_MAP);

  const xAxis = data.rows.map(r => {
    const { key } = section.columns[0];
    return r[key];
  });

  const series = data.rows.map(r => {
    const { key } = section.columns[1];
    const { key: labelKey } = section.columns[0];
    return { meta: r[labelKey], value: r[key] };
  });

  var options = {
    plugins: [],
    //high: 10,
    low: 0,
    axisX: {
      showGrid: 1,
      showLabel: 1,
    },
    axisY: {
      labelInterpolationFnc: function(t) {
        if (t === 0) return '';
        const parts = getLabelWithParts(t, section.columns[1]);
        return parts.join('');
      },
      offset: 40,
      scaleMinSpace: 25,
    },
    chartPadding: {
      left: 20,
      right: 20,
    },
  };

  if (ChartistTooltip) {
    options.plugins.push(
      ChartistTooltip({
        anchorToPoint: true,
        tooltipFnc: (meta, value) => {
          const parts = getLabelWithParts(value, section.columns[1]);
          return `<span class="ct-tooltip-meta ct-tooltip-meta--${color}">${meta}</span><br/><span class="ct-tooltip-label ct-tooltip-label--${color}">${parts.join(
            '',
          )}</span>`;
        },
      }),
    );
  }

  var type = 'Bar';

  return (
    <Container>
      <div style={{ marginTop: '20px', height: '300px' }}>
        <ChartistGraph
          key={section.title}
          className={`barGraph barGraph--${color}`}
          data={{
            labels: xAxis,
            series: [series],
          }}
          options={options}
          type={type}
        />
      </div>
    </Container>
  );
};

TableGraph.defaultProps = {
  color: 'green',
};

export default TableGraph;

export const fragment = graphql`
  fragment TableGraphColumnFields on contentfulLayoutComponentComponentJsonNodeProps {
    columns {
      label
      prefix
      suffix
      type
      key
    }
    title
  }
  fragment TableGraphDataFields on contentfulLayoutComponentTableJsonNode {
    internal {
      content
    }
  }
`;
