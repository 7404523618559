import React, { useState } from 'react';
import { TabsContainer, TimedTabs } from '@sygnia/components';
import { graphql } from 'gatsby';
import Layout from './layout';
import { CONTENT_TYPES } from '../common/constants';
import { StageSelector } from '@sygnia/components';
import AccordionWrapper from './CollapseWrapper';
import { BasicContent } from './ContentWrapper';

const TOGGLE_MAPPING = {
  [CONTENT_TYPES.ACCORDION]: {
    component: AccordionWrapper,
    tabs: modules =>
      modules.map(mod => ({
        title: mod.title,
        content: <BasicContent mb={0} section={{ richContent: mod.content }} />,
      })),
    options: {
      layout: 'center',
    },
  },
  [CONTENT_TYPES.TAB]: {
    component: TabsContainer,
    tabs: modules => modules.map(mod => mod.title),
    options: {
      layout: 'right',
    },
  },
  [CONTENT_TYPES.FLOATING_TABS]: {
    component: TimedTabs,
    tabs: modules =>
      modules.map(mod => {
        if (mod.__typename === CONTENT_TYPES.COMPONENT) {
          const {
            component: { props },
          } = mod;
          return props.title;
        }
        return mod.title;
      }),
    options: {
      align: 'center',
      duration: 0.5,
    },
  },
  [CONTENT_TYPES.STAGE_SELECTOR]: {
    component: StageSelector,
    tabs: modules => modules.map(mod => mod.title),
    options: {},
  },
};

export const ToggleWrapper = ({ section, colorScheme }) => {
  const [activeTab, setActiveTab] = useState(0);
  if (!section.modules) return null;

  const TabComponent = TOGGLE_MAPPING[section.layout];
  const activeComponent = (
    <Layout color={colorScheme} layout={[section.modules[activeTab]]} />
  );

  return (
    <>
      <TabComponent.component
        color={colorScheme}
        tabs={TabComponent.tabs(section.modules)}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        {...TabComponent.options}
      />
      {activeComponent}
    </>
  );
};

ToggleWrapper.defaultProps = {
  section: {},
};

export const fragment = graphql`
  fragment ToggleLayoutFields on ContentfulLayoutContentToggle {
    id
    title
    layout
    modules {
      __typename
      ... on Node {
        ...HeroImageLayoutFields
        ...SliderLayoutFields
        ...ContentGenericFields
        ...LayoutColumnFields
        ...ComponentLayoutFields
      }
    }
  }
`;

ToggleWrapper.fragment = fragment;
