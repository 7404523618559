import React from 'react';
import PropTypes from 'prop-types';
import { Widget, Text } from '@sygnia/components';
import { FormWrapper } from '../modules/FormWrapper';

const LandingPageWidget = props => {
  const {
    description,
    section: { section, title, styles, id },
  } = props;

  return (
    <Widget
      mb={[4]}
      title={title}
      description={description}
      bgColor={section.bgColor || 'baseGrayVeryLight'}
      {...styles}
    >
      <Text mb={[3]}>{description}</Text>
      <FormWrapper section={section} />
    </Widget>
  );
};

LandingPageWidget.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  section: PropTypes.object,
};

LandingPageWidget.defaultProps = {
  title: '',
};

export default LandingPageWidget;
