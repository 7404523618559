import React, { useState, createRef } from 'react';
import styled from 'styled-components';
import {
  ScrollTo,
  Configure,
  connectPagination,
} from 'react-instantsearch-dom';
import { Root } from './styles';
import { CustomHits, VirtualMenu } from './hitComps';
import { Container } from '@sygnia/components';
import AlgoliaWrapper from './AlgoliaWrapper';
import SearchFilters from './ui/Filters';
import PaginationWrapper from './ui/Pagination';

const CustomPagination = connectPagination(PaginationWrapper);

const StyledContainer = styled(Container)`
  @media (min-width: 768px) {
    max-height: ${props => !props.isCard && '550px'};
    overflow: auto;
    width: 100%;
  }
`;
export default function Search(props) {
  const baseProps = props.section || props;

  const {
    indices,
    refinements,
    viewAs = [],
    virtual,
    hasFilter,
    filters = '',
    isWrapped,
    isSearchable,
    perPageOptions,
    perPageLimit,
    showPagination,
    excludeColumns,
    showLink,
  } = baseProps;

  if (!indices.length) return null;
  const ref = createRef();
  const [query, setQuery] = useState({});
  const [view, setView] = useState(viewAs.length ? viewAs[0] : indices[0]);

  let Wrapper = React.Fragment;

  if (isWrapped) {
    Wrapper = Container;
  }

  const showFilter =
    hasFilter && ((refinements && refinements.length) || isSearchable);

  return (
    <AlgoliaWrapper
      indices={indices}
      root={{ Root, props: { ref } }}
      hitsPerPage={perPageLimit}
    >
      {!showFilter ? (
        <Configure filters={filters} hitsPerPage={perPageLimit} />
      ) : null}
      {virtual
        ? virtual.map(v => (
            <VirtualMenu
              attribute={v.key}
              key={v.key}
              defaultRefinement={v.value}
            />
          ))
        : null}
      <ScrollTo>
        <StyledContainer isCard={view.isCard}>
          {showFilter && (
            <SearchFilters
              isSearchable={isSearchable}
              refinements={refinements}
              setQuery={setQuery}
              query={query}
              setView={setView}
              currentView={view}
              views={viewAs}
            />
          )}
          <Wrapper>
            <CustomHits
              view={view}
              index={indices[0]}
              showLink={showLink}
              excludeColumns={excludeColumns}
            />
          </Wrapper>
        </StyledContainer>
      </ScrollTo>
      {showFilter || showPagination ? (
        <CustomPagination
          perPageOptions={perPageOptions}
          perPageLimit={perPageLimit}
        />
      ) : null}
    </AlgoliaWrapper>
  );
}

Search.defaultProps = {
  indices: [],
  virtual: [],
  title: 'Search',
  hasFilter: true,
  isWrapped: true,
  isTable: false,
  showPagination: true,
  perPageLimit: 20,
  perPageOptions: [
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 50,
      label: '50',
    },
  ],
  showLink: false,
  excludeColumns: [],
};
