import React from 'react';
import PropTypes from 'prop-types';
import CheckboxNative from './CheckboxNative';
import Text from '../../Text/Text';
import styled from 'styled-components';
import { theme } from '../../common/theme';
import FieldTemplate from '../FieldTemplate';

const StyledWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledLabel = styled(Text)`
  display: inline-block;
  padding-top: 2px;
  padding-left: ${theme.space[1]}px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props =>
    props.disabled ? theme.colors.baseGrayDark : theme.colors.baseBlack};
  font-family: ${theme.fonts.dmSans};
  font-size: ${theme.fontSizes.small};
`;

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ checked: event.target.checked });
  }

  render() {
    const { label, disabled, ...props } = this.props;

    return (
      <FieldTemplate {...props}>
        <StyledWrapper>
          <CheckboxNative disabled={disabled} {...props} />
          <StyledLabel html={label} disabled={disabled} />
        </StyledWrapper>
      </FieldTemplate>
    );
  }
}

export default Checkbox;

Checkbox.propTypes = {
  /** Label of the Checkbox */
  label: PropTypes.string.isRequired,
  /** Should the Checkbox be checked by default? */
  checked: PropTypes.bool,
  /** Should the Checkbox be disabled by default? */
  disabled: PropTypes.bool,
  /** Validation message displayed */
  error: PropTypes.string,
};

Checkbox.defaultProps = {
  label: 'Checkbox Label',
  checked: false,
  disabled: false,
  error: null,
};
