import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';

import { Text, Heading, ButtonAction } from '@sygnia/components';
import { theme } from '@sygnia/components';

const ProgressBox = styled(Box)`
  width: ${({ width }) => width}%;
  height: ${({ theme }) => theme.space[1]}px;
  background: ${({ color }) => color};
  opacity: ${({ theme, completed }) =>
    completed ? theme.opacity.full : theme.opacity.faint};
`;

const TwoPotTemplate = ({
  wizardTitle,
  stepNumber,
  totalSteps,
  stepTitle,
  fields,
  description,
  color,
  setStepNumber,
  variant,
  onStartOver = () => {},
  ...props
}) => {
  const isAlternative = variant === 'alternative';

  const { heading, activeStepIndicator, inactiveStepIndicator, text } = color;

  return (
    <>
      {isAlternative ? (
        <>
          <Flex mb={[1]} justifyContent="space-between" alignItems="center">
            <Flex>
              <Text fontWeight="bold" mr={[1]}>
                {stepNumber + 1}/{totalSteps}
              </Text>
              <Text>{wizardTitle}</Text>
            </Flex>

            <ButtonAction
              size="small"
              onClick={onStartOver}
              icon="restart"
              isIconAfter
            >
              Start Over
            </ButtonAction>
          </Flex>
          <Flex>
            <ProgressBox
              completed
              width={((stepNumber + 1) / totalSteps) * 100}
              color={activeStepIndicator}
            />
            <ProgressBox
              width={100 - ((stepNumber + 1) / totalSteps) * 100}
              color={inactiveStepIndicator}
            />
          </Flex>
        </>
      ) : null}
      <Box bg="baseWhite" px={[2, 2, 5]} pt={[2, 6, 8]} pb={[2, 6, 10]}>
        {!isAlternative ? (
          <Flex mb={[1]}>
            <Text fontWeight="bold" mr={[1]}>
              {stepNumber + 1}/{totalSteps}
            </Text>
            <Text>{wizardTitle}</Text>
          </Flex>
        ) : null}
        <Heading size="h2" mb={[1]} color={heading}>
          {stepTitle}
        </Heading>
        <Text color={text} html={description} />
        <Box mt={[3]}>{props.children}</Box>
      </Box>
    </>
  );
};

TwoPotTemplate.propTypes = {
  wizardTitle: PropTypes.string,
  stepNumber: PropTypes.number,
  totalSteps: PropTypes.number,
  stepTitle: PropTypes.string,
  fields: PropTypes.array,
  description: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  setStepNumber: PropTypes.func,
  variant: PropTypes.string,
};

TwoPotTemplate.defaultProps = {
  stepNumber: 0,
  totalSteps: 0,
  stepTitle: '',
  fields: [],
  description: '',
  color: 'black',
  setStepNumber: () => {},
  variant: 'default',
};

export default TwoPotTemplate;
