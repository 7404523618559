import React from 'react';
import { Video } from '@sygnia/components';
import { Flex } from 'rebass';
import { mapper } from '../common/utils';

const VideoWrapper = props => {
  const { section } = props;

  return (
    <Flex>
      <Video src={section.src} />
    </Flex>
  );
};

export default VideoWrapper;
