import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../Text/Text';
import { theme } from '../common/theme';

const StyledBreadCrumb = styled.li`
  display: flex;
  align-items: center;
  margin-right: ${props => !props.isLast && `${theme.space[1]}px`};
  width: 100%;
  i {
    font-size: 8px;
    font-weight: bold;
  }
  @media (min-width: ${theme.breakpoints[1]}) {
    width: inherit;
  }
`;

const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

const Breadcrumb = props => {
  const { label, isLast, ...link } = props;

  return (
    <StyledBreadCrumb {...link} isLast={isLast}>
      <TruncatedText size="small" mr={!isLast && [1]}>
        {label}
      </TruncatedText>
      {!isLast && <i className="icon icon-angle-right" />}
    </StyledBreadCrumb>
  );
};

export default Breadcrumb;

Breadcrumb.propTypes = {
  /** Link label */
  label: PropTypes.string.isRequired,
  /** Is this the last breadcrumb in the list? */
  isLast: PropTypes.bool.isRequired,
};

Breadcrumb.defaultProps = {
  label: null,
  isLast: false,
};
