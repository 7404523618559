import React, { useState, useRef, useEffect } from 'react';
import CurrencyFormat, { propTypes } from 'react-currency-format';
import get from 'lodash/get';
import {
  ButtonAction,
  Button,
  Section,
  Table,
  Text,
  Heading,
  Container,
  ListPosts,
  ProgressBar,
} from '@sygnia/components';
import { Flex, Box } from 'rebass';
import moment from 'moment';
import { graphql } from 'gatsby';
import {
  formIntro,
  formRetirement,
  formGoal,
  getFieldConditional,
} from './forms';
import { FormBuilderContainer } from './FormBuilder';
import {
  CONTENT_TITLES,
  ROBOSTEPS,
  STATIC_LINKS,
} from '../../common/constants';
import TableWrapper from '../table/TableWrapper';
import BarGraph from '../graphs/BarGraph';
import RoboAdvisorTemplate from './RoboAdvisorTemplate';
import { useStaticQuery } from 'gatsby';
import Layout from '../../modules/layout';
import { BasicContent } from '../../modules/ContentWrapper';
import { Loader, Input } from '@sygnia/components';
import { Form } from './FormContainer';
import {
  logGtmEvent,
  GTM_CATEGORY,
  GTM_EVENT,
  GTM_LOCATION,
} from '@sygnia/components';

const TabelCell = ({ children, ...restProps }) => (
  <Table.Td css={{ padding: '24px 8px' }} {...restProps}>
    {children}
  </Table.Td>
);

const getPersonFields = data => {
  return {
    IncludePartner: false,
    HasInvestmentProperty: false,
    RiskTolerance: data.investingProfile,
    Profile: {
      GenderClient: 0,
      GenderPartner: 0,
      DOBClient: moment()
        .startOf('year')
        .utcOffset(2)
        .subtract(parseInt(data.personAge), 'years')
        .format(),
      DOBPartner: '',

      RetAgeClient: data.personRetirementAge || 65,
      RetAgePartner: 0,
      lumpSumAmount: parseInt(data.lumpSumAmount) || 0,
      monthlyContributions: parseInt(data.contributionAmount) || 0,
    },

    IncomeAndExpenses: {
      SalaryClient: parseInt(data.income) || 0,
      SalaryPartner: 0,
      OtherIncomeClient: 0,
      OtherIncomePartner: 0,
      LivingExpenses: parseInt(data.expenses) || 0,
    },
    RetirementSavings: {
      MonthlyContributionPercClient: 1,
      MonthlyContributionPercPartner: 0,
      CurrentMValClient: parseInt(data.cashInvestments) || 0,
      CurrentMValPartner: 0,
      RiskStrategyClient: 0,
      RiskStrategyPartner: 0,
      ContribTypeClient: 0,
      ContribTypePartner: 0,
      MonthlyContributionAmountClient: 0,
      MonthlyContributionAmountPartner: 0,
    },
    Children: [],
    ResidentialProperty: {
      CurrentMVal: 0,
      OutstandingBondAmount: 0,
      OutstandingBondTermYears: 0,
      RentalIncomeMonthly: 0,
    },
    InvestmentProperty: {
      CurrentMVal: 0,
      OutstandingBondAmount: 0,
      OutstandingBondTermYears: 0,
      RentalIncomeMonthly: 0,
    },
    OtherInvestment: {
      TotalCurrentMValClient: 0,
      TotalMonthlyContribClient: 0,
      RiskStrategyClient: 0,
      TotalCurrentMValPartner: 0,
      TotalMonthlyContribPartner: 0,
      RiskStrategyPartner: 0,
    },
    ProductSetCode: 'ROBOADV',
    PlanDate: new Date(),
  };
};

const validate = (values, form) => {
  let errors = {};
  const { fields } = form;
  fields.forEach(field => {
    const fieldShouldShow = getFieldConditional(field, values);
    if (field.required && !values[field.name] && fieldShouldShow) {
      errors[field.name] = 'Required Field';
    } else if (
      field.validate === 'phone' &&
      !/^0(6[0-9]|7[0-46-9]|8[1-4])\d{7}$/i.test(values[field.name]) &&
      fieldShouldShow
    ) {
      errors[field.name] = 'Invalid phone number';
    } else if (
      field.validate === 'email' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field.name]) &&
      fieldShouldShow
    ) {
      errors[field.name] = 'Invalid email address';
    } else if (field.type === 'group' && fieldShouldShow) {
      const groupErr = validate(values, field);
      if (groupErr.errors) {
        errors = {
          ...errors,
          ...groupErr.errors,
        };
      }
    } else if (
      field.validate &&
      typeof field.validate === 'function' &&
      fieldShouldShow
    ) {
      const { check, message } = field.validate(values[field.name]);

      if (!check) {
        errors[field.name] = message;
      }
    }
  });

  return {
    errors,
    isValid: !Object.keys(errors).length,
  };
};

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const FormWizard = () => {
  const [view, setView] = useState(ROBOSTEPS.INTRO);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(null);
  const [currentIndex, setSection] = useState(0);
  const [formData, setForm] = useState({});
  const [form, setSelectedForm] = useState(formIntro);
  const [formErrors, setErrors] = useState(null);
  const currentSection = form.fields[currentIndex];

  const pageArchive = useStaticQuery(graphql`
    query pageArchive {
      contentfulPage(contentful_id: { eq: "Kj4HjRxvmfTjl0tyKBrr3" }) {
        ...LayoutPageFields
      }
    }
  `);

  const onStartOver = () => {
    setSection(0);
    setFormView('intro');
  };

  useEffect(() => {
    setForm({
      ...formData,
      ...(form.initConfig || {}),
    });
  }, [form]);

  const ChangeAnswers = (
    <Button
      variant="secondary"
      mr={[0, 3]}
      mb={[2, 0]}
      onClick={() => {
        setFormView('form');
        setSection(0);
      }}
    >
      {CONTENT_TITLES.changeAnswers}
    </Button>
  );

  const onFieldChange = (name, value) => {
    setForm({
      ...formData,
      [name]: value,
    });
  };

  const onStart = () => {
    const { errors, isValid } = validate(formData, currentSection);

    if (!isValid) {
      setErrors(errors);
    } else {
      setErrors(null);
      setSelectedForm(
        formData.introOptions === 'retirement' ? formRetirement : formGoal,
      );
      setFormView('form');
    }
  };

  const setFormView = view => {
    executeScroll();
    setView(view);
  };

  const onNextSection = () => {
    const { errors, isValid } = validate(formData, currentSection);
    if (!isValid) {
      setErrors(errors);
    } else {
      setErrors(null);
      setSection(currentIndex + 1);
    }
  };

  const onPreviousSection = () => {
    if (currentIndex >= 1) {
      setSection(currentIndex - 1);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    executeScroll();
    const data = {
      DocNo: 'string',
      Desc: 'string',
      RetirementInput: getPersonFields(formData),
    };

    var options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    // change name // conver to useFetch;

    const response = await fetch(
      'https://webapi-dev.sygnia.co.za/roboadvisor/calculateRetirment',
      options,
    );
    const result = await response.json();

    await onViewPlanSubmit(result);
    const { Result: savingsResult } = await onSavingsPlanSubmit(
      result,
      formData,
    );

    setResult({
      ...result,
      savingsResult,
      formData: {
        ...data,
      },
    });
    setFormView(
      formData.introOptions === 'retirement'
        ? ROBOSTEPS.RESULTS
        : ROBOSTEPS.GOAL_RESULTS,
    );
    setLoading(false);
  };

  const onSavingsPlanSubmit = async (retirementResult, input) => {
    var options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        goalAmount:
          formData.introOptions === 'retirement'
            ? retirementResult.MedShortfall
            : parseFloat(input.goalAmount),
        timehorizon:
          formData.introOptions === 'retirement'
            ? retirementResult.YearsTillRetirement
            : parseInt(input.timehorizon),
        lumpSumAmount: input.lumpSumAmount
          ? parseFloat(input.lumpSumAmount)
          : 0,
        prodType: 0,
        riskProfile: retirementResult.RiskProfile,
        targetField: 0,
        parmOGContrib: input.contributionAmount
          ? parseFloat(input.contributionAmount)
          : 0,
      }),
    };

    const response = await fetch(
      'https://webapi-dev.sygnia.co.za/roboadvisor/getSavingResult',
      options,
    );
    return response.json();
  };

  const onViewPlanSubmit = async profile => {
    var options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        riskProfileId: profile.RiskProfile,
        isReg28: true,
        productSetCode: 'ROBOADV',
        isSingle: false,
      }),
    };

    const response = await fetch(
      'https://webapi-dev.sygnia.co.za/roboadvisor/productDetail',
      options,
    );
    const resultPlan = await response.json();

    setPlan(resultPlan);
  };

  const onApply = async data => {
    setLoading(true);

    const {
      formData: { RetirementInput },
      ...RetirementResult
    } = result;

    var options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        RetirementInput,
        RetirementResult,
        type: formData.introOptions,
        email: data.email,
        FirstName: data.firstName,
        LastName: data.lastName,
        objective: formData.whatGoal || null,
      }),
    };

    const response = await fetch(
      'https://webapi-dev.sygnia.co.za/roboadvisor/submitSavings',
      options,
    );
    const resultPlan = await response.json();

    window.location.href = resultPlan.logiformsUrl;

    setLoading(false);
  };

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const planStep = () => {
    if (view === ROBOSTEPS.PLAN) {
      return (
        <Section bg="gray">
          <Container width={[1, 1, 9 / 12]} px={[0]}>
            <Box bg="baseWhite" px={[2, 2, 5]} pt={[4, 6, 8]} pb={[2, 6, 10]}>
              <Heading size="h2" color="yellow" pb={[2]}>
                Based on your current risk profile, here is how we would invest
                for you.
              </Heading>
              <Box bg="baseWhite" mb={[5]} mt={[5]}>
                <Flex
                  mb={[2]}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading size="h4">{CONTENT_TITLES.riskProfileRobo}</Heading>
                  <Text size="h4">
                    <a target="_blank" href={STATIC_LINKS.riskProfileURL}>
                      Why is this my risk profile?
                    </a>
                  </Text>
                </Flex>
                <ProgressBar
                  mb={0}
                  start={0}
                  progress={result.RiskProfile * 10}
                  barColor="baseGrayLight"
                  color="yellow"
                  label={`${result.RiskProfile}/10`}
                />
              </Box>
              <Box bg="baseWhite" mb={[5]} mt={[5]}>
                <ListPosts titleSize={'h3'} title={CONTENT_TITLES.assetMatrix}>
                  <div className="responsive-table">
                    <TableWrapper
                      section={{
                        table: {
                          columns: [
                            {
                              label: CONTENT_TITLES.unitTrust,
                              key: 'LispProductName',
                            },
                            {
                              label: 'Percentage',
                              key: 'AssetMixPercentage',
                            },
                            {
                              label: CONTENT_TITLES.assetClass,
                              key: 'LispProductAssetMix',
                            },

                            {
                              label: 'Allocation',
                              key: 'AssetMixAlloc',
                              showAsPercentage: true,
                              radius: 0,
                            },
                          ],
                          rows: plan.ProductAssetMix.sort((a, b) =>
                            a.AssetMixAlloc <= b.AssetMixAlloc ? 1 : -1,
                          ).map(p => ({
                            ...p,
                            AssetMixAlloc: (p.AssetMixAlloc * 100).toFixed(1),
                            AssetMixPercentage: (
                              <p style={{ textAlign: 'right' }}>{`${(
                                p.AssetMixAlloc * 100
                              ).toFixed(1)}%`}</p>
                            ),
                          })),
                        },
                      }}
                    />
                  </div>
                </ListPosts>
              </Box>
              <ListPosts
                titleSize={'h3'}
                title={CONTENT_TITLES.historicalPerformance}
              >
                <Box mt={5} mb={[5]}>
                  <BarGraph
                    graphClass="performance-graph"
                    xAxis={plan.FinplanModelProductPerf.map(p =>
                      moment(p.DATE).format('YYYY'),
                    )}
                    series={[
                      [...plan.FinplanModelProductPerf.map(p => p.RET * 100)],
                    ]}
                    options={{
                      axisX: {
                        stokeWidth: '10px',
                      },
                      axisY: {
                        labelInterpolationFnc: function(t) {
                          return `${t}%`;
                        },
                      },
                    }}
                  />
                </Box>
              </ListPosts>
              <small>{CONTENT_TITLES.disclaimer}</small>
              <Flex
                my={[5]}
                justifyContent="space-between"
                flexDirection={['column', 'row']}
              >
                {ChangeAnswers}
                <Button color="yellow" onClick={() => setFormView('apply')}>
                  Continue
                </Button>
              </Flex>
            </Box>
          </Container>
        </Section>
      );
    }
    return null;
  };

  const resultsStep = () => {
    if (view === ROBOSTEPS.RESULTS) {
      return (
        <Section bg="gray">
          <Container width={[1, 1, 9 / 12]} px={[0]}>
            <Box bg="baseWhite" px={[2, 2, 5]} pt={[4, 6, 8]} pb={[2, 6, 10]}>
              <Heading size={['h2']} color="yellow" pb={[2]}>
                Thank you!
              </Heading>
              <Box bg="baseWhite" mb={[5]} mt={[2, 5]}>
                <Table.Table>
                  <tbody>
                    <Table.Tr>
                      <Table.Th
                        css={{
                          padding: '24px 8px',
                          borderTop: '1px solid #000',
                        }}
                      >
                        {CONTENT_TITLES.retirementGoal}
                      </Table.Th>
                      <Table.Td
                        align={'right'}
                        css={{
                          padding: '24px 8px',
                          borderTop: '1px solid #000',
                          borderBottom: '1px solid #000',
                        }}
                      >
                        <CurrencyFormat
                          thousandSeparator={' '}
                          value={result.GoalNav.toFixed(0)}
                          displayType={'text'}
                          prefix={'R '}
                        />
                      </Table.Td>
                    </Table.Tr>
                  </tbody>
                </Table.Table>
              </Box>
              <ListPosts title={'Here is your financial snapshot:'}>
                <Table.Table>
                  <tbody>
                    <Table.Tr>
                      <TabelCell>
                        <Text fontWeight="bold">
                          {CONTENT_TITLES.yearToAccumulate} {}
                        </Text>
                      </TabelCell>
                      <TabelCell align={'right'}>
                        {result.YearsTillRetirement}{' '}
                        {result.YearsTillRetirement > 1 ? 'years' : 'year'}
                      </TabelCell>
                    </Table.Tr>
                    <Table.Tr>
                      <TabelCell>
                        <Text fontWeight="bold">
                          {CONTENT_TITLES.initialLumpSum}
                        </Text>
                      </TabelCell>
                      <TabelCell align={'right'}>
                        <CurrencyFormat
                          thousandSeparator={' '}
                          value={result.formData.RetirementInput.Profile.lumpSumAmount.toFixed(
                            0,
                          )}
                          displayType={'text'}
                          prefix={'R '}
                        />
                      </TabelCell>
                    </Table.Tr>
                    <Table.Tr>
                      <TabelCell>
                        <Text fontWeight="bold">
                          {CONTENT_TITLES.monthlyContributions}
                        </Text>
                      </TabelCell>
                      <TabelCell align={'right'}>
                        <CurrencyFormat
                          thousandSeparator={' '}
                          value={
                            result.formData.RetirementInput.Profile
                              .monthlyContributions
                          }
                          displayType={'text'}
                          prefix={'R '}
                        />
                      </TabelCell>
                    </Table.Tr>
                    <Table.Tr>
                      <TabelCell>
                        <Text fontWeight="bold">
                          {CONTENT_TITLES.estimateTax}
                        </Text>
                      </TabelCell>
                      <TabelCell align={'right'}>
                        <CurrencyFormat
                          thousandSeparator={' '}
                          value={result.MedNav.toFixed(0)}
                          displayType={'text'}
                          prefix={'R '}
                        />
                      </TabelCell>
                    </Table.Tr>
                  </tbody>
                </Table.Table>
              </ListPosts>

              <Flex my={[5]} flexDirection={['column', 'row']}>
                {ChangeAnswers}
                <Button color="yellow" onClick={() => setFormView('plan')}>
                  View your plan
                </Button>
              </Flex>
              <small>{CONTENT_TITLES.disclaimer}</small>
            </Box>
          </Container>
        </Section>
      );
    }

    return null;
  };

  const resultsGoalStep = () => {
    if (view === ROBOSTEPS.GOAL_RESULTS) {
      return (
        <Section bg="gray">
          <Container width={[1, 1, 9 / 12]} px={[0]}>
            <Box bg="baseWhite" px={[2, 2, 5]} pt={[4, 6, 8]} pb={[2, 6, 10]}>
              <Heading size={['h2']} color="yellow" pb={[2]}>
                Thank you!
              </Heading>
              <Box bg="baseWhite" mb={[5]} mt={[2, 5]}>
                <Table.Table>
                  <tbody>
                    <Table.Tr>
                      <Table.Th
                        css={{
                          padding: '24px 8px',
                          borderTop: '1px solid #000',
                        }}
                      >
                        {CONTENT_TITLES.retirementGoal}
                      </Table.Th>
                      <Table.Td
                        align={'right'}
                        css={{
                          padding: '24px 8px',
                          borderTop: '1px solid #000',
                          borderBottom: '1px solid #000',
                        }}
                      >
                        <CurrencyFormat
                          thousandSeparator={' '}
                          value={parseFloat(formData.goalAmount).toFixed(0)}
                          displayType={'text'}
                          prefix={'R '}
                        />
                      </Table.Td>
                    </Table.Tr>
                  </tbody>
                </Table.Table>
              </Box>
              <ListPosts title={'Here is your financial snapshot:'}>
                <Table.Table>
                  <tbody>
                    <Table.Tr>
                      <TabelCell>
                        <Text fontWeight="bold">
                          {CONTENT_TITLES.yearToAccumulate} {}
                        </Text>
                      </TabelCell>
                      <TabelCell align={'right'}>
                        {formData.timehorizon}{' '}
                        {formData.timehorizon > 1 ? 'years' : 'year'}
                      </TabelCell>
                    </Table.Tr>
                    <Table.Tr>
                      <TabelCell>
                        <Text fontWeight="bold">
                          {CONTENT_TITLES.initialLumpSum}
                        </Text>
                      </TabelCell>
                      <TabelCell align={'right'}>
                        <CurrencyFormat
                          thousandSeparator={' '}
                          value={result.formData.RetirementInput.Profile.lumpSumAmount.toFixed(
                            0,
                          )}
                          displayType={'text'}
                          prefix={'R '}
                        />
                      </TabelCell>
                    </Table.Tr>
                    <Table.Tr>
                      <TabelCell>
                        <Text fontWeight="bold">
                          {CONTENT_TITLES.monthlyContributions}
                        </Text>
                      </TabelCell>
                      <TabelCell align={'right'}>
                        {result?.savingsResult?.OGContrib && (
                          <CurrencyFormat
                            thousandSeparator={' '}
                            value={result?.savingsResult?.OGContrib?.toFixed(0)}
                            displayType={'text'}
                            prefix={'R '}
                          />
                        )}
                      </TabelCell>
                    </Table.Tr>
                    <Table.Tr>
                      <TabelCell>
                        <Text fontWeight="bold">
                          {CONTENT_TITLES.estimateTax}
                        </Text>
                      </TabelCell>
                      <TabelCell align={'right'}>
                        {result?.savingsResult?.EndValue && (
                          <CurrencyFormat
                            thousandSeparator={' '}
                            value={result?.savingsResult?.EndValue?.toFixed(0)}
                            displayType={'text'}
                            prefix={'R '}
                          />
                        )}
                      </TabelCell>
                    </Table.Tr>
                  </tbody>
                </Table.Table>
              </ListPosts>

              <Flex my={[5]} flexDirection={['column', 'row']}>
                {ChangeAnswers}
                <Button color="yellow" onClick={() => setFormView('plan')}>
                  View your plan
                </Button>
              </Flex>
              <small>{CONTENT_TITLES.disclaimer}</small>
            </Box>
          </Container>
        </Section>
      );
    }

    return null;
  };

  const applyStep = () => {
    if (view === ROBOSTEPS.APPLY) {
      const page = get(pageArchive, 'contentfulPage');
      const [content, ...restLayout] = page.layout;

      const title = content.title;
      const html = content.content.childMarkdownRemark.html.replace(
        '{{name}}',
        formData.introOptions === 'retirement'
          ? formRetirement.content
          : formGoal.content,
      );

      return (
        <Section bg="gray">
          <Container width={[1, 1, 9 / 12]} px={[0]}>
            <Box bg="baseWhite" px={[2, 2, 5]} pt={[2, 6, 8]} pb={[2, 6, 10]}>
              <Heading size="h2" color="yellow" pb={[2]}>
                {title}
              </Heading>
              <BasicContent html={html} />

              <Form
                onSubmit={formData => {
                  onApply(formData);
                }}
                form={{
                  buttonLabel: 'Apply Now',
                  fields: [
                    {
                      label: 'First Name',
                      placeholder: 'Enter your first name',
                      name: 'firstName',
                      required: true,
                      type: 'text',
                    },
                    {
                      label: 'Last Name',
                      placeholder: 'Enter your last name',
                      name: 'lastName',
                      required: true,
                      type: 'email',
                    },
                    {
                      label: 'Email',
                      placeholder: 'Enter email address',
                      name: 'email',
                      required: true,
                      type: 'email',
                    },
                  ],
                }}
              />

              <ButtonAction
                ml={[0, 1]}
                mt={[2, 0]}
                variant="secondary"
                onClick={() => setFormView('plan')}
              >
                Back to Plan
              </ButtonAction>

              {restLayout.length ? <Layout layout={restLayout} /> : null}
            </Box>
          </Container>
        </Section>
      );
    }

    return null;
  };

  const introStep = () => {
    if (view === ROBOSTEPS.INTRO) {
      return (
        <Section bg="gray">
          <Container width={[1, 1, 9 / 12]} px={[0]}>
            <Box bg="baseWhite" px={[2, 2, 5]} pt={[2, 6, 8]} pb={[2, 6, 10]}>
              <Heading size="h2" color="yellow" pb={[2]}>
                {formIntro.label}
              </Heading>
              <Text>{formIntro.description}</Text>
              <Box mt={[3]}></Box>

              <FormBuilderContainer
                formData={formData}
                form={formIntro}
                errors={formErrors}
                wrapper={Box}
                wrapperOptions={{ pb: [2] }}
                handleFieldChange={onFieldChange}
              />
              <Box mt={[3]}>
                <Button
                  mt={[0, 0, 2]}
                  onClick={() => {
                    onStart();
                    logGtmEvent({
                      eventName: GTM_EVENT.ROBO_ADVISOR_STARTED,
                      data: {
                        category: GTM_CATEGORY.FORM,
                        label: 'Start',
                        location: GTM_LOCATION.CONTENT,
                      },
                    });
                  }}
                >
                  Start
                </Button>
              </Box>
            </Box>
          </Container>
        </Section>
      );
    }

    return null;
  };

  const loadingComponent = () => {
    return (
      <Section bg="gray">
        <Container width={[1, 1, 9 / 12]} px={[0]} css={{ minHeight: '400px' }}>
          <Box bg="baseWhite" px={[2, 2, 5]} pt={[4, 6, 8]} pb={[2, 6, 10]}>
            <Loader description={'Loading...'} />
          </Box>
        </Container>
      </Section>
    );
  };

  const formStep = () => {
    if (view === ROBOSTEPS.FORM) {
      return (
        <Section bg="gray">
          <Container width={[1, 1, 9 / 12]}>
            <RoboAdvisorTemplate
              isAlternative
              color={'yellow'}
              variant="alternative"
              stepTitle={currentSection.label}
              description={currentSection.description}
              wizardTitle={form.title}
              totalSteps={form.fields.length}
              stepNumber={currentIndex}
              onStartOver={onStartOver}
            >
              <FormBuilderContainer
                formData={formData}
                form={currentSection}
                formType={form.formType}
                errors={formErrors}
                wrapper={Box}
                wrapperOptions={{ pb: [2] }}
                handleFieldChange={onFieldChange}
              />

              <Flex
                justifyContent="flex-start"
                width="100%"
                flexDirection={['column', 'row']}
              >
                {currentIndex !== form.fields.length - 1 ? (
                  <Button color="yellow" onClick={onNextSection}>
                    Next
                  </Button>
                ) : null}
                {currentIndex === form.fields.length - 1 ? (
                  <Button color="yellow" onClick={onSubmit}>
                    Submit
                  </Button>
                ) : null}
                {currentIndex !== 0 ? (
                  <ButtonAction
                    ml={[0, 1]}
                    mt={[2, 0]}
                    variant="secondary"
                    onClick={onPreviousSection}
                  >
                    Previous
                  </ButtonAction>
                ) : null}
              </Flex>
            </RoboAdvisorTemplate>
          </Container>
        </Section>
      );
    }

    return null;
  };

  return (
    <div ref={myRef}>
      {loading ? (
        loadingComponent()
      ) : (
        <>
          {introStep()}
          {formStep()}
          {planStep()}
          {resultsStep()}
          {resultsGoalStep()}
          {applyStep()}
        </>
      )}
    </div>
  );
};

export default FormWizard;
