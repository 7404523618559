import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonArrow from '../ButtonArrow/ButtonArrow';
import { theme } from '../common/theme';

/**
 * Half the size of the ButtonArrow component,
 * in order to correctly vertically center the
 * buttons.
 **/
const offset = 45 / 2;

const StyledButtonArrow = styled(ButtonArrow)`
  display: block;
  position: absolute;
  z-index: 1;
  ${({ layout, offset }) => `${layout}: ${offset}px`};
  top: calc(90% - ${offset}px);
  @media (min-width: ${theme.breakpoints[1]}) {
    top: calc(87% - ${offset}px);
  }
  @media (min-width: ${theme.breakpoints[2]}) {
    top: calc(50% - ${offset}px);
  }
`;

const SliderArrow = ({ onClick, layout, colorScheme, offset }) => {
  return (
    <StyledButtonArrow
      className={'slick-slide-button'}
      data-testid={`slider-arrow-${layout}`}
      direction={layout}
      onClick={onClick}
      layout={layout}
      colorScheme={colorScheme}
      offset={offset}
    />
  );
};

SliderArrow.propTypes = {
  /** Function to call when the SliderArrow is clicked */
  onClick: PropTypes.func,
  /** The layout positioning of the arrow */
  layout: PropTypes.string,
  /** The color scheme the button will take on */
  colorScheme: PropTypes.oneOf(['light', 'dark']),
  /** The left and right offset of the buttons */
  offset: PropTypes.number,
};

SliderArrow.defaultProps = {
  onClick: null,
  layout: 'left',
  colorScheme: 'light',
  offset: 10,
};

export default SliderArrow;
