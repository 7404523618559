import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Table, Container } from '@sygnia/components';
import { Box } from 'rebass';
import LinkGroup, { BUTTON_TYPES } from '../../modules/LinkGroup';
import {
  CONTENT_TITLES,
  GTM_CATEGORY,
  GTM_EVENT,
  GTM_LOCATION,
} from '../../common/constants';

const ETF_FUNDS_QUERY = gql`
  {
    etfs {
      name
      assetclass
      ter
      isin
      currency
      nav
    }
  }
`;

const FundETFTable = props => {
  const { loading, error, data } = useQuery(ETF_FUNDS_QUERY);

  if (!data) return null;
  if (loading) return null;
  if (error) return <p>{error}</p>;

  const funds = useStaticQuery(graphql`
    query geEtfArchive {
      allContentfulFunds {
        nodes {
          title
          slug
          category
          internalIndentifier
          productType
          fundSilo
          ...FundCardLayoutFields
        }
      }
    }
  `);

  const filteredFunds = funds.allContentfulFunds.nodes
    .filter(p => {
      if (props.fundTypes && !props.fundTypes.includes(p.productType)) {
        return false;
      }

      if (!data.etfs.some(fund => fund.isin === p.internalIndentifier)) {
        return false;
      }

      return true;
    })
    .map(p => {
      const match = data.etfs.find(fund => fund.isin === p.internalIndentifier);

      return {
        ...p,
        ...match,
      };
    });

  const columns = [
    {
      title: 'Fund',
      key: 'title',
      render: ({ title, slug }) => <Link to={`/fund/${slug}`}>{title}</Link>,
    },
    { title: 'Asset Class', key: 'assetclass' },
    { title: 'ISIN', key: 'isin' },
    { title: 'NAV', key: 'nav' },
    { title: 'TER', key: 'ter', render: ({ ter }) => <>{ter}%</> },
    {
      title: null,
      render: fund =>
        fund.factSheet?.file?.url && (
          <LinkGroup
            links={[
              {
                type: BUTTON_TYPES.DOWNLOAD,
                as: 'a',
                label: CONTENT_TITLES.factSheet,
                external: true,
                to: fund.factSheet.file.url,
                gtmEventInfo: {
                  eventName: GTM_EVENT.FACT_SHEET_DOWNLOADED,
                  data: {
                    category: GTM_CATEGORY.DOCUMENT,
                    label: CONTENT_TITLES.factSheet,
                    location: GTM_LOCATION.CONTENT,
                  },
                },
              },
            ]}
          />
        ),
    },
  ];

  return (
    <Container>
      <Box py={[3, 5]}>
        <div className="responsive-table">
          <Table.Table>
            <Table.Thead>
              <Table.Tr>
                {columns.map(({ title }) => (
                  <Table.Th>{title}</Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <tbody>
              {filteredFunds
                .sort((a, b) =>
                  a.name === b.name ? 0 : a.name < b.name ? -1 : 1,
                )
                .map(fund => {
                  return (
                    <Table.Tr key={fund.title}>
                      {columns.map(({ key, render }) =>
                        render ? (
                          <Table.Td>{render(fund)}</Table.Td>
                        ) : (
                          <Table.Td>{fund[key]}</Table.Td>
                        ),
                      )}
                    </Table.Tr>
                  );
                })}
            </tbody>
          </Table.Table>
        </div>
        <Box py={[1]}>
          <small>Source: Profile Data</small>
        </Box>
      </Box>
    </Container>
  );
};
FundETFTable.defaultProps = {
  fundTypes: ['ETF', 'Actively Managed ETF'],
};
export default FundETFTable;
