import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import Text from '../Text/Text';
import Heading from '../Heading/Heading';
import { theme } from '../common/theme';
import { Collapse } from '../CollapseWrapper/Collapse';
import ButtonAction from '../ButtonAction/ButtonAction';
import Content from '../Content';

const StyledQNA = styled(Box)`
  display: block;
  border-bottom: ${isCollapsable =>
    isCollapsable ? '1px solid white' : 'transparent'};
`;

const StyledAvatar = styled(Box)`
  border-radius: 50%;
  background-image: url('${({ avatar }) => avatar}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: ${theme.space[8]}px;
  height: ${theme.space[8]}px;
`;

const QNA = props => {
  const {
    question,
    answer,
    subtitle,
    isCollapsable,
    avatar,
    isOpen,
    onClick,
  } = props;

  return (
    <StyledQNA p={[2, 4]} isCollapsable={isCollapsable} {...props}>
      <Flex flexDirection={['column', 'row']}>
        {avatar && (
          <Box mb={[2, 0]}>
            <StyledAvatar avatar={avatar} mr={[4]} />
          </Box>
        )}

        <Box>
          {subtitle && (
            <Heading mb={1} size={'h6'}>
              {subtitle}
            </Heading>
          )}
          {question && (
            <Text mb={1} size="intro" fontWeight={'bold'} html={question} />
          )}
          {answer && !isCollapsable && (
            <Box>
              <Content.Body mb="0">
                <Text size="intro" html={answer} />
              </Content.Body>
            </Box>
          )}
          {isCollapsable && (
            <Collapse
              isOpen={isOpen}
              borderBottom={'none'}
              renderHeader={() =>
                !isOpen && (
                  <ButtonAction
                    mt={[1, 2]}
                    mb={[1, 2]}
                    px={0}
                    py={0}
                    onClick={onClick}
                  >
                    {'View Answer'}
                  </ButtonAction>
                )
              }
              renderBody={
                <Box mx={'-32px'}>
                  <Content.Body mb={0}>
                    <Text size="intro" html={answer} />
                    {isOpen && (
                      <ButtonAction mt={[1, 2]} px={0} py={0} onClick={onClick}>
                        {'Close'}
                      </ButtonAction>
                    )}
                  </Content.Body>
                </Box>
              }
            />
          )}
        </Box>
      </Flex>
    </StyledQNA>
  );
};

export default QNA;

QNA.propTypes = {
  /** Subtitle of QNA */
  subtitle: PropTypes.string,
  /** question text */
  question: PropTypes.string,
  /** answer text */
  answer: PropTypes.node,
  /** Prop to show image */
  avatar: PropTypes.string,
  /** onClick event to be handled by the wrapper  */
  onClick: PropTypes.node,
  /** Boolean to show the full answer, or to add a toggle to reveal the answer */
  isCollapsable: PropTypes.bool,
  /** Boolean to open or close a Collapsable QNA */
  isOpen: PropTypes.bool,
};

QNA.defaultProps = {
  subtitle: '',
  question: '',
  answer: null,
  avatar: null,
  isCollapsable: false,
  isOpen: false,
};
