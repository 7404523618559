import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { theme } from '../../common/theme';
import FieldTemplate from '../FieldTemplate';
import { baseStyles } from '../Styles';

const StyledSelect = styled.select`
  ${baseStyles};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: block;
  appearance: none; /* Hides default arrows*/
  background-image: ${props =>
    props.disabled
      ? 'none'
      : "url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMyNTM4M2MiIHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2Ij48cGF0aCBkPSJNMTM5NSA3MzZxMCAxMy0xMCAyM2wtNDY2IDQ2NnEtMTAgMTAtMjMgMTB0LTIzLTEwTDQwNyA3NTlxLTEwLTEwLTEwLTIzdDEwLTIzbDUwLTUwcTEwLTEwIDIzLTEwdDIzIDEwbDM5MyAzOTMgMzkzLTM5M3ExMC0xMCAyMy0xMHQyMyAxMGw1MCA1MHExMCAxMCAxMCAyM3oiPjwvcGF0aD48L3N2Zz4=')"};
  background-repeat: no-repeat, repeat;
  background-size: 18px auto, 100%;
  background-position: 98% center;
  color: ${props =>
    props.disabled
      ? theme.colors.baseGray //ToDo: Check with Iaan style for disabled.
      : props.value && props.value != -1
      ? theme.colors.baseBlack
      : theme.colors.baseGrayDark};
  border-radius: 0px;
  background-color: ${theme.colors.baseWhite};
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
  ${({ formType }) =>
    formType === 'modern'
      ? css`
          border: none;
          border-bottom: 1px solid ${theme.colors.baseGrayLight};
        `
      : css`
          border: 1px solid ${theme.colors.baseGrayLight};
          padding: ${theme.space[1]}px;
        `}
`;

export const Select = props => {
  return (
    <FieldTemplate {...props}>
      <StyledSelect id={props.name} {...props}>
        {props.placeholder ? (
          <option value={-1}>{props.placeholder}</option>
        ) : (
          <option />
        )}
        {props.items.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </StyledSelect>
    </FieldTemplate>
  );
};

Select.propTypes = {
  /** Items listed */
  items: PropTypes.array.isRequired,
  /** Defines a name for the Select */
  name: PropTypes.string,
  /** Is this a required field? */
  required: PropTypes.bool,
  /** Should the Select be disabled? */
  disabled: PropTypes.bool,
  /** Label for Select */
  label: PropTypes.string,
  /** Placeholder (Will appear as first item in the dropdown) */
  placeholder: PropTypes.string,
  /** Validation message displayed */
  error: PropTypes.string,
  /** Defines the number of visible options in the Select */
  size: PropTypes.number,
  /** formType will determine the design of the input */
  formType: PropTypes.oneOf(['default', 'modern']),
};

Select.defaultProps = {
  items: null,
  label: null,
  placeholder: null,
  required: false,
  disabled: false,
  error: null,
  size: 1,
  formType: 'modern',
};

export default Select;
