import React from 'react';
import PropTypes from 'prop-types';
import { Card as RebassCard, Box } from 'rebass';
import { Row, Column } from '../Grid';
import Text from '../Text/Text';
import Heading from '../Heading/Heading';
import HideAt from '../Responsive/HideAt';

const PeopleModal = ({ image, title, meta, subtext, content }) => {
  return (
    <>
      <Row>
        <Column>
          <Heading size="h1">{title}</Heading>
          <Text mt={[1]} size="intro" html={subtext} />
          <Box mt={[3]}>
            <Heading size="h5" html={meta} />
          </Box>
        </Column>
        <HideAt breakpoint="small">
          <Column flex={'0 0 auto'} width={[5 / 12]}>
            <Box>
              <RebassCard
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                backgroundPosition="center center"
                backgroundImage={`url(${image})`}
                pb="101%"
              />
            </Box>
          </Column>
        </HideAt>
      </Row>
      <Box mt={[4]}>
        <Text size="body" html={content} />
      </Box>
    </>
  );
};

PeopleModal.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  meta: PropTypes.array,
  subtext: PropTypes.string,
  content: PropTypes.string,
};

PeopleModal.defaultProps = {
  image: '',
  title: '',
  meta: [],
  subtext: '',
  content: '',
};

export default PeopleModal;
