import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../common/theme';

const StyledItem = styled.li`
  text-decoration: ${({ isActive }) =>
    isActive ? 'no-decoration' : 'underline'};
  font-family: ${theme.fonts.dmSans};
  font-weight: ${({ isActive }) => (isActive ? 'normal' : 'bold')};
  font-size: 16px;
  @media (max-width: ${theme.breakpoints[1]}) {
    font-size: 14px;
  }
  margin: 10px 0;
  line-height: 24px;
  &:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
`;

const NavigationIndexItem = ({ title, isActive }) => {
  return (
    <StyledItem isActive={isActive} fontSize={[1, 6]}>
      {title}
    </StyledItem>
  );
};

export default NavigationIndexItem;

NavigationIndexItem.propTypes = {
  /** Is this the current page? */
  isActive: PropTypes.bool,
  /** Link label */
  title: PropTypes.string,
};
