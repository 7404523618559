import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../Text/Text';
import Heading from '../Heading/Heading';
import { Box } from 'rebass';
import { theme } from '../common/theme';
import { TableCssDefinition } from '../Table/Table';
import { mediaQuery } from '../common/utils';

const StyledTitle = styled(Box)`
  border-bottom: 1px solid #000;
`;

const display = {
  h3: {
    pb: 2,
    mb: 4,
  },
  h1: {
    pb: 4,
  },
};

const ContentTitle = props => {
  const { children, size } = props;
  return (
    children && (
      <StyledTitle {...display[size]}>
        <Heading size={size}>{children}</Heading>
      </StyledTitle>
    )
  );
};

const StyledContent = styled(Text)`
  img {
    width: inherit;
    max-width: 100%;
    height: auto;
  }
  p {
    margin-bottom: ${theme.space[2]}px;
    font-family: ${theme.fonts.dmSans};
    font-size: ${theme.fontSizes.body};
  }
  a {
    color: inherit;
    text-decoration: underline !important;
    &:hover {
      text-decoration: none !important;
    }
  }

  .styledButton {
    text-decoration: none !important;
  }
  b,
  strong {
    font-weight: ${theme.fontWeight.bold};
  }
  em {
    font-style: italic;
  }
  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-left: ${theme.space[2]}px;
    margin-bottom: ${theme.space[2]}px;
  }
  li > p {
    margin-bottom: 0px;
  }
  small {
    font-size: ${theme.fontSizes.small};
  }
  hr {
    border-style: solid;
    border-width: 1px;
    border-color: ${theme.colors.baseBlack};
    margin-block-start: ${theme.space[1]}px;
    margin-block-end: ${theme.space[3]}px;
    border-bottom: 0;
  }
  blockquote {
    margin: 0;
    padding: 0;
    line-height: 1.6;
    font-size: ${theme.fontSizes.intro};
    margin-top: ${theme.space[4]}px;
    margin-bottom: ${theme.space[4]}px;
  }
  cite {
    font-style: italic;
  }
  address {
    font-style: normal;
  }
  figure {
    margin: 0;
    padding: 0;
    margin-top: ${theme.space[4]}px;
    margin-bottom: ${theme.space[4]}px;
  }
  figcaption {
    margin-top: ${theme.space[1]}px;
    font-size: ${theme.fontSizes.small};
  }
  table {
    ${TableCssDefinition.table};
  }
  th {
    ${TableCssDefinition.th}
  }
  td {
    ${TableCssDefinition.td}
  }
  tr {
    ${TableCssDefinition.tr}
  }
  h6 {
    font-size: ${theme.fontSizes.h6};
  }
  h5 {
    font-size: ${theme.fontSizes.h5};
  }
  h4 {
    font-size: ${theme.fontSizes.h4};
  }
  h3 {
    font-size: ${theme.fontSizes.h3};
    margin-top: ${theme.space[2]}px;
    margin-bottom: ${theme.space[2]}px;
  }
  h2 {
    font-size: ${theme.fontSizes.h2};
  }
  h1 {
    font-size: ${theme.fontSizes.h1};
  }
  ${mediaQuery.xs} {
    h3 {
      font-size: 24px;
      font-weight: 700;
    }
  }
  ${mediaQuery.small} {
    h3 {
      font-size: 24px;
      font-weight: 700;
    }
  }
  ${mediaQuery.medium} {
    h3 {
      font-size: 24px;
      font-weight: 700;
    }
  }
  ${mediaQuery.large} {
    h3 {
      font-size: 30px;
      font-weight: 700;
    }
  }
  ${mediaQuery.xlarge} {
    h3 {
      font-size: 30px;
      font-weight: 700;
    }
  }
`;

const ContentBody = props => {
  const { html, mb } = props;
  if (html) {
    return <StyledContent dangerouslySetInnerHTML={{ __html: html }} mb={mb} />;
  }

  return <StyledContent mb={mb}>{props.children}</StyledContent>;
};

export default { Title: ContentTitle, Body: ContentBody };

ContentTitle.defaultProps = {
  /**  Children renders the Title */
  children: null,
  size: 'h3',
};

ContentTitle.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(display)),
};

ContentBody.propTypes = {
  /**  html renders the Content */
  html: PropTypes.node,
  /** children renders the Content */
  children: PropTypes.node,
  /**  mb content margin bottom */
  mb: PropTypes.array,
};

ContentBody.defaultProps = {
  html: null,
  mb: [6],
};
