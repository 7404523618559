import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../common/theme';
import Text from '../Text/Text';
import styled from 'styled-components';

import AnimatedLink from '../AnimatedLink/AnimatedLink';

const StyledNavLink = styled(Text)`
  position: relative;
  display: ${({ hideOnMobile }) => (!hideOnMobile ? 'flex' : 'none')};
  width: fit-content;
  align-items: center;
  opacity: ${({ isVisible }) =>
    isVisible ? theme.opacity.full : theme.opacity.none};
  transform: ${({ isVisible }) =>
    isVisible ? 'translateX(0px)' : 'translateX(600px)'};
  transition: transform ${theme.speed.slow} ease,
    opacity ${theme.speed.fast} ease;
`;

const NavLink = ({
  isVisible,
  color,
  isLast,
  icon,
  text,
  isSiloActive,
  ...link
}) => (
  <StyledNavLink
    mr={!isLast && [0, 4, 4, 4]}
    isVisible={isVisible}
    color={color}
    isSiloActive={isSiloActive}
    {...link}
  >
    <React.Fragment>
      <AnimatedLink
        size="small"
        fontWeight={theme.fontWeight.bold}
        color={color}
      >
        {text}
      </AnimatedLink>
      {icon && icon}
    </React.Fragment>
  </StyledNavLink>
);

NavLink.propTypes = {
  /** Should the item be isVisibleed */
  isVisible: PropTypes.bool,
  /** Is this the last item in the list? If so, the margin will be adjusted */
  isLast: PropTypes.bool,
  /** Should there be an icon. Accepts a react element to render. */
  icon: PropTypes.node,
  /** Text as label of the nav item */
  text: PropTypes.string.isRequired,
  /** Set the color of the nav item */
  color: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
  /** Is the Silo active */
  isSiloActive: PropTypes.bool,
  /** Is the Link visible on Mobile */
  hideOnMobile: PropTypes.bool,
};

NavLink.defaultProps = {
  isVisible: false,
  isLast: false,
  icon: null,
  text: null,
  color: 'baseBlack',
  isSiloActive: false,
  hideOnMobile: false,
};

export default NavLink;
