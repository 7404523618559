import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import Heading from '../../components/Heading/Heading';
import { theme } from '../common/theme';

const StyledBorder = styled(Box)`
  border-top: ${({ borderColor }) => `1px solid ${borderColor}`};
`;

const ContentHeading = props => {
  const { title, size, borderColor } = props;

  return (
    <>
      <Heading size={size} mb={[2, 3]}>
        {title}
      </Heading>
      <StyledBorder borderColor={borderColor} />
    </>
  );
};

ContentHeading.propTypes = {
  /** Title for the component */
  title: PropTypes.string,
  /** Size of the Heading */
  size: PropTypes.oneOf(['hero', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  /** Color of the Border */
  borderColor: PropTypes.oneOf(Object.keys(theme.colors)),
};

ContentHeading.defaultProps = {
  title: null,
  size: 'h1',
  borderColor: theme.colors.baseBlack,
};

export default ContentHeading;
