import React from 'react';
import PropTypes from 'prop-types';
import { Container, Column, Row } from '../Grid';
import styled from 'styled-components';
import { theme } from '../common/theme';
import { Box } from 'rebass';

const StyledRow = styled(Row)`
  ${({ isMobile }) => isMobile && 'height: 100%;'};

  border-left: ${({ borderColor, isMobile }) =>
    isMobile && `1px solid ${theme.colors[borderColor]}`};

  & > * {
    border-right: 1px solid ${({ borderColor }) => theme.colors[borderColor]};
  }

  & > *:last-child {
    border-right: none;
  }

  ${({ isMobile, borderColor }) =>
    isMobile &&
    `
      border-top: none;

      & > * {
        border-right: none;
      }

      & > *:first-of-type {
        border-bottom: 1px solid ${theme.colors[borderColor]};
      }

      .link-primary-description {
        display: none;
      }

      .link-primary-icon {
        opacity: 1;
      }
    `}
`;

const StyledWrapper = styled(Box)`
  ${({ isActive, borderColor }) =>
    isActive &&
    `
    border-top: 1px solid ${theme.colors[borderColor]};
    border-bottom: 1px solid ${theme.colors[borderColor]};
  `};
  max-height: ${({ isActive }) => (isActive ? '1000px' : '0px')};
  transition: all ${theme.speed.default} ease;
  overflow: hidden;
  ${({ isMobile }) => !isMobile && 'left: 0'};

  ${({ isMobile, isActive }) =>
    isMobile &&
    `
      right: ${isActive ? '0px' : '-100vw'};
      max-height: 100vh;
      transition: right ${theme.speed.default} ease;
      overflow-x: hidden;
      overflow-y: scroll;
    `}
  z-index: 1000;
  position: fixed;
  top: ${({ offset }) => offset}px;
  height: 100%;
`;

const StyledContainer = styled(Container)`
  ${({ isMobile }) => isMobile && 'margin-bottom: 100px;'};
`;

const StyledRowFooter = styled(Row)`
  border-top: 1px solid ${({ borderColor }) => theme.colors[borderColor]};
`;
/**
 * Generic menu constructed from components provided in props.
 * The `content` prop defines an array of arrays, each containing
 * objects that define the widths of their components, and the components
 * to be rendered themselves.
 */
/*
  TODO: ensure arrays have an id or key parameter
*/
const Menu = ({
  content,
  borderColor,
  bg,
  isActive,
  isMobile,
  isSiloActive,
  footer,
}) => {
  return (
    <StyledWrapper
      borderColor={borderColor}
      bg={bg}
      isActive={isActive}
      isSiloActive={isSiloActive}
      offset={'64px'}
      isMobile={isMobile}
      width={['80vw', '80vw', 1]}
      px={0}
    >
      <StyledContainer px="0px" isMobile={isMobile} navHeight={'64px'}>
        <StyledRow
          flexDirection={['column', 'column', 'row']}
          borderColor={borderColor}
          mx={0}
          px={[3, 3, 0]}
          isMobile={isMobile}
        >
          {content.map(({ width, components }, index) => (
            <Column key={index} width={width} px={[0]} flex={'0 1 auto'}>
              {components.map((comp, index) => (
                <Box key={index} mx={0}>
                  {comp}
                </Box>
              ))}
            </Column>
          ))}
        </StyledRow>
      </StyledContainer>
      {!isMobile && (
        <StyledRowFooter
          borderColor={borderColor}
          justifyContent="center"
          alignItems="center"
          mx={0}
          px={[3, 3, 0]}
        >
          <Column width={[1, 1, 2 / 3]} px={[0, 0, 3]} flex="0 1 auto">
            {footer}
          </Column>
        </StyledRowFooter>
      )}
    </StyledWrapper>
  );
};

Menu.propTypes = {
  /** defines the components to be rendered in the menu */
  content: PropTypes.array,
  /** defines the color of the borders to be rendered */
  borderColor: PropTypes.oneOf([
    'baseGray',
    'baseGrayLight',
    'baseGrayVeryLight',
  ]),
  /** defines the background color of the menu */
  bg: PropTypes.oneOf(['baseWhite', 'baseGrayLight', 'baseGrayVeryLight']),
  /** defines whether the menu is currently active. Parent
   *  components will provide this as they will control the
   *  active state
   */
  isActive: PropTypes.bool,
  /** defines whether the silo menu is currently active.*/
  isSiloActive: PropTypes.bool,
  /** defines whether the component is being rendered on a mobile screen */
  isMobile: PropTypes.bool,
  /** component rendering the footer content */
  footer: PropTypes.node,
};

Menu.defaultProps = {
  content: [],
  borderColor: 'baseGrayLight',
  bg: 'baseWhite',
  isActive: false,
  isSiloActive: false,
  isMobile: false,
  footer: null,
};

export default Menu;
