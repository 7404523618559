import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import Text from '../Text/Text';
import { theme } from '../common/theme';

const StyledProgressBar = styled(Box)`
  display: block;
`;

const StyledBar = styled(Box)`
  position: relative;
  display: block;
  border-radius: ${({ radius }) => radius}};
  height: ${theme.space[2]}px;
  background-color: ${({ barColor }) => theme.colors[barColor]}};
`;

const StyledProgress = styled(Box)`
  position: absolute;
  top: 0;
  border-radius: ${({ radius }) => radius}};
  height: 16px;
  width: ${props => props.progress}%;
  left: ${props => props.start}%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledLabel = styled(Text)`
  text-align: center;
  margin-top: 24px;
`;

const ProgressBar = props => {
  const { label, progress, start, color, barColor, radius = '12px' } = props;

  return (
    <StyledProgressBar {...props} mb={label ? [6] : [0]}>
      <StyledBar radius={radius} barColor={barColor}>
        <StyledProgress
          radius={radius}
          progress={progress}
          start={start}
          bg={color}
        >
          {label && (
            <StyledLabel size="small" lineHeight="1">
              {label}
            </StyledLabel>
          )}
        </StyledProgress>
      </StyledBar>
    </StyledProgressBar>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  /** Title of progress bar */
  label: PropTypes.string,
  /** How much progress (as percentage) */
  progress: PropTypes.number,
  /** Where should the bar start (as percentage) */
  start: PropTypes.number,
  /** Sets the progress bar color */
  color: PropTypes.oneOf([
    'baseBlack',
    'brandGreen',
    'brandPurple',
    'brandViolet',
    'brandRed',
    'brandYellow',
    'brandNavy',
  ]).isRequired,
  radius: PropTypes.string,
  barColor: PropTypes.oneOf(['baseWhite', 'baseGrayLight']),
};

ProgressBar.defaultProps = {
  label: null,
  start: 0,
  progress: 0,
  color: 'baseBlack',
  barColor: 'baseWhite',
};
