import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex } from 'rebass';
import {
  Search as SearchInput,
  Column,
  Row,
  Heading,
  theme,
} from '@sygnia/components';
import { BasicContent } from '../../modules/ContentWrapper';
import AccordionWrapper from '../../modules/CollapseWrapper';
import { groupBy } from 'lodash';

export const FAQArchive = ({ isSearchable }) => {
  const [query, setQuery] = useState('');

  const faqArchive = useStaticQuery(graphql`
    query faqArchive {
      allContentfulFaq(
        filter: { notSearchable: { ne: true } }
        sort: { fields: category___sortOrder, order: ASC }
      ) {
        nodes {
          title
          category {
            title
          }
          content {
            document: json
          }
          notSearchable
        }
      }
    }
  `);

  const { nodes: items } = faqArchive.allContentfulFaq;

  const filterItems = items => {
    return items.filter(item => {
      return item.title.toLowerCase().search(query.toLowerCase()) !== -1;
    });
  };

  let filtered = query ? filterItems(items) : items;

  const grouped = groupBy(filtered, item => {
    if (item.category && item.category.length) {
      return item.category[0].title;
    }

    return 'General';
  });

  return (
    <>
      <Box bg={theme.colors.baseGrayVeryLight} mb={[5]} px={2} py={2}>
        <Flex justifyContent="flex-end">
          <Box width="50%">
            {isSearchable ? (
              <SearchInput
                onChange={e => setQuery(e.target.value)}
                value={query}
              />
            ) : null}
          </Box>
        </Flex>
      </Box>

      <Row
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start']}
        justifyContent={['space-between']}
        flexWrap={'wrap'}
      >
        <Column width={[1]}>
          {Object.keys(grouped).map(group => {
            if (!group) return null;
            return (
              <Box mb={[6]}>
                <Heading mb={[2]} size="h3">
                  {group}
                </Heading>
                <AccordionWrapper
                  title={group}
                  borderColor={theme.colors.baseGrayLight}
                  tabs={grouped[group].map(faq => ({
                    title: faq.title,
                    content: (
                      <BasicContent
                        mb={[0]}
                        section={{ richContent: faq.content }}
                      />
                    ),
                  }))}
                />
              </Box>
            );
          })}
        </Column>
      </Row>
    </>
  );
};
