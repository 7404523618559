import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { Box, Flex } from 'rebass';
import Drawing from '../Drawing/Drawing';
import { drawings } from '../common/drawings';
import { theme } from '../common/theme';

const BenefitsItem = ({ title, image, uploadedImage, text, color }) => (
  <Flex
    className="BenefitsItem_Flex"
    mb={[6, 3, 0]}
    flexDirection={['row', 'row', 'column']}
    alignItems={'center'}
    justifyContent={image ? 'left' : 'center'}
  >
    {image && (
      <Box
        textAlign="center"
        mb={[0, 3]}
        px={[2, 2, 0]}
        width={[4 / 12, 4 / 12, 5 / 12]}
        mx="auto"
      >
        <Drawing name={image} color={color} />
      </Box>
    )}
    {!image && uploadedImage && (
      <Box textAlign="center" mb={[0, 3]} width={[7 / 12]}>
        <img src={uploadedImage.fluid.src} alt={title ? title : ''} />
      </Box>
    )}
    <Flex
      flexDirection={['column']}
      width={[8 / 12, 8 / 12, 1]}
      alignItems={[image ? 'left' : 'center', 'flex-start', 'center']}
    >
      {title && (
        <Box style={{ maxWidth: '100%' }}>
          <Heading
            size="h5"
            color={color}
            textAlign={[image ? 'left' : 'center', 'left', 'center']}
            textTransform="uppercase"
            data-testid="benefits-item-title"
          >
            {title}
          </Heading>
        </Box>
      )}
      {text && (
        <Box mt={[1, 3]} style={{ maxWidth: '100%' }}>
          <Text
            textAlign={[image ? 'left' : 'center', 'left', 'center']}
            size={['intro', 'body']}
            html={text}
            fontWeight={theme.fontWeight.lightBold}
          />
        </Box>
      )}
    </Flex>
  </Flex>
);

export default BenefitsItem;

BenefitsItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOf(Object.keys(drawings)).isRequired,
  uploadedImage: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.oneOf([
    'baseBlack',
    'brandGreen',
    'brandPurple',
    'brandViolet',
    'brandRed',
    'brandYellow',
    'brandNavy',
    'black',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]).isRequired,
};

BenefitsItem.defaultProps = {
  title: null,
  image: null,
  uploadedImage: null,
  text: null,
  color: 'baseBlack',
};
