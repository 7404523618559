import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '@sygnia/components';

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
  };
};

export const ModalWrapper = ({ isShowing, hide, children, ...props }) =>
  isShowing
    ? ReactDOM.createPortal(
        <Modal showModal={isShowing} closeModal={hide} {...props}>
          {children}
        </Modal>,
        document.body,
      )
    : null;

export default Modal;
