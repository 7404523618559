import React from 'react';
import PropTypes from 'prop-types';
import { Image, Box, Card as RebassCard, Flex } from 'rebass';
import styled, { css } from 'styled-components';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { Row, Column } from '../Grid';
import { theme } from '../common/theme';
import { rgba } from '../common/utils';

export const colorScheme = {
  black: {
    title: theme.colors.baseBlack,
    bg: rgba(theme.colors.baseGrayLight, 0.5),
  },
  green: {
    title: theme.colors.brandGreen,
    bg: rgba(theme.colors.brandGreenPale, 0.5),
  },
  purple: {
    title: theme.colors.brandPurple,
    bg: rgba(theme.colors.brandPurplePale, 0.5),
  },
  violet: {
    title: theme.colors.brandViolet,
    bg: rgba(theme.colors.brandVioletPale, 0.5),
  },
  red: {
    title: theme.colors.brandRed,
    bg: rgba(theme.colors.brandRedPale, 0.5),
  },
  yellow: {
    title: theme.colors.brandYellow,
    bg: rgba(theme.colors.brandYellowPale, 0.5),
  },
  navy: {
    title: theme.colors.brandNavy,
    bg: rgba(theme.colors.brandNavyPale, 0.5),
  },
  gray: {
    title: theme.colors.baseBlack,
    bg: rgba(theme.colors.baseBlackPale, 0.5),
  },
  whiteYellow: {
    title: theme.colors.brandRed,
    bg: theme.colors.baseWhite,
  },
  whiteRed: {
    title: theme.colors.brandRed,
    bg: theme.colors.baseWhite,
  },
  white: {
    title: theme.colors.baseBlack,
    bg: theme.colors.baseWhite,
  },
  transparent: {
    title: theme.colors.baseBlack,
    bg: theme.colors.baseWhite,
  },
  xMas: {
    title: theme.colors.baseBlack,
    bg: theme.colors.baseWhite,
  },
};

const layoutProps = {
  left: {
    wrapper: {
      py: [9, 16],
    },
    contentWrapper: {
      mr: 'auto',
      ml: ['auto', 'auto', theme.space[12]],
      width: [1, 1, 9 / 12, 5 / 12],
      flex: '0 1 auto',
    },
    text: {
      textAlign: ['center', 'left'],
    },
    cta: {
      justifyContent: ['center', 'flex-start'],
      alignItems: ['center', 'flex-start'],
    },
  },
  right: {
    wrapper: {
      py: [9, 16],
    },
    contentWrapper: {
      mr: ['auto', 'auto', theme.space[12]],
      ml: 'auto',
      width: [1, 1, 9 / 12, 5 / 12],
      flex: '0 1 auto',
    },
    text: {
      textAlign: ['center', 'center', 'left'],
    },
    cta: {
      justifyContent: ['center', 'center', 'flex-start'],
      alignItems: ['center', 'center', 'flex-start'],
    },
  },
  center: {
    wrapper: {
      py: [9, 16],
    },
    contentWrapper: {
      mr: 'auto',
      ml: 'auto',
      width: [1, 1, 10 / 12, 10 / 12],
      flex: '0 1 auto',
    },
    text: {
      textAlign: 'center',
    },
    cta: {
      justifyContent: ['center'],
      alignItems: ['center'],
    },
  },
  leftHalf: {
    container: {
      pl: 0,
      pr: 0,
    },
    row: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    contentWrapper: {
      mx: 'auto',
      px: 0,
      width: [1, 1, 1 / 2, 6 / 12],
      flex: '0 0 auto',
      order: [2, 2, 1],
    },
    imageWrapper: {
      mx: 'auto',
      px: 0,
      width: [1, 1, 1 / 2, 1 / 2],
      order: [1, 1, 2],
    },
    content: {
      px: [2, 2, 5, 15],
      py: [4, 8],
    },
    text: {
      textAlign: 'left',
    },
    cta: {
      justifyContent: ['flex-start'],
      alignItems: ['center'],
    },
  },
  rightHalf: {
    container: {
      pl: 0,
      pr: 0,
    },
    row: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    contentWrapper: {
      width: [1, 1, 1 / 2, 6 / 12],
      flex: '0 0 auto',
      mx: 'auto',
      px: 0,
      order: [2],
    },
    imageWrapper: {
      mx: 'auto',
      px: 0,
      width: [1, 1, 1 / 2, 1 / 2],
      order: [1],
    },
    content: {
      px: [2, 2, 5, 7],
      py: [4, 8],
    },
    text: {
      textAlign: 'left',
    },
    cta: {
      justifyContent: ['flex-start'],
      alignItems: ['center'],
    },
  },
};

const bgImageStyles = image => {
  return css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${image});
  `;
};

const StyledContainer = styled(Box)`
  margin-left: auto;
  padding-left: 16px;
  margin-right: auto;
  padding-right: 16px;
  max-width: ${props =>
    props.layout === 'rightHalf' || props.layout === 'leftHalf'
      ? '100%'
      : '1220px'};
`;

const StyledCardBanner = styled(RebassCard)`
  overflow-x: hidden;
  display: block;
  position: relative;
  :after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props =>
      props.layout === 'center' ? colorScheme[props.color].bg : 'none'};
  }
  ${({ layout, image }) =>
    layout === 'center'
      ? bgImageStyles(image)
      : `
        border-top: 0px solid ${theme.colors.baseGrayLight};
      `};
  @media (min-width: ${theme.breakpoints[2]}) {
    ${props => bgImageStyles(props.image)};
  }
`;

const StyledContent = styled(Box)`
  position: relative;
  z-index: 2;
  max-width: 100%;
  .styledButton {
    margin-right: 8px;
  }
`;

const StyledText = styled(Text)`
  p {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    width: inherit !important;
  }
`;

const StyledImage = styled(Image)`
  /* overflow-x: hidden;
  position: relative;
  padding-bottom: 85%;
  :after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */
`;

const isBgImage = layout => {
  return layout !== 'rightHalf' && layout !== 'leftHalf';
};

export const CardBanner = props => {
  const {
    title,
    text,
    renderCta,
    eyebrow,
    image,
    color,
    layout,
    style,
  } = props;

  const meta = props.style ? JSON.parse(props.style.internal.content) : {};
  return (
    <StyledCardBanner
      {...props}
      {...layoutProps[layout].wrapper}
      image={isBgImage(layout) && image}
      {...style}
    >
      <StyledContainer
        className="container"
        layout={layout}
        {...layoutProps[layout].container}
      >
        <Row {...layoutProps[layout].row}>
          {!isBgImage(layout) && image && (
            <Column flex={'0 1 auto'} {...layoutProps[layout].imageWrapper}>
              <StyledImage src={image} />
            </Column>
          )}
          <Column {...layoutProps[layout].contentWrapper}>
            <StyledContent {...layoutProps[layout].content}>
              {eyebrow && (
                <Heading
                  className="CardBanner_Eyebrow"
                  size="h5"
                  color={colorScheme[color].eyebrow}
                  textTransform="uppercase"
                  mb={[1, 3]}
                  {...layoutProps[layout].text}
                >
                  {eyebrow}
                </Heading>
              )}
              {title && (
                <Heading
                  className="CardBanner_Title"
                  size={meta?.titleFontSize ? meta.titleFontSize : 'h2'}
                  color={colorScheme[color].title}
                  mb={[2, 3]}
                  {...layoutProps[layout].text}
                >
                  {title}
                </Heading>
              )}
              {text && (
                <StyledText
                  className="CardBanner_Text"
                  size={meta?.contentFontSize ? meta.contentFontSize : 'intro'}
                  html={text}
                  color={colorScheme[color].text}
                  {...layoutProps[layout].text}
                />
              )}
              {renderCta && (
                <Flex
                  mt={[3, 5]}
                  alignItems={['flex-start', 'center']}
                  flexWrap={'wrap'}
                  flexDirection={['row', 'column', 'row']}
                  {...layoutProps[layout].cta}
                >
                  {renderCta}
                </Flex>
              )}
            </StyledContent>
          </Column>
        </Row>
      </StyledContainer>
    </StyledCardBanner>
  );
};

export default CardBanner;

CardBanner.propTypes = {
  /**  Title of the Card */
  title: PropTypes.string.isRequired,
  /**  Eyebrow of the Card */
  eyebrow: PropTypes.string,
  /** Text as content */
  text: PropTypes.string,
  /** Function for Calls to action */
  renderCta: PropTypes.func,
  /** Image URL to be used as background image */
  image: PropTypes.string,
  /** Color as prop. Will set the button color*/
  color: PropTypes.oneOf([
    'black',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]).isRequired,
  /** Set the alignment of component. Center alignment will add bg overlay to make text more legible */
  layout: PropTypes.oneOf(['left', 'right', 'center', 'leftHalf', 'rightHalf'])
    .isRequired,
  style: PropTypes.object,
};

CardBanner.defaultProps = {
  eyebrow: null,
  title: null,
  text: null,
  color: 'black',
  renderCta: null,
  image: null,
  layout: 'Left',
};
