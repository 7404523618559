import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { Box, Flex } from 'rebass';
import { theme } from '../common/theme';

const StyledCTACentered = styled(Box)`
  max-width: ${props => (props.size === 'Small' ? '683px' : '100%')};
  margin: 0 auto;
`;

const StyledContent = styled(Flex)`
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CTACentered = props => {
  const {
    title,
    description,
    renderCta,
    image,
    footnote,
    colors,
    size,
  } = props;

  return (
    <StyledCTACentered height={['100%']} bg={colors.bg} size={size}>
      <StyledContent py={[8, 12]}>
        {image && <Image src={image} width={[6 / 12, 2 / 12]} />}
        {title && (
          <Box my={[1, 2]} width={[11 / 12, 8 / 12]}>
            <Heading fontSize={['h3', 'h2', 'h1']} color={colors.title}>
              {title}
            </Heading>
          </Box>
        )}
        {description && (
          <Box
            my={[1]}
            width={size === 'Small' ? [1] : [11 / 12, 4 / 12]}
            px={size === 'Small' ? [3, 8] : []}
          >
            <Text
              size={['intro']}
              lineHeight={'32px'}
              textAlign={'center'}
              color={colors.description}
              html={description}
              fontWeight={theme.fontWeight.bold}
            />
          </Box>
        )}
        {renderCta && (
          <Flex
            my={[2, 3]}
            justifyContent={'flex-start'}
            alignItems={['center']}
            flexWrap={'wrap'}
            flexDirection={['column', 'row']}
          >
            {renderCta('baseBlack')}
          </Flex>
        )}

        {footnote && (
          <Box mt={[1, 2]} width={[11 / 12, 6 / 12]}>
            <Text
              size={['intro']}
              fontWeight={theme.fontWeight.lightBold}
              lineHeight={'1.55'}
              textAlign={'center'}
              color={colors.description}
              html={footnote}
            />
          </Box>
        )}
      </StyledContent>
    </StyledCTACentered>
  );
};

export default CTACentered;

CTACentered.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  footnote: PropTypes.string,
  meta: PropTypes.object,
  colors: PropTypes.object,
  renderCta: PropTypes.func,
  size: PropTypes.string,
};

CTACentered.defaultProps = {
  /** Optional title for CTACentered */
  title: null,
  /** Description or Source */
  description: null,
  /** Text CTACentered */
  image: null,
  footnote: null,
  meta: {},
  size: null,
};
