import React, { useState, useRef, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { calculateTax } from './twoPotTaxModel.js';
import {
  ButtonAction,
  Button,
  Section,
  Table,
  Text,
  Heading,
  Container,
} from '@sygnia/components';
import { Flex, Box } from 'rebass';
import { formIntro, getFieldConditional, formTwoPot } from './twoPortForms';
import { FormBuilderContainer } from './FormBuilder';
import { CONTENT_TITLES, ROBOSTEPS } from '../../common/constants';
import TwoPotTemplate from './TwoPotTemplate';
import { Loader } from '@sygnia/components';
import {
  logGtmEvent,
  GTM_CATEGORY,
  GTM_EVENT,
  GTM_LOCATION,
} from '@sygnia/components';

const getTaxPayload = data => {
  return {
    age: parseInt(data.age),
    income: parseInt(data.income),
    withdrawalAmount: parseInt(data.withdrawalAmount),
  };
};

const validate = (values, form) => {
  let errors = {};
  const { fields } = form;
  fields.forEach(field => {
    const fieldShouldShow = getFieldConditional(field, values);
    if (field.required && !values[field.name] && fieldShouldShow) {
      errors[field.name] = 'Required Field';
    } else if (
      field.name === 'income' &&
      !/^[1-9]\d*$/i.test(values[field.name]) &&
      fieldShouldShow
    ) {
      errors[field.name] = 'Salary should be a number greater than zero';
    } else if (
      field.name === 'age' &&
      !/^[1-9]\d*$/i.test(values[field.name]) &&
      fieldShouldShow
    ) {
      errors[field.name] = 'Age should be a number greater than zero';
    } else if (
      field.name === 'withdrawalAmount' &&
      !/^[1-9]\d*$/i.test(values[field.name]) &&
      fieldShouldShow
    ) {
      errors[field.name] =
        'Withdrawal amount should be a number greater than zero';
    } else if (field.type === 'group' && fieldShouldShow) {
      const groupErr = validate(values, field);
      if (groupErr.errors) {
        errors = {
          ...errors,
          ...groupErr.errors,
        };
      }
    } else if (
      field.validate &&
      typeof field.validate === 'function' &&
      fieldShouldShow
    ) {
      const { check, message } = field.validate(values[field.name]);

      if (!check) {
        errors[field.name] = message;
      }
    }
  });

  return {
    errors,
    isValid: !Object.keys(errors).length,
  };
};

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const TwoPotFormWizard = props => {
  const meta = props.section.meta
    ? JSON.parse(props.section.meta.internal.content)
    : {};
  const [view, setView] = useState(ROBOSTEPS.INTRO);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setSection] = useState(0);
  const [formData, setForm] = useState({});
  const [form, setSelectedForm] = useState(formIntro);
  const [formErrors, setErrors] = useState(null);
  const currentSection = form.fields[currentIndex];

  const onStartOver = () => {
    setSection(0);
    setFormView('intro');
  };

  useEffect(() => {
    setForm({
      ...formData,
      ...(form.initConfig || {}),
    });
  }, [form]);

  const ChangeAnswers = (
    <Button
      variant="secondary"
      mr={[0, 3]}
      mb={[2, 0]}
      onClick={() => {
        setFormView('form');
        setSection(0);
      }}
    >
      {CONTENT_TITLES.changeAnswers}
    </Button>
  );

  const onFieldChange = (name, value) => {
    setForm({
      ...formData,
      [name]: value,
    });
  };

  const onStart = () => {
    const { errors, isValid } = validate(formData, currentSection);

    if (!isValid) {
      setErrors(errors);
    } else {
      setErrors(null);
      setSelectedForm(formTwoPot);
      setFormView('form');
    }
  };

  const setFormView = view => {
    executeScroll();
    setView(view);
  };

  const onNextSection = () => {
    const { errors, isValid } = validate(formData, currentSection);
    if (!isValid) {
      setErrors(errors);
    } else {
      setErrors(null);
      setSection(currentIndex + 1);
    }
  };

  const onPreviousSection = () => {
    if (currentIndex >= 1) {
      setSection(currentIndex - 1);
    }
  };

  const onSubmit = async () => {
    const { errors, isValid } = validate(formData, currentSection);
    if (!isValid) {
      setErrors(errors);
    } else {
      setLoading(true);
      executeScroll();

      setFormView(ROBOSTEPS.RESULTS);
      const taxPayload = getTaxPayload(formData);
      const taxAmount = calculateTax(
        taxPayload.income,
        taxPayload.age,
        taxPayload.withdrawalAmount,
      );
      // Amount you receive = withdrawalAmount - taxAmount
      const revenueAmount = taxPayload.withdrawalAmount - taxAmount;
      const results = { taxAmount, revenueAmount };
      setResult(results);
      setLoading(false);
    }
  };

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const resultsStep = () => {
    if (view === ROBOSTEPS.RESULTS) {
      return (
        <Section bg="gray" pb={meta.pb}>
          <Container width={[1, 1, 9 / 12]} px={[0]}>
            <Box bg="baseWhite" px={[2, 2, 5]} pt={[4, 6, 8]} pb={[2, 6, 10]}>
              <Heading size={['h2']} color="lightBlue" pb={[2]}>
                Thank you!
              </Heading>
              <Box bg="baseWhite" mb={[5]} mt={[2, 5]}>
                <Table.Table>
                  <tbody>
                    <Table.Tr>
                      <Table.Th
                        css={{
                          padding: '24px 8px',
                          borderTop: '1px solid #000',
                        }}
                      >
                        {CONTENT_TITLES.twoPotEstimatedTax}
                      </Table.Th>
                      <Table.Td
                        align={'right'}
                        css={{
                          padding: '24px 8px',
                          borderTop: '1px solid #000',
                          borderBottom: '1px solid #000',
                        }}
                      >
                        <CurrencyFormat
                          thousandSeparator={' '}
                          value={result.taxAmount}
                          displayType={'text'}
                          prefix={'R '}
                        />
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Th
                        css={{
                          padding: '24px 8px',
                          borderTop: '1px solid #000',
                        }}
                      >
                        {CONTENT_TITLES.twoPotRevenue}
                      </Table.Th>
                      <Table.Td
                        align={'right'}
                        css={{
                          padding: '24px 8px',
                          borderTop: '1px solid #000',
                          borderBottom: '1px solid #000',
                        }}
                      >
                        <CurrencyFormat
                          thousandSeparator={' '}
                          value={result.revenueAmount}
                          displayType={'text'}
                          prefix={'R '}
                        />
                      </Table.Td>
                    </Table.Tr>
                  </tbody>
                </Table.Table>
              </Box>

              <Flex my={[5]} flexDirection={['column', 'row']}>
                {ChangeAnswers}
              </Flex>
            </Box>
          </Container>
        </Section>
      );
    }

    return null;
  };

  const introStep = () => {
    if (view === ROBOSTEPS.INTRO) {
      return (
        <Section bg="gray" pb={meta.pb}>
          <Container width={[1, 1, 9 / 12]} px={[0]}>
            <Box bg="baseWhite" px={[2, 2, 5]} pt={[2, 6, 8]} pb={[2, 6, 10]}>
              <Heading
                size="h2"
                color={meta.customColors && meta.customColors.heading}
                pb={[2]}
              >
                {formIntro.label}
              </Heading>
              <Text>{formIntro.description}</Text>
              <Box mt={[3]}></Box>

              <FormBuilderContainer
                formData={formData}
                form={formIntro}
                errors={formErrors}
                wrapper={Box}
                wrapperOptions={{ pb: [2] }}
                handleFieldChange={onFieldChange}
              />
              <Box mt={[3]}>
                <Button
                  mt={[0, 0, 2]}
                  meta={meta}
                  onClick={() => {
                    onStart();
                    logGtmEvent({
                      eventName: GTM_EVENT.TWO_POT_CALCULATOR_STARTED,
                      data: {
                        category: GTM_CATEGORY.FORM,
                        label: 'Start',
                        location: GTM_LOCATION.CONTENT,
                      },
                    });
                  }}
                >
                  Start
                </Button>
              </Box>
            </Box>
          </Container>
        </Section>
      );
    }

    return null;
  };

  const loadingComponent = () => {
    return (
      <Section bg="gray">
        <Container width={[1, 1, 9 / 12]} px={[0]} css={{ minHeight: '400px' }}>
          <Box bg="baseWhite" px={[2, 2, 5]} pt={[4, 6, 8]} pb={[2, 6, 10]}>
            <Loader description={'Loading...'} />
          </Box>
        </Container>
      </Section>
    );
  };

  const formStep = () => {
    if (view === ROBOSTEPS.FORM) {
      return (
        <Section bg="gray" pb={meta.pb}>
          <Container width={[1, 1, 9 / 12]}>
            <TwoPotTemplate
              isAlternative
              color={meta.customColors}
              variant="alternative"
              stepTitle={currentSection.label}
              description={currentSection.description}
              wizardTitle={form.title}
              totalSteps={form.fields.length}
              stepNumber={currentIndex}
              onStartOver={onStartOver}
            >
              <FormBuilderContainer
                formData={formData}
                form={currentSection}
                formType={form.formType}
                errors={formErrors}
                wrapper={Box}
                wrapperOptions={{ pb: [2] }}
                handleFieldChange={onFieldChange}
                buttonColor={meta.customColors.ButtonPrimaryBg}
              />

              <Flex
                justifyContent="flex-start"
                width="100%"
                flexDirection={['column', 'row']}
              >
                {currentIndex !== form.fields.length - 1 ? (
                  <Button onClick={onNextSection} meta={meta}>
                    Next
                  </Button>
                ) : null}
                {currentIndex === form.fields.length - 1 ? (
                  <Button onClick={onSubmit} meta={meta}>
                    Submit
                  </Button>
                ) : null}
                {currentIndex !== 0 ? (
                  <ButtonAction
                    ml={[0, 1]}
                    mt={[2, 0]}
                    variant="secondary"
                    onClick={onPreviousSection}
                  >
                    Previous
                  </ButtonAction>
                ) : null}
              </Flex>
            </TwoPotTemplate>
          </Container>
        </Section>
      );
    }

    return null;
  };
  return (
    <div ref={myRef}>
      {loading ? (
        loadingComponent()
      ) : (
        <>
          {introStep()}
          {formStep()}
          {resultsStep()}
        </>
      )}
    </div>
  );
};

export default TwoPotFormWizard;
