import React from 'react';
import { ButtonAction } from '@sygnia/components';
import { Box, Flex } from 'rebass';

const updateObjectInArray = (array, action) => {
  return array.map((item, index) => {
    if (index !== action.index) {
      return item;
    }
    return {
      ...item,
      [action.name]: action.value,
    };
  });
};

const MultiInput = props => {
  const {
    name,
    fields,
    getField,
    value = [{}],
    handleFieldChange,
    limit,
    addLabel = 'Add',
    ...rest
  } = props;

  const updateArray = (fieldName, fieldValue, index) => {
    const updateArray = updateObjectInArray(value, {
      value: fieldValue,
      name: fieldName,
      index,
    });

    handleFieldChange(name, updateArray);
  };

  const addItem = () => {
    if (limit && value.length >= limit) return;
    handleFieldChange(name, [...value, {}]);
  };

  const removeItem = index => {
    handleFieldChange(
      name,
      value.filter((a, idx) => {
        return idx !== index;
      }),
    );
  };

  return (
    <Box py={[2]}>
      {value.map((i, index) => {
        return (
          <Flex
            css={{ borderBottom: '1px solid #eee' }}
            mb={[5]}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box pb={[6]}>
              {fields.map(f => {
                return (
                  <>
                    {getField({
                      ...f,
                      own: true,
                      name: `${name}[${index}].${f.name}`,
                      onChange: e => {
                        const inputValue = ['checkbox'].includes(e.target.type)
                          ? e.target.checked
                          : e.target.value;

                        updateArray(f.name, inputValue, index);
                      },
                      handleFieldChange: (name, value) => {
                        updateArray(f.name, value, index);
                      },
                      value: i[f.name],
                      ...(['radio', 'checkbox'].includes(f.type)
                        ? { checked: i[f.name] }
                        : {}),
                    })}
                  </>
                );
              })}
            </Box>
            <div>
              <ButtonAction
                onClick={() => removeItem(index)}
                isIconAfter={false}
                icon={'trash'}
              >
                Remove
              </ButtonAction>
            </div>
          </Flex>
        );
      })}
      {limit && value.length < limit ? (
        <ButtonAction onClick={addItem} isIconAfter={false} icon={'plus'}>
          {addLabel}
        </ButtonAction>
      ) : null}
    </Box>
  );
};

export default MultiInput;
