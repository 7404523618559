import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex, Card as RebassCard } from 'rebass';
import { layout } from 'styled-system';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { theme } from '../common/theme';
import { animated } from 'react-spring';
import { mediaQuery } from '../common/utils';
import { Column } from '../Grid';

const StyledHeroLanding = styled(Box)`
  ${layout};
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const StyledHeroLandingWrapper = styled(Flex)`
  ${layout};
  min-height: 400px;
  align-items: stretch;
  max-width: 1220px;
  margin: 0 auto;
`;

const StyledImageWrapper = styled(animated(Flex))`
  flex-grow: 1;
`;

const StyledImage = styled(RebassCard)`
  ${layout};
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  flex-grow: 1;
  min-height: 250px;
  background-position: center center;
  background-image: url(${props => props.images.small});
  ${mediaQuery.small} {
    min-height: 380px;
    background-image: url(${props => props.images.small});
  }
  ${mediaQuery.medium} {
    background-image: url(${props => props.images.medium});
  }
  ${mediaQuery.large} {
    background-image: url(${props => props.images.large});
  }
  ${mediaQuery.xlarge} {
    background-image: url(${props => props.images.xlarge});
  }
`;

const StyledShareWrapper = styled(Column)`
  padding-top: ${theme.space[8]}px;
  padding-left: 0px;
  padding-right: 0px;
`;

const StyledHeading = styled(Heading)`
  ${mediaQuery.xs} {
    font-size: 32px;
  }
  ${mediaQuery.small} {
    font-size: 32px;
  }
  ${mediaQuery.medium} {
    font-size: 32px;
  }
  ${mediaQuery.large} {
    font-size: 58px;
  }
  ${mediaQuery.xlarge} {
    font-size: 58px;
  }
`;

const AnimatedFlex = animated(Flex);

const HeroLanding = props => {
  const {
    eyeBrow,
    headline,
    description,
    color,
    secondaryColor,
    images,
    share,
    renderCta,
  } = props;

  return (
    <StyledHeroLanding bg={color} images={images}>
      <StyledHeroLandingWrapper
        alignItems="center"
        flexDirection={['column', 'column', 'column', 'row', 'row']}
      >
        {headline && (
          <AnimatedFlex
            width={[1, 1, 1, 1]}
            mx={!images.medium && 'auto'}
            order={['0', '3']}
          >
            <Flex
              width={[1, 1, 1, 1, 1]}
              mx={'auto'}
              pt={[4, 5, 8]}
              pb={[4, 0, 5, 8]}
              px={[2, 2, 2, 2]}
              alignItems={['center', 'center', 'center', 'flex-start']}
              justifyContent={'center'}
              flexDirection={['column']}
            >
              {eyeBrow && (
                <Heading
                  size="h5"
                  color={theme.colors.baseBlack}
                  mb={[1, 3]}
                  textAlign="left"
                >
                  {eyeBrow}
                </Heading>
              )}
              {headline && (
                <Box style={{ maxWidth: '100%' }}>
                  <StyledHeading
                    color={theme.colors.baseBlack}
                    textAlign={
                      !images.medium
                        ? 'left'
                        : ['center', 'center', 'center', 'left']
                    }
                    mb={[1, 3, 3, 3]}
                    width={[1, 1, 1, 8 / 12]}
                    px={[4, 0, 0, 0]}
                  >
                    {headline}
                  </StyledHeading>
                </Box>
              )}
              {description && (
                <Box
                  style={{ maxWidth: '100%' }}
                  bg={secondaryColor}
                  px={`${theme.space[1]}px`}
                >
                  <Text
                    size={['intro']}
                    as="div"
                    textAlign={['center', 'left', 'left', 'left']}
                    fontFamily={theme.fonts.dmSans}
                    color={theme.colors.baseBlack}
                    html={description}
                  />
                </Box>
              )}
              {renderCta() && (
                <Flex
                  mt={[2, 5]}
                  flexDirection={['column', 'column', 'column', 'row']}
                  justifyContent={'flex-start'}
                  flexWrap={'wrap'}
                  alignItems={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center',
                  ]}
                >
                  {renderCta(color)}
                </Flex>
              )}
              {share && <StyledShareWrapper>{share}</StyledShareWrapper>}
            </Flex>
          </AnimatedFlex>
        )}

        {images && (
          <StyledImageWrapper
            style={{ background: color }}
            width={!headline && !description ? [1] : [9 / 12, 1, 1, 1 / 2]}
            order={['1', '4']}
            mx={'auto'}
            px={[2]}
            pb={[4, 5, 8]}
          >
            <StyledImage images={images} />
          </StyledImageWrapper>
        )}
      </StyledHeroLandingWrapper>
    </StyledHeroLanding>
  );
};

export default HeroLanding;

HeroLanding.propTypes = {
  /**  Eyebrow of the Hero */
  eyeBrow: PropTypes.string,
  /** Title of the Hero */
  headline: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object,
  ]),
  /** Introduction description */
  description: PropTypes.string,
  /** URL of the image to use as background */
  images: PropTypes.object,
};

HeroLanding.defaultProps = {
  eyeBrow: null,
  headline: null,
  description: null,
  color: 'black',
  secondaryColor: '#FFCC57',
  images: null,
  color: {},
  share: null,
  bgSize: 'contain',
};
