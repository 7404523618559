import { Box } from 'rebass';
import styled from 'styled-components';

import { theme } from '../common/theme';

const TabBorder = styled(Box)`
  content: '';
  width: ${({ isActive }) => (isActive ? '100%' : '0')};
  height: ${({ height }) => `${height}px`};
  background: ${({ color }) => color};
  position: absolute;
  left: 0;
  bottom: -${theme.space[3]}px;
  ${({ transition }) => (transition ? transition : '')};
`;

export default TabBorder;
