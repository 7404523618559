import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { theme } from '../../common/theme';
import FieldTemplate from '../FieldTemplate';
import { baseStyles } from '../Styles';

export const Input = props => {
  const { formType, prefixText, suffixText } = props;
  let [focused, setFocus] = useState(false);

  return (
    <FieldTemplate {...props}>
      {prefixText && (
        <StyledPrefix formType={formType} focused={focused}>
          {prefixText}
        </StyledPrefix>
      )}
      <StyledInput
        {...props}
        placeholder={props.placeholder}
        focused={focused}
        onFocus={() => setFocus(!focused)}
        onBlur={() => setFocus(!focused)}
      />
      {suffixText && (
        <StyledSuffix formType={formType} focused={focused}>
          {suffixText}
        </StyledSuffix>
      )}
    </FieldTemplate>
  );
};

const StyledFix = styled.span`
  border: 1px solid ${theme.colors.baseGrayLight};
  color: ${theme.colors.baseBlack};
  font-size: ${theme.fontSizes.body};
  font-family: ${theme.fonts.dmSans};
`;

const StyledPrefix = styled(StyledFix)`
  ${({ formType, focused }) =>
    formType === 'modern'
      ? css`
          padding: ${theme.space[1]}px ${theme.space[0]}px;
          border: none;
          border-bottom: 1px solid
            ${focused ? theme.colors.baseGrayDark : theme.colors.baseGrayLight};
        `
      : css`
          padding: 6px;
          border: 1px solid ${theme.colors.baseGrayLight};
          border-right: 1px solid transparent;
        `}
`;

const StyledSuffix = styled(StyledFix)`
  ${({ formType, focused }) =>
    formType === 'modern'
      ? css`
          padding: ${theme.space[1]}px ${theme.space[0]}px;
          border: none;
          border-bottom: 1px solid
            ${focused ? theme.colors.baseGrayDark : theme.colors.baseGrayLight};
        `
      : css`
          padding: ${theme.space[1]}px;
          border: 1px solid ${theme.colors.baseGrayLight};
          border-left: 1px solid transparent;
        `}
`;

const StyledInput = styled.input`
  ${baseStyles};
  ${({ prefixText, formType }) =>
    formType === 'modern'
      ? prefixText &&
        css`
          padding: 10px ${theme.space[1]}px;
          border-left: 1px solid transparent;
        `
      : prefixText
      ? css`
          padding: ${theme.space[1]}px ${theme.space[0]}px;
          border-left: 1px solid transparent;
        `
      : css`
          padding: ${theme.space[1]}px;
        `}
  ${({ suffixText }) => suffixText && 'border-right: 1px solid transparent'};
  background-color: 'transparent';
`;

Input.propTypes = {
  /** formType will determine the design of the input */
  formType: PropTypes.oneOf(['default', 'modern']),
  /** Visible text in input before user enters anything.
   * (This also becomes the label when the field is in focus) */
  placeholder: PropTypes.string.isRequired,
  /** Label displayed above input. Not Required */
  label: PropTypes.string,
  /** Type of field */
  type: PropTypes.string,
  /** Name of field */
  name: PropTypes.string,
  /** Prefix to be displayed inside field e.g 'http://'. (Icons NOT yet allowed)*/
  prefixText: PropTypes.string,
  /** Suffix to be displayed inside field e.g '.com'. (Icons NOT yet allowed)*/
  suffixText: PropTypes.string,
  /** Default value of field */
  defaultValue: PropTypes.string,
  /** Is this a required field? */
  required: PropTypes.bool,
  /** Should the field be disabled? */
  disabled: PropTypes.bool,
  /** Validation message displayed */
  error: PropTypes.string,
  /**Any text helping the user on what to enter in the field */
  fieldDescription: PropTypes.string,
};

Input.defaultProps = {
  formType: 'default',
  placeholder: null,
  name: null,
  label: null,
  type: 'text',
  prefixText: null,
  suffixText: null,
  defaultValue: null,
  required: false,
  disabled: false,
  error: null,
  fieldDescription: null,
};

export default Input;
