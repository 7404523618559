import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Column, Section, theme } from '@sygnia/components';
import Layout from './layout';
import { CONTENT_TYPES } from '../common/constants';
import { CardWrapper } from './CardWrapper';
import { MenuWrapper } from './MenuWrapper';
import { graphql } from 'gatsby';

const StyledColumn = styled(Column)`
  ${props =>
    props.layoutType === CONTENT_TYPES.ACCORDION &&
    css`
      @media only screen and (min-width: 160px) and (max-width: ${theme
          .breakpoints[1]}) {
        width: 100%;
      }
      @media only screen and (min-width: ${theme
          .breakpoints[1]}) and (max-width: ${theme.breakpoints[2]}) {
        width: 76%;
      }
      @media only screen and (min-width: ${theme
          .breakpoints[2]}) and (max-width: ${theme.breakpoints[3]}) {
        width: 65%;
      }
      @media only screen and (min-width: ${theme.breakpoints[3]}) {
        width: 45%;
      }
    `}
`;

const CARD_MAPPING = {
  [CONTENT_TYPES.COMPONENT]: Layout,
  [CONTENT_TYPES.GENERAL_CARD]: CardWrapper,
  [CONTENT_TYPES.CONTENT_MENU]: MenuWrapper,
};

export const ColumnWrapper = ({ section, colorScheme, ...rest }) => {
  const color =
    section.colorScheme && section.colorScheme !== CONTENT_TYPES.INHERIT
      ? section.colorScheme.toLowerCase() // TODO: add color function
      : colorScheme;

  const bg =
    section.backgroundColor && section.backgroundColor !== CONTENT_TYPES.INHERIT
      ? section.backgroundColor.toLowerCase() // TODO: add color function
      : color;

  const WrapComponent = section.displayTitle ? Section : React.Fragment;
  const WrapComponentOptions = section.displayTitle
    ? {
        sectionTitle: section.title,
        color,
        bg,
        align: section.alignment ? section.alignment.toLowerCase() : 'center',
      }
    : {};

  const sectionLayout = {
    row: {},
    column: [],
  };

  if (section.properties) {
    const {
      properties: { row, column },
    } = section;
    sectionLayout.row = row || {};
    sectionLayout.column = column || [];
  }

  return (
    <WrapComponent {...WrapComponentOptions} bg={bg}>
      <Row
        className="ColumnWrapper_Row"
        flexDirection={['column', 'column', 'row']}
        alignItems={['center', 'center', 'flex-start']}
        justifyContent={['center']}
        {...sectionLayout.row}
      >
        {section.modules &&
          section.modules.map((mod, i) => {
            if (mod === null) return null;
            let key = mod.__typename;

            const Component = CARD_MAPPING[key] || Layout;
            if (!Component) return null;
            return (
              <StyledColumn
                key={i}
                width={
                  section.properties && section.properties.columns
                    ? section.properties.columns
                    : mod.layout === CONTENT_TYPES.ACCORDION
                    ? null
                    : [1, 1, 0.5]
                }
                flex="0 1 auto"
                {...(sectionLayout.column.length && sectionLayout.column[i])}
                layoutType={mod.layout}
              >
                <Component
                  color={color}
                  bg={bg}
                  key={i}
                  section={mod}
                  layout={[mod]}
                />
              </StyledColumn>
            );
          })}
      </Row>
    </WrapComponent>
  );
};

ColumnWrapper.defaultProps = {
  section: {},
};

export const fragment = graphql`
  fragment LayoutColumnFields on ContentfulLayoutColumn {
    title: name
    displayTitle
    colorScheme
    backgroundColor
    modules {
      __typename
      ... on Node {
        ...ContentGenericFields
        ...ComponentLayoutFields
        ...LayoutMenuFields
        ... on ContentfulLayoutContentToggle {
          title
          layout
          modules {
            __typename
            ... on ContentfulFaq {
              title
              content {
                document: json
              }
            }
          }
        }
      }
    }
    properties {
      columns
      order
      row {
        my
      }
      column {
        pt
      }
    }
  }
`;
