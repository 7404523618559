import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import { layout } from 'styled-system';
import { theme } from '../common/theme';
import Text from '../Text/Text';
import Option from '../Option/Option';

const StyledSelectWrapper = styled(Box)`
  position: relative;
  display: block;
`;

const StyledSelect = styled(Box)`
  background-color: ${theme.colors.baseWhite};
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid
    ${props =>
      props.isOpen ? theme.colors.baseGraydark : theme.colors.baseWhite};
  border-radius: 2px;
  z-index: 9;
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    border-color: ${theme.colors.baseGrayDark};
  }
`;

const StyledIcon = styled.i`
  color: ${theme.colors.baseBlack};
  margin-left: ${theme.space[2]}px;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform ${theme.speed.default} ease;
`;

const StyledOptions = styled(Box)`
  ${layout}
  border-radius: 2px;
  border: 1px solid ${theme.colors.baseGrayLight};
  background-color: ${theme.colors.baseWhite};
  padding: ${theme.space[3]}px;
  top: ${theme.space[6]}px;
  right: 0px;
  position: absolute;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 10;
`;

function useComponentVisible(isMultiSelect, initialIsVisible) {
  const [isOpen, setOpen] = useState(initialIsVisible);
  const overlayRef = useRef(null);

  const handleClickInside = () => {
    if (!isMultiSelect && isOpen) {
      setOpen(false);
    }
  };

  const handleClickOutside = event => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickInside);
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickInside);
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return { overlayRef, isOpen, setOpen };
}

export const Dropdown = props => {
  const { label, options, isMultiSelect, renderOptions, ...rest } = props;
  // console.log('rest:', rest);
  const { overlayRef, isOpen, setOpen } = useComponentVisible(
    isMultiSelect,
    false,
  );

  return (
    <Box
      width={[1]}
      className="Dropdown_OverlayRef"
      ref={overlayRef}
      mx={[0, 1]}
    >
      <StyledSelectWrapper {...rest}>
        <StyledSelect
          className="Dropdown_StyledSelect"
          // width={[1, 1, 1, 'auto']}
          width={[1]}
          mb={[1, 0]}
          onClick={() => setOpen(!isOpen)}
          isOpen={isOpen}
        >
          <Text size="small" fontWeight="bold">
            {label}
          </Text>
          <StyledIcon isOpen={isOpen} className={'icon icon-angle-down'} />
        </StyledSelect>

        <StyledOptions
          isOpen={isOpen}
          width={[1, 'auto']}
          left={['0px', 'unset']}
          minWidth={['100%', '256px']}
        >
          {renderOptions ? renderOptions(Option, isMultiSelect) : null}
          {options.map((o, index) => (
            <Option
              key={index}
              optionId={index}
              label={o.label}
              optionsLabel={label}
              isMultiSelect={isMultiSelect}
            />
          ))}

          {/* {isMultiSelect && (
            <Flex justifyContent="space-between" mt={[1]}> */}
          {/* TODO: Clear should de-select all options */}
          {/* TODO: Clear and Apply should only be option is multi-select is possible */}
          {/* <ButtonAction fontWeight="normal" px="0" py="0">
                Clear
              </ButtonAction>
              <ButtonAction px="0" py="0" onClick={() => setOpen(!isOpen)}>
                Apply
              </ButtonAction>
            </Flex>
          )} */}
        </StyledOptions>
      </StyledSelectWrapper>
    </Box>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  renderOptions: PropTypes.func,
};

Dropdown.defaultProps = {
  label: null,
  options: [],
  onClick: () => {},
  isOpen: false,
  isMultiSelect: false,
  renderOptions: () => {},
};

export default Dropdown;
