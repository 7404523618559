/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import { theme } from '../common/theme';

import { CircleItem } from './common';

const FlexContainer = styled(Flex)`
  height: 110px;
  position: relative;
  overflow: hidden;
`;

const SelectionContainer = styled(Flex)`
  position: absolute;
  transition: left 0.3s ease;
  width: 30px;
  left: ${({ id, activeId }) => {
    if (id === activeId) return 'calc(50% - 15px);';
    else {
      if (id === activeId - 1) return 'calc(10% - 15px)';
      if (id === activeId + 1) return 'calc(90% - 15px);';
      if (id < activeId) return `${(activeId - id - 1) * -25}%;`;
      if (id > activeId)
        return `calc((90% - 15px) + ${(id - activeId - 1) * 35}%);`;
    }
  }};
`;

const Line = styled(Box)`
  content: '';
  background-color: white;
  height: 4px;
  width: ${({ active, numItems }) =>
    active === 0 || active === numItems - 1 ? '50%' : '100%'};
  z-index: 0;
  position: absolute;
  left: ${({ active, numItems }) => {
    if (active === 0) return '50%';
    if (active === 1) return '10%';
    if (active === numItems - 2) return '-10%';
    else return '0';
  }};
  transition: all 0.3s ease;
  transform: translateY(12px);
`;

const SelectionItem = ({ id, activeId, setActive, color, content }) => (
  <SelectionContainer
    width={1}
    alignItems="center"
    id={id}
    activeId={activeId}
    justifyContent="center"
  >
    <CircleItem
      id={id}
      activeId={activeId}
      content={content}
      color={color}
      setActive={setActive}
    />
  </SelectionContainer>
);

const StageSelectorMobile = ({ activeTab, setActiveTab, tabs, color }) => (
  <FlexContainer justifyContent="center" p={3}>
    {tabs.map((option, id) => (
      <SelectionItem
        key={id}
        id={id}
        activeId={activeTab}
        setActive={setActiveTab}
        color={color}
        content={option}
      />
    ))}
    <Line active={activeTab} numItems={tabs.length} />
  </FlexContainer>
);

StageSelectorMobile.propTypes = {
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  tabs: PropTypes.array,
  color: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
};

StageSelectorMobile.defaultProps = {
  activeTab: -1,
  setActiveTab: null,
  tabs: [],
  color: 'baseWhite',
};

export default StageSelectorMobile;
