import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { theme } from '../common/theme';
import { Box, Flex } from 'rebass';
import { Column } from '../Grid';

const StyledHeroWrapper = styled(Box)``;

const StyledHeroContent = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margintop: ${theme.space[8]}px;

  @media only screen and (min-width: 160px) and (max-width: ${theme
      .breakpoints[1]}) {
    width: 100%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[1]}) and (max-width: ${theme.breakpoints[2]}) {
    width: 75%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[2]}) and (max-width: ${theme.breakpoints[3]}) {
    width: 65%;
  }
  @media only screen and (min-width: ${theme.breakpoints[3]}) {
    width: 45%;
  }
`;

const StyledImageWrapper = styled(Flex)`
  position: relative;
  &:before {
    z-index: 10;
    background-color: white;
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const StyledImageContainer = styled(Box)`
  z-index: 20;
  @media only screen and (min-width: 160px) and (max-width: ${theme
      .breakpoints[1]}) {
    width: 100%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[1]}) and (max-width: ${theme.breakpoints[2]}) {
    width: 85%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[2]}) and (max-width: ${theme.breakpoints[3]}) {
    width: 75%;
  }
  @media only screen and (min-width: ${theme.breakpoints[3]}) {
    width: 65%;
  }
`;

const StyledShareWrapper = styled(Column)`
  border-top: 1px solid ${theme.colors.baseGrayDark};
  padding-top: ${theme.space[3]}px;
  padding-left: 0px;
  padding-right: 0px;
`;

const HeroEditorial = props => {
  const { title, description, colors, image, children, share } = props;

  return (
    <StyledHeroWrapper bg={colors.bg}>
      <Column
        width={[1, 1, 74 / 100, 76 / 100, 52 / 100]}
        mx={'auto'}
        px={[2, 2, 2, 9]}
        pt={[2, 3]}
      >
        {children}
      </Column>
      <StyledHeroContent
        boxSizing="border-box"
        borderWidth={0}
        borderStyle={'solid'}
        borderColor={colors.bg}
        mx={'auto'}
        px={[2, 2]}
        pt={[2, 3]}
        mb={[3, 3]}
      >
        {title && (
          <Heading
            as="h1"
            size="h2"
            color={colors.title}
            mb={description ? [3] : [8]}
          >
            {title}
          </Heading>
        )}

        {description ? (
          <Text
            as="span"
            size="intro"
            lineHeight={'1.55'}
            color={colors.text}
            html={description}
            fontFamily={theme.fonts.dmSans}
          />
        ) : null}
      </StyledHeroContent>
      {share && (
        <Column
          width={[1, 1, 74 / 100, 76 / 100, 52 / 100]}
          mx={'auto'}
          px={[2, 2, 2, 9]}
        >
          <StyledShareWrapper>{share}</StyledShareWrapper>
        </Column>
      )}
      {image && (
        <StyledImageWrapper>
          <StyledImageContainer mx={'auto'}>
            <img
              src={image}
              width="100%"
              height="auto"
              text-align="center"
              alt=""
            />
          </StyledImageContainer>
        </StyledImageWrapper>
      )}
    </StyledHeroWrapper>
  );
};

export default HeroEditorial;

HeroEditorial.propTypes = {
  /** Title of the Hero*/
  title: PropTypes.string,
  /** Image of the Hero */
  image: PropTypes.string,
  /**  The description for the Hero*/
  description: PropTypes.string,
  /** Color as prop. Will set the bg color, button and title color */
  color: PropTypes.oneOf([
    'white',
    'black',
    'gray',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]).isRequired,
  /** Images as prop. With different image sizes */
  images: PropTypes.shape({
    small: PropTypes.string,
    large: PropTypes.string,
    medium: PropTypes.string,
  }),
  colors: PropTypes.shape({
    bg: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  children: PropTypes.node,
  share: PropTypes.node,
};

HeroEditorial.defaultProps = {
  title: null,
  description: null,
  color: 'black',
  image: null,
};
