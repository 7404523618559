import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import { theme } from '../common/theme';
import Text from '../Text/Text';
import Button from '../Button/Button';
import SliderArrow from '../SliderArrow/SliderArrow';

const ModalContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: ${({ showModal }) => (showModal ? 'block' : 'none')};
  z-index: 99;
`;

const ModalContent = styled(Box)`
  position: fixed;
  background: white;
  height: 100vh;
  padding: ${theme.space[6]}px;

  ${({ isFullScreen }) =>
    isFullScreen
      ? `
      max-height: 100vh;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      `
      : `
      max-height: 80vh;
      max-width: 720px;
      min-width: 0px;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    `}
`;

const CloseIcon = styled(Flex)`
  position: absolute;
  cursor: pointer;
  right: ${theme.space[2]}px;
  top: ${theme.space[2]}px;
  z-index: 1;
`;

const StyledSliderArrow = styled(SliderArrow)`
  position: relative;
  ${({ layout }) => (layout === 'left' ? 'left: -20px;' : 'right: -20px;')}
`;

const OverflowContainer = styled(Box)`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
`;

const Modal = ({
  closeModal,
  showModal,
  children,
  isFullScreen,
  isMultiView,
  goToNext,
  goToPrevious,
}) => {
  const modalRef = useRef(null);
  const handleOutsideClick = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  });

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('hide-overflow');
      document.documentElement.classList.add('hide-overflow');
    }
  }, [showModal]);

  //ComponentWillUnMount
  useEffect(() => {
    return () => {
      document.body.classList.remove('hide-overflow');
      document.documentElement.classList.remove('hide-overflow');
    };
  }, []);

  return (
    <ModalContainer showModal={showModal}>
      <ModalContent ref={modalRef} isFullScreen={isFullScreen}>
        {isMultiView ? (
          <>
            <StyledSliderArrow
              colorScheme="light"
              onClick={goToPrevious}
              layout="left"
              offset={-75}
            />
            <StyledSliderArrow
              colorScheme="light"
              onClick={goToNext}
              layout="right"
              offset={-75}
            />
          </>
        ) : null}
        <OverflowContainer>
          {children}
          <Button variant={'primary'} onClick={() => closeModal()} mt={[6]}>
            Close
          </Button>
        </OverflowContainer>
        <CloseIcon
          justifyContent="center"
          onClick={() => closeModal()}
          data-testid="close-modal-button"
        >
          {isFullScreen ? (
            <Text size="small" fontWeight="bold" mr={[1]} lineHeight={'1.5'}>
              Close
            </Text>
          ) : null}
          <i className="icon icon-close" />
        </CloseIcon>
      </ModalContent>
    </ModalContainer>
  );
};

Modal.propTypes = {
  /** Function that will close the modal */
  closeModal: PropTypes.func,
  /** Should the modal be shown? */
  showModal: PropTypes.bool,
  /** The content to render in the modal */
  children: PropTypes.node.isRequired,
  /** Whether the modal should display in fullscreen mode */
  isFullScreen: PropTypes.bool,
  /** Is the content part of an array? */
  isMultiView: PropTypes.bool,
  /** Function to move to the next item */
  goToNext: PropTypes.func,
  /** Function to move to the previous item */
  goToPrevious: PropTypes.func,
};

Modal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  children: null,
  isFullScreen: false,
  isMultiView: false,
  goToNext: () => {},
  goToPrevious: () => {},
};

export default Modal;
