import React from 'react';
import PropTypes from 'prop-types';
import { Card as RebassCard, Flex, Box } from 'rebass';
import styled from 'styled-components';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { Container, Row, Column } from '../Grid';
import { theme } from '../common/theme';

export const colorScheme = {
  black: {
    title: theme.colors.baseBlack,
    bg: theme.colors.baseWhite,
    bright: theme.colors.baseBlack,
  },
  green: {
    title: theme.colors.brandGreen,
    bg: theme.colors.baseWhite,
    bright: theme.colors.brandGreenBright,
  },
  purple: {
    title: theme.colors.brandPurple,
    bg: theme.colors.baseWhite,
    bright: theme.colors.brandPurpleBright,
  },
  violet: {
    title: theme.colors.brandViolet,
    bg: theme.colors.baseWhite,
    bright: theme.colors.brandVioletBright,
  },
  red: {
    title: theme.colors.brandRed,
    bg: theme.colors.baseWhite,
    bright: theme.colors.brandRedBright,
  },
  yellow: {
    title: theme.colors.brandPurple,
    bg: theme.colors.baseWhite,
    bright: theme.colors.brandYellowBright,
  },
  navy: {
    title: theme.colors.brandNavy,
    bg: theme.colors.baseWhite,
    bright: theme.colors.brandNavyBright,
  },
  gray: {
    title: theme.colors.baseBlack,
    bg: theme.colors.baseWhite,
    bright: theme.colors.baseBlackBright,
  },
  white: {
    title: theme.colors.brandBlack,
    bg: theme.colors.baseWhite,
    bright: theme.colors.baseBlackBright,
  },
  whiteYellow: {
    title: theme.colors.brandRed,
    bg: theme.colors.baseWhite,
    bright: theme.colors.baseWBright,
  },
  whiteRed: {
    title: theme.colors.brandRed,
    bg: theme.colors.baseWhite,
    bright: theme.colors.baseWBright,
  },
  purpleOrange: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandPurple,
    hover: theme.colors.brandPurplePop,
  },
  xMas: {
    title: theme.colors.baseWhite,
    bg: theme.colors.brandGreen,
    eyebrow: theme.colors.brandGreenPop,
  },
};

const layoutProps = {
  left: {
    wrapper: {
      width: [1, 1, 5 / 12, 4 / 12],
      flex: '0 1 auto',
      order: [1, 1, 0],
    },
    image: {
      width: [1, 1, 6 / 12, 6 / 12],
      flex: '0 1 auto',
      order: [0, 0, 1],
    },
    text: {
      textAlign: ['left'],
    },
    cta: {
      justifyContent: ['flex-start', 'flex-start'],
      alignItems: ['flex-start', 'flex-start'],
    },
  },
  right: {
    wrapper: {
      width: [1, 1, 5 / 12, 4 / 12],
      flex: '0 1 auto',
      order: [1],
    },
    image: {
      width: [1, 1, 6 / 12, 6 / 12],
      flex: '0 1 auto',
      order: [0],
    },
    text: {
      textAlign: ['left'],
    },
    cta: {
      justifyContent: ['flex-start'],
      alignItems: ['flex-start'],
    },
  },
};

const StyledCardPoster = styled(RebassCard)`
  position: relative;
`;

const StyledHeading = styled(Heading)`
  em {
    font-weight: 100;
  }
`;

const StyledImage = styled(Box)`
  padding-bottom: 65%;
  background-image: url(${props => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`;
export const CardPoster = props => {
  const {
    bannerTitle,
    text,
    renderCta,
    eyebrow,
    image,
    color,
    layout,
    ...rest
  } = props;
  return (
    <StyledCardPoster bg={colorScheme[color].bg} {...rest}>
      <Container>
        <Row
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-around"
        >
          <Column {...layoutProps[layout].wrapper}>
            {eyebrow && (
              <Heading
                size="h5"
                color={colorScheme[color].eyebrow}
                textTransform="uppercase"
                mb={[1, 3]}
                {...layoutProps[layout].text}
              >
                {eyebrow}
              </Heading>
            )}
            {bannerTitle && (
              <StyledHeading
                size="h2"
                lineHeight="1.25"
                color={colorScheme[color].title}
                mb={[2, 3]}
                {...layoutProps[layout].text}
                html={bannerTitle}
              />
            )}
            {text && (
              <Text
                size={'intro'}
                html={text}
                color={colorScheme[color].title}
                {...layoutProps[layout].text}
              />
            )}
            {renderCta && (
              <Flex
                mt={[3, 5]}
                alignItems={['flex-start', 'center']}
                flexWrap={'wrap'}
                flexDirection={['column', 'row']}
                {...layoutProps[layout].cta}
              >
                {renderCta}
              </Flex>
            )}
          </Column>
          <Column {...layoutProps[layout].image}>
            {image && <StyledImage mb={[3, 4, 0]} image={image} />}
          </Column>
        </Row>
      </Container>
    </StyledCardPoster>
  );
};

export default CardPoster;

CardPoster.propTypes = {
  /**  Title of the Card */
  bannerTitle: PropTypes.string.isRequired,
  /**  Eyebrow of the Card */
  eyebrow: PropTypes.string,
  /** Text as content */
  text: PropTypes.string,
  /** Function for Calls to action */
  renderCta: PropTypes.func,
  /** Image URL to be used as background image */
  image: PropTypes.string,
  /** Color as prop. Will set the button color*/
  color: PropTypes.oneOf([
    'black',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
    'xMas',
  ]).isRequired,
  /** Set the alignment of component. Center alignment will add bg overlay to make text more legible */
  layout: PropTypes.oneOf(['left', 'right']).isRequired,
};

CardPoster.defaultProps = {
  eyebrow: null,
  title: null,
  text: null,
  color: 'black',
  renderCta: null,
  image: null,
  layout: 'left',
};
