import React from 'react';
import {
  SectionCard,
  Container,
  Column,
  Row,
  Heading,
  Text,
  theme,
} from '@sygnia/components';
import { Box } from 'rebass';
import styled from 'styled-components';
import { mapperWithFunction, setupLink } from '../common/utils';
import { graphql } from 'gatsby';

const getColors = {
  black: {
    title: 'baseBlack',
    bg: 'baseGrayLight',
  },
  green: {
    title: 'brandGreen',
    bg: 'brandGreenPale',
  },
  purple: {
    title: 'brandPurple',
    bg: 'brandPurplePale',
  },
  violet: {
    title: 'brandViolet',
    bg: 'baseWhite',
  },
  red: {
    title: 'brandRed',
    bg: 'brandRedPale',
  },
  yellow: {
    title: 'brandYellow',
    bg: 'brandYellowPale',
  },
  navy: {
    title: 'brandNavy',
    bg: 'baseWhite',
  },
};

const CONTENT_MAP = {
  title: ['title'],
  description: ['description', 'childMarkdownRemark', 'html'],
  modules: ['modules'],
  color: ['color'],
};

const CARD_CONTENT_MAP = {
  title: ['title'],
  image1: ['image', 'fluid', 'src'],
  imageOverlay1: ['imageOverlay', 'fluid', 'src'],
  image: value => {
    return value.image?.file?.url || value.image?.fluid?.src || null;
  },
  imageOverlay: value => {
    return (
      value.imageOverlay?.file?.url || value.imageOverlay?.fluid?.src || null
    );
  },
  color: ['colorScheme'],
};

const StyledColumn = styled(Column)`
  border-right: ${props =>
    props.hasBorder ? `1px solid ${theme.colors[props.borderColor]};` : 'none'};

  @media only screen and (max-width: ${theme.breakpoints[1]}) {
    border-right: 0;
  }
`;

export const SectionCardWrapper = ({ section }) => {
  const { title, description, modules, color } = mapperWithFunction(
    section,
    CONTENT_MAP,
  );
  const modCount = modules.length;

  return (
    <Box
      className="SectionCardWraper_Box"
      py={[7, 7, 10]}
      bg={getColors[color].bg}
      width={[1]}
      flex="0 1 auto"
      mx={'auto'}
    >
      <Box width={[10 / 12, 6 / 12]} flex="0 1 auto" mx={'auto'}>
        {title && (
          <Heading
            size={'h1'}
            color={getColors[color].title}
            textAlign={'center'}
          >
            {title}
          </Heading>
        )}
        {description && (
          <Box mt={[1, 2]} mb={[2, 5]}>
            <Text
              size={'intro'}
              color={getColors[color].title}
              textAlign={'center'}
              html={description}
            />
          </Box>
        )}
      </Box>

      {modules && (
        <Container>
          <Row
            flexDirection={['column', 'column', 'row']}
            alignItems={['center', 'center', 'baseline']}
          >
            {modules.map((item, index) => {
              const cardInfo = mapperWithFunction(item, CARD_CONTENT_MAP);
              const cardlink = item.links ? setupLink(item.links[0]) : null; //Links are limited to 1.

              return (
                <StyledColumn
                  key={index}
                  hasBorder={index !== modCount - 1}
                  width={[1, 1, 4 / 12]}
                  mb={[5, 3]}
                  borderColor={getColors[color].title}
                >
                  <SectionCard
                    key={item.title}
                    {...cardInfo}
                    link={{ ...cardlink }}
                  />
                </StyledColumn>
              );
            })}
          </Row>
        </Container>
      )}
    </Box>
  );
};

SectionCardWrapper.defaultProps = {};
export const fragment = graphql`
  fragment SectionCardLayoutFields on ContentfulLayoutSectionCards {
    __typename
    id
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    color
    modules {
      __typename
      ... on Node {
        # ...ContentGenericFields
        ...SectionCardFields
      }
    }
  }
  fragment SectionCardFields on ContentfulSectionCard {
    id
    title
    colorScheme
    image {
      fluid {
        src
      }
    }
    imageOverlay {
      fluid {
        src
      }
    }
    links {
      title
      label
      type
      to
      external
      file {
        title
        file {
          fileName
          url
        }
      }
    }
  }
`;

SectionCardWrapper.fragment = fragment;
