import React from 'react';
import { MenuSection, ListLinks, theme } from '@sygnia/components';
import { mapperWithFunction } from '../common/utils';
import { MENU_CONTENT_MAP } from './FooterMenuWrapper';
import { graphql } from 'gatsby';

export const MenuWrapper = props => {
  const {
    section: { title, items, inContent, style },
    colorScheme,
    bg,
  } = props;

  const formatMenu = links => {
    return links?.map(item => {
      const link = mapperWithFunction(item, MENU_CONTENT_MAP[item.__typename])
        .link;

      return {
        ...link,
        borderColor:
          (colorScheme && colorScheme.toLowerCase().includes('white')) ||
          (bg && bg === 'white')
            ? theme.colors.baseGrayLight
            : theme.colors.baseWhite,

        title: link.displayName,
      };
    });
  };

  if (inContent) {
    return (
      <ListLinks
        listTitle={title}
        items={formatMenu(items)}
        style={style}
        {...props}
      />
    );
  }

  return (
    <MenuSection
      px={[0]}
      mt={[2, 2, 0]}
      mb={[0, 0, 2]}
      sectionHeader={title}
      links={formatMenu(items)}
      {...props}
    />
  );
};

export const fragment = graphql`
  fragment LayoutMenuFields on ContentfulMenu {
    title
    inContent
    style {
      mt
    }
    items: menuItems {
      __typename
      ... on Node {
        ... on ContentfulLinks {
          label
          type
          external
          file {
            file {
              url
              fileName
              contentType
            }
          }
          to
        }
        ... on ContentfulPage {
          slug
          label: name
          pageSummary {
            title
          }
          contentfulparent
        }
        ... on ContentfulProduct {
          slug
          label: title
        }
        ... on ContentfulPeople {
          ...PeopleLayoutFields
        }
      }
    }
  }
`;
