import React from 'react';
import { Text, theme } from '@sygnia/components';
import { Link } from 'gatsby';

const MenuFooter = () => (
  <Text
    size="small"
    fontWeight={theme.fontWeight.bold}
    my={2}
    textAlign={['left', 'center']}
  >
    Have any questions? Go to our <Link to="/faq">FAQ section</Link>
  </Text>
);

export default MenuFooter;
