import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex, Card as RebassCard } from 'rebass';
import { layout } from 'styled-system';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { theme } from '../common/theme';
import { animated } from 'react-spring';
// import { useVerticalSpring, useHorizontalSpring } from '../common/animations';
import { mediaQuery } from '../common/utils';

const StyledCardHero = styled(Box)`
  ${layout};
  display: block;
  position: relative;
  overflow: hidden;
  @media (max-width: ${theme.breakpoints[2]}) {
    max-height: unset;
  }
`;

const StyledTextWrapper = styled(Box)`
  @media (max-width: ${theme.breakpoints[2]}) {
    min-height: 45px;
  }
`;

const StyledCardHeroWrapper = styled(Flex)`
  min-height: 680px;
  align-items: stretch;
  ${layout};
`;

const StyledImageWrapper = styled(animated(Flex))`
  flex-grow: 1;
`;

const StyledImage = styled(RebassCard)`
  ${layout};
  width: 100%;
  flex-grow: 1;
  min-height: 380px;
  background-image: url(${props => props.images.small});
  ${mediaQuery.medium} {
    min-height: 680px;
    background-image: url(${props => props.images.medium});
  }
  ${mediaQuery.large} {
    background-image: url(${props => props.images.large});
  }
  ${mediaQuery.xlarge} {
    background-image: url(${props => props.images.xlarge});
  }
  background-size: ${props => props.bgSize};
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 380px;
  @media (min-width: ${theme.breakpoints[2]}) {
    min-height: 680px;
  }
`;

const AnimatedFlex = animated(Flex);

const getColors = {
  white: {
    title: 'baseBlack',
    titleHero: 'baseBlack',
    bg: 'baseWhite',
    bgHero: 'baseWhite',
    textHero: 'baseBlack',
    text: 'baseBlack',
  },
  black: {
    title: 'baseBlack',
    titleHero: 'baseBlack',
    bg: 'baseGrayLight',
    bgHero: 'baseGrayVeryLight',
    textHero: 'baseBlack',
    text: 'baseBlack',
  },
  green: {
    title: 'brandGreen',
    titleHero: 'baseWhite',
    emphasis: 'brandGreenPop',
    bg: 'brandGreenPale',
    bgHero: 'brandGreen',
    textHero: 'baseWhite',
    text: 'brandGreen',
  },
  purple: {
    title: 'brandPurple',
    emphasis: 'brandYellowPop',
    titleHero: 'baseWhite',
    bg: 'brandPurplePale',
    bgHero: 'brandPurple',
    textHero: 'baseWhite',
    text: 'brandPurple',
  },
  violet: {
    title: 'baseWhite',
    emphasis: 'brandYellow',
    titleHero: 'baseWhite',
    bg: 'brandVioletPale',
    bgHero: 'brandViolet',
    textHero: 'baseWhite',
    text: 'brandViolet',
  },
  red: {
    title: 'brandRed',
    titleHero: 'brandRedPop',
    bg: 'brandRedPale',
    bgHero: 'brandRed',
    textHero: 'baseWhite',
    text: 'brandRed',
  },
  yellow: {
    title: 'brandYellow',
    titleHero: 'baseWhite',
    emphasis: 'brandYellowPop',
    bg: 'brandYellowPale',
    bgHero: 'brandYellow',
    textHero: 'baseWhite',
    text: 'brandYellow',
  },
  navy: {
    title: 'brandNavy',
    titleHero: 'baseWhite',
    emphasis: 'brandNavyPop',
    bg: 'brandNavyPale',
    bgHero: 'brandNavy',
    textHero: 'baseWhite',
    text: 'brandNavy',
  },
  purpleGreen: {
    title: 'brandPurple',
    emphasis: 'brandGreenPop',
    titleHero: 'baseWhite',
    bg: 'brandViolet',
    bgHero: 'brandViolet',
    textHero: 'baseWhite',
    text: 'brandPurple',
    size: 'contain',
    imageBg: '#34348c',
  },
  purpleOrange: {
    title: 'baseWhite',
    emphasis: 'brandRedPop',
    titleHero: 'baseWhite',
    bg: 'brandPurple',
    bgHero: 'brandPurple',
    textHero: 'baseWhite',
    text: 'baseWhite',
    imageBg: 'brandPurpleBright',
  },
  xMas: {
    title: 'brandPurple',
    emphasis: 'brandGreenPop',
    titleHero: 'baseWhite',
    bg: 'brandViolet',
    bgHero: 'brandGreen',
    textHero: 'baseWhite',
    text: 'brandPurple',
    size: 'cover',
    imageBg: 'brandGreen',
  },
  greenYellow: {
    title: 'brandYellowBright',
    titleHero: 'baseWhite',
    emphasis: 'brandYellowBright',
    bg: 'brandGreen',
    bgHero: 'brandGreen',
    textHero: 'baseWhite',
    text: 'brandYellowBright',
  },
  transparent: {
    //Hack Alert! Used for China banner.
    title: 'baseWhite',
    titleHero: 'baseWhite',
    emphasis: 'yellowBright',
    bg: 'redBerry',
    bgHero: 'redBerry',
    textHero: 'baseWhite',
    text: 'brandYellowBright',
  },
  inherit: {
    //Hack Alert! Used for UK Funds banner.
    title: 'baseWhite',
    titleHero: 'baseWhite',
    emphasis: '#1078BA',
    bg: '#043A61',
    bgHero: '#043A61',
    textHero: 'baseWhite',
    text: 'baseWhite',
  },
};

const CardHero = props => {
  const {
    eyeBrow,
    heroTitle,
    text,
    color,
    renderCta,
    images,
    isFeatured,
    isAlt,
    // transitionStatus,
    // shouldAnimate,
  } = props;

  const meta = props.meta ? JSON.parse(props.meta.internal.content) : {};
  const customColors = meta.customColors || null;
  const slideLayout = meta.slideLayout || null;
  // const entering = ['entering', 'entered'].includes(transitionStatus);
  // const titleSpring = useVerticalSpring(shouldAnimate, entering, 50);
  // const imageSpring = useHorizontalSpring(shouldAnimate, entering, 200);

  return (
    <StyledCardHero
      bg={
        customColors
          ? customColors.bg
          : isFeatured
          ? getColors[color].bgHero
          : getColors[color].bg
      }
      images={images}
      mb={meta?.slideLayout?.marginBottom ? meta.slideLayout.marginBottom : [0]}
      {...slideLayout}
      {...props}
    >
      <StyledCardHeroWrapper
        alignItems="center"
        flexDirection={['column', 'column', 'column', 'row', 'row']}
      >
        {heroTitle && (
          <AnimatedFlex
            // style={{
            //   opacity: titleSpring.opacity,
            //   transform: titleSpring.y.interpolate(y => `translateY(${y}px)`),
            // }}
            width={[1, 1, 1, 1 / 2]}
            mx={!images.medium && 'auto'}
            order={['2', '2', '2', isAlt ? '4' : '3']}
          >
            <Flex
              width={[1, 1, 1, 1, 8 / 12]}
              mx={'auto'}
              pt={[8, 17]}
              pb={[6, 17]}
              px={[2, 2, 5, 5]}
              alignItems={['center', 'center', 'center', 'flex-start']}
              justifyContent={'center'}
              flexDirection={['column']}
            >
              {eyeBrow && (
                <Heading
                  size="h5"
                  color={
                    customColors
                      ? customColors.eyeBrow
                      : isFeatured
                      ? getColors[color].titleHero
                      : getColors[color].title
                  }
                  textTransform="uppercase"
                  mb={[1, 3]}
                  textAlign="left"
                  // {...layoutProps[layout].text}
                >
                  {eyeBrow}
                </Heading>
              )}
              {heroTitle && (
                <Box style={{ maxWidth: '100%' }}>
                  <Heading
                    as={isFeatured ? 'h1' : 'h3'}
                    size={isFeatured ? 'hero' : 'h1'}
                    emphasis={
                      customColors
                        ? customColors.emphasis
                        : getColors[color]
                        ? getColors[color].emphasis
                        : null
                    }
                    color={
                      customColors
                        ? customColors.title
                        : isFeatured
                        ? getColors[color].titleHero
                        : getColors[color].title
                    }
                    textAlign={
                      !images.medium
                        ? 'center'
                        : ['center', 'center', 'center', 'left']
                    }
                    mb={isFeatured ? [2, 3, 3, 3] : [2, 2, 2, 3]}
                  >
                    {heroTitle}
                  </Heading>
                </Box>
              )}
              {text && (
                <Box style={{ maxWidth: '100%' }}>
                  <StyledTextWrapper>
                    <Text
                      as="div"
                      size={meta?.textFontSize ? meta.textFontSize : 'intro'}
                      color={
                        customColors
                          ? customColors.text
                          : isFeatured
                          ? getColors[color].textHero
                          : getColors[color].text
                      }
                      lineHeight={'1.4'}
                      textAlign={
                        !images
                          ? 'center'
                          : ['center', 'center', 'center', 'left']
                      }
                    >
                      {text}
                    </Text>
                  </StyledTextWrapper>

                  {renderCta && (
                    <Flex
                      flexWrap={'wrap'}
                      mt={[4, 4, 4, 4]}
                      justifyContent={
                        !images
                          ? 'center'
                          : ['center', 'center', 'center', 'flex-start']
                      }
                      alignItems={['center']}
                    >
                      {renderCta}
                    </Flex>
                  )}
                </Box>
              )}
            </Flex>
          </AnimatedFlex>
        )}

        {images && (
          <StyledImageWrapper
            style={{
              ...(customColors
                ? { background: customColors.imageBg }
                : getColors[color].imageBg
                ? { background: getColors[color].imageBg }
                : {}),
              // opacity: imageSpring.opacity,
              // transform: imageSpring.x.interpolate(x => `translateX(${x}px)`),
            }}
            width={!heroTitle && !text ? [1] : [1, 1, 1, 1 / 2]}
            order={['1', '1', '1', isAlt ? '3' : '4']}
          >
            <StyledImage
              images={images}
              bgSize={
                (customColors && customColors.size) ||
                getColors[color].size ||
                'cover'
              } //contain?
            />
          </StyledImageWrapper>
        )}
      </StyledCardHeroWrapper>
    </StyledCardHero>
  );
};

export default CardHero;

CardHero.propTypes = {
  /**  Eyebrow of the Hero */
  eyeBrow: PropTypes.string,
  /** Title of the Hero */
  heroTitle: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object,
  ]),
  /** Introduction text */
  text: PropTypes.string,
  /** Color as prop. Will set the bg color, button and title color */
  color: PropTypes.oneOf([
    'black',
    'white',
    'green',
    'purple',
    'purpleGreen',
    'violet',
    'red',
    'yellow',
    'navy',
    'xMas',
    'purpleOrange',
    'greenYellow',
  ]).isRequired,
  /** Function to pass in Calls to Action */
  renderCta: PropTypes.func,
  /** URL of the image to use as background */
  images: PropTypes.object,
  /** Alternate the order of the image and the text when multiple cards are being used */
  isAlt: PropTypes.bool,
  /** Is this component the Feature of the Page? */
  isFeatured: PropTypes.bool,
  /** Transition status of parent page */
  transitionStatus: PropTypes.string,
  /** Should the Nav animate? */
  shouldAnimate: PropTypes.bool,
  meta: PropTypes.object,
};

CardHero.defaultProps = {
  eyeBrow: null,
  heroTitle: null,
  text: null,
  color: 'white',
  renderCta: null,
  images: null,
  isFeatured: false,
  isAlt: false,
  shouldAnimate: false,
  bgSize: 'cover',
};
