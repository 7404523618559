import styled, { css } from 'styled-components';
import { theme } from '../common/theme';

export const TableCssDefinition = {
  table: css`
    width: 100%;
    border-collapse: collapse;
    font-family: ${theme.fonts.dmSans};
    font-size: ${theme.fontSizes.body};
    line-height: 1.45;
  `,
  thead: css`
    ${props => props.css && css(...props.css)};
  `,
  th: css`
    text-align: ${props => (props.align ? props.align : 'left')};
    font-weight: ${theme.fontWeight.bold};
    border-bottom: 1px solid;
    border-bottom-color: ${theme.colors.baseBlack};
    padding: ${theme.space[1]}px ${theme.space[2]}px;
    ${props => props.css && css(...props.css)};
  `,
  tbody: css`
    ${props => props.css && css(...props.css)};
  `,
  tr: css`
    border-bottom: 1px solid #eee;
    ${props => props.css && css(...props.css)};
    &:hover {
      background-color: ${theme.colors.baseGrayVeryLight};
    }
  `,
  td: css`
    text-align: ${props => (props.align ? props.align : 'left')};
    padding: ${theme.space[1]}px ${theme.space[2]}px;
    ${props => props.css && css(...props.css)};
    &:hover {
      text-decoration: underline !important;
    }
  `,
};

// TODO: Add hover prop to control hover behaviour

const Table = Object.keys(TableCssDefinition).reduce((result, item) => {
  const ComponentName = item.charAt(0).toUpperCase() + item.slice(1);
  result[ComponentName] = styled[item]`
    ${TableCssDefinition[item]}
  `;
  return result;
}, {});

export default Table;
