import React, { Component } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import qs from 'qs';

const updateAfter = 700;
const searchStateToUrl = searchState =>
  searchState ? `${window.location.pathname}?${qs.stringify(searchState)}` : '';

export const withUrlSync = App =>
  class urlSync extends Component {
    constructor() {
      super();
      this.state = { searchState: {} };
    }

    componentDidMount() {
      this.setState({ searchState: qs.parse(window.location.search.slice(1)) });
      window.addEventListener('popstate', ({ state: searchState }) =>
        this.setState({ searchState }),
      );
    }

    onSearchStateChange = searchState => {
      clearTimeout(this.debouncedSetState);
      this.debouncedSetState = setTimeout(() => {
        window.history.pushState(
          searchState,
          null,
          searchStateToUrl(searchState),
        );
      }, updateAfter);
      this.setState({ searchState });
    };

    render() {
      if (this.props.disableSync) {
        return <App {...this.props} />;
      }

      return (
        <App
          {...this.props}
          searchState={this.state.searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={searchStateToUrl}
        />
      );
    }
  };

const AlgoliaWrapper = ({ indices = [], children, ...restProps }) => {
  if (!indices.length) return null;

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_API_KEY,
  );

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0].name}
      {...restProps}
    >
      {children}
    </InstantSearch>
  );
};

export default withUrlSync(AlgoliaWrapper);
