import { GTM_EVENT } from './constants';
import PropTypes from 'prop-types';

const logGtmEvent = ({ eventName, data }) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  }
};

logGtmEvent.propTypes = {
  eventName: PropTypes.oneOf(Object.keys(GTM_EVENT)),
  data: PropTypes.object,
};

logGtmEvent.defaultProps = {
  eventName: null,
  data: {},
};

export default logGtmEvent;
