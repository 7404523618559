import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../common/theme';

const StyledIcon = styled.i`
  min-width: 23px;
  font-size: ${theme.fontSizes.body};
  margin-left: ${theme.space[1]}px;
`;

const Icon = ({ name }) => <StyledIcon className={`icon icon-${name}`} />;

Icon.propTypes = {
  /** Name of the icon to use. */
  name: PropTypes.string,
};

Icon.defaultProps = {
  name: '',
};

export default Icon;
