import React from 'react';
import { Search } from '@sygnia/components';

const SearchBox = ({ currentRefinement = '', refine }) => {
  return (
    <Search
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
    />
  );
};

export default SearchBox;
