import React from 'react';
import { Dropdown } from '@sygnia/components';

const HitsPerPage = ({ currentRefinement, items, refine }) => (
  <Dropdown
    mx={2}
    label={currentRefinement}
    renderOptions={Component => {
      return (
        <>
          {items.map(item => (
            <Component
              key={item.value}
              isSelected={item.isRefined}
              isMultiSelect={false}
              onChange={() => {
                refine(item.value);
              }}
              isMultiSelect={false}
              label={item.label}
            />
          ))}
        </>
      );
    }}
  ></Dropdown>
);

export default HitsPerPage;
