import React from 'react';
import { Text, theme } from '@sygnia/components';

export const Message = ({ form = null }) => {
  if (
    form === 'newsletter' ||
    form === 'open_account' ||
    form === 'surf' ||
    form === 'stargazer'
  ) {
    return (
      <>
        <Text mt={[3]} color="green">
          Thanks for sending us your information!
        </Text>
        <Text mt={[1]} color="green">
          We value your trust and will make sure that everything you share with
          us is treated with the utmost care. Our team will be in touch shortly
          in case we need any additional information.
        </Text>
        <Text mt={[1]} color="green">
          The Sygnia Team
        </Text>
      </>
    );
  } else if (form === 'landing_page_basic') {
    return (
      <>
        <Text mt={[3]} color="black" fontFamily={theme.fonts.dmSans}>
          Success! Thank you for submitting your details. We will be in touch
          soon.
        </Text>
      </>
    );
  } else {
    return (
      <>
        <Text mt={[3]} color="black" fontFamily={theme.fonts.dmSans}>
          Thank you for submitting.
        </Text>
      </>
    );
  }
};
