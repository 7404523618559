import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text/Text';
import { Box, Flex } from 'rebass';
import { Container, Row, Column } from '../Grid';
import ButtonArrow from '../ButtonArrow';
import styled from 'styled-components';
import { theme } from '../common/theme';
import { layout } from 'styled-system';

const StyledSeparator = styled(Box)`
  ${layout};
  background-color: ${theme.colors.baseGrayLight};
`;

const StyledSelect = styled(Flex)`
  ${layout};
  align-items: center;
`;

export class Pagination extends Component {
  render() {
    const {
      dropdownOptions,
      stats,
      hitsPerPage,
      onNext,
      onPrev,
      total,
    } = this.props;
    const shouldDisplayDropdown =
      !total ||
      (dropdownOptions &&
        !dropdownOptions.every(({ label }) => parseInt(label) >= total));

    return (
      <Box bg="#F6F6F6" py={[3]}>
        <Container>
          <Row justifyContent="center">
            <Column width={[1, 8 / 12, 8 / 12, 7 / 12]} flex="0 0 auto">
              <Flex justifyContent="space-between" alignItems="center">
                <ButtonArrow
                  direction="left"
                  colorScheme="dark"
                  mr={[2]}
                  onClick={onPrev}
                  isDisabled={onPrev ? false : true}
                />
                <Flex
                  alignItems="center"
                  flexDirection={['column', 'column', 'row']}
                >
                  <Text fontWeight="bold" size="small" textAlign="center">
                    {stats}
                  </Text>
                  {stats && shouldDisplayDropdown ? (
                    <StyledSeparator
                      mx={4}
                      height={'45px'}
                      width={'1px'}
                      display={['none', 'none', 'block']}
                    />
                  ) : null}
                  {shouldDisplayDropdown ? (
                    <StyledSelect display={['none', 'none', 'flex']}>
                      <Text fontWeight="bold" size="small">
                        View
                      </Text>
                      {hitsPerPage}
                      <Text fontWeight="bold" size="small" flex="0 0 auto">
                        per page
                      </Text>
                    </StyledSelect>
                  ) : null}
                </Flex>
                <ButtonArrow
                  direction="right"
                  colorScheme="dark"
                  ml={[2]}
                  onClick={onNext}
                  isDisabled={onNext ? false : true}
                />
              </Flex>
            </Column>
          </Row>
        </Container>
      </Box>
    );
  }
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  dropdownOptions: PropTypes.array,
  stats: PropTypes.node,
  hitsPerPage: PropTypes.node,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};

Pagination.defaultProps = {
  total: null,
  dropdownOptions: [],
};

export default Pagination;
