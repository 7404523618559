import React from 'react';
import PropTypes from 'prop-types';
import Option from '../../Option/Option';
import FieldTemplate from '../FieldTemplate';
import { Flex } from 'rebass';

const getLayoutByOrientation = {
  vertical: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  horizontal: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
};

const Radio = ({ options, orientation, ...inputOptions }) => {
  return (
    <FieldTemplate {...inputOptions}>
      <Flex
        classname="Radio_Flex"
        flexDirection={getLayoutByOrientation[orientation].flexDirection}
        justifyContent={getLayoutByOrientation[orientation].justifyContent}
        alignItems={getLayoutByOrientation[orientation].alignItems}
      >
        {options.map((option, index) => (
          <Option
            key={index}
            {...inputOptions}
            label={option.label}
            isMultiSelect={false}
            showOnRight={false}
            checked={
              inputOptions.value
                ? inputOptions.value.toString() === option.value.toString()
                : false
            }
            value={option.value}
          />
        ))}
      </Flex>
    </FieldTemplate>
  );
};

Radio.propTypes = {
  /** Label of Section */
  label: PropTypes.string,
  /** Radio Options */
  options: PropTypes.string,
  /** Error */
  error: PropTypes.string,
  /** Orientation: Should the radio buttons appear next to each other, or below each other? */
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
};

Radio.defaultProps = {
  label: null,
  options: [],
  error: null,
  orientation: 'vertical',
};

export default Radio;
