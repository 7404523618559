import React from 'react';
import { Blockquote } from '@sygnia/components';
import { mapperWithFunction } from '../common/utils';
import { graphql } from 'gatsby';

const BLOCKQUOTE_CONTENT_MAP = {
  image: ['image', 'fluid', 'src'],
  role: ['role'],
  cite: ['cite'],
  headline: ['headline', 'headline'],
};

const fallbackColors = {
  green: {
    bg: '#FFFFFF',
    title: 'baseBlack',
    button: 'baseBlack',
    description: 'baseBlack',
  },
};

export const BlockquoteWrapper = ({ section }) => {
  const mappedOptions = mapperWithFunction(section, BLOCKQUOTE_CONTENT_MAP);
  const meta = section.meta?.internal
    ? JSON.parse(section.meta.internal.content)
    : null;

  const colors = meta?.colors || fallbackColors.green;
  return <Blockquote {...mappedOptions} color={colors} />;
};

BlockquoteWrapper.defaultProps = {
  section: {},
};

export const fragment = graphql`
  fragment BlockQuoteFields on ContentfulBlockquote {
    role
    cite
    headline {
      headline
    }
    image {
      fluid {
        src
      }
    }
    meta {
      internal {
        content
      }
    }
  }
`;

BlockquoteWrapper.fragment = fragment;
