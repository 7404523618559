import _ from 'lodash';

const cpi = [0.045, 0.05, 0.095];

const annualRates = {
  sygnia: {
    effectiveMonthlyReturn: Math.pow(1 + cpi[2], 1 / 12) - 1,
    annualFee: 0.0044,
  },
  industry: {
    effectiveMonthlyReturn: Math.pow(1 + cpi[2], 1 / 12) - 1,
    annualFee: 0.01598333,
  },
  bank: {
    effectiveMonthlyReturn: Math.pow(1 + cpi[1], 1 / 12) - 1,
    annualFee: 0,
  },
};

var roundToNearest = function(t, e) {
  return Math.floor(e / t) * t;
};

export class FinancialModel {
  constructor(initialDeposit, monthlyContribution, years) {
    this.initialDeposit = initialDeposit;
    this.monthlyContribution = monthlyContribution;
    this.years = _.range(0, years + 1, 1);
  }

  buildSeries(initialDeposit, monthlyContribution) {
    this.initialDeposit = initialDeposit;
    this.monthlyContribution = monthlyContribution;
    const rates = annualRates;

    const sygnia = this.buildFeesGraphSeries(rates.sygnia);
    const traditional = this.buildFeesGraphSeries(rates.industry);
    const bank = this.buildFeesGraphSeries(rates.bank);

    return {
      xAxisLabels: this.years,
      sygnia: {
        series: sygnia,
        getValueAtYear: function(year) {
          return sygnia[year];
        },
      },
      traditional: {
        series: traditional,
        getValueAtYear: function(year) {
          return traditional[year];
        },
      },
      bank: {
        series: bank,
        getValueAtYear: function(year) {
          return bank[year];
        },
      },
    };
  }

  buildFeesGraphSeries(rate) {
    const riskModel = new RiskCalculatorModel({
      startingAmount: this.initialDeposit,
      monthlyContribution: this.monthlyContribution,
      rate,
    });

    return this.years.map(year => {
      return roundToNearest(100, riskModel.valueAtYearMinusFees(year));
    });
  }
}

class RiskCalculatorModel {
  constructor(init) {
    this.startingAmount = init.startingAmount;
    this.monthlyContribution = init.monthlyContribution;
    this.rate = init.rate;
  }

  valueAtYear(year) {
    let total = this.startingAmount;
    for (let n = 0; n <= 12 * year; n++)
      total =
        (1 + this.rate.effectiveMonthlyReturn) *
        (total + this.monthlyContribution);
    return total;
  }

  valueAtYearMinusFees(year) {
    const effectiveMonthFee = Math.pow(1 + this.rate.annualFee, 1 / 12) - 1;
    let total = this.startingAmount;

    for (let n = 1; n <= 12 * year; n++) {
      const contribution =
        (1 + this.rate.effectiveMonthlyReturn) *
        (total + this.monthlyContribution);
      total = contribution - effectiveMonthFee * contribution;
    }

    return total;
  }
}
