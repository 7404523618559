import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { Container, Row, Column } from '../Grid';
import { borders, flex } from 'styled-system';
import { theme } from '../common/theme';
import { rgba } from '../common/utils';
import Drawing from '../Drawing/Drawing';

const StyledHero = styled(Box)`
  display: block;
`;

const StyledBox = styled(Box)`
  ${borders};
  ${flex};
`;

const getColors = {
  white: {
    title: 'baseBlack',
    bg: 'baseWhite',
    border: theme.colors.baseBlack,
    text: 'baseBlack',
  },
  black: {
    title: 'baseBlack',
    bg: 'baseWhite',
    border: theme.colors.baseBlack,
    text: 'baseBlack',
  },
  gray: {
    title: 'baseBlack',
    bg: 'baseGrayVeryLight',
    border: theme.colors.baseBlack,
    text: 'baseBlack',
  },
  green: {
    title: 'brandGreenPop',
    bg: 'brandGreen',
    border: theme.colors.baseWhite,
    text: 'baseWhite',
  },
  purple: {
    title: 'brandPurplePop',
    bg: 'brandPurple',
    border: theme.colors.baseWhite,
    text: 'baseWhite',
  },
  violet: {
    title: 'brandVioletPop',
    bg: 'brandViolet',
    border: theme.colors.baseWhite,
    text: 'baseWhite',
  },
  purpleOrange: {
    title: 'brandYellowPop',
    text: theme.colors.baseWhite,
    bg: 'brandViolet',
    border: theme.colors.baseWhite,
  },
  red: {
    title: 'brandRedPop',
    bg: 'brandRed',
    border: theme.colors.baseWhite,
    text: 'baseWhite',
  },
  yellow: {
    title: 'brandYellowPop',
    bg: 'brandYellow',
    border: theme.colors.baseWhite,
    text: 'baseWhite',
  },
  navy: {
    title: 'brandNavyPop',
    bg: 'brandNavy',
    border: theme.colors.baseWhite,
    text: 'baseWhite',
  },
};

const Hero = props => {
  const {
    heroTitle,
    title,
    text,
    renderMeta,
    color,
    hasLogo,
    renderCta,
  } = props;

  // If there is only a title, we need to adjust the spacing in parts of the component
  const isTitleOnly = renderMeta() || text || renderCta() ? false : true;

  return (
    <StyledHero
      pt={[4, 13]}
      pb={isTitleOnly ? [1, 4] : [4, 13]}
      bg={getColors[color].bg}
      {...props}
    >
      <Container>
        <Row>
          {hasLogo && (
            <Column width={[1, 3 / 12]} flex={'0 1 auto'}>
              <Box mb={2} width={['25px', '55px']}>
                <Drawing
                  className="Hero_Drawing"
                  name="logo"
                  color={getColors[color].title}
                />
              </Box>
            </Column>
          )}
          <Column width={[1, 9 / 12]} flex={'0 1 auto'}>
            {heroTitle && (
              <Heading
                className="Hero_Heading"
                size="hero"
                as="h1"
                color={getColors[color].title}
              >
                {heroTitle || title}
              </Heading>
            )}
          </Column>
        </Row>

        <Row>
          <Column width={[0, 3 / 12]} flex={'0 1 auto'}>
            <StyledBox
              mt={[0, 5]}
              pt={[0, 5]}
              borderTop={`1px solid ${getColors[color].border}`}
            />
          </Column>

          <Column width={[1, 9 / 12]} flex={'0 1 auto'}>
            <StyledBox
              mt={[3, 5]}
              pt={[3, 5]}
              borderTop={`solid 1px ${rgba(getColors[color].border, 0.25)}`}
            />
          </Column>
        </Row>

        {!isTitleOnly ? (
          <Row>
            <Column width={[1, 3 / 12]} flex={'0 1 auto'} py={[0, 1]}>
              {renderMeta() && renderMeta(getColors[color].text)}
            </Column>

            <Column width={[1, 7 / 12]} flex={'0 1 auto'}>
              {text && (
                <Text
                  size="intro"
                  lineHeight={'1.55'}
                  color={getColors[color].text}
                  html={text.replace('\n', '<br/>')}
                />
              )}
              {renderCta() && (
                <Flex
                  mt={text ? [2, 5] : null}
                  flexDirection={['column', 'column', 'column', 'row']}
                  justifyContent={'flex-start'}
                  flexWrap={'wrap'}
                  alignItems={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center',
                  ]}
                >
                  {renderCta(color)}
                </Flex>
              )}
            </Column>
          </Row>
        ) : null}
      </Container>
    </StyledHero>
  );
};

export default Hero;

Hero.propTypes = {
  /**  DEPRECATED: title prop deprecated*/
  title: PropTypes.string,
  /**  Title of the Hero Component */
  heroTitle: PropTypes.string.isRequired,
  /**  The renderMeta is a renderProp that allows the passing in of other components */
  renderMeta: PropTypes.func,
  /**  The introduction text to be displayed below the Title */
  text: PropTypes.string,
  /** Color as prop. Will set the bg color, button and title color */
  color: PropTypes.oneOf([
    'white',
    'black',
    'gray',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]).isRequired,
  hasLogo: PropTypes.bool,
  renderCta: PropTypes.func,
};

Hero.defaultProps = {
  title: null,
  renderMeta: null,
  text: null,
  color: 'black',
  hasLogo: false,
  renderCta: null,
};
