import axios from 'axios';

const accessToken = process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN;
const spaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID;
const baseURL = `https://cdn.contentful.com/spaces/${spaceId}/environments/master/`;

const defaultParams = { access_token: accessToken };

const contentfulClient = axios.create({
  baseURL,
});

contentfulClient.interceptors.request.use(config => {
  config.params = {
    ...defaultParams,
    ...config.params,
  };
  return config;
});

export default contentfulClient;
