import React from 'react';
import { Container, Row, Column } from '@sygnia/components';
import { BasicContent } from './ContentWrapper';

export const ContentGenericWrapper = props => {
  const {
    contentGrid,
    sidebarGrid,
    section: { meta },
  } = props;

  let contentGridObj = contentGrid || {};
  if (meta && meta.contentGrid) {
    contentGridObj = {
      ...contentGrid,
      ...meta.contentGrid,
    };
  }

  let sidebarGridObj = sidebarGrid || {};
  if (meta && meta.sidebarGrid) {
    sidebarGridObj = {
      ...sidebarGridObj,
      ...meta.sidebarGrid,
    };
  }

  return (
    <Container mt={[5]}>
      <Row
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start']}
        justifyContent={['space-between']}
        flexWrap={'wrap'}
      >
        <Column {...sidebarGridObj}></Column>
        <Column {...contentGridObj}>
          <BasicContent {...props} />
        </Column>
      </Row>
    </Container>
  );
};

ContentGenericWrapper.defaultProps = {
  contentGrid: {
    width: [1, 1, 1, 9 / 12],
    mb: [3, 3, 3, 0],
    mx: 0,
    mt: [3, 5],
    order: 0,
    flex: '0 0 auto',
  },

  sidebarGrid: {
    width: [1, 1, 1, 3 / 12],
    mb: [3, 3, 3, 0],
    mt: [3, 3, 3, 0],
    mx: 0,
    flex: '0 0 auto',
  },
};
