import React from 'react';
import { BenefitsItem, Card, CardPrimary } from '@sygnia/components';
import LinkGroup from './LinkGroup';
import { CONTENT_TYPES } from '../common/constants';
import { mapperWithFunction } from '../common/utils';
import { BasicContent } from './ContentWrapper';

const CONTENT_MAPPING = {
  [CONTENT_TYPES.CONTENT_PAGE]: {
    section: value => {
      return {
        ...value.pageSummary,
        links: [value],
      };
    },
  },
};

export const InfoCardWrapper = props => {
  const { section, color } = props;

  const renderCta = section.links ? (
    <LinkGroup color={color} links={section.links || []} />
  ) : null;

  return (
    <BenefitsItem
      color={color}
      title={section.title}
      image={section.meta && section.meta.drawing}
      uploadedImage={section.image}
      text={section.content ? section.content.childMarkdownRemark.html : null}
      renderCta={() => renderCta}
    />
  );
};

const getColorScheme = (colorScheme, color) => {
  if (!colorScheme) return color;
  switch (colorScheme) {
    case CONTENT_TYPES.INHERIT:
      return color;
    case CONTENT_TYPES.TRANSPARENT:
      return color;
    default:
      return colorScheme;
  }
};

export const CardPrimaryWrapper = props => {
  const { section, color, ...rest } = props;

  const sectionInfo = CONTENT_MAPPING[section.__typename]
    ? mapperWithFunction(section, CONTENT_MAPPING[section.__typename]).section
    : section;

  const colorScheme = getColorScheme(sectionInfo.colorScheme, color);
  const isTransparent = sectionInfo.colorScheme === CONTENT_TYPES.TRANSPARENT;

  let renderCta;

  if (sectionInfo.links) {
    renderCta = () => (
      <LinkGroup color={colorScheme} isBlock links={sectionInfo.links || []} />
    );
  }

  return (
    <CardPrimary
      color={colorScheme}
      cardTitle={sectionInfo.title}
      text={
        sectionInfo.content
          ? sectionInfo.content.childMarkdownRemark.html
          : null
      }
      renderCta={renderCta}
      isTransparent={isTransparent}
      {...rest}
    />
  );
};

export const CardWrapper = props => {
  const { section, color, inColumn } = props;

  const colorScheme =
    section.colorScheme && section.colorScheme !== CONTENT_TYPES.INHERIT
      ? section.colorScheme.toLowerCase() // TODO: add color function
      : color;

  const bg =
    section.backgroundColor && section.backgroundColor !== CONTENT_TYPES.INHERIT
      ? section.backgroundColor.toLowerCase() // TODO: add color function
      : color;

  const sectionInfo = CONTENT_MAPPING[section.__typename]
    ? mapperWithFunction(section, CONTENT_MAPPING[section.__typename]).section
    : section;

  const isTransparent = bg === CONTENT_TYPES.TRANSPARENT;

  const renderCta = sectionInfo.links ? (
    <LinkGroup color={colorScheme} links={sectionInfo.links || []} />
  ) : null;

  const richContent = section.richContent ? (
    <BasicContent mb={[0]} section={{ richContent: section.richContent }} />
  ) : null;

  let showTitle = true;
  let cardTitleProps = {};
  if (sectionInfo.meta) {
    if (sectionInfo.meta.showTitle === false) {
      showTitle = false;
    }

    if (sectionInfo.meta.title) {
      cardTitleProps.cardTitleSize = sectionInfo.meta.title;
    }
  }
  const customTitleColor = section.meta?.customColors?.text;
  return (
    <Card
      cardTitle={showTitle && sectionInfo.title}
      {...cardTitleProps}
      image={
        sectionInfo.image
          ? sectionInfo.image?.file?.url || sectionInfo.image?.fluid?.src
          : null
      }
      text={
        sectionInfo.content
          ? sectionInfo.content.childMarkdownRemark.html
          : null
      }
      cardTitleColor={customTitleColor ? customTitleColor : colorScheme}
      color={colorScheme}
      bg={bg}
      renderCta={() => renderCta}
      renderRichContent={richContent}
      isTransparent={isTransparent}
      paddingOverride={inColumn}
      {...cardTitleProps}
    />
  );
};
