import React from 'react';
import {
  Card,
  GTM_EVENT,
  GTM_CATEGORY,
  GTM_LOCATION,
} from '@sygnia/components';
import { mapper } from '../common/utils';
import LinkGroup from './LinkGroup';
import { graphql } from 'gatsby';
import { CONTENT_TITLES } from '../common/constants';

const PRODUCT_CONTENT_MAP = {
  text: ['productSummary', 'content', 'childMarkdownRemark', 'html'],
  cardTitle: ['productSummary', 'title'],
  image: ['productSummary', 'image', 'fluid', 'src'],
};

export const ProductCardWrapper = props => {
  const { section } = props;

  let productLinks = [
    {
      label: CONTENT_TITLES.moreInfo,
      to: `/product/${section.slug}/`,
      meta: section.productSummary?.meta,
    },
  ];

  if (section.links) {
    productLinks = [...productLinks, section.links];
  }

  const renderCta = productLinks ? (
    <LinkGroup color={'black'} links={productLinks} />
  ) : null;

  return (
    <Card
      {...mapper(section, PRODUCT_CONTENT_MAP)}
      renderCta={() => renderCta}
    />
  );
};

ProductCardWrapper.defaultProps = {
  section: {},
  contentType: 'page',
};

export const ProductCardLayoutFields = graphql`
  fragment ProductCardLayoutFields on ContentfulProduct {
    title
    slug
    productSummary {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid {
          src
        }
      }
      meta {
        internal {
          content
        }
      }
    }
  }
`;

ProductCardWrapper.fragment = ProductCardLayoutFields;
