import React, { Component } from 'react';
import {
  Radio,
  Input,
  InputNumber,
  Checkbox,
  Alert,
  Select,
  TextArea,
  theme,
} from '@sygnia/components';
import MultiInput from './ui/MultiField';
import { Box } from 'rebass';
import styled from 'styled-components';

const StyledSpacer = styled(Box)`
  height: ${theme.space[4]}px;
`;

class FormBuilderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errors: [],
      sent: false,
      loading: false,
      form: {},
      input: '',
    };
  }

  formatValues = (values, state) => {
    let formatValues = {
      ...values,
    };
    const {
      form: { fields },
    } = this.props;

    fields.forEach(field => {
      if (field.validate === 'phone' && values[field.id]) {
        formatValues[field.id] = formatValues[field.id].replace(/[^0-9]+/g, '');
      } else if (field.validate === 'email' && values[field.id]) {
        formatValues[field.id] = formatValues[field.id].replace(/\s/g, '');
      } else if (field.validate === 'idnumber' && values[field.id]) {
        formatValues[field.id] = formatValues[field.id].replace(/[^0-9]+/g, '');
      }
    });

    return formatValues;
  };

  getErrors(id) {
    const { errors } = this.props;

    if (!errors) return null;

    const index = Object.keys(errors).indexOf(id);
    if (index > -1) {
      return errors[Object.keys(errors)[index]];
    }

    return null;
  }

  getGroupField(group) {
    return group.fields.map(field => {
      const shouldShow = this.getFieldConditional(field);

      if (shouldShow) return this.getField(field);

      return null;
    });
  }

  getField(field) {
    const { handleFieldChange, formData, formType } = this.props;
    const buttonColor = this.props.buttonColor;

    let fieldOptions = { ...field };

    if (field.own) {
      fieldOptions.onChange = field.onChange;
      fieldOptions.value = field.value || formData[field.name];
    } else if (handleFieldChange) {
      fieldOptions.onChange = e => {
        const inputValue = ['checkbox'].includes(e.target.type)
          ? e.target.checked
          : field.type === 'number'
          ? parseFloat(e.target.value)
          : e.target.value;
        const inputName = e.target.name;

        handleFieldChange(inputName, inputValue);
      };
      fieldOptions.value = formData[field.name];
    }

    switch (field.type) {
      case 'spacer':
        return <StyledSpacer />;
      case 'radio':
        return <Radio {...fieldOptions} error={this.getErrors(field.name)} />;
      case 'section':
      case 'group':
        return this.getGroupField(field, {});
      case 'multi':
        return (
          <MultiInput
            {...fieldOptions}
            handleFieldChange={handleFieldChange}
            value={formData[field.name]}
            getField={f => this.createField(f)}
            error={this.getErrors(field.name)}
          />
        );
      case 'number':
        return (
          <InputNumber
            handleFieldChange={handleFieldChange}
            value={formData[field.name]}
            {...fieldOptions}
            error={this.getErrors(field.name)}
            buttonColor={this.props.buttonColor}
          />
        );
      case 'component': //Use fieldDescription instead! Formwrapper handles it.
        return (
          <Alert mx={[1, 2]} my={[1, 2]} status="info" text={field.name} />
        );
      case 'checkbox':
        return (
          <Checkbox
            checked={!!formData[field.name]}
            onChange={e => {
              handleFieldChange(fieldOptions.name, field.value);
            }}
            {...fieldOptions}
            error={
              this.props.errors && this.props.errors[fieldOptions.name]
                ? this.props.errors[fieldOptions.name]
                : null
            }
          />
        );
      case 'select':
        const { errors } = this.props;

        return (
          <Select
            {...fieldOptions}
            key={fieldOptions.id}
            formType={formType}
            error={
              errors && errors[fieldOptions.name]
                ? errors[fieldOptions.name]
                : null
            }
          />
        );
      case 'textArea':
        return (
          <TextArea
            {...fieldOptions}
            formType={formType}
            error={this.getErrors(fieldOptions.id)}
            key={fieldOptions.id}
          />
        );
      default:
      case 'hidden':
      case 'email':
      case 'tel':
      case 'text':
      case 'date':
        return (
          <Input
            {...fieldOptions}
            formType={formType}
            error={this.getErrors(fieldOptions.name)}
            key={fieldOptions.id}
          />
        );
    }
  }

  getFieldConditional = field => {
    if (field.conditional) {
      const math_it_up = {
        is: (x, y) => {
          return x === y;
        },
        isnot: (x, y) => {
          return x !== y;
        },
        includes: (x, y) => {
          return y.includes(x);
        },
        interect: (x, y) => {
          var z = x.filter(function(val) {
            return y.split(',').indexOf(val) !== -1;
          });
          return z.length > 0;
        },
      };

      let currentValues = {};

      const { formData = {} } = this.props;
      currentValues = formData;

      const {
        conditionalLogic: { actionType, logic },
      } = field;

      let a;

      if (typeof currentValues[logic.field] === 'undefined') {
        a = false;
      } else {
        a = math_it_up[logic.match](currentValues[logic.field], logic.value);
      }

      if (a) {
        if (actionType === 'show') {
          return true;
        } else {
          return false;
        }
      } else {
        if (actionType === 'show') {
          return false;
        } else {
          return true;
        }
      }
    }

    return true;
  };

  createField(field) {
    const shouldShow = this.getFieldConditional(field);
    if (shouldShow) return this.getField(field);
    return null;
  }

  showField(field) {
    const shouldShow = this.getFieldConditional(field);
    if (shouldShow) {
      return true;
    }

    return false;
  }

  getUrlParams(search) {
    let hashes = search.slice(search.indexOf('?') + 1).split('&');
    let params = {};
    hashes.forEach(hash => {
      let [key, val] = hash.split('=');
      params[key] = decodeURIComponent(val);
    });

    return params;
  }

  onSubmit = async e => {};

  render() {
    const { form, wrapper, wrapperOptions = {} } = this.props;
    const Wrapper = wrapper ? wrapper : React.Fragment;

    return form.fields.map(field => (
      <Wrapper {...wrapperOptions} key={field.name}>
        {this.createField(field)}
      </Wrapper>
    ));
  }
}

FormBuilderContainer.defaultProps = {
  form: null,
  onSubmit: null,
  buttonClass: 'col-md-12',
};

export { FormBuilderContainer };
