import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, theme } from '@sygnia/components';

const AccordionWrapper = ({ tabs, color }) => {
  const [indexOpen, setOpen] = useState(false);

  return tabs.map((item, index) => {
    return (
      <Accordion
        onOpen={() => (indexOpen === index ? setOpen(null) : setOpen(index))}
        isOpen={indexOpen === index}
        title={item.title}
        borderColor={
          color === 'white'
            ? theme.colors.baseGrayLight
            : theme.colors.baseWhite
        }
      >
        {item.content}
      </Accordion>
    );
  });
};
AccordionWrapper.propTypes = {
  /** List of footer sections */
  tabs: PropTypes.array,
  /** Color combination */
  color: PropTypes.oneOf(Object.keys(theme.colors)),
};

AccordionWrapper.defaultProps = {
  tabs: [],
  color: 'white',
};

export default AccordionWrapper;
