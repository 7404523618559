import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LinkGroup, { BUTTON_TYPES } from '../../modules/LinkGroup';
import { Table, theme } from '@sygnia/components';
import {
  CONTENT_TITLES,
  GTM_CATEGORY,
  GTM_EVENT,
  GTM_LOCATION,
} from '../../common/constants';

export const HEADINGS = {
  _3m: '3 Months',
  _6m: '6 Months',
  _1y: '1 Year',
  _3y: '3 Years',
  _5y: '5 Years',
  _10y: '10 Years',
};

const StyledTable = styled(Table.Table)`
  @media (min-width: 990px) {
    overflow: auto;
  }
  @media (max-width: ${theme.breakpoints[2]}) {
    max-height: 900px;
    overflow: auto;
  }
`;

const StyledTh = styled(Table.Thead)`
  @media (min-width: 990px) {
    position: sticky;
    top: 72px;
    background-color: ${theme.colors.white};
    z-index: 1;
    width: 100%;
    padding-bottom: 50px !important;
  }
  @media (max-width: ${theme.breakpoints[2]}) {
    position: sticky;
    top: -1px;
    background-color: ${theme.colors.white};
    z-index: 1;
    width: 100%;
    padding-bottom: 50px !important;
  }
`;

const StyledTheader = styled(Table.Th)`
  @media (min-width: 990px) {
    padding-top: 16px !important;
  }
`;

const StyledTableWrapper = styled.div`
  @media (max-width: ${theme.breakpoints[2]}) {
    max-height: 370px;
    overflow: auto;
  }
`;

export const FundPerformanceTable = ({ hits, excludeColumns, showLink }) => {
  return (
    <StyledTableWrapper className="responsive-table">
      <StyledTable>
        <StyledTh>
          <Table.Tr>
            <StyledTheader>Fund</StyledTheader>
            <Table.Th></Table.Th>
            {Object.keys(HEADINGS)
              .filter(p => {
                return !excludeColumns.includes(p);
              })
              .map(p => {
                return (
                  <StyledTheader nowrap id="thNoWrap">
                    {HEADINGS[p]}
                  </StyledTheader>
                );
              })}
          </Table.Tr>
        </StyledTh>
        <tbody>
          {hits.map(fund => {
            const columns = [
              showLink && fund.slug ? (
                <Link to={`/fund/${fund.slug}`}>{fund.title}</Link>
              ) : (
                fund.title
              ),

              fund.factSheet ? (
                <LinkGroup
                  links={[
                    {
                      type: BUTTON_TYPES.DOWNLOAD,
                      as: 'a',
                      label: CONTENT_TITLES.factSheet,
                      external: true,
                      to: fund.factSheet.file.url,
                      gtmEventInfo: {
                        eventName: GTM_EVENT.FACT_SHEET_DOWNLOADED,
                        data: {
                          category: GTM_CATEGORY.DOCUMENT,
                          label: CONTENT_TITLES.factSheet,
                          location: GTM_LOCATION.CONTENT,
                        },
                      },
                    },
                  ]}
                />
              ) : (
                <span />
              ),
            ];

            Object.keys(HEADINGS)
              .filter(m => {
                return !excludeColumns.includes(m);
              })
              .forEach(p => {
                columns.push(
                  <span>
                    {fund.fundPerformance && fund.fundPerformance[p]
                      ? `${parseFloat(fund.fundPerformance[p]).toFixed(2)}%`
                      : '-'}
                  </span>,
                );
              });

            return (
              <>
                <Table.Tr>
                  {columns.map(c => (
                    <Table.Td>{c}</Table.Td>
                  ))}
                </Table.Tr>
              </>
            );
          })}
        </tbody>
      </StyledTable>
    </StyledTableWrapper>
  );
};

FundPerformanceTable.defaultProps = {
  marginTop: `${theme.space[2]}px`,
};
