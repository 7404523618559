import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../Text/Text';
import { theme } from '../../common/theme';

const getTypeScheme = {
  step: {
    size: 'body',
    fontStyle: 'normal',
  },
  group: {
    size: 'small',
    fontStyle: 'normal',
  },
  field: {
    size: 'tiny',
    fontStyle: 'italic',
  },
};

const getStatusScheme = {
  none: {
    color: theme.colors.baseBlack,
  },
  success: {
    color: theme.colors.statusSuccess,
  },
  info: {
    color: theme.colors.statusInfo,
  },
  warning: {
    color: theme.colors.statusWarning,
  },
  error: {
    color: theme.colors.statusError,
  },
};

const Description = props => {
  const { text, type, status } = props;

  return (
    <Text
      size={getTypeScheme[type].size}
      fontStyle={getTypeScheme[type].fontStyle}
      color={getStatusScheme[status].color}
      html={text}
      {...props}
    />
  );
};

export default Description;

Description.propTypes = {
  /** Text as content */
  text: PropTypes.string,
  /** Type of the Description */
  type: PropTypes.oneOf(Object.keys(getTypeScheme)).isRequired,
  /** Status of the Description */
  status: PropTypes.oneOf(Object.keys(getStatusScheme)),
};

Description.defaultProps = {
  text: null,
  type: 'step',
  status: 'none',
};
