import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import LinkPrimary from '../Menu/LinkPrimary';
import Text from '../Text/Text';
import { theme } from '../common/theme';

export const MenuCard = ({
  titleColor,
  isHovered,
  title,
  content,
  spacing,
  link,
}) => (
  <Box {...spacing}>
    <LinkPrimary color={titleColor} isHovered={isHovered} link={link}>
      {title}
    </LinkPrimary>
    <Text
      size="body"
      className="link-primary-description"
      mt={[0, 0, 1]}
      html={content}
    />
  </Box>
);

MenuCard.propTypes = {
  /** Color of the title text */
  titleColor: PropTypes.oneOf(Object.keys(theme.colors)),
  /** Hovered color */
  isHovered: PropTypes.oneOf(Object.keys(theme.colors)),
  /** The title of the card */
  title: PropTypes.string,
  /** The content to display underneath the title */
  content: PropTypes.string,
  /** The spacings to use on the Box container */
  spacing: PropTypes.object,
  /** Link information  */
  link: PropTypes.object,
};

MenuCard.defaultProps = {
  titleColor: 'baseBlack',
  isHovered: 'baseGray',
  title: '',
  content: '',
  spacing: {},
};

export default MenuCard;
