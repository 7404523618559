import React, { useState } from 'react';
import BarGraph from '../graphs/BarGraph';
import { FinancialSavingsModel } from './FinancialModel';
import {
  Container,
  Heading,
  ShowAt,
  theme,
  Row,
  Column,
} from '@sygnia/components';
import CurrencyFormat from 'react-currency-format';
import { Flex, Box } from 'rebass';
import { useEffect } from 'react';
import { createRef } from 'react';
import InputRange from 'react-input-range';
import { useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import Chartist from 'chartist';
import { BasicContent } from '../../modules/ContentWrapper';
import { graphql } from 'gatsby';
import styled from 'styled-components';

const StyledHeaderWrapper = styled(Container)`
  @media only screen and (min-width: 160px) and (max-width: ${theme
      .breakpoints[1]}) {
    width: 100%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[1]}) and (max-width: ${theme.breakpoints[2]}) {
    width: 76%;
  }
  @media only screen and (min-width: ${theme
      .breakpoints[2]}) and (max-width: ${theme.breakpoints[3]}) {
    width: 65%;
  }
  @media only screen and (min-width: ${theme.breakpoints[3]}) {
    width: 45%;
  }
`;

const SavingsGraph = ({ section }) => {
  const page = useStaticQuery(graphql`
    query pageArchiveSavings {
      contentfulContentGeneric(
        contentful_id: { eq: "3hYMHZVRLv7cYtjBHiNKLk" }
      ) {
        ...ContentGenericFields
      }
    }
  `);

  const { title, ...restPage } = get(page, 'contentfulContentGeneric');

  const [timeHorizon, setTimehorizon] = useState(15);
  const chartRef = createRef();
  const model = new FinancialSavingsModel(36000, 3000, 0.07, 0.45, timeHorizon);
  const { labels, series } = model.buildSeries(timeHorizon);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chartist.update({ labels, series });
    }
  }, [timeHorizon]);

  const StyledHeading = styled(Heading)`
    @media (max-width: 768px) {
      font-size: 17px;
    }
    font-size: 30px;
  `;

  const StyledHeadline = styled(Heading)`
    @media (max-width: 768px) {
      font-size: 26px;
    }
    font-size: 30px;
  `;

  return (
    <>
      {section.headline && (
        <StyledHeaderWrapper>
          <Row>
            <Column>
              {section.headline && (
                <StyledHeadline size="h3" mb={[2, 4]} textAlign="left">
                  {section.headline}
                </StyledHeadline>
              )}
            </Column>
          </Row>
        </StyledHeaderWrapper>
      )}
      <Flex
        flexWrap={['wrap', 'wrap', 'wrap', 'unset']}
        css={{ border: '1px solid #ddd' }}
      >
        <Box width={[1]}>
          <Box py={[6, 6]} px={[5, 8]}>
            <StyledHeading size="h3" mb={[2, 4]}>
              {title}
            </StyledHeading>
            <Heading size="h6" mb={[4]}>
              Investment term length
            </Heading>
            <InputRange
              maxValue={15}
              minValue={0}
              formatLabel={value => {
                if (value === 0) {
                  return 'Today';
                }
                return `${value} ${value > 1 ? 'years' : 'year'}`;
              }}
              value={timeHorizon}
              onChange={value => setTimehorizon(value)}
            />
            <ShowAt breakpoint="large">
              <Box
                mt={[4, 6]}
                css={`
                  p > i {
                    font-size: 12px;
                    font-style: normal;
                  }
                `}
              >
                <BasicContent section={restPage} />
              </Box>
            </ShowAt>
          </Box>
        </Box>
        <Box width={[1]}>
          <BarGraph
            color="green"
            ref={chartRef}
            height="100%"
            graphClass="savings-graph"
            xAxis={labels}
            series={series}
            options={{
              axisX: { showGrid: 0 },
              axisY: {
                high: 1e6,
                showLabel: 0,
                showGrid: 0,
                scaleMinSpace: 15,
              },
              chartPadding: {
                top: 50,
                right: 15,
                bottom: 15,
                left: 0,
              },
            }}
            listener={{
              draw: function(data) {
                var options = {
                  labelClass: 'ct-bar-label',
                  labelInterpolationFnc: data => {
                    return `R ${data
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
                  },
                  labelOffset: {
                    x: 0,
                    y: 30,
                  },
                  position: {
                    x: null,
                    y: null,
                  },
                };
                var positionX =
                  options.position.x ||
                  function(data) {
                    return (data.x1 + data.x2) / 2 + options.labelOffset.x;
                  };

                var positionY =
                  options.position.y ||
                  function(data) {
                    return data.y2 - options.labelOffset.y;
                  };
                if (data.type === 'bar') {
                  data.group
                    .elem(
                      'text',
                      {
                        x: positionX(data),
                        y: positionY(data),
                        style: 'text-anchor: middle',
                      },
                      `${options.labelClass} ${options.labelClass}-${data.index}`,
                    )
                    .text(
                      options.labelInterpolationFnc(
                        data.value.x || data.value.y,
                      ),
                    );
                }
              },
            }}
          />
        </Box>
      </Flex>
      <ShowAt breakpoint="mediumAndBelow">
        <Box
          mt={[6]}
          css={`
            p > i {
              font-size: 12px;
              font-style: normal;
              line-height: 1rem;
            }
          `}
        >
          <BasicContent section={restPage} />
        </Box>
      </ShowAt>
    </>
  );
};

export default SavingsGraph;
