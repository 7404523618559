import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import LinkGroup, { BUTTON_TYPES } from '../../modules/LinkGroup';
import {
  CONTENT_TITLES,
  DOWNLOAD_AGREEMENT_TEXT,
} from '../../common/constants';
import { ResourceSetContext } from '../../context/ResourceSetContext';
import { Text } from '@sygnia/components';
import { Flex } from 'rebass';

const ETF_FUNDS_QUERY = gql`
  query getEtfDocuments($id: String!) {
    etf(id: $id) {
      documents {
        url
        type
      }
    }
  }
`;

const FundETFDocuments = props => {
  const context = useContext(ResourceSetContext);
  const fundsRS = context && context.getResourcesForComponent('funds');

  const { loading, error, data } = useQuery(ETF_FUNDS_QUERY, {
    variables: {
      id: props.fund.fundId,
    },
  });
  if (loading) return null;

  const showDownloads =
    (data && data.etf && data.etf.documents.length) ||
    props.fund ||
    (props.fund &&
      (props.fund.pricingMemorandum || props.fund.latestFinancials));

  if (showDownloads)
    return (
      <>
        <Flex>
          <Text mb={[1]} fontWeight="bold" display="inline-block">
            Downloads
            {props.fund && props.fund.hasDownloadAgreement && (
              <Text display="inline-block"> *</Text>
            )}
          </Text>
        </Flex>

        {data && data.etf && data.etf.documents.length
          ? data.etf.documents.map(doc => (
              <LinkGroup
                key={doc.url}
                links={[
                  {
                    type: BUTTON_TYPES.DOWNLOAD,
                    as: 'a',
                    label: CONTENT_TITLES[doc.type] || `Download ${doc.type}`,
                    external: true,
                    to: doc.url,
                  },
                ]}
              />
            ))
          : null}
        {props.fund && props.fund.pricingMemorandum ? (
          <LinkGroup
            key={'pricingMemo'}
            links={[
              {
                type: BUTTON_TYPES.DOWNLOAD,
                as: 'a',
                label: 'PRICING MEMORANDUM',
                external: false,
                to: props.fund.pricingMemorandum.file.url,
              },
            ]}
          />
        ) : null}
        {props.fund && props.fund.latestFinancials ? (
          <LinkGroup
            key={'latestAFS'}
            links={[
              {
                type: BUTTON_TYPES.DOWNLOAD,
                as: 'a',
                label: 'LATEST FINANCIALS',
                external: false,
                to: props.fund.latestFinancials.file.url,
              },
            ]}
          />
        ) : null}
        {data &&
          data.etf &&
          data.etf.documents.length &&
          props.fund &&
          props.fund.hasDownloadAgreement && (
            <Text size="small" fontStyle="italic" my={[1]}>
              {(fundsRS && fundsRS.downloadLicenceAgreement) ||
                DOWNLOAD_AGREEMENT_TEXT}
            </Text>
          )}
      </>
    );

  return null;
};

export default FundETFDocuments;
