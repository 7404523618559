import React from 'react';
import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';
import Text from '../Text/Text';
import { theme } from '../common/theme';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const StyledImageWrapper = styled(Box)`
  max-width: 80px;
  max-height: 80px;
  margin-right: 16px;
`;

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  align-items: stretch;
  max-width: 1220px;
  margin: 0 auto;
`;

const StyledImageContent = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledText = styled(Text)`
  p {
    font-size: ${theme.fontSizes.small};
    font-family: ${theme.fonts.sans};
    line-height: 22px;
    color: ${theme.colors.baseBlack};
    font-weight: ${theme.fontWeight.lightBold};
  }
`;
const Bio = ({ image, fullname, description, role }) => {
  return (
    <StyledWrapper mb={[10, 10]}>
      <StyledImageContent mb={[3, 0]}>
        <StyledImageWrapper>
          <img src={image} alt={fullname} />
        </StyledImageWrapper>
        <Box mt={[3, 2]}>
          <Text size="h4" fontWeight={theme.fontWeight.bold}>
            {fullname}
          </Text>
          <Text size="h4" my={[0.5]} fontFamily={theme.fonts.dmSans}>
            {role}
          </Text>
        </Box>
      </StyledImageContent>
      <StyledText pl={[0, 2]} width={[1, 10 / 12]} ml={[0, 10]} mt={[1, 1]}>
        {documentToReactComponents(description)}
      </StyledText>
    </StyledWrapper>
  );
};

export default Bio;

Bio.propTypes = {
  /** Bio image */
  image: PropTypes.string,
  /** Bio name */
  fullname: PropTypes.string,
  /** Bio description */
  description: PropTypes.string,
  /** Bio role */
  role: PropTypes.string,
};
