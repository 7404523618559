import React from 'react';
import { HeroLanding, Share, theme } from '@sygnia/components';
import LinkGroup from './LinkGroup';
import { mapperWithFunction } from '../common/utils';
import { get } from 'lodash';

const CONTENT_MAP = {
  headline: ['headline'],
  color: ['color'],
  eyeBrow: ['eyeBrow'],
  image: ['image', 'fluid', 'src'],
  images: value => {
    return {
      small: get(value.small, 'fluid.src'),
      medium: get(value.medium, 'fluid.src'),
      large: get(value.large, 'fluid.src'),
      xlarge: get(value.xlarge, 'fluid.src'),
    };
  },
  description: ['description', 'childMarkdownRemark', 'html'],
  sdDescription: ['sdDescription'],
  pageUrl: ['url'],
  origin: ['origin'],
};

export const HeroLandingWrapper = props => {
  const mappedOptions = mapperWithFunction(props.section, CONTENT_MAP);

  const renderCta = () =>
    props.section.heroLinks && props.section.heroLinks.length ? (
      <LinkGroup
        color={theme.colors.baseBlack}
        links={props.section.heroLinks || []}
      />
    ) : null;

  return <HeroLanding renderCta={renderCta} {...mappedOptions} />;
};

HeroLandingWrapper.defaultProps = {
  section: null,
};
