import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card as RebassCard, Box, Flex } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';
import { theme } from '../common/theme';

const StyledCard = styled(Box)`
  background-color: ${({ isTransparent }) =>
    isTransparent ? 'transparent' : theme.colors.baseWhite};
`;

const Card = props => {
  const {
    cardTitle,
    text,
    renderCta,
    renderRichContent,
    image,
    color,
    paddingOverride,
    cardTitleSize,
    cardTitleColor,
  } = props;

  return (
    <StyledCard mb={[2, 2, 0]} {...props} className={'yyy'}>
      {image && (
        <RebassCard
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundImage={`url(${image})`}
          backgroundRepeat="no-repeat"
          pb={'56.25%'}
        />
      )}
      <Box
        px={!paddingOverride ? [3, 4] : [0]}
        pt={[3, 4]}
        pb={[4, 5]}
        style={{ maxWidth: '100%' }}
      >
        {cardTitle && (
          <Heading
            size={cardTitleSize}
            color={cardTitleColor ? cardTitleColor : color}
            mb={[1, 2]}
            lineHeight="1.55"
          >
            {cardTitle}
          </Heading>
        )}

        {text || renderCta ? (
          <Box mt={[1, 2]}>
            {text && (
              <Text size="body" lineHeight={'1.5'} mb={[3, 5]} html={text} />
            )}
            {renderRichContent && renderRichContent}
            {renderCta && (
              <Flex
                mt={[2, 3]}
                justifyContent={'flex-start'}
                alignItems={['flex-start', 'center']}
                flexWrap={'wrap'}
                flexDirection={['column', 'row']}
              >
                {renderCta(color)}
              </Flex>
            )}
          </Box>
        ) : null}
      </Box>
    </StyledCard>
  );
};

export default Card;

Card.propTypes = {
  /**  Is background transparent */
  isTransparent: PropTypes.bool,
  /**  Title of the Card */
  cardTitle: PropTypes.string.isRequired,
  /** Text as content */
  text: PropTypes.string,
  /** Size of title */
  cardTitleSize: PropTypes.string,
  // TODO: Decide if CTA's are render props or static to card type
  /** Function for Calls to action */
  renderCta: PropTypes.func,
  /** Content to be rendered */
  renderRichContent: PropTypes.node,
  /** Image URL to be used as background image */
  image: PropTypes.string,
  /** Color as prop. Will set the button color*/
  color: PropTypes.oneOf([
    'black',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]).isRequired,
  /** Padding override */
  paddingOverride: PropTypes.bool,
  /** Color override for card title color **/
  cardTitleColor: PropTypes.oneOf([
    'black',
    'green',
    'purple',
    'violet',
    'red',
    'yellow',
    'navy',
  ]),
};

Card.defaultProps = {
  cardTitleSize: 'h3',
  isTransparent: false,
  title: null,
  text: null,
  color: 'black',
  renderCta: null,
  renderRichContent: null,
  image: null,
  paddingOverride: false,
};
