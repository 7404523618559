import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import Text from '../Text/Text';
import Heading from '../Heading/Heading';
import { theme } from '../common/theme';

const StyledBox = styled(Box)`
  padding-left: ${({ theme }) => theme.space[2]}px;
  border-left: ${({ theme, color }) =>
    `${theme.space[1]}px solid ${theme.colors[color]}`};
`;

const FeesLabel = ({ labelText, value, color }) => (
  <StyledBox color={color}>
    <Text size="small">{labelText}</Text>
    <Flex justifyContent="space-between">
      <Heading size="h3">R</Heading>
      <Heading size="h3">{value}</Heading>
    </Flex>
  </StyledBox>
);

FeesLabel.propTypes = {
  labelText: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(theme.colors)),
};

FeesLabel.defaultProps = {
  labelText: '',
  value: '',
  color: 'baseBlack',
};

export default FeesLabel;
