import React, { useState } from 'react';
import { RetirementAgeFinancialModel } from './RetirementAgeFinancialModel';
import { Text } from '@sygnia/components';
import { graphql } from 'gatsby';
import { FormBuilderContainer } from '../forms/FormBuilder';
import { Container, Heading, ShowAt } from '@sygnia/components';
import { Flex, Box } from 'rebass';
import InputRange from 'react-input-range';
import { useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import { BasicContent } from '../../modules/ContentWrapper';

export const form = {
  title: "Let's work together",
  label: 'What would you like to do?',
  description:
    'Not sure which one is more important? It’s easier to relax and enjoy life if you know your future is secure. So start with retirement planning as a way to build a solid foundation for your financial plan.',
  fields: [
    {
      name: 'age',
      type: 'input',
      label: 'Current Age',
      required: true,
    },
    {
      name: 'salary',
      type: 'input',
      prefixText: 'R',
      label: 'Current Monthly Salary (Rand, before tax)',
      description: '(Rand, after tax)',
      required: true,
    },
    {
      name: 'savings',
      type: 'input',
      prefixText: 'R',
      label: 'Existing Savings',
      required: true,
    },
    {
      name: 'contribution',
      type: 'input',
      label: 'Pension Contribution as % Salary',
      required: true,
    },
    {
      name: 'additional',
      type: 'input',
      prefixText: 'R',
      label: 'Additional monthly savings',
      description: '(Rand, after tax)',
      required: true,
    },
    {
      name: 'retirement',
      type: 'input',
      prefixText: 'R',
      label: 'Required Retirement Monthly Salary',
      description: '(Rand, after tax)',
      required: true,
    },
  ],
};

export const RetirementAgeCalculator = props => {
  const [formData, setForm] = useState({
    age: 35,
    salary: 40000,
    additional: 0,
    retirement: 40000,
    contribution: 15,
    savings: 0,
  });

  const model = new RetirementAgeFinancialModel(
    formData.salary,
    formData.retirement,
    formData.additional,
    formData.contribution / 100,
  );

  const retirementAge = model.buildSeries(formData.age || 1, formData.savings);

  const onFieldChange = (name, value) => {
    setForm({
      ...formData,
      [name]: parseInt(value) || 0,
    });
  };

  const page = useStaticQuery(graphql`
    query pageArchiveRetirement {
      contentfulContentGeneric(
        contentful_id: { eq: "1TkLLD7Q5p4Plj5FZnVkqj" }
      ) {
        ...ContentGenericFields
      }
    }
  `);

  const { title, ...restPage } = get(page, 'contentfulContentGeneric');

  return (
    <Container>
      <Flex
        flexWrap={['wrap', 'wrap', 'wrap', 'unset']}
        css={{ border: '1px solid #ddd' }}
      >
        <Box py={[6, 6]} px={[6, 8]} width={[1]}>
          <>
            <FormBuilderContainer
              form={form}
              formData={formData}
              handleFieldChange={onFieldChange}
              formData={formData}
            />
            <hr />
            <Heading size="h3" py={[2]}>
              Resulting Earliest Retirement Age: {retirementAge}
            </Heading>
            <hr />
          </>
        </Box>
        <Box bg="baseGrayVeryLight" py={[6, 6]} px={[6, 8]} width={[1]}>
          <Box
            mt={[4, 6]}
            css={`
              p > i {
                font-size: 12px;
                font-style: normal;
              }
            `}
          >
            <BasicContent section={restPage} />
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};
