import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import Text from '../Text/Text';
import { borders } from 'styled-system';
import { theme } from '../common/theme';

const StyledItem = styled(Box)`
  ${borders};
  display: flex;
  align-items: center;
  position: relative;
  /* Reset anchor tag defaults */
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledIcon = styled.i`
  ${borders};
  display: block;
  transition: all ${theme.speed.default} ease;
  margin-left: ${theme.space[2]}px;
  opacity: 1;

  @media (min-width: ${theme.breakpoints[1]}) {
    opacity: 0;
    margin-left: ${theme.space[1]}px;

    ${StyledItem}:hover & {
      margin-left: ${theme.space[2]}px;
      opacity: 1;
    }
  }
`;

/** The single ListLink will be rendered as part of the list of links <ListLinks/> component */
export const ListLink = props => {
  const { title, borderColor, bg, ...link } = props;

  return (
    <StyledItem
      py={[2]}
      width={'100%'}
      borderBottom={'1px solid'}
      borderColor={
        borderColor
          ? borderColor
          : bg === 'white'
          ? 'baseGrayLight'
          : 'baseWhite'
      }
      {...link}
    >
      <Flex>
        <Text size={'body'} lineHeight="1">
          {title}
        </Text>
        <StyledIcon className="icon icon-arrow-right" />
      </Flex>
    </StyledItem>
  );
};

ListLink.propTypes = {
  /** Label of the link */
  title: PropTypes.string.isRequired,
  /** If linking to a Page in the app, the "to" prop is required to render a <Link /> component */
  to: PropTypes.string,
  /** If linking to an external url, the "href" prop is required to render a <a/> tag */
  href: PropTypes.string,
  // TODO: select colors from theme.js
  bg: PropTypes.string,
  /** override borderColor */
  borderColor: PropTypes.string,
};

ListLink.defaultProps = {
  title: null,
  to: null,
  href: null,
  bg: 'white',
};
