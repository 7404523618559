import { STATIC_LINKS } from '../../common/constants';

export const form_index = form => {
  const forms = {
    formIntro: formIntro,
    sections: sections,
    formRetirement: formRetirement,
    formGoal: formGoal,
    open_account: open_account,
    contact: contact,
    stargazer: stargazer,
  };

  return forms[form];
};

export const formIntro = {
  title: "Let's work together",
  label: 'What would you like to do?',
  description:
    'Not sure which one is more important? It’s easier to relax and enjoy life if you know your future is secure. So start with retirement planning as a way to build a solid foundation for your financial plan.',
  fields: [
    {
      type: 'section',
      fields: [
        {
          name: 'introOptions',
          type: 'radio',
          label: 'I would like to',
          required: true,
          options: [
            {
              value: 'retirement',
              label: 'Save for retirement',
            },
            {
              value: 'goal',
              label: 'Save for a specific goal',
            },
          ],
        },
        {
          name: 'terms',
          type: 'checkbox',
          required: true,
          label: STATIC_LINKS.termsLink,
        },
      ],
    },
  ],
};

const sections = {
  age: {
    type: 'section',
    label: 'How old are you?',
    description:
      'This helps us to determine your risk profile. And don’t worry – we never share this information with anyone.',
    fields: [
      {
        name: 'personAge',
        type: 'number',
        label: 'Your age',
        defaultValue: 30,
      },
    ],
  },
  income: {
    type: 'section',
    label: 'What is your gross income?',
    description:
      'Your gross income is your salary before any taxes are taken out. Other sources of income can add to your gross income, too, including interest on accounts, dividend payouts, rental income, alimony, secondary or freelance wages, pension, tips or the online sale of personal goods.',
    fields: [
      {
        name: 'income',
        type: 'text',
        prefixText: 'R',
        label: 'Amount',
        required: true,
        inputmode: 'numeric',
        pattern: '[0-9]*',
      },
    ],
  },
  expenses: {
    type: 'section',
    label: 'What are your total expenses?',
    description:
      'When estimating your monthly living expenses exclude any tax, retirement or other savings contributions, or any mortgage bond-related and children’s education-related payments. These are large expenditure items and require special consideration.',
    fields: [
      {
        name: 'expenses',
        type: 'text',
        prefixText: 'R',
        required: true,
        label: 'Amount',
        description:
          'Exclude bond repayments and children‘s education expenses',
        inputmode: 'numeric',
        pattern: '[0-9]*',
      },
    ],
  },
  priority: {
    type: 'section',
    label: 'When investing, what is your priority?',
    description:
      'A higher risk profile will result in a higher allocation to growth assets such as South African and offshore equities and listed property. Although growth assets are likely to deliver the highest real returns over the long term, they may experience periods of high monthly volatility and prolonged periods of negative returns. We determine your actual risk profile by combining this information with your age, income bracket and investment time horizon. You can override our recommended risk profile later.',
    fields: [
      {
        name: 'investingProfile',
        type: 'radio',
        label: 'I want to',
        required: true,
        options: [
          {
            value: 1,
            label: 'Protect capital',
            description:
              'Avoid the risk of capital loss while generating a steady, if modest, income.',
          },
          {
            value: 2,
            label: 'Earn an income',
            description:
              'Avoid capital loss but will accept some negative months’ returns in order to increase my income and capital gains.',
          },
          {
            value: 3,
            label: 'Get some upside for taking some risk',
            description:
              'Place equal weight on generating strong returns over the long term and managing my risk of capital loss over the short to medium term.',
          },
          {
            value: 4,
            label: 'Get strong returns and manage risk',
            description:
              'Prioritise growth over the long term and will accept a significant risk of capital loss over the short to medium term, but want to manage risk via the diversification of investments.',
          },
          {
            value: 5,
            label: 'Prioritise growth but manage risk',
            description:
              'Proritise maximum gains over the long term and will accept a high risk of capital loss over the short term.',
          },
          {
            value: 6,
            label: 'Prioritise growth',
            description:
              'Prioritise maximum gains over the long term and will accept a high risk of capital loss over the short to medium term.',
          },
        ],
      },
    ],
  },
  lumpSum: {
    type: 'section',
    label: 'Do you have a lump sum to invest?',
    fields: [
      {
        name: 'hasLumpSum',
        type: 'radio',
        required: true,
        options: [
          {
            value: 'yes',
            label: 'Yes',
          },
          {
            value: 'no',
            label: 'No',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            label: 'Amount',
            name: 'lumpSumAmount',
            type: 'text',
            conditional: true,
            conditionalLogic: {
              actionType: 'show',
              logic: {
                field: 'hasLumpSum',
                match: 'is',
                value: 'yes',
              },
            },
            prefixText: 'R',
            required: true,
            inputmode: 'numeric',
            pattern: '[0-9]*',
            validate: value => {
              return {
                check: value >= 1e4,
                message: 'Lump sum contribution must be larger than R10 000',
              };
            },
          },
        ],
      },
    ],
  },
  contributeMonthly: {
    type: 'section',
    label: 'Will you contribute monthly?',
    fields: [
      {
        name: 'contributeMonthly',
        type: 'radio',
        required: true,
        options: [
          {
            value: 'yes',
            label: 'Yes',
          },
          {
            value: 'no',
            label: 'No',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            label: 'Amount',
            name: 'contributionAmount',
            type: 'text',
            conditional: true,
            conditionalLogic: {
              actionType: 'show',
              logic: {
                field: 'contributeMonthly',
                match: 'is',
                value: 'yes',
              },
            },
            prefixText: 'R',
            required: true,
            inputmode: 'numeric',
            pattern: '[0-9]*',
          },
        ],
      },
    ],
  },
};

export const formRetirement = {
  title: 'Save for retirement',
  content: 'Retirement Annuity.',
  initConfig: {
    personAge: 30,
    personRetirementAge: 65,
    hasLumpSum: 'no',
    contributeMonthly: 'no',
  },
  fields: [
    sections.age,
    {
      type: 'section',
      label: 'At what age would you like to retire?',
      description: 'Most South Africans expect to retire at age 65.',
      fields: [
        {
          label: 'Retirement age',
          name: 'personRetirementAge',
          type: 'number',
          defaultValue: 65,
        },
      ],
    },
    sections.income,
    {
      type: 'section',
      label: 'What is the total value of your cash and investments?',
      description:
        'Your retirement savings include your employer-sponsored retirement fund and any retirement annuities or preservation fund savings you might have.',
      fields: [
        {
          name: 'cashInvestments',
          type: 'text',
          prefixText: 'R',
          required: true,
          label: 'Amount',
          inputmode: 'numeric',
          pattern: '[0-9]*',
        },
      ],
    },
    sections.expenses,
    sections.lumpSum,
    sections.contributeMonthly,
    sections.priority,
  ],
};

export const formGoal = {
  title: 'Save for a specific goal',
  content: 'Direct Investment Plan',
  initConfig: {
    personAge: 30,
    hasLumpSum: 'no',
    contributeMonthly: 'no',
    timehorizon: 10,
  },
  fields: [
    {
      type: 'section',
      label: 'What is that goal?',
      fields: [
        {
          name: 'whatGoal',
          type: 'radio',
          required: true,
          options: [
            {
              value: 'Property',
              label: 'A home',
            },
            {
              value: 'Child',
              label: 'An education',
            },
            {
              value: 'Holiday',
              label: 'Travel',
            },
            {
              value: 'General',
              label: 'Emergency fund',
            },
            {
              value: 'General',
              label: 'Other',
            },
          ],
        },
      ],
    },
    {
      type: 'section',
      label: 'How much will you need?',
      fields: [
        {
          name: 'goalAmount',
          type: 'text',
          prefixText: 'R',
          label: 'Amount',
          required: true,
          inputmode: 'numeric',
          pattern: '[0-9]*',
        },
      ],
    },
    {
      type: 'section',
      label: 'How long would you like to invest for?',
      fields: [
        {
          name: 'timehorizon',
          type: 'number',
          label: 'Years',
          defaultValue: 30,
        },
      ],
    },
    sections.lumpSum,
    sections.contributeMonthly,
    sections.age,
    sections.income,
    sections.expenses,
    sections.priority,
  ],
};

export const saveInvestment = {
  title: 'Plan your future',
  label: 'Save your investment plan.',
  buttonLabel: 'Save and apply',
  events: [],
  description:
    'Lorem ipsum faucibus mollis interdum. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus mangna, vel scelerisque nisl.',
  fields: [
    {
      defaultvalue: '',
      name: 'name',
      type: 'text',
      required: true,
      label: 'First name',
    },
    {
      defaultvalue: '',
      name: 'surname',
      type: 'text',
      required: true,
      label: 'Last name',
    },
    {
      defaultvalue: '',
      name: 'email',
      type: 'email',
      required: true,
      label: 'Your email address',
    },
    {
      defaultvalue: '',
      name: 'remember',
      type: 'checkbox',
      required: false,
      label: 'Remember me on the computer',
    },
  ],
};

export const getFieldConditional = (field, currentValues = {}) => {
  if (field.conditional) {
    const math_it_up = {
      is: (x, y) => {
        return x === y;
      },
      isnot: (x, y) => {
        return x !== y;
      },
      includes: (x, y) => {
        return x.includes(y);
      },
      interect: (x, y) => {
        var z = x.filter(function(val) {
          return y.split(',').indexOf(val) !== -1;
        });
        return z.length > 0;
      },
    };

    const {
      conditionalLogic: { actionType, logic },
    } = field;

    let a;

    if (typeof currentValues[logic.field] === 'undefined') {
      a = false;
    } else {
      a = math_it_up[logic.match](currentValues[logic.field], logic.value);
    }

    if (a) {
      if (actionType === 'show') {
        return true;
      } else {
        return false;
      }
    } else {
      if (actionType === 'show') {
        return false;
      } else {
        return true;
      }
    }
  }

  return true;
};

export const open_account = {
  id: 'open_account',
  name: 'open_account',
  logiform_id: 'open_account',
  formType: 'default',
  buttonLabel: 'Submit',
  title: "Let's get started",
  fields: [
    {
      defaultvalue: '',
      name: 'name',
      type: 'text',
      required: true,
      label: 'Name',
    },
    {
      defaultvalue: '',
      name: 'surname',
      type: 'text',
      required: true,
      label: 'Surname',
    },
    {
      defaultvalue: '',
      name: 'email',
      type: 'email',
      required: true,
      label: 'Email address',
    },
    {
      name: 'account',
      type: 'select',
      required: true,
      label: 'What kind of account would you like to open?',
      placeholder: 'Please select',
      items: [
        { value: 'direct_investment', label: 'Sygnia Direct investment' },
        { value: 'tax_free', label: 'Sygnia Tax Free Savings Account' },
        { value: 'retirement_annuity', label: 'Sygnia Retirement Annuity' },
        { value: 'living_annuity', label: 'Sygnia Living Annuity' },
        { value: 'for_life', label: 'Sygnia ForLife' },
        { value: 'investment_policy', label: 'Sygnia Investment Policy' },
        { value: 'preservation_find', label: 'Sygnia Preservation Fund' },
        { value: 'other', label: 'Other' },
      ],
    },
    {
      name: 'terms',
      type: 'checkbox',
      required: true,
      label: STATIC_LINKS.termsLink,
    },
    {
      name: 'signUp',
      type: 'checkbox',
      required: false,
      label:
        'Sign me up for emails on new products, launches, investor news and events. Unsubscribe anytime.',
    },
  ],
};

export const contact = {
  id: 'contact',
  name: 'contact',
  formType: 'modern',
  logiform_id: 'contact',
  buttonLabel: 'Submit',
  title: 'Get in touch',
  fields: [
    {
      type: 'spacer',
    },
    {
      name: 'title',
      type: 'select',
      required: true,
      label: 'Title',
      placeholder: 'Please select',
      items: [
        { value: 'mr', label: 'Mr' },
        { value: 'mrs', label: 'Mrs' },
        { value: 'miss', label: 'Miss' },
        { value: 'ms', label: 'Ms' },
      ],
    },
    {
      name: 'name',
      type: 'text',
      required: true,
      label: 'Name',
      // placeholder: 'Please enter your name'
    },
    {
      name: 'surname',
      type: 'text',
      required: true,
      label: 'Surname',
      // placeholder: 'Please enter your surname'
    },
    {
      name: 'company',
      type: 'text',
      required: true,
      label: 'Company',
    },
    {
      name: 'email',
      type: 'email',
      required: true,
      label: 'Email',
    },
    {
      name: 'phone',
      type: 'text',
      required: true,
      label: 'Phone number',
    },
    {
      name: 'message',
      type: 'textArea',
      required: false,
      label: 'Message',
      placeholder: 'Please enter your message',
    },
    {
      defaultvalue: '',
      name: 'signUp',
      type: 'checkbox',
      required: false,
      label:
        "I'd like to receive the most recent information about updates, industry news, sales and events.",
    },
    {
      name: 'terms',
      type: 'checkbox',
      required: true,
      label: STATIC_LINKS.termsLink,
    },
  ],
};

export const stargazer = {
  id: 'stargazer',
  name: 'stargazer',
  formType: 'modern',
  logiform_id: 'stargazer',
  buttonLabel: 'Sign me up',
  title: 'Get in touch',
  fields: [
    {
      type: 'spacer',
    },
    {
      name: 'fullName',
      type: 'text',
      required: true,
      label: 'Full name',
    },
    {
      name: 'email',
      type: 'email',
      required: true,
      label: 'Email address',
    },
    {
      name: 'terms',
      type: 'checkbox',
      required: true,
      label: STATIC_LINKS.newsletterLink,
    },
  ],
};
