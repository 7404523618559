import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from '../Grid';
import GuideBreadcrumb from './GuideBreadcrumb';

const StyledBreadcrumbs = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: no-wrap;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const GuideBreadcrumbs = ({ items }) => {
  return (
    <Container mx={0} py={[1, 2]} px={[0, 0]} width={[1, 1, 1]}>
      <StyledBreadcrumbs>
        {items.map((link, index, arr) => (
          <GuideBreadcrumb
            {...link}
            key={index}
            isLast={arr.length - 1 === index ? true : false}
            isFirst={index === 0 ? true : false}
          />
        ))}
      </StyledBreadcrumbs>
    </Container>
  );
};

export default GuideBreadcrumbs;

GuideBreadcrumbs.propTypes = {
  /** Breadcrumb Items */
  items: PropTypes.array.isRequired,
};

GuideBreadcrumbs.defaultProps = {
  items: null,
};
