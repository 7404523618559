import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import Heading from '../Heading/Heading';
import styled from 'styled-components';
import { theme } from '../common/theme';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share';

const StyledShareWrapper = styled(Box)`
  max-width: ${props => (props.orientation === 'horizontal' ? '40%' : '')};
  margin-bottom: ${props => (props.orientation === 'horizontal' ? '20px' : '')};
  display: ${props => (props.orientation === 'horizontal' ? 'flex' : 'block')};
  gap: ${props => (props.orientation === 'horizontal' ? '20px' : '')};
`;

const StyledHeading = styled(Heading)`
  margin-top: ${props => props.orientation === 'horizontal' && '4px'};
`;

const StyledItems = styled.ul`
  margin: 0;
  padding: 0;
  display: ${props => (props.orientation === 'horizontal' ? 'flex' : 'block')};
  justify-content: ${props =>
    props.orientation === 'horizontal' ? 'center' : ''};
  gap: ${props => (props.orientation === 'horizontal' ? '10px' : '')};
`;
const StyledItem = styled.li`
  display: flex;
  margin-bottom: ${theme.space[1]}px;
  i {
    margin-right: 4px;
  }
  .SocialMediaShareButton {
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    &:hover {
      text-decoration: none;
      border-bottom-color: ${theme.colors.baseGrayDark};
    }
  }
`;

const Share = props => {
  const { itemDetails, componentTitle, orientation } = props;
  const { title, description, pageUrl, image } = itemDetails;
  const showTitle = orientation === 'vertical';

  return (
    <StyledShareWrapper orientation={orientation}>
      {
        <StyledHeading size="h6" mb={[1, 2]} orientation={orientation}>
          {componentTitle}
          {!showTitle && ':'}
        </StyledHeading>
      }

      <StyledItems orientation={orientation}>
        <StyledItem>
          <FacebookShareButton url={pageUrl} quote={description} image={image}>
            <i className={`icon icon-fw icon-facebook`} />
            {showTitle && 'Facebook'}
          </FacebookShareButton>
        </StyledItem>
        <StyledItem>
          <TwitterShareButton url={pageUrl} title={title} image={image}>
            <i className={`icon icon-fw icon-twitter`} />
            {showTitle && 'Twitter'}
          </TwitterShareButton>
        </StyledItem>
        <StyledItem>
          <LinkedinShareButton url={pageUrl} title={title} image={image}>
            <i className={`icon icon-fw icon-linkedin`} />
            {showTitle && 'LinkedIn'}
          </LinkedinShareButton>
        </StyledItem>
        <StyledItem>
          <EmailShareButton
            url={pageUrl}
            subject={title}
            body={description}
            image={image}
          >
            <i className={`icon icon-fw icon-email`} />
            {showTitle && 'Email'}
          </EmailShareButton>
        </StyledItem>
      </StyledItems>
    </StyledShareWrapper>
  );
};

export default Share;

Share.propTypes = {
  /** Social media sharing details */
  itemDetails: PropTypes.object,
  /** Heading for Share Component */
  componentTitle: PropTypes.string,
  /** Orientation for Share Component */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

Share.defaultProps = {
  itemDetails: {},
  componentTitle: 'Share',
  orientation: 'vertical',
};
