import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import Tab from './Tab';
import { theme } from '../common/theme';

const Wrapper = styled(Box)`
  overflow-x: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const StyledTabs = styled(Flex)`
  border-bottom: 1px solid ${({ borderColor }) => theme.colors[borderColor]};
  margin-right: -1px;
  width: 100%;
`;

export const colors = {
  black: {
    textColor: 'baseBlack',
    activeBorderColor: 'baseBlack',
    inactiveBorderColor: 'baseGrayVeryLight',
  },
  yellow: {
    textColor: 'brandYellow',
    activeBorderColor: 'brandYellow',
    inactiveBorderColor: 'brandYellowPale',
  },
  red: {
    textColor: 'brandRed',
    activeBorderColor: 'brandRed',
    inactiveBorderColor: 'brandRedPale',
  },
  purple: {
    textColor: 'brandPurple',
    activeBorderColor: 'brandPurple',
    inactiveBorderColor: 'brandPurplePale',
  },
  violet: {
    textColor: 'brandViolet',
    activeBorderColor: 'brandViolet',
    inactiveBorderColor: 'brandVioletPale',
  },
  navy: {
    textColor: 'brandNavy',
    activeBorderColor: 'brandNavy',
    inactiveBorderColor: 'brandNavyPale',
  },
  green: {
    textColor: 'brandGreen',
    activeBorderColor: 'brandGreen',
    inactiveBorderColor: 'brandGreenPale',
  },
};

const TabsContainer = ({ tabs, activeTab, setActiveTab, color }) => {
  const { textColor, activeBorderColor, inactiveBorderColor } = colors[color];

  return (
    <Wrapper id="tabs-wrapper" width={1}>
      <StyledTabs
        data-testid="test-tabs"
        pt={[1]}
        borderColor={activeBorderColor}
      >
        {tabs.map((name, idx) => (
          <Tab
            key={idx}
            idx={idx}
            name={name}
            isActive={activeTab === idx}
            setActiveTab={setActiveTab}
            color={textColor}
            activeBorderColor={activeBorderColor}
            inactiveBorderColor={inactiveBorderColor}
          />
        ))}
      </StyledTabs>
    </Wrapper>
  );
};

TabsContainer.propTypes = {
  /** Array containing tab names */
  tabs: PropTypes.array,
  /** Integer indicating the index of the active tab */
  activeTab: PropTypes.number,
  /** Function to set the active tab index */
  setActiveTab: PropTypes.func,
  /** Defines the main color to be used */
  color: PropTypes.oneOf(Object.keys(colors)),
};

TabsContainer.defaultProps = {
  tabs: [],
  activeTab: 0,
  setActiveTab: () => {},
  color: 'black',
};

export default TabsContainer;
