import React from 'react';
import { Widget, Text } from '@sygnia/components';
import { FormWrapper } from '../modules/FormWrapper';
import { CONTENT_TITLES } from '../common/constants';

const NewsletterWidget = props => {
  const {
    title,
    description,
    section: { formId },
  } = props;

  return (
    <Widget mt={[6]} mb={[4]} title={title} description={description}>
      <Text mb={[3]}>{CONTENT_TITLES.newsletter}</Text>
      <FormWrapper formId={formId} />
    </Widget>
  );
};

NewsletterWidget.defaultProps = {
  title: 'Sign up for our newsletter',
  description: null,
};

export default NewsletterWidget;
