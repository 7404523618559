import React, { useRef, useEffect, useState } from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../common/theme';
import Heading from '../Heading/Heading';
import { TabItem, TabBorder } from '../TabItem';
import { Container } from '../Grid';

const StickyTabs = styled(Flex)`
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const StickyContainer = styled.div`
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  z-index: 70;
  background: ${({ theme, bg }) => {
    return theme.colors[bg];
  }};
  top: 63px;
  @media (min-width: ${theme.breakpoints[0]}) {
    top: 68px;
  }
`;

const getColors = {
  white: {
    color: 'baseBlack',
    bg: 'baseWhite',
  },
  black: {
    color: 'baseWhite',
    bg: 'baseBlack',
  },
  gray: {
    color: 'baseBlack',
    bg: 'baseGrayVeryLight',
  },
  green: {
    color: 'baseBlack',
    bg: 'brandGreenPale',
  },
  purple: {
    color: 'baseBlack',
    bg: 'brandPurplePale',
  },
  violet: {
    color: 'baseBlack',
    bg: 'brandVioletPale',
  },
  red: {
    color: 'baseBlack',
    bg: 'brandRedPale',
  },
  yellow: {
    color: 'baseBlack',
    bg: 'brandYellowPale',
  },
  navy: {
    color: 'baseBlack',
    bg: 'brandNavyPale',
  },
};

/**
    tabs will be a list of objects containing the ids and labels of sections in order
    e.g. {id: "mid-career", label: "Mid-Career"}
*/
const TabAnchors = ({
  tabs,
  colorScheme,
  active,
  setActive,
  wrapInContainer,
}) => {
  const navRef = useRef(null);
  const [contentRefs, setContentRefs] = useState([]);
  const [tabsHeight, setTabsHeight] = useState(0);

  let tabItemRefs = [];
  tabs.forEach(() => tabItemRefs.push(useRef(null)));

  // when component mounts we want to register a scroll event listener
  useEffect(() => {
    setContentRefs(tabs.map(({ id }) => document.getElementById(id)));
  }, []);

  useEffect(() => {
    const scrollCallback = () => {
      for (let i = contentRefs.length - 1; i >= 0; i--) {
        if (
          window.scrollY >=
          contentRefs[i].offsetTop - (tabsHeight + theme.space[5] + 75)
        ) {
          setActive(i);
          break;
        }
      }
    };

    setTabsHeight(document.getElementById('sticky-tabs').clientHeight);
    window.addEventListener('scroll', scrollCallback);
    return () => window.removeEventListener('scroll', scrollCallback);
  }, [contentRefs]);

  const [isIE, setIsIE] = useState(false);
  useEffect(() => {
    setIsIE(/*@cc_on!@*/ false || !!document.documentMode);
  }, []);

  const { color, bg } = getColors[colorScheme];
  const tabsToRender = (
    <Container>
      <StickyTabs
        id="sticky-tabs"
        py={3}
        bg={bg}
        justifyContent="flex-start"
        isSticky={true}
      >
        {contentRefs.map((ref, idx) => (
          <TabItem
            ref={tabItemRefs[idx]}
            key={idx}
            flex="0 0 auto"
            pr={3}
            mr={1}
            color={color}
            alignItems="center"
            isActive={active === idx}
          >
            <Heading
              size="h6"
              color={color}
              onClick={() => {
                if (isIE) {
                  window.scrollTo(
                    0,
                    ref.offsetTop - (tabsHeight + theme.space[5] + 50),
                  );
                } else {
                  window.scrollTo({
                    top: ref.offsetTop - (tabsHeight + theme.space[5] + 50),
                    behavior: 'smooth',
                  });
                  navRef.current.scrollTo({
                    left: tabItemRefs[idx].current.offsetLeft - theme.space[2],
                    behavior: 'smooth',
                  });
                }
              }}
            >
              {tabs[idx].name}
            </Heading>
            <TabBorder
              transition={`transition: all ${theme.speed.default} linear`}
              height={1}
              mt={1}
              isActive={active === idx}
              bg={color}
            />
          </TabItem>
        ))}
      </StickyTabs>
    </Container>
  );

  return wrapInContainer ? (
    <StickyContainer bg={bg} ref={navRef} isSticky={true}>
      {tabsToRender}
    </StickyContainer>
  ) : (
    tabsToRender
  );
};

export default TabAnchors;

TabAnchors.propTypes = {
  /** Array of tabs to render */
  tabs: PropTypes.array,
  /** Color of the text within the tab */
  colorScheme: PropTypes.oneOf(Object.keys(getColors)),
  /** The index of the active tab in the list */
  active: PropTypes.number,
  /** Function to set the active tab index */
  setActive: PropTypes.func,
  /** Should the tabs be sticky? */
  shouldBeSticky: PropTypes.bool,
  /** Should the tabs be wrapped in a container? */
  wrapInContainer: PropTypes.bool,
};

TabAnchors.defaultProps = {
  tabs: [],
  colorScheme: 'gray',
  active: 0,
  setActive: null,
  shouldBeSticky: true,
  wrapInContainer: false,
};
