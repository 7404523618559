import React, { createRef, useEffect, useState } from 'react';
import { Search as SearchInput } from '@sygnia/components';
import { theme } from '@sygnia/components';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import AlgoliaWrapper from '../AlgoliaWrapper';
import { Index, Configure, connectSearchBox } from 'react-instantsearch-dom';
import SearchMenuHits from './SearchMenuHits';
import MenuFooter from '../../MenuFooter';

const Root = styled.div`
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const StyledRelative = styled.div`
  position: relative;
  height: 100%;
`;

const StyledAbsolute = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
`;

const StyledOverflowContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const StyledSearchFooter = styled.div`
  background: #fff;
  border-top: 1px solid ${props => props.theme.colors.baseBlack};
`;

const StyledSearchMenu = styled.div`
  position: fixed;
  top: 0;
  margin-top: 0px;
  bottom: 0;
  right: 0;
  width: 464px;
  background-color: ${props => {
    return props.theme.colors.baseGrayLight;
  }};
  transition: all 0.2s ease-in-out;
  transform: translateX(${props => (props.isOpen ? '0' : '100%')});
  box-shadow: ${props =>
    props.isOpen ? '0 2px 64px 0 rgba(0, 0, 0, 0.15)' : 'none'};
  z-index: 1160;
`;

const CloseIcon = styled(Flex)`
  cursor: pointer;
  font-size: 12px !important;
  z-index: 1;
`;

const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`];
  const detectClickOutside = event => {
    !ref?.current?.contains(event.target) && handler();
  };

  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside);
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside);
    };
  });
};

//const CustomSearchBox = connectSearchBox(SearchInput);

const CustomSearchBox = connectSearchBox(
  ({ refine, onSearchToggle, ...rest }) => {
    return (
      <Flex mb={[1]} justifyContent="space-between" alignItems="center">
        <SearchInput
          type="text"
          placeholder="Search"
          aria-label="Search"
          onChange={e => refine(e.target.value)}
          {...rest}
        />
        <Box py={[1]} px={[1]}>
          <CloseIcon
            color={theme.baseBlack}
            onClick={() => {
              onSearchToggle(false);
            }}
            data-testid="close-search-button"
          >
            <i className="icon icon-close" />
          </CloseIcon>
        </Box>
      </Flex>
    );
  },
);

const SearchMenu = ({ searchRef, isSearchOpen = false, onSearchToggle }) => {
  const ref = createRef();

  const [search, setQuery] = useState({});

  const indices = [
    { name: 'news_date_desc', title: 'News', hitComp: 'NewsHit' },
    { name: 'funds', title: 'Funds', hitComp: 'FundsHit' },
    { name: 'documents_date_desc', title: 'Downloads', hitComp: 'DownloadHit' },
  ];

  useClickOutside(ref, () => {
    setTimeout(() => {
      if (isSearchOpen) {
        onSearchToggle(false);
      }
    }, 200);
  });

  return (
    <>
      <StyledSearchMenu isOpen={isSearchOpen} ref={ref}>
        {isSearchOpen && (
          <Root>
            <AlgoliaWrapper
              disableSync={true}
              indices={indices}
              searchState={search}
              onSearchStateChange={searchInput => {
                setQuery({ ...search, ...searchInput });
              }}
            >
              {' '}
              <Box py={[2]} px={[2]}>
                <CustomSearchBox onSearchToggle={onSearchToggle} />
              </Box>
              <StyledRelative>
                <StyledAbsolute>
                  <StyledOverflowContainer>
                    {indices.map(({ name, title, hitComp }) => (
                      <Index key={name} indexName={name}>
                        <Configure hitsPerPage={5} />
                        <SearchMenuHits view={{ title, hitComp, name }} />
                      </Index>
                    ))}
                  </StyledOverflowContainer>
                </StyledAbsolute>
              </StyledRelative>
              <StyledSearchFooter>
                <MenuFooter />
              </StyledSearchFooter>
            </AlgoliaWrapper>
          </Root>
        )}
      </StyledSearchMenu>
    </>
  );
};

export default SearchMenu;
