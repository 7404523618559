import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import { CircleItem } from './common';
import { theme } from '../common/theme';

const Line = styled(Box)`
  content: '';
  height: 4px;
`;

const CircleGrouping = styled(Flex)`
  position: relative;
`;

const ContainerBox = styled(Box)`
  position: relative;
`;

const SelectionItem = ({
  id,
  showLine,
  activeTab,
  setActiveTab,
  content,
  color,
  width,
}) => {
  return (
    <Flex
      width={showLine ? width / 12 : 0}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      mb={[4]}
    >
      <CircleGrouping flexDirection="column" alignItems="center">
        <CircleItem
          id={id}
          activeId={activeTab}
          content={content}
          color={color}
          setActive={setActiveTab}
        />
      </CircleGrouping>
      {showLine && <Line width={1} bg="baseWhite" />}
    </Flex>
  );
};

SelectionItem.propTypes = {
  id: PropTypes.number.isRequired,
  showLine: PropTypes.bool.isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
  width: PropTypes.number,
};

const StageSelector = ({ activeTab, setActiveTab, tabs, color }) => {
  return (
    <ContainerBox width={1} p={5}>
      <Flex justifyContent="center">
        {tabs.map((option, id) => (
          <SelectionItem
            width={12 / tabs.length}
            key={id}
            id={id}
            showLine={id !== tabs.length - 1}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            content={option}
            color={color}
          />
        ))}
      </Flex>
    </ContainerBox>
  );
};

StageSelector.propTypes = {
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  tabs: PropTypes.array,
  color: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
};

StageSelector.defaultProps = {
  activeTab: -1,
  setActiveTab: null,
  tabs: [],
  color: 'baseWhite',
};

export default StageSelector;
