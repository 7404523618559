import React, { useContext } from 'react';
import { Text, Widget, ProgressBar } from '@sygnia/components';
import { FundPageContext } from '../templates/fund-template';
import { PeopleCardWrapper } from '../modules/PeopleCardWrapper';
import { graphql } from 'gatsby';
import { CONTENT_TITLES } from '../common/constants';
import FundETFDocuments from './funds/FundETFDocuments';

const PROGRESS_TYPES = {
  HIGH: 'High',
  MEDIUM_HIGH: 'Medium High',
  MEDIUM: 'Medium',
  LOW_MEDIUM: 'Low Medium',
  LOW: 'Low',
};

const PROGRESS_OPTIONS = {
  [PROGRESS_TYPES.LOW]: {
    start: '0',
    progress: '20',
    label: 'Low',
    color: 'baseBlack',
    heading: CONTENT_TITLES.riskProfile,
  },
  [PROGRESS_TYPES.LOW_MEDIUM]: {
    start: '20',
    progress: '20',
    label: 'Low Medium',
    color: 'baseBlack',
    heading: CONTENT_TITLES.riskProfile,
  },
  [PROGRESS_TYPES.MEDIUM]: {
    start: '40',
    progress: '20',
    label: 'Medium',
    color: 'baseBlack',
    heading: CONTENT_TITLES.riskProfile,
  },
  [PROGRESS_TYPES.MEDIUM_HIGH]: {
    start: '60',
    progress: '20',
    label: 'Medium High',
    color: 'baseBlack',
    heading: CONTENT_TITLES.riskProfile,
  },
  [PROGRESS_TYPES.HIGH]: {
    start: '80',
    progress: '20',
    label: 'High',
    color: 'baseBlack',
    heading: CONTENT_TITLES.riskProfile,
  },
};
const FundInfoWidget = ({ title, description }) => {
  const { fund } = useContext(FundPageContext.Consumer);
  if (!fund) return null;
  return (
    <>
      <Widget mt={[6]} mb={[4]} title={title} description={description}>
        {fund.riskProfile ? (
          <>
            <Text fontWeight="bold">{CONTENT_TITLES.riskProfile}</Text>
            <ProgressBar {...PROGRESS_OPTIONS[fund.riskProfile]} />
          </>
        ) : null}
        {fund.timeHorizon ? (
          <>
            <Text fontWeight="bold">{CONTENT_TITLES.timeHorizon}</Text>
            <ProgressBar
              start={0}
              progress={fund.timeHorizon * 10}
              label={`${fund.timeHorizon}+ years`}
            />
          </>
        ) : null}
        {fund.launchDate ? (
          <>
            <Text fontWeight="bold">{CONTENT_TITLES.launchDate}</Text>
            <Text mb={[1]}>{fund.launchDate}</Text>
          </>
        ) : null}
        {fund.classLaunchDate ? (
          <>
            <Text fontWeight="bold">{CONTENT_TITLES.classLaunchDate}</Text>
            <Text mb={[1]}>{fund.classLaunchDate}</Text>
          </>
        ) : null}
        {fund.fundSize ? (
          <>
            <Text fontWeight="bold">{CONTENT_TITLES.fundSize}</Text>
            <Text mb={[1]}>{fund.fundSize}</Text>
          </>
        ) : null}
        {fund.regulation28 ? (
          <>
            <Text fontWeight="bold">{CONTENT_TITLES.regulation28}</Text>
            <Text mb={[1]}>{fund.regulation28}</Text>
          </>
        ) : null}
        {fund.benchmark ? (
          <>
            <Text fontWeight="bold">{CONTENT_TITLES.benchmark}</Text>
            <Text mb={[1]}>{fund.benchmark.join(' | ')}</Text>
          </>
        ) : null}
        {fund.productType === 'ETF' ||
        fund.productType === 'Actively Managed ETF' ? (
          <FundETFDocuments fund={fund} />
        ) : null}
      </Widget>
      <Widget
        title={CONTENT_TITLES.portfolioManagers}
        description={description}
      >
        {fund.portfolioManagers &&
          fund.portfolioManagers.map(manager => (
            <PeopleCardWrapper section={manager} isSmall />
          ))}
      </Widget>
    </>
  );
};

FundInfoWidget.defaultProps = {
  title: CONTENT_TITLES.fundOverview,
  description: null,
};

// add graphql fragment
export const fragment = graphql`
  fragment FundInfoParam on ContentfulFunds {
    riskProfile
    timeHorizon
    launchDate(formatString: "DD-MM-YYYY")
    classLaunchDate(formatString: "DD-MM-YYYY")
    fundSize
    regulation28
    benchmark
    hasDownloadAgreement
    pricingMemorandum {
      file {
        url
      }
    }
    latestFinancials {
      file {
        url
      }
    }

    portfolioManagers {
      ...PeopleLayoutFields
    }
  }
`;

export default FundInfoWidget;
