import React from 'react';
import {
  connectStateResults,
  connectHitsPerPage,
} from 'react-instantsearch-dom';
import HitsPerPage from './HitsPerPage';
import Stats from './Stats';
import { Pagination } from '@sygnia/components';

const CustomHitsPerPage = connectHitsPerPage(HitsPerPage);
const StatsResults = connectStateResults(Stats);

const PaginationWrapper = ({
  currentRefinement,
  nbPages,
  refine,
  perPageOptions,
  perPageLimit,
}) => {
  const onPrev =
    currentRefinement !== 1 ? () => refine(currentRefinement - 1) : null;
  const onNext =
    currentRefinement < nbPages ? () => refine(currentRefinement + 1) : null;

  return (
    <Pagination
      dropdownOptions={perPageOptions}
      stats={<StatsResults />}
      hitsPerPage={
        <CustomHitsPerPage
          items={perPageOptions}
          defaultRefinement={perPageLimit}
        />
      }
      onPrev={onPrev}
      onNext={onNext}
    />
  );
};

export default PaginationWrapper;
