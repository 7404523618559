import React from 'react';
import { ShareLanding } from '@sygnia/components';
import { strip_html_tags, mapper } from '../common/utils';
import { SUMMARY_DEFAULTS } from '../common/constants';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

export const ShareLandingWrapper = props => {
  const {
    section: { summary = null, url = SUMMARY_DEFAULTS.url },
  } = props;

  const SUMMARY_MAP = {
    description: ['content', 'childMarkdownRemark', 'excerpt'],
    title: ['title'],
    image: ['image', 'file', 'url'],
  };

  const SHARE_MAP = {
    description: ['description'],
    title: ['title'],
    pageTitle: ['title'],
    image: ['image'],
  };

  const { site } = useStaticQuery(query);
  const { pathname } = useLocation();
  const pageUrl =
    url ||
    `${site.siteMetadata.siteUrl}${pathname}` ||
    site.siteMetadata.websiteUrl;
  const summaryObj = mapper(summary, SUMMARY_MAP);
  const shareObj = mapper(props.section, SHARE_MAP);

  const description = summaryObj.description
    ? strip_html_tags(summaryObj.description)
    : shareObj.description
    ? shareObj.description
    : SUMMARY_DEFAULTS.DESCRIPTION;
  const title = summaryObj.title
    ? `${summaryObj.title} | Sygnia`
    : shareObj.pageTitle
    ? shareObj.pageTitle
    : shareObj.title
    ? `${shareObj.title} | Sygnia`
    : SUMMARY_DEFAULTS.TITLE;
  const image = summaryObj.image
    ? `https:${summaryObj.image}`
    : shareObj.image
    ? `https:${shareObj.image}`
    : SUMMARY_DEFAULTS.IMAGE;

  return (
    <>
      <ShareLanding itemDetails={{ title, description, pageUrl, image }} />
    </>
  );
};

export const fragment = graphql`
  fragment ShareFields on ContentfulShare {
    id
    orientation
    title
    image {
      fluid {
        src
      }
    }
    description {
      description
    }
    page {
      title
      slug
    }
  }
`;

const query = graphql`
  query LandingSEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
