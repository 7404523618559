import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FieldTemplate from '../FieldTemplate';
import { baseStyles } from '../Styles';

const StyledTextArea = styled.textarea`
  ${baseStyles};
`;

export const TextArea = props => {
  let [focused, setFocus] = useState(false);

  return (
    <FieldTemplate {...props} focused={focused}>
      <StyledTextArea
        {...props}
        placeholder={focused ? null : props.placeholder}
        focused={focused}
        onFocus={() => setFocus(!focused)}
        onBlur={() => setFocus(!focused)}
      />
    </FieldTemplate>
  );
};

TextArea.propTypes = {
  /** formType will determine the design of the input */
  formType: PropTypes.oneOf(['default', 'modern']),
  /** Defines a name for the field */
  name: PropTypes.string,
  /** Label displayed above field. */
  label: PropTypes.string,
  /** DefaultValue of field */
  defaultValue: PropTypes.string,
  /** Visible text in field before user enters anything. */
  placeholder: PropTypes.string.isRequired,
  /** Is this a required field? */
  required: PropTypes.bool,
  /** Should the field be disabled? */
  disabled: PropTypes.bool,
  /** Specifies the visible number of lines */
  rows: PropTypes.number,
  /** Specifies the visible width */
  cols: PropTypes.number,
  /** Specifies the maximum number of characters allowed */
  maxLength: PropTypes.number,
  /** Validation message displayed */
  error: PropTypes.string,
  rest: PropTypes.any,
};

TextArea.defaultProps = {
  formType: 'modern',
  name: null,
  label: null,
  defaultValue: null,
  required: false,
  disabled: false,
  rows: 2,
  cols: 20,
  maxLength: undefined,
  error: null,
  rest: {},
};

export default TextArea;
