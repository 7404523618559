import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import Heading from '../Heading/Heading';
import Text from '../Text/Text';

const ListMeta = props => {
  const { meta, as, options, ...rest } = props;

  if (!meta.length) return null;
  const defaultStyle = {
    lineHeight: '1.2',
    size: 'h5',
    textTransform: 'uppercase',
    mb: '1',
  };

  const setOptions = {
    ...defaultStyle,
    ...options,
  };

  const components = {
    heading: Heading,
    text: Text,
  };

  const component = components[as] || as;

  return (
    <Flex {...rest}>
      {meta.map(
        (d, index) =>
          d && createElement(component, ({ key: index }, setOptions), d),
      )}
    </Flex>
  );
};

ListMeta.propTypes = {
  /**  Title of the Card */
  meta: PropTypes.array.isRequired,
  /**  Options for styling the items */
  options: PropTypes.object,
  /**  What should the list render? */
  as: PropTypes.string,
};

ListMeta.defaultProps = {
  as: 'heading',
  meta: [],
  options: {},
  flexDirection: 'column',
};

export default ListMeta;
