import React from 'react';
import { Table } from '@sygnia/components';
import { ProgressBar } from '@sygnia/components';

const TableWrapper = ({ section }) => {
  var he = require('he');

  if (!section.table) return null;

  const {
    table: { columns, rows, hasFooter, hideHeader },
  } = section;

  let footerRows = [];
  if (hasFooter) {
    footerRows = [rows.pop()];
  }

  return (
    <div className="responsive-table">
      <Table.Table>
        {hideHeader !== true ? (
          <Table.Thead>
            <Table.Tr>
              {columns.map(c => (
                <Table.Th key={c.id}>
                  {c.showLabel !== false ? c.label : null}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
        ) : null}
        <tbody>
          {rows.map(row => (
            <>
              <Table.Tr>
                {columns.map(c => {
                  if (c.showAsPercentage) {
                    return (
                      <Table.Td key={`${row.id}-${c.key}`}>
                        <ProgressBar
                          mb={0}
                          start={0}
                          radius={c.radius || '12px'}
                          barColor={'baseGrayLight'}
                          progress={parseFloat(row[c.key])}
                        />
                      </Table.Td>
                    );
                  }

                  return (
                    <Table.Td key={`${row.id}-${c.key}`}>
                      {row[c.key] && typeof row[c.key] === 'string'
                        ? he.decode(row[c.key])
                        : row[c.key]}
                    </Table.Td>
                  );
                })}
              </Table.Tr>
            </>
          ))}
        </tbody>
        {footerRows && footerRows.length ? (
          <Table.Thead>
            {footerRows.map(row => (
              <>
                <Table.Tr>
                  {columns.map(c => (
                    <Table.Th key={`${row.id}-${c.key}`}>{row[c.key]}</Table.Th>
                  ))}
                </Table.Tr>
              </>
            ))}
          </Table.Thead>
        ) : null}
      </Table.Table>
    </div>
  );
};

export default TableWrapper;
