import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaQuery } from '../common/utils';

const HideAtLarge = styled.div`
  ${mediaQuery.small} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.medium} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.large} {
    display: none;
  }
`;

const HideAtMedium = styled.div`
  ${mediaQuery.small} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.medium} {
    display: none;
  }
  ${mediaQuery.large} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
`;

const HideAtMediumAndBelow = styled.div`
  ${mediaQuery.small} {
    display: none;
  }
  ${mediaQuery.medium} {
    display: none;
  }
  ${mediaQuery.large} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
`;

const HideAtMediumAndAbove = styled.div`
  ${mediaQuery.small} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.medium} {
    display: none;
  }
  ${mediaQuery.large} {
    display: none;
  }
`;

const HideAtSmall = styled.div`
  display: none;
  ${mediaQuery.medium} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.large} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
`;

export default function HideAt({ breakpoint, flex, children }) {
  let Component;
  switch (breakpoint) {
    case 'small':
      Component = HideAtSmall;
      break;
    case 'medium':
      Component = HideAtMedium;
      break;
    case 'mediumAndBelow':
      Component = HideAtMediumAndBelow;
      break;
    case 'mediumAndAbove':
      Component = HideAtMediumAndAbove;
      break;
    case 'large':
      Component = HideAtLarge;
      break;
    default:
      Component = React.Fragment;
  }

  return <Component flex={flex}>{children}</Component>;
}

HideAt.propTypes = {
  /** Breakpoint for various viewports */
  breakpoint: PropTypes.array,
  /** Should the display be flex? else set to block */
  flex: PropTypes.string,
  /** Children node wrapper */
  children: PropTypes.node,
};
